<template>
  <v-row>
    <v-col
      cols="12"
      class="'pb-0"
    >
      <v-menu
        ref="menu"
        v-model="dateMenu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            ref="datepickerText"
            v-model="dateRangeText"
            :label="getLabel"
            append-icon="mdi-calendar-range-outline"
            hide-details
            readonly
            outlined
            dense
            clearable
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="dateRange"
          class="datepicker-element"
          first-day-of-week="1"
          range
          no-title
          @input="handleInput"
        >
          <v-spacer />
          <v-btn
            text
            color="primary"
            @click="dateMenu = false"
          >
            {{ $t('base.cancel') }}
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(date)"
          >
            {{ $t('base.ok') }}
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import { formatSqlDate } from '@/global/services/helpers/dates'

export default {
  name: 'DatepickerRange',

  props: {
    label: {
      type: String,
      default: null
    },

    value: {
      type: Object,
      default: () => ({})
    },

    allowOneDate: {
      type: Boolean,
      default: false
    },

    dateRangeType: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      dateRange: [],
      date: null,
      dateMenu: false
    }
  },

  computed: {
    dateRangeText: {
      get () {
        return this.dateRange
          .map(item => formatSqlDate(item))
          .join(' ~ ')
      },

      set (value) {
        this.$emit('input', value || '')
        this.$emit('set-datepicker-filter', value || '')
      }
    },

    getLabel () {
      return this.label ?? this.$t('base.date_range')
    }
  },

  watch: {
    dateRange: {
      deep: true,
      handler () {
        if (this.value && this.allowOneDate) {
          const dateField = this.dateRangeType
          if (dateField in this.value && Object.values(this.value).length === 1) {
            const value = [this.value[dateField], this.value[dateField]]
            this.$emit('set-datepicker-filter', value || '')
          }
        }
      }
    },

    dateMenu (dateMenu) {
      if (!dateMenu && !this.allowOneDate && this.dateRange.length === 1) {
        this.dateRange = [...Object.values(this.value)]
      }
    },

    value: {
      deep: true,
      handler (value) {
        if (value) {
          if (!this.allowOneDate) {
            if (!(
              'from_date' in value &&
              'to_date' in value &&
              !!value.from_date &&
              !!value.to_date
            )) {
              this.$emit('input', null)
              this.dateRange.splice(0)
              return
            }

            this.$set(this.dateRange, 0, value.from_date)
            this.$set(this.dateRange, 1, value.to_date)
          }
        }
        else {
          this.dateRange.splice(0)
          this.$emit('input', value)
        }
      }
    }
  },

  mounted () {
    this.$refs.datepickerText.$el.click()
  },

  methods: {
    handleInput (event) {
      if (event.length === 2 || (event.length === 1 && this.allowOneDate)) {
        this.$emit('set-datepicker-filter', event)
      }
    }
  }
}
</script>
