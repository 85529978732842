var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.anyModifiableColumns)?_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","offset-overflow":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"link":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical ")])]}}],null,false,3617067879)},[_c('v-list',{attrs:{"subheader":"","flat":""}},[_c('v-subheader',[_vm._v(_vm._s(_vm.$t('base.choose_columns')))]),_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.visibleColumns),callback:function ($$v) {_vm.visibleColumns=$$v},expression:"visibleColumns"}},_vm._l((_vm.columns),function(ref,name){
var label = ref.label;
var alwaysVisible = ref.alwaysVisible; if ( alwaysVisible === void 0 ) alwaysVisible = false;
return _c('v-list-item',{key:name,staticClass:"list-item-min-height",attrs:{"value":name,"disabled":alwaysVisible},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.handleLabelClick(name)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"my-1 mr-4"},[_c('v-checkbox',{ref:name,refInFor:true,attrs:{"input-value":active,"disabled":alwaysVisible,"color":"primary"},on:{"change":function (event) { return _vm.setColumnVisibility(name, event); }}})],1),_c('v-list-item-title',{staticClass:"main-font-size",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.handleLabelClick(name)}}},[_vm._v(" "+_vm._s(label)+" ")])]}}],null,true)})}),1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }