<template>
  <l-control position="bottomleft">
    <div
      class="map-type-wrapper"
    >
      <div
        class="map-type-toggle"
        @click="toggleShowMapTypes"
      >
        <div class="toggle-content">
          <span v-if="mapTypesVisible">{{ mapType.text }}</span>
          <template v-else>
            <v-icon>mdi-layers-triple-outline</v-icon>
            <span>{{ $t('base.map') }}</span>
          </template>
        </div>
      </div>
      <div
        v-show="mapTypesVisible"
        class="map-type-options"
      >
        <div
          v-for="(item, i) in mapItems"
          :key="i"
          class="map-type-item"
          :class="{ 'active': item.text === mapType.text }"
          @click="updateMapType(item)"
        >
          <img
            :src="item.preview_image"
            :alt="item.text"
          >
          <p>{{ item.text }}</p>
        </div>
      </div>
    </div>
  </l-control>
</template>

<script>

import { LControl } from 'vue2-leaflet'

export default {
  name: 'MapTypeToggler',

  components: {
    LControl
  },

  props: {
    storeRoute: {
      type: String,
      default: () => ('live-tracking')
    }
  },

  data () {
    return {
      selectedMap: 0,
      mapItems: [],
      mapTypesVisible: false
    }
  },

  computed: {
    viewConfig () {
      return this.$store.getters['satellite-tracking/' + this.storeRoute + '/viewConfig']
    },
    mapType () {
      return this.$store.getters['map-type/getMapType']
    }
  },

  watch: {
    viewConfig: {
      immediate: true,
      deep: true,
      handler (viewConfig) {
        this.mapItems = viewConfig.mapTypeToggler
      }
    }
  },

  methods: {
    toggleShowMapTypes () {
      this.mapTypesVisible = !this.mapTypesVisible
      if (this.mapTypesVisible && this.$parent?.mapObject?._size.x <= 640) {
        document.querySelector('.leaflet-bottom.leaflet-left').style.width = '100%'
        document.querySelector('.leaflet-bottom.leaflet-left .leaflet-control').style.width = '100%'
        document.querySelector('.map-type-wrapper').style.maxWidth = 'calc(100% - 20px)'
        document.querySelector('.map-type-wrapper').style.overflow = 'hidden'
        document.querySelector('.map-type-wrapper .map-type-options').style.overflowX = 'auto'
        document.querySelector('.map-type-options').style.height = '90px'
        document.querySelector('.map-type-toggle').style.height = '90px'
      }
      else {
        document.querySelector('.leaflet-bottom.leaflet-left').style.width = '100%'
        document.querySelector('.leaflet-bottom.leaflet-left .leaflet-control').style.width = '100%'
        document.querySelector('.map-type-wrapper').style.maxWidth = 'calc(100% - 20px)'
        document.querySelector('.map-type-wrapper').style.overflow = 'hidden'
        document.querySelector('.map-type-wrapper .map-type-options').style.overflowX = 'auto'
        document.querySelector('.map-type-options').style.height = '75px'
        document.querySelector('.map-type-toggle').style.height = '75px'
      }
    },

    updateMapType (item) {
      this.$emit('update', item)
      this.mapTypesVisible = false
      document.querySelector('.leaflet-bottom.leaflet-left').style.width = '100%'
      document.querySelector('.leaflet-bottom.leaflet-left .leaflet-control').style.width = '100%'
      document.querySelector('.map-type-wrapper').style.maxWidth = 'calc(100% - 20px)'
      document.querySelector('.map-type-wrapper').style.overflow = 'hidden'
      document.querySelector('.map-type-wrapper .map-type-options').style.overflowX = 'auto'
      document.querySelector('.map-type-options').style.height = '75px'
      document.querySelector('.map-type-toggle').style.height = '75px'
    }
  }
}
</script>

<style lang="scss">
.map-type-wrapper {
  position: relative;
  display: flex;

  .map-type-toggle {
    display: flex;
    width: 75px;
    height: 75px;
    padding: 0.25rem;
    margin-top: auto;
    border: 2px solid #65778C;
    border-radius: 6px;
    background: url('/img/map_types/ecomobile.png') center center no-repeat;
    background-size: cover;
    cursor: pointer;

    .toggle-content {
      display: flex;
      align-items: flex-end;

      span {
        padding-left: 3px;
      }
    }
  }

  .map-type-options {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 75px;
    padding: 0.5rem;
    margin-left: 0.7rem;
    border: 2px solid #65778C;
    border-radius: 6px;
    background: #fff;

    .map-type-item {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 0.5rem;
      cursor: pointer;

      &:hover,
      &.active {
        img {
          border-color: var(--v-blue-base);
        }

        p {
          color: var(--v-blue-base);
        }
      }

      img {
        width: 45px;
        height: 45px;
        border: 2px solid #65778C;
        border-radius: 6px;
      }

      p {
        position: absolute;
        left: 50%;
        bottom: -1rem;
        transform: translateX(-50%);
        margin: 0;
        font-size: 11px;
        text-align: center;
      }
    }
  }
}

@media (max-width: 640px) {
  .leaflet-bottom.leaflet-left {
    width: 100%;

    .leaflet-control {
      width: 100%;
    }
  }

  .map-type-wrapper {
    max-width: calc(100% - 20px);
    overflow: hidden;

    .map-type-options {
      overflow-x: auto;
      height: 75px;
    }

    .map-type-toggle {
      height: 75px;
    }
  }
}
</style>
