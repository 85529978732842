<template>
  <v-autocomplete
    v-model="itemId"
    :hide-details="hideDetails"
    :items="internalItems"
    :label="label"
    :readonly="readonly"
    :clearable="clearable && !readonly"
    :append-icon="!readonly ? '$dropdown' : ''"
    :search-input.sync="itemSearch"
    :error-messages="errorMessages"
    :loading="fetchingData"
    :no-filter="true"
    :outlined="outlined"
    :autofocus="autofocus"
    @focus="autofocus && openMenuAutomatically ? simulateClick($event) : ''"
    :class="[hideDetails ? 'py-3' : '']"
    :dense="dense"
    :solo="solo"
    :flat="flat"
  >
    <template #append-item>
      <v-list-item
        v-if="hasMoreItems"
        class="has-more-items"
        @click="makeSearch(itemSearch ? itemSearch : '', true)"
      >
        <v-list-item-title class="font-italic text-decoration-underline">
          {{ $t('base.got_more_items') }}
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import { debounce, has, map } from 'lodash'
import { api } from '@/global/services/api'

export default {
  name: 'Autocomplete',

  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    openMenuAutomatically: {
      type: Boolean,
      default: false
    },
    solo: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Number, String, Object],
      default: null
    },

    label: {
      type: String,
      required: true
    },

    options: {
      type: Object,
      required: true,
      validator: (options) => {
        return has(options, 'module') && has(options, 'route')
      }
    },

    readonly: {
      type: Boolean,
      default: false
    },

    clearable: {
      type: Boolean,
      default: true
    },

    companyScope: {
      type: Boolean,
      default: false
    },

    includeAll: {
      type: Boolean,
      default: false
    },

    items: {
      type: Array,
      default: () => ([])
    },

    editIds: {
      type: Array,
      default: () => ([])
    },

    errorMessages: {
      type: Array,
      default: () => ([])
    },

    hideDetails: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      itemSearch: null,
      fetchingData: false,
      hasMoreItems: false
    }
  },

  computed: {
    itemId: {
      get () {
        if (typeof this.value === 'object' && this.value !== null) {
          if (this.value.id) {
            return this.value.id
          }
        }
        return this.value
      },

      set (value) {
        this.$emit('input', value || null)
      }
    },

    internalItems: {
      get () {
        return this.items || []
      },

      set (value) {
        this.$emit('update:items', value)
      }
    }
  },

  watch: {
    itemSearch: debounce(function (newVal) {
      this.fetchingData = true
      this.makeSearch(newVal).finally(() => (this.fetchingData = false))
    }, 500),

    editIds: {
      deep: true,
      handler () {
        this.makeSearch(this.itemSearch)
      }
    }
  },

  async created () {
    try {
      this.fetchingData = true
      await this.makeSearch('')
    }
    finally {
      this.fetchingData = false
    }
  },

  methods: {
    async makeSearch (query, allItems = false) {
      let pickedId = this.value ?? null
      let limitResults = true

      if (this.editIds?.length) {
        pickedId = this.editIds
        limitResults = false
      }

      const params = {
        query: query ?? '',
        pickedId: pickedId,
        limitResults: limitResults && !allItems,
        includeAll: this.includeAll || allItems
      }

      const {
        data,
        has_more: hasMore = false
      } = await api()[this.options.module].get(this.options.route, params)

      if (this.companyScope) this.$emit('fetch', data)

      this.internalItems = map(data, ({ id, name }) => ({
        value: !isNaN(id) ? parseInt(id) : id,
        text: name
      }))

      if (this.editIds?.length) {
        const editItems = this.internalItems.filter(item => (this.editIds.includes(item.value)))
        this.$emit('fetch-edit-items', editItems)
      }

      this.hasMoreItems = hasMore
    },
    simulateClick (event) {
      setTimeout(() => {
        event.target.click()
        const inputElement = document.activeElement
        if (event.target.value && event.target.value !== '' && inputElement && typeof inputElement.setSelectionRange === 'function') {
          const textLength = event.target.value.length
          inputElement.setSelectionRange(textLength, textLength)
        }
      }, 240)
    }
  }
}
</script>

<style scoped lang="scss">
.has-more-items {
  cursor: pointer !important;
}
</style>
