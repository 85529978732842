<template>
  <div>
    <can-bus-speedometer
      v-if="tab.canbus_speedometer"
      :namespace="namespace"
    />
    <data-column-field
      v-if="tab.content_type === 'data_column'"
      :tab="tab"
      :module="module"
      :namespace="namespace"
    />
    <data-table-field
      v-if="tab.content_type === 'data_table'"
      :tab="tab"
      :module="module"
      :namespace="namespace"
      :fetching-data="fetchingData"
      :data-table-data="dataTableData"
    />
    <working-hours-field
      v-if="tab.content_type === 'working_hours'"
      :namespace="namespace"
    />
    <simple-table-field
      v-if="tab.content_type === 'simple_table'"
      :tab="tab"
      :namespace="namespace"
    />
    <data-column-additional-fields
      v-if="tab.content_type === 'dynamic_inputs' && additionalDataColumnFields"
      :parent-module="module"
      :parent-namespace="namespace"
      :parent-id="showItem.item.id"
      :additional-data="additionalDataColumnFields"
    />
  </div>
</template>

<script>
import DataColumnField from '@/global/components/show-details/fields/data-column/DataColumnField'
import DataTableField from '@/global/components/show-details/fields/DataTableField'
import WorkingHoursField from '@/global/components/show-details/fields/WorkingHoursField'
import SimpleTableField from '@/global/components/show-details/fields/SimpleTableField'
import CanBusSpeedometer from '@/modules/fleet-module/components/CanBusSpeedometer.vue'
import DataColumnAdditionalFields from '@/global/components/show-details/fields/data-column/DataColumnAdditionalFields'

export default {
  name: 'ShowDetail',

  components: {
    DataColumnField,
    DataTableField,
    WorkingHoursField,
    SimpleTableField,
    CanBusSpeedometer,
    DataColumnAdditionalFields
  },

  props: {
    tab: {
      type: Object,
      default: () => ({}),
      required: true
    },
    module: {
      type: String,
      required: true
    },
    fetchingData: {
      type: Boolean,
      default: false
    },
    dataTableData: {
      type: Object,
      default: () => ({})
    },
    namespace: {
      type: String,
      required: true
    },
    showItem: {
      type: Object,
      required: true
    }
  },

  computed: {

    additionalDataColumnFields () {
      if (this.tab.additional_data) {
        return {
          data: this.showItem.item[this.tab.additional_data.name],
          fields: this.tab.additional_data.fields_to_display,
          label: this.tab.additional_data.label,
          createFormTitle: this.tab.additional_data.create_form_title,
          editFormTitle: this.tab.additional_data.edit_form_title,
          module: this.tab.additional_data.module,
          route: this.tab.additional_data.route,
          is_only_show: this.tab.additional_data.is_only_show,
          show_create_button: 'show_create_button' in this.tab.additional_data ? this.tab.additional_data.show_create_button : true,
          additional_actions: this.tab.additional_data.additional_actions
        }
      }

      return null
    }
  }
}
</script>
