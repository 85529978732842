<template>
  <div class="px-0 pt-0 ma-0">
    <!-- For easier understanding additional data has next structure
     additional_data: Array[]
        0: Object                 <- could be more than one additional data, let say these are comments
          data:                   <- comments data
            0: Object             <- comment 1
              field1.key: value
              field2.key: value
            1: Object             <- comment 2
              same as above
          fields: Object          <- comment fields that needs to be displayed
            field1: Object
              key: value
              label: value
              type: value
            field2: Object
              ...
     -->
    <div>
      <v-data-table
        :headers="getDataTableHeaders(additionalData)"
        :items="additionalData.data"
        class="data-table"
      >
        <template #[`item.status`]="{ item }">
          <td>{{ additionalData.fields.status.value_labels[item.status] }}</td>
        </template>
        <template #[`item.alarm_on`]="{ item }">
          <td
            class="pl-6"
          >
            <v-icon
              v-if="item.alarm_on"
              color="success"
              class="pb-2"
              small
            >
              mdi-check-circle
            </v-icon>
            <v-icon
              v-else
              small
              color="red"
              class="pb-2"
            >
              mdi-close-circle
            </v-icon>
          </td>
        </template>
        <template #[`item.leasing_value_without_tax`]="{ item }">
          <td>{{ item.leasing_value_without_tax }} {{ item.currency.abbreviation }}</td>
        </template>
        <template #[`item.leasing_value_with_tax`]="{ item }">
          <td>{{ item.leasing_value_with_tax }} {{ item.currency.abbreviation }}</td>
        </template>
        <template #[`item.leasing_tax_amount`]="{ item }">
          <td>{{ item.leasing_tax_amount }} {{ item.currency.abbreviation }}</td>
        </template>
        <template #[`item.vehicle_redemption_value`]="{ item }">
          <td>{{ item.vehicle_redemption_value }} {{ item.currency.abbreviation }}</td>
        </template>
        <template #[`item.vehicle_net_value`]="{ item }">
          <td>{{ item.vehicle_net_value }} {{ item.currency.abbreviation }}</td>
        </template>
        <template #[`item.monthly_instalment`]="{ item }">
          <td>{{ item.monthly_instalment }} {{ item.currency.abbreviation }}</td>
        </template>
        <template #[`item.participation_amount`]="{ item }">
          <td>{{ item.participation_amount }} {{ item.currency.abbreviation }}</td>
        </template>
        <template #[`item.exceeded_km_price`]="{ item }">
          <td>{{ item.exceeded_km_price }} {{ item.currency.abbreviation }}</td>
        </template>
        <template #[`item.participiation_amount`]="{ item }">
          <td>{{ item.participiation_amount }} {{ item.currency.abbreviation }}</td>
        </template>
        <template #[`item.interest_rate`]="{ item }">
          <td>{{ item.interest_rate }} %</td>
        </template>
        <template #[`item.type`]="{ item }">
          <td>{{ item.type.label }}</td>
        </template>
        <template #[`item.valid_until`]="{ item }">
          <td>{{ formatSqlDate(item.valid_until) }}</td>
        </template>
        <template #[`item.issued`]="{ item }">
          <td>{{ formatSqlDate(item.issued) }}</td>
        </template>
        <template #[`item.start_date`]="{ item }">
          <td>{{ formatSqlDate(item.start_date) }}</td>
        </template>
        <template #[`item.expiry_date`]="{ item }">
          <td>{{ formatSqlDate(item.expiry_date) }}</td>
        </template>
        <template #[`item.vehicle_pickup_date`]="{ item }">
          <td>{{ formatSqlDate(item.vehicle_pickup_date) }}</td>
        </template>
        <template #[`item.inspection_date`]="{ item }">
          <td>{{ formatSqlDate(item.inspection_date) }}</td>
        </template>
        <template #[`item.input_date`]="{ item }">
          <td>{{ formatSqlDate(item.input_date) }}</td>
        </template>
        <template #[`item.valid_until_date`]="{ item }">
          <td>{{ formatSqlDate(item.valid_until_date) }}</td>
        </template>
        <template #[`item.currency`]="{ item }">
          <td>{{ item.currency.abbreviation }}</td>
        </template>
        <template #[`item.interval`]="{ item }">
          <td>{{ item.interval }} {{ item.unit }}</td>
        </template>
        <template #[`item.start_state`]="{ item }">
          <td
            v-if="item.type.id === serviceTypes.km || item.type.id === serviceTypes.hours"
          >{{ item.start_state }} {{ item.unit }}</td>
          <td
            v-if="item.type.id === serviceTypes.days"
          >{{ formatSqlDate(item.start_state) }}</td>
        </template>
        <template #[`item.archived_state`]="{ item }">
          <td
            v-if="item.type.id === serviceTypes.km || item.type.id === serviceTypes.hours"
          >{{ item.archived_state }} {{ item.unit }}</td>
          <td
            v-if="item.type.id === serviceTypes.days"
          >{{ formatSqlDate(item.archived_state) }}</td>
        </template>
        <template #[`item.current_state`]="{ item }">
          <td
            v-if="item.type.id === serviceTypes.km"
          >{{ showItem.item.odometer }}</td>
          <td
            v-if="item.type.id === serviceTypes.hours"
          >{{ showItem.item.odometer }}</td>
          <td
            v-if="item.type.id === serviceTypes.days"
          >{{ formatSqlDate(item.current_state) }}</td>
        </template>
        <template #[`item.until_next_service`]="{ item }">
          <td
            v-if="item.type.id === serviceTypes.km || item.type.id === serviceTypes.hours"
          >{{ item.start_state + item.interval - showItem.item.odometer_value }} {{ item.unit }}</td>
          <td
            v-if="item.type.id === serviceTypes.days"
          >{{ item.until_next_service }} {{ item.unit }}</td>
        </template>
        <template #[`item.upcoming_service`]="{ item }">
          <td
            v-if="item.type.id === serviceTypes.km || item.type.id === serviceTypes.hours"
          >{{ item.start_state + item.interval }} {{ item.unit }} </td>
          <td
            v-if="item.type.id === serviceTypes.days"
          >{{ formatSqlDate(item.upcoming_service) }}</td>
        </template>
        <template #[`item.images`]="{ item }">
          <v-badge
            :content="Object.keys(item.images).length"
            :color="Object.keys(item.images).length ? '#f3d187' : ''"
            overlap
            offset-x="10"
            offset-y="10"
          >
            <v-icon
              small
              class="mr-2"
              @click.stop="showImages(item)"
            >
              mdi-image-multiple-outline
            </v-icon>
          </v-badge>
        </template>
        <template #[`item.pdf`]="{ item }">
          <v-badge
            :content="Object.keys(item.pdf).length"
            :color="Object.keys(item.pdf).length ? '#f3d187' : ''"
            overlap
            offset-x="10"
            offset-y="10"
          >
            <v-icon
              small
              class="mr-2"
              @click.stop="showPdf(item)"
            >
              mdi-file-document-outline
            </v-icon>
          </v-badge>
        </template>
        <template #[`item.created_at`]="{ item }">
          <td>{{ formatSqlDate(item.created_at) }}</td>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-menu
            v-if="!additionalData.is_only_show"
            offset-y
            offset-overflow
            left
          >
            <template #activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
              >
                mdi-dots-vertical
              </v-icon>
            </template>
            <v-list class="pa-0">
              <v-dialog
                v-if="additionalData.additional_actions"
                v-model="archiveDialog"
                max-width="750px"
              >
                <template #activator="{ on, attrs }">
                  <v-list-item
                    v-bind="attrs"
                    v-on="on"
                    v-if="additionalData.additional_actions && additionalData.additional_actions.type === 'archive'"
                    class="d-flex align-center list-item-min-height"
                    @click="openArchiveDialog(item)"
                  >
                    <v-list-item-icon class="align-self-center my-0 mr-2">
                      <v-icon
                        small
                      >
                        {{ additionalData.additional_actions.icon }}
                      </v-icon>
                    </v-list-item-icon>

                    <v-list-item-title class="main-font-size">
                      {{ additionalData.additional_actions.label || '' }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <v-card class="dialog-card-style">
                  <v-row class="flex justify-space-between ma-0 light-grey">
                    <v-card-title>
                      {{ additionalData.additional_actions.title }}
                    </v-card-title>

                    <v-btn
                      text
                      x-small
                      class="mt-3 mr-2 pa-0 no-background-hover"
                      elevation="0"
                      @click="closeArchiveDialog"
                    >
                      <v-icon
                        color="grey darken-1"
                        size="1.8rem"
                      >
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </v-row>
                  <v-card-subtitle
                    class="mt-4"
                  >
                    {{ additionalData.additional_actions.warning_text }}
                  </v-card-subtitle>
                  <v-card-text>
                    <v-text-field
                      v-if="archiveRowType === serviceTypes.km"
                      :error-messages="validationErrors[
                        'start_state'
                      ]"
                      v-model="archiveStartState"
                      type="number"
                      :label="additionalData.additional_actions.input_label_km"
                      outlined
                      dense
                      suffix="km"
                    />
                    <v-text-field
                      v-if="archiveRowType === serviceTypes.hours"
                      :error-messages="validationErrors[
                        'start_state'
                      ]"
                      v-model="archiveStartState"
                      type="number"
                      :label="additionalData.additional_actions.input_label_h"
                      outlined
                      dense
                      suffix="h"
                    />
                    <v-menu
                      v-if="archiveRowType === serviceTypes.days"
                      :ref="`datepicker_archive_start_state`"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-height="290px"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="archiveStartState"
                          :error-messages="validationErrors[
                            'start_state'
                          ]"
                          append-icon="mdi-calendar-month-outline"
                          :label="additionalData.additional_actions.input_label_d"
                          outlined
                          readonly
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                          @click:clear="createDate['archived_start_state'] = null"
                        />
                      </template>
                      <v-date-picker
                        v-model="archiveStartState"
                        first-day-of-week="1"
                        full-width
                      >
                        <v-spacer />
                        <v-btn
                          text
                          color="primary"
                          @click="$refs['datepicker_archive_start_state'].save(createDate['archived_start_state'])"
                        >
                          {{ $t('base.ok') }}
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                    <v-text-field
                      :error-messages="validationErrors[
                        'note'
                      ]"
                      v-model="archiveNote"
                      type="text"
                      :label="additionalData.additional_actions.note_label"
                      outlined
                      dense
                    />
                  </v-card-text>

                  <v-card-actions
                    class="pa-3 light-grey"
                  >
                    <v-spacer />
                    <v-btn
                      class="mr-4"
                      color="primary"
                      @click="closeArchiveDialog"
                    >
                      {{ $t('base.close') }}
                    </v-btn>
                    <v-btn
                      class="mx-1"
                      type="submit"
                      color="primary"
                      @click="archiveRow"
                    >
                      {{ $t('base.save') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-list-item
                class="d-flex align-center list-item-min-height"
                @click="editItem(item)"
              >
                <v-list-item-icon class="align-self-center my-0 mr-2">
                  <v-icon
                    small
                  >
                    mdi-pencil
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-title class="main-font-size">
                  {{ $t('base.edit') || '' }}
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="additionalData.additional_actions && additionalData.additional_actions.type === 'status'"
                class="d-flex align-center list-item-min-height"
                @click="handleStatusChange(item)"
              >
                <v-list-item-icon class="align-self-center my-0 mr-2">
                  <v-icon
                    small
                  >
                    {{ item.status === 1 ? additionalData.additional_actions.icon_deactivate : additionalData.additional_actions.icon_activate}}
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-title class="main-font-size">
                  {{ item.status === 1 ? additionalData.additional_actions.label_deactivate : additionalData.additional_actions.label_activate}}
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                class="d-flex align-center list-item-min-height"
                @click="deleteItem(item.id)"
              >
                <v-list-item-icon class="align-self-center my-0 mr-2">
                  <v-icon
                    small
                  >
                    mdi-delete
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-title class="main-font-size">
                  {{ $t('base.delete') || '' }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

        </template>
        <template #no-data>
          {{ $t('base.no_data') }}
        </template>
        <template #foot>
          <v-toolbar
            flat
          >
            <!-- Create/edit dialog -->
            <v-dialog
              v-model="dialog"
              max-width="750px"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  v-if="additionalData.show_create_button"
                  class="ml-0 mt-1 add-additional-item"
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  @click="clearForm()"
                >
                  <v-icon
                    left
                    color="success"
                  >
                    mdi-plus-circle
                  </v-icon>
                  {{ $t('base.add_new_row') }}
                </v-btn>
              </template>
              <v-card class="dialog-card-style">
                <v-row class="flex justify-space-between ma-0 light-grey">
                  <v-card-title>
                    {{ 'id' in editedItem
                      ? additionalData.editFormTitle
                      : additionalData.createFormTitle }}
                  </v-card-title>

                  <v-btn
                    text
                    x-small
                    class="mt-3 mr-2 pa-0 no-background-hover"
                    elevation="0"
                    @click="closeDialog"
                  >
                    <v-icon
                      color="grey darken-1"
                      size="1.8rem"
                    >
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-row>

                <v-card-text>
                  <v-container v-if="additionalData.fields">
                    <v-row
                      v-for="field in additionalData.fields"
                      :key="'form_field_' + field.label"
                      class="testing"
                    >
                      <v-col
                        v-if="field.type === 'text' && field.creatable"
                        cols="12"
                      >
                        <v-text-field
                          v-model="editedItem[field.key]"
                          :error-messages="validationErrors[field.key]"
                          :label="field.label"
                        />
                      </v-col>
                      <v-col
                        v-if="field.type === 'number' && field.creatable"
                        cols="12"
                      >
                        <v-text-field
                          v-model="editedItem[field.key]"
                          :error-messages="validationErrors[field.key]"
                          type="number"
                          :label="field.label"
                          outlined
                          dense
                          :suffix="field.suffix ? field.suffix[editedItem.type] : null"
                          @input="clearInputValidationErrors(field.key)"
                          @keyup="changeMonthlyLimit(editedItem[field.key], field.key)"
                        />
                        <v-text-field
                          v-if="field.monthly_limit"
                          :value="monthlyLimit"
                          :label="field.monthly_label"
                          outlined
                          dense
                          disabled
                        />
                      </v-col>

                      <v-col
                        v-if="field.type === 'select' && field.creatable"
                        cols="12"
                      >
                        <v-select
                          v-if="field.select_options_wm && showItem.item.is_working_machine"
                          v-model="editedItem[field.key]"
                          :error-messages="validationErrors[field.key]"
                          :label="field.label"
                          :items="prepareItems(field.select_options_wm)"
                          @input="clearInputValidationErrors(field.key)"
                        />
                        <v-select
                          v-else
                          v-model="editedItem[field.key]"
                          :error-messages="validationErrors[field.key]"
                          :label="field.label"
                          :items="prepareItems(field.select_options)"
                          @input="clearInputValidationErrors(field.key)"
                      />
                      </v-col>
                      <v-col
                        v-if="field.type === 'boolean' && field.creatable"
                        cols="12"
                      >
                        <v-checkbox
                          v-model="editedItem[field.key]"
                          :error-messages="validationErrors[field.key]"
                          :label="field.label"
                          @input="clearInputValidationErrors(field.key)"
                        />
                      </v-col>
                      <v-col
                        v-if="field.type === 'date' && field.creatable"
                        cols="12"
                      >
                        <v-menu
                          v-if="field.type === 'date' && field.creatable"
                          :ref="`datepicker_${field.key}`"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-height="290px"
                        >
                          <template #activator="{ on, attrs }">
                            <v-text-field
                              v-if="editedItem[field.key]"
                              v-model="editedItem[field.key]"
                              :error-messages="validationErrors[field.key]"
                              append-icon="mdi-calendar-month-outline"
                              :label="field.label"
                              outlined
                              readonly
                              dense
                              clearable
                              v-bind="attrs"
                              v-on="on"
                              @click:clear="editedItem[field.key] = null"
                              @input="clearInputValidationErrors(field.key)"
                            />
                            <v-text-field
                              v-else
                              v-model="createFormattedDate[field.key]"
                              :error-messages="validationErrors[field.key]"
                              append-icon="mdi-calendar-month-outline"
                              :label="field.label"
                              outlined
                              readonly
                              dense
                              clearable
                              v-bind="attrs"
                              v-on="on"
                              @click:clear="createDate[field.key] = null"
                              @input="clearInputValidationErrors(field.key)"
                            />
                          </template>
                          <v-date-picker
                            v-if="editedItem[field.key]"
                            v-model="editedItem[field.key]"
                            first-day-of-week="1"
                            full-width
                          >
                            <v-spacer />
                            <v-btn
                              text
                              color="primary"
                              @click="$refs[`datepicker_${field.key}`][0].save(editedItem[field.key])"
                            >
                              {{ $t('base.ok') }}
                            </v-btn>
                          </v-date-picker>
                          <v-date-picker
                            v-else
                            v-model="createDate[field.key]"
                            first-day-of-week="1"
                            full-width
                          >
                            <v-spacer />
                            <v-btn
                              text
                              color="primary"
                              @click="$refs[`datepicker_${field.key}`][0].save(createDate[field.key])"
                            >
                              {{ $t('base.ok') }}
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                        v-if="field.type === 'service_start_state' && field.creatable"
                        cols="12"
                      >
                        <v-text-field
                          v-if="editedItem.type === serviceTypes.km || editedItem.type === serviceTypes.hours"
                          :error-messages="validationErrors[field.key]"
                          v-model="editedItem[field.key]"
                          type="number"
                          :label="field.label"
                          outlined
                          dense
                          min="0"
                          :suffix="field.suffix ? field.suffix[editedItem.type] : null"
                          @input="clearInputValidationErrors(field.key)"
                        />
                        <v-menu
                          v-if="editedItem.type === serviceTypes.days"
                          :ref="`datepicker_${field.key}`"
                          :close-on-content-click="true"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-height="290px"
                        >
                          <template #activator="{ on, attrs }">
                            <v-text-field
                              v-if="editedItem[field.key]"
                              v-model="editedItem[field.key]"
                              :error-messages="validationErrors[field.key]"
                              append-icon="mdi-calendar-month-outline"
                              :label="field.label"
                              outlined
                              readonly
                              dense
                              clearable
                              v-bind="attrs"
                              v-on="on"
                              @click:clear="editedItem[field.key] = null"
                              @input="clearInputValidationErrors(field.key)"
                            />
                            <v-text-field
                              v-else
                              v-model="createFormattedDate[field.key]"
                              :error-messages="validationErrors[field.key]"
                              append-icon="mdi-calendar-month-outline"
                              :label="field.label"
                              outlined
                              readonly
                              dense
                              clearable
                              v-bind="attrs"
                              v-on="on"
                              @click:clear="createDate[field.key] = null"
                              @input="clearInputValidationErrors(field.key)"
                            />
                          </template>
                          <v-date-picker
                            v-if="editedItem[field.key]"
                            v-model="editedItem[field.key]"
                            first-day-of-week="1"
                            full-width
                          >
                            <v-spacer />
                            <v-btn
                              text
                              color="primary"
                              @click="$refs[`datepicker_${field.key}`][0].save(editedItem[field.key])"
                            >
                              {{ $t('base.ok') }}
                            </v-btn>
                          </v-date-picker>
                          <v-date-picker
                            v-else
                            v-model="createDate[field.key]"
                            first-day-of-week="1"
                            full-width
                          >
                            <v-spacer />
                            <v-btn
                              text
                              color="primary"
                            >
                              {{ $t('base.ok') }}
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                        v-if="field.type === 'images' && field.creatable"
                        cols="12"
                      >
                        <v-file-input
                          v-model="editedItem[field.key]"
                          :error-messages="validationErrors[field.key]"
                          :rules="imageInputRules"
                          :label="field.label"
                          accept="image/*"
                          counter
                          multiple
                          small-chips
                          truncate-length="20"
                          @update:error="updateFileErrors($event, field.key)"
                        />
                      </v-col>
                      <v-col
                        v-if="field.type === 'pdf' && field.creatable"
                        cols="12"
                      >
                        <v-file-input
                          v-model="editedItem[field.key]"
                          :error-messages="validationErrors[field.key]"
                          :rules="pdfInputRules"
                          :label="field.label"
                          accept="application/pdf"
                          counter
                          multiple
                          small-chips
                          truncate-length="20"
                          @update:error="updateFileErrors($event, field.key)"
                        />
                      </v-col>
                    </v-row>
                    <v-row v-if="existingImages.length > 0">
                      <v-col>
                        <v-list dense>
                          <v-subheader>{{ $t('fleet/vehicle_comment.existing_images') }}</v-subheader>
                          <v-list-item-group
                            color="primary"
                          >
                            <v-list-item
                              v-for="(image, i) in existingImages"
                              :key="i"
                            >
                              <v-list-item-avatar tile>
                                <v-img
                                  max-height="80"
                                  max-width="80"
                                  :src="image.url"
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title v-text="image.fileName" />
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-btn icon>
                                  <v-icon
                                    color="grey lighten-1"
                                    @click="removeImage(image)"
                                  >
                                    mdi-delete
                                  </v-icon>
                                </v-btn>
                              </v-list-item-action>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-col>
                    </v-row>
                    <v-row v-if="existingPdfs.length > 0">
                      <v-col>
                        <v-list dense>
                          <v-subheader>{{ $t('fleet/vehicle_comment.existing_pdfs') }}</v-subheader>
                          <v-list-item-group
                            color="primary"
                          >
                            <v-list-item
                              v-for="(pdf, i) in existingPdfs"
                              :key="i"
                            >
                              <v-list-item-avatar tile>
                                <v-icon>
                                  mdi-file-document-outline
                                </v-icon>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title v-text="pdf.fileName" />
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-btn icon>
                                  <v-icon
                                    color="grey lighten-1"
                                    @click="removePdf(pdf)"
                                  >
                                    mdi-delete
                                  </v-icon>
                                </v-btn>
                              </v-list-item-action>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions
                  class="pa-3 light-grey"
                >
                  <v-spacer />
                  <v-btn
                    class="mr-4"
                    color="primary"
                    @click="closeDialog"
                  >
                    {{ $t('base.close') }}
                  </v-btn>
                  <v-btn
                    class="mx-1"
                    type="submit"
                    color="primary"
                    @click="saveItem"
                  >
                    {{ $t('base.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- ./ Create edit dialog -->
            <!-- Delete dialog -->
            <v-dialog
              v-model="dialogDelete"
              max-width="500px"
            >
              <v-card class="dialog-card-style">
                <v-row class="flex justify-space-between ma-0">
                  <v-card-title>{{ $t('base.delete_confirmation_message') }}</v-card-title>
                  <v-btn
                    fab
                    x-small
                    error
                    color="error"
                    class="mt-2 mr-3"
                    @click="closeDelete"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-row>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeDelete"
                  >
                    {{ $t('base.close') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteItemConfirm"
                  >
                    {{ $t('base.delete') }}
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- ./ Delete dialog -->
          </v-toolbar>
        </template>
      </v-data-table>
      <!-- Images preview dialog -->
      <v-dialog
        v-model="imagesDialog"
        persistent
        :max-width="$vuetify.breakpoint.mdAndUp ? '50%' : '80%'"
      >
        <v-card>
          <v-card-title />
          <v-card-text>
            <v-carousel
              :hide-delimiters="imagesToDisplay.length === 0"
              :height="imagesToDisplay.length === 0 ? '450' : 'auto'"
              @change="setImageForDownload($event)"
            >
              <v-carousel-item
                v-if="imagesToDisplay.length === 0"
                src="/img/default_images/image-not-found.png"
              />
              <v-carousel-item
                v-for="(image, i) in imagesToDisplay"
                v-else
                :key="i"
                :src="image.original_url"
              >
              </v-carousel-item>
            </v-carousel>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              v-if="imageForDownload"
              color="primary"
              class=""
              :href="imageForDownload"
              download
            >
              <v-icon>
                mdi-download
              </v-icon>
              Preuzmi
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="imagesDialog = false"
            >
              {{ $t('base.close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ./ Images preview dialog -->
    </div>
  </div>
</template>

<script>
import { formatSqlDate } from '@/global/services/helpers/dates'
import { api } from '@/global/services/api'
import { createNamespacedHelpers } from 'vuex'
import { has } from 'lodash'

const { mapGetters } = createNamespacedHelpers('fleet/vehicle')
const SERVICE_TYPE_KM = 1
const SERVICE_TYPE_HOURS = 2
const SERVICE_TYPE_DAYS = 3

export default {
  name: 'DataColumnAdditionalFields',
  props: {
    additionalData: {
      type: Object,
      required: true
    },
    parentNamespace: {
      type: String,
      required: true
    },
    parentModule: {
      type: String,
      required: true
    },
    parentId: {
      type: Number,
      required: true
    },
    isOnlyShow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialog: false,
      archiveDialog: false,
      imagesDialog: false,
      // images that will be shown in carousel preview
      imagesToDisplay: [],
      pdfsToDisplay: [],
      deleteItemId: null,
      dialogDelete: false,
      // images that will be shown in create/edit dialog
      existingImages: [],
      existingPdfs: [],
      editedItem: {},
      validationErrors: {},
      fileErrors: [],
      createDate: {},
      createFormattedDate: {},
      serviceTypes: {},
      monthlyLimit: null,
      archiveStartState: null,
      archiveRowType: null,
      archiveRowItem: {},
      archiveNote: null,
      imageForDownload: null,
      imageInputRules: null,
      pdfInputRules: null
    }
  },

  computed: {
    ...mapGetters(['showItem'])
  },

  watch: {
    createDate: {
      handler (dates) {
        for (const [key, date] of Object.entries(dates)) {
          this.createFormattedDate[key] = date ? formatSqlDate(date) : null
        }
      },
      immediate: true,
      deep: true
    },
    dialog: {
      handler (value) {
        if (value && this.editedItem.status === undefined) {
          this.editedItem.status = true
        }
        if (!value) {
          this.createFormattedDate = {}
          this.resetDatePicker()
        }
      }
    },
    existingImages: {
      handler (images) {
        if (this.additionalData.fields.images) {
          const maxValue = (this.additionalData.fields.images.max_files - images.length) < 0 ? 0 : (this.additionalData.fields.images.max_files - images.length)
          this.imageInputRules = [
            files => !files || !files.some(file => file.size > 10e6) || this.$t('fleet/vehicle_comment.image_size_max_10_mb'),
            files => !files || !files[maxValue] || this.$t('fleet/vehicle_document.max_file_count_reached')
          ]
        }
      }
    },
    existingPdfs: {
      handler (pdf) {
        if (this.additionalData.fields.pdf) {
          const maxValue = (this.additionalData.fields.pdf.max_files - pdf.length) < 0 ? 0 : (this.additionalData.fields.pdf.max_files - pdf.length)
          this.pdfInputRules = [
            files => !files || !files.some(file => file.size > 10e6) || this.$t('fleet/vehicle_comment.image_size_max_10_mb'),
            files => !files || !files[maxValue] || this.$t('fleet/vehicle_document.max_file_count_reached')
          ]
        }
      }
    }
  },

  created () {
    this.serviceTypes.km = SERVICE_TYPE_KM
    this.serviceTypes.hours = SERVICE_TYPE_HOURS
    this.serviceTypes.days = SERVICE_TYPE_DAYS
  },

  methods: {
    resetDatePicker () {
      this.createDate = {}
    },

    setImageForDownload (imageIndex) {
      if (Object.values(this.imagesToDisplay)[imageIndex].original_url) {
        this.imageForDownload = Object.values(this.imagesToDisplay)[imageIndex].original_url
      }
    },

    async handleStatusChange (item) {
      if (item.id) {
        try {
          const formData = new FormData()

          formData.append('_method', 'PATCH')
          formData.append('status', !item.status)

          await api()[this.additionalData.module]
            .post(this.additionalData.additional_actions.route + '/' + item.id, formData)
          this.closeArchiveDialog()
        }
        catch (e) {
          console.log(e)
        }
      }
    },

    async archiveRow () {
      if (this.archiveRowItem.id) {
        try {
          const formData = new FormData()

          formData.append('_method', 'PATCH')

          if (this.archiveStartState) {
            formData.append('start_state', this.archiveStartState)
          }

          formData.append('note', this.archiveNote)

          await api()[this.additionalData.module]
            .post(this.additionalData.additional_actions.route + '/' + this.archiveRowItem.id, formData)
          this.closeArchiveDialog()
        }
        catch (e) {
          this.validationErrors = e.getValidationErrors()
          console.log(e)
        }
      }
    },

    openArchiveDialog (item) {
      this.archiveDialog = true
      this.archiveRowType = item.type.id
      this.archiveRowItem = item
    },

    closeArchiveDialog () {
      this.archiveFormData = {}
      this.archiveDialog = false
    },

    changeMonthlyLimit (value, fieldKey) {
      if (fieldKey === 'yearly_limit') {
        this.monthlyLimit = Number(value / 12).toFixed(2)
      }
    },

    updateFileErrors (value, key) {
      this.fileErrors[key] = value
    },
    formatSqlDate,
    deleteItem (id) {
      this.deleteItemId = id
      this.dialogDelete = true
    },
    prepareItems (items) {
      return items.map(item => {
        return {
          text: item.label ? item.label : item.name,
          value: item.id
        }
      })
    },
    async deleteItemConfirm () {
      try {
        await api()[this.additionalData.module].delete(this.additionalData.route + '/' + this.deleteItemId)
      }
      catch (e) {
        console.log(e)
      }
      await this.reloadItem()
      this.closeDelete()
    },
    closeDelete () {
      this.deleteItemId = null
      this.dialogDelete = false
    },
    clearInputValidationErrors (key) {
      if (has(this.validationErrors, key)) {
        this.validationErrors[key] = []
      }
    },
    async reloadItem () {
      // parentNamespace and id are parameters of the item whose details are displayed,
      // they can be different from additional_fields if there is a separate model from additional_field_item
      // this line refreshes shown(parent) model after deleting additional item
      await this.$store.dispatch(this.parentNamespace + '/fetch', this.parentId)
    },
    getDataTableHeaders (item) {
      const headers = Object.keys(item.fields).map(key => {
        return {
          text: item.fields[key].label,
          value: key
        }
      })

      if (!item.is_only_show) {
        headers.push(
          {
            text: this.$t('base.actions'),
            value: 'actions',
            width: '10%',
            align: 'center',
            sortable: false
          }
        )
      }
      else {
        headers.push(
          {
            text: ' ',
            width: '20%',
            align: 'center',
            sortable: false
          }
        )
      }
      return headers
    },
    showImages (item) {
      this.imagesDialog = true
      this.imagesToDisplay = item.images
    },

    showPdf (item) {
      window.open(Object.values(item.pdf)[0].original_url)
    },

    async removeImage (image) {
      if ('images' in this.additionalData?.fields) {
        try {
          await api()[this.additionalData.fields.images.module]
            .delete(this.additionalData.fields.images.route + '/remove-media/' + image.uuid)
          const index = this.existingImages.indexOf(image)
          if (index > -1) {
            this.existingImages.splice(index, 1)
          }
        }
        catch (e) {
          console.log(e)
        }
      }
    },
    async removePdf (pdf) {
      if ('pdf' in this.additionalData?.fields) {
        try {
          await api()[this.additionalData.fields.images.module]
            .delete(this.additionalData.fields.images.route + '/remove-media/' + pdf.uuid)
          const index = this.existingPdfs.indexOf(pdf)
          if (index > -1) {
            this.existingPdfs.splice(index, 1)
          }
        }
        catch (e) {
          console.log(e)
        }
      }
    },
    editItem (item) {
      this.existingImages = []
      this.existingPdfs = []
      const itemCopy = { ...item }
      if ('images' in itemCopy) {
        Object.keys(itemCopy.images).forEach(key => {
          this.existingImages.push({
            fileName: itemCopy.images[key].file_name,
            uuid: itemCopy.images[key].uuid,
            url: itemCopy.images[key].original_url
          })
        })
        itemCopy.images = []
      }
      if ('pdf' in itemCopy) {
        Object.keys(itemCopy.pdf).forEach(key => {
          this.existingPdfs.push({
            fileName: itemCopy.pdf[key].file_name,
            uuid: itemCopy.pdf[key].uuid,
            url: itemCopy.pdf[key].original_url
          })
        })
        itemCopy.pdf = []
      }

      this.editedItem = Object.assign({}, itemCopy)

      // Iterate through all fields to find select fields (relation field) to handle matching of selected relations
      for (const key in this.additionalData.fields) {
        if (this.additionalData.fields[key].type === 'select') {
          if (itemCopy[key]) {
            this.editedItem[key] = {
              text: itemCopy[key].label ? itemCopy[key].label : itemCopy[key].name,
              value: itemCopy[key].id
            }
          }
        }
      }

      this.dialog = true
    },
    clearForm () {
      this.editedItem = {}
      this.imagesToDisplay = []
      this.existingImages = []
      this.pdfsToDisplay = []
      this.existingPdfs = []
    },
    closeDialog () {
      this.clearForm()
      this.dialog = false
    },
    async saveItem () {
      for (const [key] of Object.entries(this.fileErrors)) {
        if (this.fileErrors[key]) {
          return
        }
      }

      this.$set(this.editedItem, 'parentId', this.parentId)
      if (this.editedItem.id) {
        try {
          const formData = this.getFormData()
          // as we are sending formData we have to use post and inject method in formData
          formData.append('_method', 'PATCH')
          await api()[this.additionalData.module]
            .post(this.additionalData.route + '/' + this.editedItem.id, formData)
          this.closeDialog()
        }
        catch (e) {
          this.validationErrors = e.getValidationErrors()
          console.log(e)
        }
      }
      else {
        try {
          await api()[this.additionalData.module].post(this.additionalData.route, this.getFormData())
          this.closeDialog()
        }
        catch (e) {
          this.validationErrors = e.getValidationErrors()
          console.log(e)
        }
      }
      await this.reloadItem()
    },
    getFormData () {
      const formData = new FormData()
      for (const key in this.editedItem) {
        if (key === 'images') {
          this.editedItem[key].forEach(image => {
            formData.append('images[]', image)
          })
        }
        if (key === 'pdf') {
          this.editedItem[key].forEach(pdf => {
            formData.append('pdf[0]', pdf) // for now [0] is hardcoded if sometimes multiple pdf-s are needed, make this dynamic
          })
        }
        if (typeof this.editedItem[key] === 'object' && this.editedItem[key] !== null && this.editedItem[key].value) {
          formData.append(key, this.editedItem[key].value)
        }
        else if (key === 'status' || key === 'alarm_on') {
          if (this.editedItem[key]) {
            formData.append(key, 1)
          }
          else {
            formData.append(key, 0)
          }
        }
        else formData.append(key, this.editedItem[key] === null ? '' : this.editedItem[key])
      }

      for (const key in this.createDate) {
        formData.append(key, this.createDate[key])
      }

      return formData
    }
  }
}
</script>

<style scoped lang="scss">
tr {
  white-space: nowrap;
  height: 35px !important;
  .long-text-style{
    min-width: 25rem;
    white-space: normal;
  }
  > th {
    border: none!important;
    height: 35px !important;
    &.custom-width {
      white-space: nowrap;
      min-width: 7%;
      width: 10%;
    }
  }
  td {
    border: none !important;
    padding-left: 0.2rem !important;
    height: 35px !important;
    > .add-additional-item {
      font-size: 0.8rem;
      height: 2.2rem !important;
      text-transform: none;
      color: var(--v-primary-darken-5);
      border-color: var(--v-primary-base);

      > span {
        > i {
          font-size: 24px !important;
        }
      }
    }
  }
}

p {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1.2;
}

.dialog-card-style {
  border: 2px solid var(--v-grey-lighten-1-base) !important;
  .v-card__title {
    padding: 10px 8px 8px 16px;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.6);
  }
}

::v-deep .data-table thead th {
  white-space: nowrap;
}
</style>
