import { api } from '@/global/services/api'

const state = {
  user: [],
  viewConfig: {}
}

const getters = {
  user: state => state.user,
  viewConfig: state => state.viewConfig
}

const actions = {
  async fetch ({ commit }) {
    try {
      const { config, data } = await api().base.get('profile')

      commit('SET_USER', data)
      commit('SET_VIEW_CONFIG', config)
    }
    catch (e) {
      console.dir(e)
    }
  }

}

const mutations = {
  SET_USER: (state, payload) => (state.user = payload),
  SET_VIEW_CONFIG: (state, payload) => (state.viewConfig = payload)
}

export default {
  state,
  getters,
  actions,
  mutations
}
