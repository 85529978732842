<template>
  <CenteredLayout background-primary>
    <slot />
  </CenteredLayout>
</template>

<script>
import CenteredLayout from '@/global/layouts/CenteredLayout'

export default {
  name: 'LoginLayout',

  components: {
    CenteredLayout
  }
}
</script>
