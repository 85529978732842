<template>
  <div>
    <v-row class="mt-2 mb-2">
      <v-col class="pa-0">
        <h2 class="mb-0">{{ title }}</h2>
      </v-col>
    </v-row>

    <v-data-table
      v-stripped
      :headers="headers"
      :items="data"
      :show-select="false"
      :disable-pagination="true"
      :hide-default-footer="true"
      class="mx-n2 elevation-0"
    >
      <template #[`item.datetime`]="{ item }">
        {{ formatSqlDate(item.datetime) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { formatSqlDate } from '@/global/services/helpers/dates'

export default {
  name: 'CompletedServicesTable.vue',

  props: {
    title: {
      type: String,
      default: ''
    },

    data: {
      type: Array,
      default: () => ([])
    },

    fields: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    headers () {
      const headers = []
      for (const [field, options] of Object.entries(this.fields)) {
        headers.push({
          text: options.label,
          value: field
        })
      }
      return headers
    }
  },

  methods: {
    formatSqlDate
  }
}
</script>
