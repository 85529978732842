import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

export function install (vueReference) {
  if (!process.env.VUE_APP_BUGSNAG_API_KEY) {
    return
  }

  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()],
    releaseStage: [process.env.NODE_ENV, process.env.VUE_APP_DEVELOPER_ID].filter(Boolean).join('-'),
    redactedKeys: [
      /password/i // Requires more testing, not working correctly
    ]
  })

  Bugsnag.getPlugin('vue').installVueErrorHandler(vueReference)
}

export function notify (exception) {
  Bugsnag.notify(exception)
}
