import { api } from '@/global/services/api'

const initialState = () => {
  return {
    loading: false,
    viewConfig: {},
    selectedVehicle: null,
    selectedDateFrom: null,
    selectedDateTo: null,
    temperatureSensorCurrentData: [],
    assignedTemperatureSensorsBetweenDates: [],
    temperaturesFromDateToDate: null,
    temperaturesFromDateToDatePinData: {},
    temperaturesChartData: null,
    temperaturesChartPinData: {},
    colors: [
      'rgb(126,88,98)',
      'rgb(96,139,169)',
      'rgb(64,164,52)',
      'rgb(190,178,64)'
    ],
    backgroundColors: [
      'rgba(126,88,98, 0.2)',
      'rgba(96,139,169, 0.2)',
      'rgba(64,164,52, 0.2)',
      'rgba(190,178,64, 0.2)'
    ],
    labelTextColors: [
      'rgb(250,135,159)',
      'rgb(143,207,255)',
      'rgb(108,231,94)',
      'rgb(224,213,120)'
    ],
    tooltipTextColors: [
      'rgb(103,72,80)',
      'rgb(84,122,148)',
      'rgb(47,124,39)',
      'rgb(134,127,45)'
    ],
    temperatureRanges: {},
    chartLineToggleStatuses: {
      sensor1: true,
      sensor2: true,
      sensor3: true,
      sensor4: true
    },
    showReferenceValues: false
  }
}

const state = initialState()

const getters = {
  viewConfig: state => state.viewConfig,
  loading: state => state.loading,
  getColors: state => state.colors,
  getBackgroundColors: state => state.backgroundColors,
  getLabelTextColors: state => state.labelTextColors,
  getTooltipTextColors: state => state.tooltipTextColors,
  getSelectedVehicle: state => state.selectedVehicle,
  getSelectedDateFrom: state => state.selectedDateFrom,
  getSelectedDateTo: state => state.selectedDateTo,
  getTemperatureSensorCurrentData: state => state.temperatureSensorCurrentData,
  getAssignedTemperatureSensorsBetweenDates: state => state.assignedTemperatureSensorsBetweenDates,
  getTemperatureRanges: state => state.temperatureRanges,
  getTemperaturesFromDateToDate: state => state.temperaturesFromDateToDate,
  getTemperaturesFromDateToDatePinData: state => state.temperaturesFromDateToDatePinData,
  getTemperaturesChartData: state => state.temperaturesChartData,
  getTemperaturesChartPinData: state => state.temperaturesChartPinData,
  getChartLineToggleStatuses: state => state.chartLineToggleStatuses,
  getShowReferenceValues: state => state.showReferenceValues
}

const actions = {
  async fetchConfig ({ commit, dispatch }) {
    commit('SET_LOADING', true)

    try {
      const { config } = await api()['satellite-tracking'].get('temperature-sensor-graph/config')

      commit('SET_VIEW_CONFIG', config)
      dispatch('map-type/setMapType', config.mapTypeToggler.find(item => item.key === config.selected_map_type) || config.mapTypeToggler[0], { root: true })
    }
    catch (e) {
      console.dir(e)
    }
    finally {
      commit('SET_LOADING', false)
    }
  },

  async fetchAssignedTemperatureSensorsBetweenDates ({ commit, state }, loading = true) {
    if (loading) commit('SET_LOADING', true)

    if (state.selectedVehicle) {
      try {
        const { data } = await api()['satellite-tracking']
          .get('temperature-sensor/assigned-sensors-between-dates-new', {
            vehicle_id: state.selectedVehicle[0].id,
            date_from: state.selectedDateFrom,
            date_to: state.selectedDateTo
          })
        commit('SET_ASSIGNED_TEMPERATURE_SENSORS_BETWEEN_DATES', data)

        // this is used for binding sliders with values as sliders are dynamic
        // Also it is used in component TemperaturesFromDateToDate.vue
        // for checking conditions given by sliders
        if (data && data.length) {
          data.forEach(sensorName => {
            commit('SET_TEMPERATURE_RANGE', { key: sensorName, value: [15, 42] })
          })
        }
      }
      catch (e) {
        console.dir(e)
      }
      finally {
        if (loading) commit('SET_LOADING', false)
      }
    }
    else {
      commit('SET_LOADING', false)
    }
  },

  async fetchCurrentTemperatureSensorData ({ commit, state }, loading = true) {
    try {
      const { data } = await api()['satellite-tracking'].get('temperature-sensor/current-sensors-data', {
        vehicle_id: state.selectedVehicle[0].id
      })

      commit('SET_TEMPERATURE_SENSOR_CURRENT_DATA', data)
    }
    catch (e) {
      console.dir(e)
    }
    finally {
      if (loading) commit('SET_LOADING', false)
    }
  },

  async fetchTemperatureSensorData ({ commit, state, dispatch }) {
    commit('SET_LOADING', true)
    commit('SET_TEMPERATURES_CHART_DATA', {})
    try {
      const { data } = await api()['satellite-tracking'].get('temperature-sensor/report-data-new', {
        vehicle_id: state.selectedVehicle[0].id,
        date_from: state.selectedDateFrom,
        date_to: state.selectedDateTo
      })
      commit('SET_TEMPERATURES_CHART_DATA', data.chart_data)
    }
    catch (e) {
      console.dir(e)
    }
    finally {
      commit('SET_LOADING', false)
    }

    dispatch('fetchCurrentTemperatureSensorData')
  },

  setMapType ({ dispatch }, payload) {
    dispatch('map-type/setMapType', payload, { root: true })
  },

  setSelectedVehicle ({ commit }, vehicle) {
    commit('SET_SELECTED_VEHICLE', vehicle)
  },

  setSelectedDateFrom ({ commit }, date) {
    commit('SET_SELECTED_DATE_FROM', date)
  },

  setSelectedDateTo ({ commit }, date) {
    commit('SET_SELECTED_DATE_TO', date)
  },

  setTemperaturesFromDateToDatePinData ({ commit }, data) {
    commit('SET_TEMPERATURES_FROM_DATE_TO_DATE_PIN_DATA', data)
  },

  setTemperaturesChartPinData ({ commit }, data) {
    commit('SET_TEMPERATURES_CHART_PIN_DATA', data)
  },

  setTemperatureRange ({ commit }, data) {
    commit('SET_TEMPERATURE_RANGE', data)
  },

  setChartLineToggleStatuses ({ commit }, data) {
    commit('SET_CHART_LINE_TOGGLE_STATUSES', data)
  },

  setShowReferenceValues ({ commit }, payload) {
    commit('SET_SHOW_REFERENCE_VALUES', payload)
  },

  resetState ({ commit }) {
    commit('RESET_STATE')
  }
}

const mutations = {
  SET_VIEW_CONFIG: (state, payload) => (state.viewConfig = payload),

  SET_LOADING: (state, payload) => (state.loading = payload),

  SET_ASSIGNED_TEMPERATURE_SENSORS_BETWEEN_DATES: (state, payload) => (
    state.assignedTemperatureSensorsBetweenDates = payload
  ),

  SET_TEMPERATURE_SENSOR_CURRENT_DATA: (state, payload) => (
    state.temperatureSensorCurrentData = payload
  ),

  SET_SELECTED_VEHICLE: (state, payload) => (state.selectedVehicle = payload),

  SET_SELECTED_DATE_FROM: (state, payload) => (state.selectedDateFrom = payload),

  SET_SELECTED_DATE_TO: (state, payload) => (state.selectedDateTo = payload),

  SET_TEMPERATURES_FROM_DATE_TO_DATE_PIN_DATA: (state, payload) => (
    state.temperaturesFromDateToDatePinData = payload
  ),

  SET_TEMPERATURE_RANGE: (state, payload) => (state.temperatureRanges[payload.key] = payload.value),

  SET_TEMPERATURES_FROM_DATE_TO_DATE: (state, payload) => (state.temperaturesFromDateToDate = payload),

  SET_TEMPERATURES_CHART_DATA: (state, payload) => (state.temperaturesChartData = payload),

  SET_TEMPERATURES_CHART_PIN_DATA: (state, payload) => (state.temperaturesChartPinData = payload),

  SET_CHART_LINE_TOGGLE_STATUSES: (state, payload) => (state.chartLineToggleStatuses[payload.key] = payload.value),

  SET_SHOW_REFERENCE_VALUES: (state, payload) => (state.showReferenceValues = payload),

  RESET_STATE: (state) => Object.assign(state, initialState())
}

export default {
  state,
  getters,
  actions,
  mutations
}
