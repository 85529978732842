<template>
  <img
    v-if="iconImage"
    :src="'/img/icons/' + iconImage"
    alt="No status ico"
  >
</template>

<script>

export default {
  name: 'IconImageField',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    config: {
      type: Object,
      required: true
    }
  },
  computed: {
    iconImage () {
      return this.config.icon ?? this.item[this.config.icon_field]
    }
  }
}
</script>
