import locations from '@/modules/locations-module/locations/routes/index.child'

export default {
  path: '/locations',
  component: () => import(/* webpackChunkName: "locations" */ '../views/LocationsView.vue'),
  meta: {
    exclude: false,
    requiresAuth: true
  },
  children: [
    locations
  ]
}
