<template>
  <v-dialog
    v-model="dialogOpened"
    content-class="elevation-0"
    :width="defaultDialogSize[$vuetify.breakpoint.name]"
    persistent
    @keydown.enter.prevent="changeModelCompany"
    @keydown.esc.prevent="closeDialog"
  >
    <v-card>
      <v-row class="flex justify-space-between ma-0 flex-nowrap">
        <v-card-title v-if="changeCompany">
          {{ changeCompany.title }}
        </v-card-title>
        <v-btn
          text
          x-small
          class="mt-3 mr-2 pa-0 no-background-hover"
          elevation="0"
          @click="closeDialog"
        >
          <v-icon
            color="grey darken-1"
            size="1.8rem"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </v-row>

      <v-form @submit.prevent="changeModelCompany">
        <v-card-text>
          <autocomplete
            v-if="changeCompany"
            v-model="companyId"
            :label="changeCompany.text"
            :error-messages="validationErrors.company_id"
            :clearable="true"
            :items.sync="items"
            :options="autocompleteOptions"
            @input="clearInputValidationErrors('company_id')"
          />
        </v-card-text>

        <v-card-actions v-if="editForm">
          <v-spacer />

          <v-btn
            color="primary"
            text
            @click="closeDialog"
          >
            {{ editForm.button_cancel }}
          </v-btn>

          <v-btn
            type="submit"
            color="primary"
            text
          >
            {{ editForm.button_save }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { api } from '@/global/services/api'
import Autocomplete from '@/modules/base-module/autocomplete/components/Autocomplete'
import { has } from 'lodash'

export default {
  components: {
    Autocomplete
  },

  props: {
    openChangeCompanyDialog: {
      type: Boolean,
      default: false,
      required: true
    },

    viewConfig: {
      type: Object,
      default: () => ({}),
      required: true
    },

    changeCompanyModel: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },

  data () {
    return {
      dialogOpened: false,
      title: null,
      dialogTitle: null,
      editForm: {},
      changeCompany: {},
      autocompleteOptions: {},
      defaultDialogSize: { xl: '40%', lg: '40%', md: '50%', sm: '66.66%', xs: '100%' },
      items: [],
      companyId: null,
      validationErrors: {}
    }
  },

  watch: {
    viewConfig: function (viewConfig) {
      if (has(viewConfig, 'change_company_scope')) {
        this.editForm = viewConfig.edit_form
        this.changeCompany = viewConfig.actions.change_company
        this.autocompleteOptions = viewConfig.change_company_scope.autocomplete_options
      }
    },

    changeCompanyModel: function (model) {
      this.companyId = model.company ? model.company.id : null
    },

    openChangeCompanyDialog: function (openChangeCompanyDialog) {
      this.dialogOpened = openChangeCompanyDialog
    }
  },

  methods: {
    closeDialog () {
      this.companyId = null
      this.$emit('close-dialog')
    },

    async changeModelCompany () {
      this.validationErrors = {}

      try {
        const uri = `${this.viewConfig.routeString}/${this.changeCompanyModel.id}/change-company`
        await api().base.patch(uri, { company_id: this.companyId })
        this.$emit('close-dialog')
        this.closeDialog()
      }
      catch (e) {
        if (e.isValidationError) {
          this.validationErrors = e.getValidationErrors()
        }
        else if (e.isForbiddenError) {
          const { title, message } = e.response.data
          const exceptionData = {
            title: title,
            message: message
          }

          this.$emit('exception', exceptionData)

          // this.dialogOpened = false
        }
      }
    },

    clearInputValidationErrors (key) {
      if (has(this.validationErrors, key)) {
        this.validationErrors[key] = []
      }
    }
  }
}
</script>
