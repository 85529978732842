import { JsonProvider } from 'leaflet-geosearch'

export class SearchProvider extends JsonProvider {
  endpoint ({ query, type }) {
    // Result: https://example.com/api/search?q=some%20address&f=json
    return this.getUrl(window.location.origin + '/api/satellite-tracking/map-search', {
      ...this.options,
      q: query
    })
  }

  parse ({ data }) {
    // Note that `data` is the raw result returned by the server. This
    // method should return data in the SearchResult format.
    return data
  }
}
