<template>
  <v-dialog
    v-model="openDialog"
    content-class="elevation-0"
    :width="defaultDialogSize[$vuetify.breakpoint.name]"
    @click:outside="closeExceptionDialog()"
  >
    <v-card>
      <v-row class="flex justify-space-between ma-0">
        <v-card-title>{{ errorTitle }}</v-card-title>

        <v-btn
          text
          x-small
          class="mt-3 mr-2 pa-0 no-background-hover"
          elevation="0"
          @click="closeExceptionDialog"
        >
          <v-icon
            color="grey darken-1"
            size="1.8rem"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </v-row>

      <v-card-text>{{ errorMessage }}</v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue"
          text
          @click="closeExceptionDialog"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ExceptionDialog',

  props: {
    exceptionDialog: {
      type: Boolean,
      required: true,
      default: false
    },
    errorTitle: {
      type: String,
      required: true,
      default: 'Error!'
    },
    errorMessage: {
      type: String,
      required: true,
      default: 'This action is forbidden.'
    }
  },

  data () {
    return {
      openDialog: false,
      defaultDialogSize: { xl: '50%', lg: '50%', md: '50%', sm: '83.33%', xs: '100%' }
    }
  },

  watch: {
    exceptionDialog: function (newVal) {
      this.openDialog = newVal
    }
  },

  methods: {
    closeExceptionDialog () {
      this.$emit('close-exception-dialog')
    }
  }
}
</script>
