// Sourced from https://github.com/vladispavlov/html5-canvas-speedometer
import Fraction from 'fraction.js'

/**
 * Converts angle in degrees to radians.
 * @param {number} deg Angle in degrees.
 * @returns Angle in radians.
 */
function deg2Rad (deg) {
  return deg * (Math.PI / 180)
}

/** Class representing a circle (speedometer, tachometer, etc.) */
class Circle {
  /**
   * Creating a circle with the given params.
   * @param {Object} options Parameters of the circle and contained elements.
   * @param {number} options.circleBorderWidth Circle border width in pixels.
   * @param {string} options.circleBorderColor Circle border color.
   * @param {string} options.circleFillColor Circle fill color.
   * @param {string} options.markFillColor Color fill of mark.
   * @param {string} options.markStrokeColor Color fill of mark border.
   * @param {string} options.markFontColor Color of text mark.
   * @param {number} options.markFontSize Font size of text mark in pixels.
   * @param {string} options.markFontStyle Font style of text mark.
   * @param {string} options.markFontFamily Font family of text mark.
   * @param {string} options.arrowBodyFillColor Color fill of arrow circle in center.
   * @param {string} options.arrowBodyStrokeColor Border color of arrow circle in center.
   * @param {string} options.arrowColor Color of arrow (speedometer, etc).
   * @param {number} options.arrowBaseWidth Arrow base width.
   * @param {number} options.arrowBorderWidth Arrow border width.
   * @param {string} options.dangerColor Color of danger.
   * @param {number} options.dangerZoneWidth Width of danger zone.
   * @param {string} options.turnSignalColor Color of enabled turn signal.
   * @param {string} options.speedUnit Speed unit (km/h, etc).
   * @param {string} options.mileageUnit Mileage unit (km, etc).
   * @param {Object} options.icons Dictionary with icons names as key and icons objects as value.
   * @param {Object} options.turnSignal Dictionary with side of turn signal as key and turn signal object as value.
   */
  constructor (canvas, sprites, options) {
    this.canvas = canvas
    this.ctx = canvas.getContext('2d')
    this.sprites = sprites

    this.circleBorderWidth = options.circleBorderWidth
    this.circleBorderColor = options.circleBorderColor
    this.circleFillColor = options.circleFillColor

    this.markFillColor = options.markFillColor
    this.markStrokeColor = options.markStrokeColor
    this.markFontColor = options.markFontColor
    this.markFontSize = options.markFontSize
    this.markFontStyle = options.markFontStyle
    this.markFontFamily = options.markFontFamily

    this.arrowBodyFillColor = options.arrowBodyFillColor
    this.arrowBodyStrokeColor = options.arrowBodyStrokeColor

    this.arrowColor = options.arrowColor
    this.arrowBaseWidth = options.arrowBaseWidth
    this.arrowBorderWidth = options.arrowBorderWidth

    this.dangerColor = options.dangerColor
    this.dangerZoneWidth = options.dangerZoneWidth

    this.turnSignalColor = options.turnSignalColor

    this.speedUnit = options.speedUnit
    this.mileageUnit = options.mileageUnit

    this.icons = options.icons

    this.turnSignal = options.turnSignal

    this.startAngle = 0
    this.endAngle = Math.PI * 2

    this.radius = canvas.height * 0.35
  }

  /**
   * Helper method. Draws coordinate lines in the center of coordinates.
   */
  drawSupport () {
    this.ctx.beginPath()
    this.ctx.moveTo(this.x, 0)
    this.ctx.lineTo(this.x, this.canvas.height)
    this.ctx.moveTo(0, this.y)
    this.ctx.lineTo(this.canvas.width, this.y)
    this.ctx.closePath()
    this.ctx.lineWidth = 2
    this.ctx.strokeStyle = this.supportColor
    this.ctx.stroke()
  }

  /**
   * Draws circles and their stroke.
   */
  draw () {
    this.ctx.save()
    this.ctx.translate(this.x, this.y)
    this.ctx.beginPath()
    this.ctx.arc(0, 0, this.radius, this.startAngle, this.endAngle)
    this.ctx.closePath()
    this.ctx.fillStyle = this.circleFillColor
    this.ctx.lineWidth = this.circleBorderWidth
    this.ctx.strokeStyle = this.circleBorderColor
    this.ctx.fill()
    this.ctx.stroke()
    this.ctx.restore()
  }

  /**
   * Calculate angles to indicate location of marks.
   * @param {number} count The number of marks for which you need to calculate the angle.
   * @param {number} degAngle Maximum angle (in degrees) for which angle of marks are calculated.
   * @param {number} [startAngle=0] The angle (in degrees) from which to start the calculation.
   * @returns {number[]} Array of calculated angles.
   */
  calcMarksAngles (count, degAngle, startAngle = 0) {
    const addAngle = deg2Rad((degAngle - 180) / 2)
    const radAngle = deg2Rad(degAngle)
    const angles = []

    for (let i = 0; i < count; i++) {
      const angle = ((i * radAngle / (count - 1)) - addAngle - deg2Rad(startAngle)) * (-1)
      angles.push(angle)
    }

    return angles
  }

  /**
   * Draws danger zone on circles.
   * @param {number[]} angles Array of marks angles.
   * @param {number} offset Offset from edge of circle.
   * @param {number} [firstNMarks=0] Number of first marks to fill in danger zone.
   * @param {number} [lastNMarks=0] Number of last marks to fill in danger zone.
   */
  drawDangerZone (angles, offset, firstNMarks = 0, lastNMarks = 0) {
    const radius = this.radius - this.circleBorderWidth - offset

    this.ctx.save()
    this.ctx.translate(this.x, this.y)
    this.ctx.beginPath()

    if (lastNMarks > 0) {
      this.ctx.arc(0, 0, radius, angles[lastNMarks - 1], angles[0], false)
    }
    else if (firstNMarks > 0) {
      this.ctx.arc(0, 0, radius, angles[firstNMarks - 1], angles[0], false)
    }

    this.ctx.lineWidth = this.dangerZoneWidth
    this.ctx.strokeStyle = this.dangerColor
    this.ctx.stroke()
    this.ctx.restore()
  }

  /**
   * Draws specific mark.
   * @param {number} x1 x-coordinate where to start drawing.
   * @param {number} y1 y-coordinate where to start drawing.
   * @param {number} x2 x-coordinate where to finish drawing.
   * @param {number} y2 y-coordinate where to finish drawing.
   * @param {number} lineWidth Width of line.
   * @param {string} strokeStyle Color of line.
   */
  drawMark (x1, y1, x2, y2, lineWidth, strokeStyle) {
    this.ctx.beginPath()
    this.ctx.moveTo(x1, y1)
    this.ctx.lineTo(x2, y2)
    this.ctx.closePath()
    this.ctx.lineWidth = lineWidth
    this.ctx.strokeStyle = strokeStyle
    this.ctx.stroke()
  }

  /**
   * Draws marks on circles.
   * @param {number[]} angles Array of marks angles.
   * @param {number} length Length of marks. Calculated from center of circle, lower value = bigger mark length.
   * @param {number} lineWidth Width of marks.
   * @param {boolean} border Draws border to mark or not.
   * @param {number} [offset=0] Offset from edge of circle.
   * @param {number} [firstNMarks=0] Number of first marks to fill in danger color.
   * @param {number} [lastNMarks=0] Number of last marks to fill in danger color.
   * @param {'even'|'odd'|'all'} [skip=null] 'even' to skip even marks, 'odd' to skip odd marks, 'all' to skip all marks.
   * @param {number} [skipFrom=0] Skip from mark. Use only if param 'skip' is not null. If 'skip' is 'all', it leave marks from.
   * @param {number} [skipTo=0] Skip to mark. Use only if param 'skip' is not null. If 'skip' is 'all', it leave marks from.
   */
  drawMarks (angles, length, lineWidth, border = true, offset = 0, firstNMarks = 0, lastNMarks = 0, skip = null, skipFrom = 0, skipTo = 0) {
    const count = angles.length - 1
    const s = count - firstNMarks
    const e = count - (count - lastNMarks)
    const sf = count - skipFrom
    const st = count - skipTo
    const radius = this.radius - this.circleBorderWidth - offset

    this.ctx.save()
    this.ctx.translate(this.x, this.y)

    for (const angle in angles) {
      if (((skip === 'even') && (angle % 2 === 1)) ||
        ((skip === 'odd') && (angle % 2 === 0)) ||
        (skip === 'all')) {
        if ((sf >= angle) && (angle >= st)) {
          continue
        }
      }

      const x1 = Math.cos(angles[angle]) * radius
      const y1 = Math.sin(angles[angle]) * radius
      const x2 = Math.cos(angles[angle]) * (radius - (radius / length))
      const y2 = Math.sin(angles[angle]) * (radius - (radius / length))

      // draws "border" for main mark
      if (border) {
        this.drawMark(x1, y1, x2, y2, lineWidth + 1, this.markStrokeColor)
      }

      // draws main mark
      if ((lastNMarks > 0 && angle < e) || (firstNMarks > 0 && angle > s)) {
        this.drawMark(x1, y1, x2, y2, lineWidth, this.dangerColor)
      }
      else {
        this.drawMark(x1, y1, x2, y2, lineWidth, this.markFillColor)
      }
    }

    this.ctx.restore()
  }

  /**
   * Calculate angles to indicate location of text marks.
   * @param {number} count The number of marks for which you need to calculate the angle.
   * @param {number} degAngle Maximum angle (in degrees) for which angle of marks are calculated.
   * @param {number} innerOffset Additional increase / decrease (in degrees) of the maximum angle for adjusting the display.
   * @param {number} [startAngle=0] The angle (in degrees) from which to start the calculation.
   * @returns {number[]} Array of calculated angles.
   */
  calcTextMarksAngles (count, degAngle, innerOffset, startAngle = 0) {
    const addAngle = deg2Rad((degAngle + innerOffset - 180) / 2)
    const radAngle = deg2Rad(degAngle + innerOffset)
    const angles = []

    for (let i = -count + 1; i <= 0; i++) {
      const angle = (i * radAngle / (count - 1)) + addAngle + deg2Rad(startAngle)
      angles.push(angle)
    }

    return angles
  }

  /**
   * Draws specific text mark.
   * @param {number} value Value to display in mark.
   * @param {number} x x-coordinate of mark.
   * @param {number} y y-coordinate of mark.
   */
  drawTextMark (value, x, y) {
    this.ctx.font = `${this.markFontStyle} ${this.markFontSize}px ${this.markFontFamily}`
    this.ctx.fillStyle = this.markFontColor
    this.ctx.fillText(value, x, y)
    this.ctx.fill()
  }

  /**
   * Draws all text marks.
   * @param {number[]} angles Array of angles. Used to indicate location of marks.
   * @param {number} step Sets the increment number.
   * @param {number} offset Offset from parent circle (in px).
   * @param {boolean} [fractions=false] Convert numbers to fractions and display as fractions.
   * @param {boolean} [reverse=false] Show text marks in reverse order.
   */
  drawTextMarks (angles, length, step, offset, fractions = false, reverse = false) {
    let value = 0
    let res = value
    const radius = this.radius - this.circleBorderWidth - offset

    if (reverse) {
      angles = angles.reverse()
    }

    this.ctx.save()
    this.ctx.translate(this.x, this.y)

    for (const angle in angles) {
      const x = Math.cos(angles[angle]) * (radius - (radius / length)) - this.textMarksOffsetX
      const y = Math.sin(angles[angle]) * (radius - (radius / length)) + this.textMarksOffsetY

      if (value !== 0 && fractions) {
        const f = new Fraction(value)
        res = `${f.n}/${f.d}`
        this.drawTextMark(res, x, y)
      }
      else {
        this.drawTextMark(value, x, y)
      }

      value += step
    }

    this.ctx.restore()
  }

  /**
   * Draws speedometer arrow body (circle)
   */
  drawArrowBody () {
    this.ctx.save()
    this.ctx.translate(this.x, this.y)
    this.ctx.beginPath()
    this.ctx.arc(0, 0, this.radius / 7.5, 0, Math.PI * 2)
    this.ctx.closePath()
    this.ctx.lineWidth = this.radius / 7.5 / 2.5
    this.ctx.strokeStyle = this.arrowBodyStrokeColor
    this.ctx.fillStyle = this.arrowBodyFillColor
    this.ctx.fill()
    this.ctx.stroke()
    this.ctx.restore()
  }

  /**
   * Draws speedometer arrow.
   * @param {number} value Value of speed. Minimum speed is 0, maximum speed is 1.
   * @param {number} degAngle Maximum angle (in degrees) for which arrow can be turn.
   * @param {number} [offset=0] Offset from edge of circle. Length of arrow.
   * @param {number} [startAngle=0] The angle (in degrees) from which to start the calculation.
   */
  drawArrow (value, degAngle, offset = 0, startAngle = 0) {
    const addAngle = deg2Rad((degAngle * (-1) - 180) / 2)
    const radAngle = deg2Rad(degAngle * (-1))

    const angle = ((value * radAngle) - addAngle - deg2Rad(startAngle)) * (-1)
    const radius = this.radius - this.circleBorderWidth - offset

    this.ctx.save()
    this.ctx.translate(this.x, this.y)
    this.ctx.rotate(angle)
    this.ctx.beginPath()
    this.ctx.moveTo(0, -this.arrowBaseWidth)
    this.ctx.lineTo(radius, 0)
    this.ctx.lineTo(0, this.arrowBaseWidth)
    this.ctx.fillStyle = this.arrowColor
    this.ctx.strokeStyle = this.arrowColor
    this.ctx.lineWidth = this.arrowBorderWidth
    this.ctx.fill()
    this.ctx.stroke()
    this.ctx.rotate(-angle)
    this.ctx.translate(-this.x, -this.y)
    this.ctx.restore()
  }

  /**
   * Draws icon on circle.
   * @param {string} icon Icon name.
   * @param {0|1|2} [state=0] State of icon.
   */
  drawIcon (icon, state = 0) {
    if (state > this.icons[icon][state]) {
      throw new Error(`${this.icons[icon]} has unknown icon state.`)
    }

    if (state === 1 && this.icons[icon].states === 2) {
      state += 1
    }

    this.ctx.save()
    this.ctx.translate(this.x, this.y)
    this.ctx.drawImage(
      this.sprites,
      this.icons[icon].position.x + 150 * state + 10 * state,
      this.icons[icon].position.y,
      150,
      150,
      -this.icons[icon].dimensions.width / 2 + this.icons[icon].offset.x,
      -this.icons[icon].dimensions.height / 2 + this.icons[icon].offset.y,
      this.icons[icon].dimensions.width,
      this.icons[icon].dimensions.height
    )
    this.ctx.restore()
  }

  /**
   * Draws turn signal.
   * @param {number} offsetX Offset in px from center of circle by x-coordinate.
   * @param {number} offsetY Offset in px from center of circle by y-coordinate.
   * @param {number} width Width in px of turn signal icon.
   * @param {number} height Height in px of turn signal icon.
   * @param {boolean} [enabled=false] Enable or disable turn signal.
   */
  drawTurnSignal (offsetX, offsetY, width, height, enabled = false) {
    this.ctx.save()
    this.ctx.translate(this.x, this.y)
    this.ctx.beginPath()
    this.ctx.moveTo(offsetX, offsetY - height + height * 0.25)
    this.ctx.lineTo(offsetX + width - width / 1.35, offsetY - height + height * 0.25)
    this.ctx.lineTo(offsetX + width - width / 1.35, offsetY - height)
    this.ctx.lineTo(offsetX + width - width / 2.5, offsetY - height / 2)
    this.ctx.lineTo(offsetX + width - width / 1.35, offsetY)
    this.ctx.lineTo(offsetX + width - width / 1.35, offsetY - height + height * 0.75)
    this.ctx.lineTo(offsetX, offsetY - height + height * 0.75)
    this.ctx.closePath()
    this.ctx.fillStyle = '#161616'
    if (enabled) {
      this.ctx.fillStyle = this.turnSignalColor
    }
    this.ctx.fill()
    this.ctx.restore()
  }

  /**
   * Draws mileage rectangle and value.
   * @param {number} value Value of mileage.
   * @param {number} offsetX Offset in px from center of circle by x-coordinate.
   * @param {number} offsetY Offset in px from center of circle by y-coordinate.
   * @param {number} width Width in px of mileage rectangle.
   * @param {number} height Height in px of mileage rectangle.
   * @param {number} radius Border radius in px of mileage rectangle.
   */
  drawMileage (value, offsetX, offsetY, width, height, radius) {
    const halfWidth = width / 2
    const halfRadius = radius / 2

    const xPhw = offsetX + halfWidth
    const xMhw = offsetX - halfWidth

    const xMhwPr = xMhw + radius
    const xPhwMr = xPhw - radius

    const xPhwMhr = xPhw - halfRadius
    const xMhwPhr = xMhw + halfRadius

    const yPh = offsetY + height

    const yPr = offsetY + radius

    const yPhr = offsetY + halfRadius

    const yPhMhr = yPh - halfRadius

    const yPhMr = yPh - radius

    this.ctx.save()
    this.ctx.translate(this.x, this.y)
    this.ctx.beginPath()
    this.ctx.moveTo(offsetX, yPh)
    this.ctx.lineTo(xPhwMr, yPh)
    this.ctx.bezierCurveTo(xPhwMr, yPh, xPhwMhr, yPhMhr, xPhw, yPhMr)
    this.ctx.lineTo(xPhw, yPr)
    this.ctx.bezierCurveTo(xPhw, yPr, xPhwMhr, yPhr, xPhwMr, offsetY)
    this.ctx.lineTo(xMhwPr, offsetY)
    this.ctx.bezierCurveTo(xMhwPr, offsetY, xMhwPhr, yPhr, xMhw, yPr)
    this.ctx.lineTo(xMhw, yPhMr)
    this.ctx.bezierCurveTo(xMhw, yPhMr, xMhwPhr, yPhMhr, xMhwPr, yPh)
    this.ctx.closePath()
    this.ctx.fillStyle = '#2b2b2b'
    this.ctx.fill()

    value = `${value} ${this.mileageUnit}`

    this.ctx.font = `normal ${height - 6}px ${this.markFontFamily}`
    this.ctx.fillStyle = this.markFontColor
    this.ctx.fillText(value, halfWidth - this.ctx.measureText(value).width - 4, yPh - 5)
    this.ctx.restore()
  }

  /**
   * Draws unit on circle.
   * @param {number} offsetY Offset in px from center of circle by y-coordinate.
   */
  drawUnit (offsetY) {
    this.ctx.save()
    this.ctx.translate(this.x, this.y)
    this.ctx.font = `${this.markFontStyle} ${this.markFontSize}px ${this.markFontFamily}`
    this.ctx.fillStyle = this.markFontColor
    this.ctx.fillText(this.speedUnit, -this.ctx.measureText(this.speedUnit).width / 2, offsetY - 2)
    this.ctx.restore()
  }

  /**
   * Draws multiplier of tachometer.
   * @param {number} multiplier Multiply by number. 100 * multiplier.
   * @param {number} offsetY Offset in px from center of circle by y-coordinate.
   */
  drawMultiplier (multiplier, offsetY) {
    const value = `x${1000 * multiplier} rpm`

    this.ctx.save()
    this.ctx.translate(this.x, this.y)
    this.ctx.font = `${this.markFontStyle} ${this.markFontSize / 1.75}px ${this.markFontFamily}`
    this.ctx.fillStyle = this.markFontColor
    this.ctx.fillText(value, -this.ctx.measureText(value).width / 2 - 6, offsetY - 2)
    this.ctx.restore()
  }
}

/** Class representing a main circle that are bigger than others and positioned in center.
 * In the current and standard case it is a speedometer.
 */
class MainCircle extends Circle {
  /**
   * Creating a main circle with the given params.
   * @param {Object} options Parameters of the circle and contained elements. Same as in parent class.
   */
  constructor (canvas, sprites, options) {
    super(canvas, sprites, options)
    this.startAngle = deg2Rad(130)
    this.endAngle = deg2Rad(50)
    const cw = canvas.width
    const ch = canvas.height
    this.radius = (ch / 2 +
      (ch / 2 - ch / 2 * Math.sin(deg2Rad(50))) / 2 -
      this.circleBorderWidth)
    this.x = cw / 2
    this.y = (ch / 2 +
      (ch / 2 - this.radius * Math.sin(deg2Rad(50))) -
      this.circleBorderWidth)

    this.textMarksOffsetX = 12
    this.textMarksOffsetY = 0

    this.supportColor = 'red'
  }
}

/** Class representing a additional circle that are lower than main and positioned in one of the sides.
 * In the current and standard case it are tachometer on the one side and gas indicator on the another side.
 */
class AdditionalCircle extends Circle {
  /**
   * Creating a additional circle with the given params.
   * @param {Object} options Parameters of the circle and contained elements. Same as in parent class.
   * @param {'left'|'right'} side The side where the circle will be displayed relative to the main circle.
   * @param {number} mainCircleRadius Radius of the main circle. Need for the correct calculation of the radius of the circle.
   */
  constructor (canvas, sprites, options, side, mainCircleRadius) {
    super(canvas, sprites, options)

    const cw = canvas.width / 2
    const x = cw - (cw -
      mainCircleRadius / 2 -
      this.radius -
      this.circleBorderWidth / 1.25)

    if (side === 'left') {
      this.x = cw - x
    }
    else if (side === 'right') {
      this.x = cw + x
    }
    else {
      throw new Error('Unknown circle side')
    }

    this.y = canvas.height / 2 + this.radius / 2 - this.circleBorderWidth * 2

    this.textMarksOffsetX = 8
    this.textMarksOffsetY = 4

    this.supportColor = 'green'
  }
}

/** Class representing icon. */
class Icon {
  /**
   * Creating a icon with the given params.
   * @param {number} offsetX Offset in px from center of circle by x-coordinate.
   * @param {number} offsetY Offset in px from center of circle by y-coordinate.
   * @param {number} spritePosX Position of icon in px in icons sprite by x-coordinate.
   * @param {number} spritePosY Position of icon in px in icons sprite by y-coordinate.
   * @param {number} width Width of icon.
   * @param {number} height Height of icon.
   * @param {2|3} states States of icon. There are only 3: 1 - Off, 2 - warning, 3 - critical/on. If 2, then only off and on.
   */
  constructor (offsetX, offsetY, spritePosX, spritePosY, width, height, states) {
    this.offset = {
      x: offsetX,
      y: offsetY
    }
    this.position = {
      x: spritePosX,
      y: spritePosY
    }
    this.dimensions = {
      width: width,
      height: height
    }
    this.states = states
  }
}

/** Class representing turn signal icon. */
class TurnSignal {
  /**
   * Creating a turn signal icon with the given params.
   * @param {number} offsetX Offset in px from center of circle by x-coordinate.
   * @param {number} offsetY Offset in px from center of circle by y-coordinate.
   * @param {number} width Width of icon.
   * @param {number} height Height of icon.
   */
  constructor (offsetX, offsetY, width, height) {
    this.offset = {
      x: offsetX,
      y: offsetY
    }
    this.dimensions = {
      width: width,
      height: height
    }
  }
}

/**
 * Clears the canvas. Required when update data on the speedometer.
 */
function clearCanvas (canvas) {
  canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height)
}

/**
 * Draws left circle (tachometer).
 * @param {Object} options Circle options such as colors, font etc. In more detail in the description of the class.
 * @param {number} radius Radius of main circle (speedometer). Required to correctly calculate the radius of a circle.
 * @param {number} value Current value of tachometer. Min is 0 (lowest), max is 1 (highest).
 * @param {Object} iconStates States of icons displayed on circle.
 */
function drawLeftCircle (canvas, sprites, options, radius, value, iconStates) {
  const lCircle = new AdditionalCircle(canvas, sprites, options, 'left', radius)
  lCircle.draw()

  const addMarksAngles = lCircle.calcMarksAngles(8, 140, 300)

  const mainMarksAngles = lCircle.calcMarksAngles(9, 160, 300)

  lCircle.drawDangerZone(addMarksAngles, 6, 0, 2)

  lCircle.drawDangerZone(mainMarksAngles, 6, 0, 2)

  lCircle.drawMarks(addMarksAngles, 7.5, 2, true, 1, 0, 2)

  lCircle.drawMarks(mainMarksAngles, 6, 3, true, 0, 0, 2)

  const textMarksAngles = lCircle.calcTextMarksAngles(9, 160, 5, 300)

  lCircle.drawTextMarks(textMarksAngles, 6, 1, 12.5)

  lCircle.drawIcon('battery', iconStates.battery)
  lCircle.drawIcon('oil', iconStates.oil)
  lCircle.drawIcon('engineFail', iconStates.engineFail)

  lCircle.drawMultiplier(1, -20)

  lCircle.drawArrow(value, 160, 5, 300)

  lCircle.drawArrowBody()
}

/**
 * Draws right circle (gas indicator).
 * @param {Object} options Circle options such as colors, font etc. In more detail in the description of the class.
 * @param {number} radius Radius of main circle (speedometer). Required to correctly calculate the radius of a circle.
 * @param {number} value Current value of gas indicator. Min is 0 (lowest), max is 1 (highest).
 * @param {Object} iconStates States of icons displayed on circle.
 */
function drawRightCircle (canvas, sprites, options, radius, value, iconStates) {
  const rCircle = new AdditionalCircle(canvas, sprites, options, 'right', radius)
  rCircle.draw()

  const addMarksAngles = rCircle.calcMarksAngles(8, 140, 80)

  const mainMarksAngles = rCircle.calcMarksAngles(9, 160, 80)

  rCircle.drawDangerZone(addMarksAngles, 6, 0, 2)

  rCircle.drawDangerZone(mainMarksAngles, 6, 0, 2)

  rCircle.drawMarks(addMarksAngles, 7.5, 2, true, 1, 0, 2, 'all', 0, 5)

  rCircle.drawMarks(mainMarksAngles, 6, 3, true, 0, 0, 2, 'even', 0, 6)

  const textMarksAngles = rCircle.calcTextMarksAngles(3, 160, -5, 80)

  rCircle.drawTextMarks(textMarksAngles, 6, 0.5, 17.5, true, true)

  rCircle.drawIcon('gas', iconStates.gas)
  rCircle.drawIcon('trunk', iconStates.trunk)
  rCircle.drawIcon('bonnet', iconStates.bonnet)
  rCircle.drawIcon('doors', iconStates.doors)

  rCircle.drawArrow(Math.abs(value - 1), 160, 5, 80)

  rCircle.drawArrowBody()
}

/**
 * Draws all circles.
 * @param {number} speedometerValue Current speed.
 * @param {number} tachometerValue Curent engine revs.
 * @param {number} gasValue Current gas tank fullness.
 * @param {number} mileage Current mileage.
 * @param {Object} turnSignals Current state of turn signals.
 * @param {Object} iconStates Current state of icons.
 * @param {String} speedUnit Current state of icons.
 * @param {String} mileageUnit Current state of icons.
 */
export function draw (
  canvas,
  sprites,
  speedometerValue,
  tachometerValue,
  gasValue,
  mileage,
  turnSignals,
  iconStates,
  speedUnit,
  mileageUnit) {
  clearCanvas(canvas)

  options = { ...options, speedUnit: speedUnit, mileageUnit: mileageUnit }

  const mCircle = new MainCircle(canvas, sprites, options)
  drawLeftCircle(canvas, sprites, options, mCircle.radius, tachometerValue, iconStates)
  drawRightCircle(canvas, sprites, options, mCircle.radius, gasValue, iconStates)

  mCircle.draw()

  const addMarksAngles = mCircle.calcMarksAngles(10, 180)

  const mainMarksAngles = mCircle.calcMarksAngles(11, 200)

  mCircle.drawMarks(addMarksAngles, 7.5, 2, true, 4, 0, 0)

  mCircle.drawMarks(mainMarksAngles, 7, 3, true, 3)

  const textMarksAngles = mCircle.calcTextMarksAngles(11, 200, 7.5)

  mCircle.drawTextMarks(textMarksAngles, 7, 20, 22.5)

  mCircle.drawTurnSignal(35, -25, 25, 15, turnSignals.right)
  mCircle.drawTurnSignal(-35, -25, -25, 15, turnSignals.left)

  mCircle.drawIcon('dippedBeam', iconStates.dippedBeam)
  mCircle.drawIcon('brake', iconStates.brake)
  mCircle.drawIcon('drift', iconStates.drift)
  mCircle.drawIcon('highBeam', iconStates.highBeam)
  mCircle.drawIcon('lock', iconStates.lock)
  mCircle.drawIcon('seatBelt', iconStates.seatBelt)
  mCircle.drawIcon('engineTemp', iconStates.engineTemp)
  mCircle.drawIcon('stab', iconStates.stab)
  mCircle.drawIcon('abs', iconStates.abs)

  mCircle.drawMileage(mileage, 0, 60, 100, 20, 2)

  mCircle.drawUnit(-25)

  mCircle.drawArrow(speedometerValue, 200, 10)

  mCircle.drawArrowBody()
}

const icons = {
  // main circle
  dippedBeam: new Icon(-22.5, 47.5, 10, 150 * 0 + 10 * 1, 17.5, 17.5, 2),
  brake: new Icon(-80, 47.5, 10, 150 * 1 + 10 * 2, 17.5, 17.5, 2),
  drift: new Icon(0, 47.5, 10, 150 * 2 + 10 * 3, 17.5, 17.5, 2),
  highBeam: new Icon(-45, 47.5, 10, 150 * 4 + 10 * 5, 17.5, 17.5, 2),
  lock: new Icon(80, 47.5, 10, 150 * 6 + 10 * 7, 17.5, 17.5, 2),
  seatBelt: new Icon(45, 47.5, 10, 150 * 8 + 10 * 9, 17.5, 17.5, 2),
  engineTemp: new Icon(62.5, 70, 10, 150 * 10 + 10 * 11, 17.5, 17.5, 3),
  stab: new Icon(22.5, 47.5, 10, 150 * 12 + 10 * 13, 17.5, 17.5, 2),
  abs: new Icon(-62.5, 70, 10, 150 * 14 + 10 * 15, 17.5, 17.5, 2),
  // right circle
  gas: new Icon(5, 55, 10, 150 * 3 + 10 * 4, 17.5, 17.5, 3),
  trunk: new Icon(22.5, 25, 10, 150 * 7 + 10 * 8, 17.5, 17.5, 2),
  bonnet: new Icon(-5, 25, 10, 150 * 11 + 10 * 12, 17.5, 17.5, 2),
  doors: new Icon(-17.5, 47.5, 10, 150 * 15 + 10 * 16, 17.5, 17.5, 2),
  // left circle
  battery: new Icon(17.5, 50, 10, 150 * 5 + 10 * 6, 17.5, 17.5, 3),
  oil: new Icon(5, 32.5, 10, 150 * 9 + 10 * 10, 17.5, 17.5, 3),
  engineFail: new Icon(-10, 50, 10, 150 * 13 + 10 * 14, 17.5, 17.5, 3)
}

const turnSignals = {
  left: new TurnSignal(-17.5, -40, 20, 15),
  right: new TurnSignal(17.5, -40, 20, 15)
}

let options = {
  circleBorderWidth: 4,
  circleBorderColor: '#8b8b8b',
  circleFillColor: '#000000',
  markFillColor: '#ffffff',
  markStrokeColor: '#000000',
  markFontColor: '#ffffff',
  markFontSize: '14',
  markFontStyle: 'italic',
  markFontFamily: 'Arial, sans-serif',
  arrowBodyFillColor: '#0d0d0d',
  arrowBodyStrokeColor: '#212121',
  arrowColor: '#ff0000',
  arrowBaseWidth: 2.5,
  arrowBorderWidth: 3,
  dangerColor: '#c1272d',
  dangerZoneWidth: 5,
  turnSignalColor: '#57d53f',
  speedUnit: 'km/h',
  mileageUnit: 'Km',
  icons: icons,
  turnSignal: turnSignals
}
