<template>
  <l-map
    ref="map"
    :options="newMapOptions"
    :style="mapStyle"
    :max-zoom="maxZoom"
    :zoom="zoom"
    :center="center"
    :class="mapClass"
    @update:center="updateCenter"
    @update:zoom="updateZoom"
    @update:bounds="updateBounds"
    @dragstart="emitHandleStartEvent"
    @dragend="emitHandleEndEvent"
    @click="handleMapClickEvent"
    @move="onMapMove"
  >
    <l-control-zoom
      v-if="showZoomControls"
      position="topleft"
    />
    <l-control
      v-show="showCopyControl"
      position="topleft"
      style="z-index: 801;"
    >
      <div
        class="tooltip-container"
        @click="handleIconClick"
        @mouseover="isTooltipVisible = true"
        @mouseleave="isTooltipVisible = false"
        @mousemove="updateTooltipPosition"
      >
        <i
          id="copy-icon"
          class="mdi mdi-content-copy"
          :style="{ color: textColor }"
          @mouseover="changeCopyIconColorOnHover"
          @mouseleave="resetCopyIconColor"
        />
        <div
          v-if="isTooltipVisible"
          :style="{ top: copyIconTooltipY + 'px', left: copyIconTooltipX + 'px' }"
          class="tooltip"
        >
          {{ copyIconTooltipContent }}
        </div>
      </div>
    </l-control>
    <map-tile-layers />
    <map-geo-search
      v-if="getMapType.type && showGeoSearchControl"
      :map-type="getMapType.type"
      :custom-options="mapGeoSearchCustomOptions"
    />
    <map-type-toggler
      v-if="showMapLayersToggler"
      @update="updateMapType"
    />
    <l-marker
      v-for="marker in markers"
      :key="'marker-key-' + marker.coordinate"
      :icon="marker.icon"
      :options="{ key: marker.key }"
      :draggable="marker.draggable"
      :lat-lng="marker.coordinate"
      @dragend="emitMarkerDragEnd"
      @dragstart="emitMarkerDragStart"
    >
      <l-tooltip v-if="marker.tooltip">
        {{ marker.tooltip }}
      </l-tooltip>
    </l-marker>
    <l-marker
      v-if="copyMarker"
      :key="'marker-key-' + copyMarker.key"
      :icon="copyMarker.icon"
      :options="{ key: copyMarker.key }"
      :draggable="copyMarker.draggable"
      :lat-lng="copyMarker.coordinate"
      @dragend="emitMarkerDragEnd"
      @dragstart="emitMarkerDragStart"
    >
      <l-tooltip v-if="copyMarker.tooltip">
        {{ copyMarker.tooltip }}
      </l-tooltip>
    </l-marker>
    <l-circle
      v-for="radiusVal in radiusValues"
      :key="'radius-key-' + radiusVal.coordinate"
      :lat-lng="radiusVal.coordinate"
      :radius="radiusVal.radius"
      :options="radiusVal.options"
    />
    <l-control
      v-show="$slots.controls"
      position="topright"
      class="mr-0"
    >
      <slot name="controls" />
    </l-control>
    <slot name="mapContent" />
    <v-overlay
      :value="showMapLoader"
      :class="{ 'map-loader': showMapLoader }"
    >
      <v-progress-circular
        indeterminate
        size="60"
      />
    </v-overlay>
    <l-control
      v-if="mapOptionsPickerConfig && !isEmpty(mapOptionsPickerConfig)"
      id="mapPicker"
      style="margin: 0 !important; padding: 0 !important;"
      @click.stop
    >
      <map-options-picker
        :map-options-picker-config="mapOptionsPickerConfig"
        :map-options-show-loading-indicators="mapOptionsShowLoadingIndicators"
        :adjust-map-options-fields="adjustMapOptionsFields"
        :initial-map-option-values="initialMapOptionValues"
        @map-options-updated="updatedOptions => $emit('map-options-updated', updatedOptions)"
      />
    </l-control>
  </l-map>
</template>

<script>
import 'leaflet.markercluster'
import 'leaflet.marker.slideto'
import 'leaflet/dist/leaflet.css'
import 'dayjs/locale/hr'
import L, { icon } from 'leaflet'
import { LCircle, LControl, LControlZoom, LMap, LMarker, LTooltip } from 'vue2-leaflet'
import 'leaflet-polylinedecorator'
import MapTypeToggler from '@/global/components/map/MapTypeToggler'
import { createNamespacedHelpers } from 'vuex'
import mapGeolocationsMixin from '@/global/mixins/mapGeolocationsMixin'
import MapTileLayers from '@/global/components/map/tile-layers/MapTileLayers'
import { defaultZagrebCenter, defaultMapOptions, companyScopeGeolocation, companyGeolocation } from '@/global/common/mapConfig'
import MapGeoSearch from '@/global/components/map/MapGeoSearch'
import { isEmpty } from 'lodash'
import MapOptionsPicker from './map-options-picker/MapOptionsPicker.vue'

const { mapActions: mapActionsTracking } = createNamespacedHelpers('satellite-tracking/live-tracking')
const { mapGetters: mapGettersConfig } = createNamespacedHelpers('base/config')
const { mapGetters: mapGettersMapType } = createNamespacedHelpers('map-type')
const { mapGetters: mapGettersTrackingHistory } = createNamespacedHelpers('satellite-tracking/tracking-history')

delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: '/img/icons/icon_sensor_start.svg',
  iconUrl: '/img/icons/icon_sensor_start.svg',
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

export default {
  name: 'MapBase',

  components: {
    MapOptionsPicker,
    LMap,
    LControlZoom,
    LControl,
    LMarker,
    MapTileLayers,
    MapTypeToggler,
    MapGeoSearch,
    LTooltip,
    LCircle
  },

  mixins: [mapGeolocationsMixin],

  props: {
    mapOptions: {
      type: Object,
      default: () => ({
        ...defaultMapOptions
      })
    },
    mapStyle: {
      type: Object,
      default: null
    },
    updateCenter: {
      type: Function,
      default: () => function () {}
    },
    updateZoom: {
      type: Function,
      default: () => function () {}
    },
    updateBounds: {
      type: Function,
      default: () => function () {}
    },
    mapGeoSearchCustomOptions: {
      type: Object,
      default: null
    },
    markers: {
      type: Array,
      default: () => []
    },
    radiusValues: {
      type: Array,
      default: () => []
    },
    showMapLayersToggler: {
      type: Boolean,
      default: true
    },
    showGeoSearchControl: {
      type: Boolean,
      default: true
    },
    showZoomControls: {
      type: Boolean,
      default: true
    },
    showCopyControl: {
      type: Boolean,
      default: true
    },
    zoom: {
      type: Number,
      default: null
    },
    center: {
      type: L.LatLng,
      default: null
    },
    showUserLocationsValue: {
      type: Boolean,
      default: false
    },
    showPartnerLocationsValue: {
      type: Boolean,
      default: false
    },
    invalidateMapSize: {
      type: Boolean,
      default: false
    },
    mapClass: {
      type: String,
      default: 'mapBase'
    },
    customRadiusOptions: {
      type: Object,
      default: () => ({
        color: '#3388ff',
        fillColor: '#3388ff',
        fillOpacity: 0.2
      })
    },
    zoomOn: {
      type: Array,
      default: null,
      validator (positions) {
        return Array.isArray(positions) && positions.every(position => position instanceof L.LatLng)
      }
    },
    mapOptionsPickerConfig: {
      type: Object,
      default: () => {}
    },
    mapOptionsShowLoadingIndicators: {
      type: Array,
      default: () => []
    },
    adjustMapOptionsFields: {
      type: Array,
      default: () => []
    },
    initialMapOptionValues: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      showTooltip: false,
      activeTooltip: null,
      isTooltipVisible: false,
      copyIconTooltipX: 0,
      copyIconTooltipY: 0,
      copyIconTooltipContent: this.$t('map_base.copy_icon_tooltip_content_enable'),
      mapCopyTooltipContent: this.$t('map_base.map_copy_tooltip_content'),
      mapCopiedTooltipContent: this.$t('map_base.map_copied_tooltip_content'),
      copyMarker: null,
      copyCoordinatesTooltipTimeoutId: 0,
      copiedCoordinatesTooltipTimeoutId: 0,
      textColor: 'gray',
      markersClusterGroup: null,
      polylineGroup: null,
      polygonGroup: null,
      maxZoom: null,
      isMapReady: false,
      onMapReadyCallbacks: [],
      showMapLoader: true,
      newMapOptions: {}
    }
  },

  computed: {
    ...mapGettersConfig(['user', 'companyScope']),
    ...mapGettersMapType(['getMapType']),
    ...mapGettersTrackingHistory(['checkboxes']),

    showUserLocations () {
      return this.showUserLocationsValue
    },

    showPartnerLocations () {
      return this.showPartnerLocationsValue
    }
  },

  watch: {
    invalidateMapSize (shouldInvalidateMapSize) {
      if (shouldInvalidateMapSize) {
        this.getMapObject()?.invalidateSize()
      }
    },
    mapOptions: {
      immediate: true,
      deep: true,
      handler (newOptions) {
        if (newOptions && !isEmpty(newOptions)) {
          this.newMapOptions = { ...defaultMapOptions, ...newOptions }
        }
      }
    }
  },

  async created () {
    this.onMapReadyCallbacks = []
    await this.fetch()
    this.$nextTick(() => {
      this.getMapObject()?.invalidateSize()
    })
  },

  beforeDestroy () {
    this.getMapObject()?.off()
    this.isMapReady = false
    this.onMapReadyCallbacks = []
  },

  /**
   * Wait for the map to finish loading before it can be utilized.
   */
  mounted () {
    const that = this
    this.onMapReadyCallbacks = []
    this.getMapObject()?.whenReady(function () {
      that.isMapReady = true
      that.initializeLocationLayerGroups()
      that.emitSetPositionRadiusEvent()
      document.querySelector('.' + that.mapClass).style.cursor = 'default'

      that.mapReady(that)
    })
    this.$nextTick(() => {
      const elem = L.DomUtil.get('mapPicker')
      if (elem) {
        L.DomEvent.on(elem, 'mousewheel', L.DomEvent.stopPropagation)
      }
      // Detecting user manual zoom with wheel-scroll on PC
      const zoomInElement = document.querySelector('a.leaflet-control-zoom-in')
      const zoomOutElement = document.querySelector('a.leaflet-control-zoom-out')
      const mapElement = document.querySelector('.vue2leaflet-map')

      if (zoomInElement) {
        L.DomEvent.addListener(zoomInElement, 'click', function () {
          that.$emit('zoom-control-click')
        })
      }

      if (zoomOutElement) {
        L.DomEvent.addListener(zoomOutElement, 'click', function () {
          that.$emit('zoom-control-click')
        })
      }

      if (mapElement) {
        L.DomEvent.addListener(mapElement, 'wheel', function (e) {
          that.$emit('mouse-wheel', e)
        })
      }

      // Detecting user manual zoom with 2 fingers on MOBILE
      const mapContainer = that.getMapObject()?.getContainer()

      mapContainer.addEventListener('touchmove', (e) => {
        if (e.touches.length === 2) {
          that.$emit('zoom-control-click')
        }
      })
    })
  },

  methods: {
    isEmpty,
    ...mapActionsTracking(['fetch', 'setMapType']),

    /**
     * This function will execute when the map is ready for use.
     * The 'map' parameter represents the current component instance ('this').
     * Once the map is ready, it will execute methods that were called before the map was ready.
     * @param map
     */
    mapReady (map) {
      // If the map were to be somehow destroyed at this moment (even though it shouldn't), halt the map loading indicator, clean map ready variables and take no further action
      if (!this.getMapObject()) {
        this.showMapLoader = false
        this.isMapReady = false
        this.onMapReadyCallbacks = []
        return
      }

      // If callbacks are available, execute them, otherwise set center and zoom on map
      if (this.onMapReadyCallbacks && this.onMapReadyCallbacks.length) {
        this.onMapReadyCallbacks.map(callback => callback(map))
      }
      else {
        this.setCenteringPositions()
      }
      this.showMapLoader = false
    },

    /**
     * Method that will be executed by external component and should be used while calling any function from this component.
     * It ensures that no operations are performed on the map until it's fully loaded and functional.
     * The 'callback' parameter is a function that will be executed after map is ready.
     * @param callback
     */
    onMapReady (callback) {
      this.onMapReadyCallbacks = []
      this.onMapReadyCallbacks.push(callback)
      if (this.isMapReady) {
        this.mapReady(this)
      }
    },

    /**
     * Start map loader indicator.
     */
    startMapLoader () {
      this.showMapLoader = true
    },

    /**
     * Stop map loader indicator.
     */
    stopMapLoader () {
      this.showMapLoader = false
    },

    updateMapType (item) {
      this.setMapType(item)
    },

    updateTooltipPosition (event) {
      this.copyIconTooltipX = event.clientX + 15
      this.copyIconTooltipY = event.clientY + 30
    },

    handleIconClick () {
      const that = this
      this.isTooltipVisible = false
      this.deleteCopyMarker()
      clearTimeout(this.copiedCoordinatesTooltipTimeoutId)
      clearTimeout(this.copyCoordinatesTooltipTimeoutId)

      if (this.activeTooltip) {
        if (this.polylineGroup && !this.getMapObject()?.hasLayer(this.polylineGroup)) {
          this.getMapObject()?.addLayer(this.polylineGroup)
        }
        if (this.markersClusterGroup && !this.getMapObject()?.hasLayer(this.markersClusterGroup)) {
          this.getMapObject()?.addLayer(this.markersClusterGroup)
        }
        document.querySelector('.' + this.mapClass).style.cursor = 'default'
        this.textColor = 'gray'
        this.copyIconTooltipContent = this.$t('map_base.copy_icon_tooltip_content_enable')
        that.activeTooltip.remove()
        that.activeTooltip = null
      }
      else {
        if (this.polylineGroup) {
          this.getMapObject()?.removeLayer(this.polylineGroup)
        }
        if (this.markersClusterGroup) {
          this.getMapObject()?.removeLayer(this.markersClusterGroup)
        }
        document.querySelector('.' + this.mapClass).style.cursor = 'crosshair'
        this.textColor = 'black'
        this.copyIconTooltipContent = this.$t('map_base.copy_icon_tooltip_content_disable')
        this.setCopyNotificationMessage(this.mapCopyTooltipContent)

        this.copyCoordinatesTooltipTimeoutId = setTimeout(() => {
          document.querySelector('.' + this.mapClass).style.cursor = 'default'
          if (that.activeTooltip) {
            that.activeTooltip.remove()
            that.activeTooltip = null
          }
        }, 300000)
      }
    },

    deleteAllGeoSearchMarkers () {
      const markerLayers = this.getMapObject()?._layers
      const markers = Object.values(markerLayers).filter(layer => layer instanceof L.Marker)
      for (const markerKey in markers) {
        if (Object.prototype.hasOwnProperty.call(markers, markerKey)) {
          const markerObject = markers[markerKey]
          if (markerObject?.options?.icon?.options?.key === 'searchMarkerKey') {
            markerObject.remove()
          }
        }
      }
    },

    deleteCopyMarker () {
      this.copyMarker = null
      const markerLayers = this.getMapObject()?._layers
      if (markerLayers) {
        const copyMarker = Object.values(markerLayers).find(layer => layer instanceof L.Marker && layer.options && layer.options.key && layer.options.key === 'copyMarker')
        if (copyMarker) {
          this.getMapObject()?.removeLayer(copyMarker)
        }
      }
    },

    changeCopyIconColorOnHover () {
      if (!this.activeTooltip) this.textColor = 'black'
    },

    resetCopyIconColor () {
      if (!this.activeTooltip) this.textColor = 'gray'
    },

    emitHandleStartEvent (event) {
      const mapElement = document.querySelector('.' + this.mapClass)
      const cursorStyle = window.getComputedStyle(mapElement).cursor
      if (!this.activeTooltip || (cursorStyle && cursorStyle === 'default')) document.querySelector('.' + this.mapClass).style.cursor = 'all-scroll'
      this.deleteAllGeoSearchMarkers()
      this.$emit('handleDragStartEvent', event)
    },

    emitMarkerDragEnd (event) {
      this.deleteAllGeoSearchMarkers()
      this.$emit('handleMarkerDragEnd', event)
    },

    emitMarkerDragStart (event) {
      this.deleteAllGeoSearchMarkers()
      this.$emit('handleMarkerDragStart', event)
    },

    emitHandleEndEvent (event) {
      const mapElement = document.querySelector('.' + this.mapClass)
      const cursorStyle = window.getComputedStyle(mapElement).cursor
      if (!this.activeTooltip || (cursorStyle && cursorStyle === 'all-scroll')) document.querySelector('.' + this.mapClass).style.cursor = 'default'
      this.$emit('handleDragEndEvent', event)
    },

    onMapMove (event) {
      if (this.activeTooltip) {
        const mapObject = this.getMapObject()
        const latLng = mapObject?.containerPointToLatLng([mapObject?.getSize().x / 2, 50])
        this.activeTooltip.setLatLng(latLng)
      }
      this.$emit('handleMapMove', event)
    },

    setCopyNotificationMessage (content) {
      const mapObject = this.getMapObject()
      const latLng = mapObject?.containerPointToLatLng([mapObject?.getSize().x / 2, 50])

      if (this.activeTooltip) {
        this.activeTooltip.remove()
        this.activeTooltip = null
      }

      if (mapObject) {
        this.activeTooltip = L.tooltip({
          direction: 'top',
          opacity: 1,
          className: 'custom-tooltip'
        })
          .setLatLng(latLng)
          .setContent(content)
          .addTo(mapObject)
      }
    },

    handleMapClickEvent (val) {
      const that = this
      this.deleteAllGeoSearchMarkers()
      clearTimeout(this.copyCoordinatesTooltipTimeoutId)
      document.querySelector('.' + this.mapClass).style.cursor = 'default'
      this.isTooltipVisible = false
      if (this.activeTooltip && !this.copyMarker) {
        const { lat, lng } = val.latlng

        navigator.clipboard.writeText(`${lat.toFixed(6)}, ${lng.toFixed(6)}`)
        this.setCopyNotificationMessage(this.mapCopiedTooltipContent)

        this.copyMarker = {
          key: 'copyMarker',
          coordinate: {
            lat: lat,
            lng: lng
          },
          icon: icon({
            iconUrl: '/img/icons/copy-marker.png',
            iconSize: [35, 35],
            iconAnchor: [17, 35]
          }),
          draggable: false
        }
        if (this.markersClusterGroup && !this.getMapObject()?.hasLayer(this.markersClusterGroup)) {
          this.getMapObject()?.addLayer(this.markersClusterGroup)
        }
        if (this.polylineGroup && !this.getMapObject()?.hasLayer(this.polylineGroup)) {
          this.getMapObject()?.addLayer(this.polylineGroup)
        }

        this.copiedCoordinatesTooltipTimeoutId = setTimeout(() => {
          if (that.activeTooltip) {
            that.activeTooltip.remove()
            that.activeTooltip = null
            that.textColor = 'gray'
            that.copyIconTooltipContent = this.$t('map_base.copy_icon_tooltip_content_enable')
          }
          this.deleteCopyMarker()
        }, 1300)
      }
      else {
        clearTimeout(this.copiedCoordinatesTooltipTimeoutId)
        this.deleteCopyMarker()
        if (this.activeTooltip) {
          this.activeTooltip.remove()
          this.activeTooltip = null
          this.textColor = 'gray'
          this.copyIconTooltipContent = this.$t('map_base.copy_icon_tooltip_content_enable')
        }
        this.$emit('handleClickEvent', val)
      }
    },

    setCenteringPositions () {
      const location = companyScopeGeolocation() || companyGeolocation() || defaultZagrebCenter
      const mapObject = this.getMapObject()
      if (mapObject) {
        mapObject.setView(location, defaultMapOptions.zoom)
      }
    },

    /**
     * Helper function to retrieve the current map instance.
     */
    getMap () {
      return this.$refs && this.$refs.map && !this._isDestroyed && !this._isBeingDestroyed ? this.$refs.map : null
    },

    /**
     * Helper function to retrieve the current map object.
     * Should be employed wherever access to the map object is required.
     */
    getMapObject () {
      const map = this.getMap()
      return map && map.mapObject && !this._isDestroyed && !this._isBeingDestroyed ? map.mapObject : null
    },

    /**
     * Helper method to adjust circle marker radius based on the current map zoom level.
     * Primarily utilized by external components.
     * Emits the position marker radius to external components utilizing this component.
     */
    emitSetPositionRadiusEvent () {
      const mapObject = this.getMapObject()
      if (!mapObject) return

      mapObject.on('zoomend', () => {
        const currentZoom = mapObject.getZoom()
        let positionMarkerRadius

        if (currentZoom < 12) {
          positionMarkerRadius = 1
        }
        else if (currentZoom <= 13) {
          positionMarkerRadius = 2
        }
        else if (currentZoom === 14) {
          positionMarkerRadius = 3
        }
        else {
          positionMarkerRadius = 4
        }

        this.$emit('set-position-marker-radius', positionMarkerRadius)
        this.$emit('marker-zoom-updated', currentZoom)
      })
    },
    /**
     ----------------------------------------------------------Function parameters description----------------------------------------------------------------------------
     - Config is a general config object for generating markers on map, see config here {@link #getMarkersConfig}. Config contains following props:
     1) markers -> An array of marker objects designed for display on the map (see example below). (Mandatory)

     2) icons -> The icons object is a collection of nested objects, each representing a Leaflet icon class (such as L.Icon, L.DivIcon, etc.). Each object contains options for generating icons, including properties like iconSize, iconAnchor, iconUrl, and others.
     (Optional, if it's omitted, markers will have default icons.)

     3) keepExisting -> The boolean value, when set to true, indicates that if the process of adding new markers is underway, the previously added markers on the map should be retained. If set to false, all existing markers will be removed from the map before adding the new ones. (Optional)

     4) fitMarkers -> Fit map to display all markers at the same time (defaults to true)

     5) fitBoundsOptions -> Options for the leaflet fitBounds.

     6) useClustering -> If set to true, markers will be clustered, otherwise will be placed in L.FeatureGroup. By default, it's true.

     7) markerClusterOptions -> Leaflet marker cluster options that manage clustering behave.

     - More about popup can be found here in official leaflet documentation: https://leafletjs.com/reference.html#popup

     ----------------------------------------------------------Markers generation example---------------------------------------------------------------------------
     const config = {
     markers: [
     {
     lat: 15.48,
     lon: 43.23,
     icon: 'icon_name1',
     draggable: true,
     click: (e) => {
     this.handleMarkerClick(e)
     },
     drag: (e) => {
     this.handleMarkerDragEnd(e)
     }
     popup: {
     popupData: [
     {
     label: 'label1',
     value: 'value1
     },
     {
     label: 'label2',
     value: 'value2'
     }
     ]
     or
     popupData: (e) => callback(e)
     or
     popupData: [
     'some data 1',
     'some data 2',
     ..
     ..
     ]
     },
     popupOptions: {
     offset: [0, 0],
     maxWidth: 300,
     closeButton: false,
     openAutomatically: true
     }
     },
     {
     lat: 15.23,
     lon: 41.18,
     icon: 'icon_name2',
     popup: {
     popupData: 'some popup data'
     }
     },
     // For icons could be passed custom icons, not mandatory to be L.Icon, can be className and applied custom style for icons etc...
     icons: {
     icon_name1: new L.Icon({
     iconUrl: '/img/icons/fuel_probe_graph_pin_line_dataset1.svg',
     iconSize: [36, 36]
     }),
     icon_name2: new L.Icon({
     iconUrl: '/img/icons/fuel_probe_graph_pin_line_dataset2.svg',
     iconSize: [36, 36]
     }),
     }
     }

     ----------------------------------------------------------Function call example---------------------------------------------------------------------------------------

     $this.$refs?.$mapBase?.generateMarkers(markerConfig)
     **/
    generateMarkers (config) {
      const markersConfig = this.getMarkersConfig(config)

      if (markersConfig.markers && markersConfig.markers.length) {
        if (!isEmpty(this.markersClusterGroup) && !markersConfig.keepExisting) {
          this.markersClusterGroup.removeFrom(this.getMapObject())
          this.markersClusterGroup = null
        }
        this.markersClusterGroup = this.getOrGenerateMarkersClusterGroup(markersConfig)
        const existingMarkers = new Set()

        this.markersClusterGroup.eachLayer((marker) => {
          const latLng = marker.getLatLng()
          existingMarkers.add(`${latLng.lat},${latLng.lng}`)
        })

        markersConfig.markers.forEach((icon) => {
          const markerCoordinates = `${icon.lat},${icon.lon}`

          if (!existingMarkers.has(markerCoordinates)) {
            let markerLayer

            if (markersConfig.icons && !markersConfig.icons.length && !isEmpty(markersConfig.icons)) {
              markerLayer = L.marker([icon.lat, icon.lon], { icon: markersConfig.icons[icon.icon], draggable: icon.draggable, id: icon.id })
            }
            else {
              markerLayer = L.marker([icon.lat, icon.lon], { draggable: icon.draggable, id: icon.id })
            }

            if (icon.click && typeof icon.click === 'function') {
              markerLayer.on('click', (e) => {
                icon.click(e)
              })
            }
            if (icon.drag && typeof icon.drag === 'function') {
              markerLayer.on('dragend', (e) => {
                icon.drag(e)
              })
            }

            if (markerLayer.getPopup()) markerLayer.unbindPopup()

            // When as popup data came callback function with custom popup data
            if (icon.popup && icon.popup.popupData && typeof icon.popup.popupData === 'function') {
              markerLayer.on('mouseover', async (e) => {
                if (!markerLayer.isPopupOpen() && !markerLayer.getPopup()) {
                  const popup = L.popup(icon.popup.popupOptions).setContent('<div class="loader" />')
                  markerLayer.bindPopup(popup).openPopup()
                  const popupContent = await icon.popup.popupData(e)
                  if (popupContent) {
                    const content = this.generatePopupContentFromCallback(popupContent)
                    markerLayer.unbindPopup().closePopup()
                    const popup = L.popup(icon.popup.popupOptions).setContent(content)
                    markerLayer.bindPopup(popup).openPopup()
                  }
                }
                else {
                  markerLayer.openPopup()
                }
              })
              markerLayer.on('mouseout', () => {
                if (markerLayer.isPopupOpen()) {
                  markerLayer.closePopup()
                }
              })
            }
            // When as popup data came standard data
            else if (icon.popup && icon.popup.popupData) {
              const popupContent = this.generatePopupContent(icon.popup)
              const popup = icon.popup.popupOptions ? L.popup(icon.popup.popupOptions).setContent(popupContent) : L.popup().setContent(popupContent)

              if (icon.popup.popupOptions && icon.popup.popupOptions.openAutomatically) {
                markerLayer.bindPopup(popup).on('add', () => {
                  markerLayer.openPopup()
                })
              }
              else {
                markerLayer.on('mouseover', () => {
                  if (!markerLayer.isPopupOpen()) {
                    markerLayer.bindPopup(popup).openPopup()
                  }
                })
                markerLayer.on('mouseout', () => {
                  if (markerLayer.isPopupOpen()) {
                    markerLayer.closePopup()
                  }
                })
              }
            }

            this.markersClusterGroup.addLayer(markerLayer)
          }
        })

        const clusterBounds = this.markersClusterGroup.getBounds()
        if (clusterBounds && markersConfig.fitMarkers) {
          this.getMapObject()?.fitBounds(clusterBounds, markersConfig.fitBoundsOptions)
        }

        this.getMapObject()?.addLayer(this.markersClusterGroup)
      }
    },
    // Prepares markers setup configuration
    getMarkersConfig (config) {
      // Note: Update these options as needed when introducing new ones in the future.
      // Ref: https://leafletjs.com/reference.html#fitbounds-options
      const defaultFitBoundsOptions = {
        paddingTopLeft: [0, 0], // Sets the amount of padding in the top left corner of a map container that shouldn't be accounted for when setting the view to fit bounds. Useful if you have some control overlays on the map like a sidebar, and you don't want them to obscure objects you're zooming to.
        paddingBottomRight: [0, 0], // The same for the bottom right corner of the map.
        padding: [0, 0], // Equivalent of setting both top left and bottom right padding to the same value.
        maxZoom: null, // The maximum possible zoom to use.
        animate: false, // If not specified, zoom animation will happen if the zoom origin is inside the current view. If true, the map will attempt animating zoom disregarding where zoom origin is. Setting false will make it always reset the view completely without animation.
        duration: 0.25, // Duration of animated panning, in seconds.
        easeLinearity: 0.25, // The curvature factor of panning animation easing (third parameter of the Cubic Bézier curve). 1.0 means linear animation, and the smaller this number, the more bowed the curve.
        noMoveStart: false // If true, panning won't fire movestart event on start (used internally for panning inertia).
      }

      // Note: Also update these options as needed when introducing new ones in the future.
      // Ref: https://github.com/Leaflet/Leaflet.markercluster?tab=readme-ov-file#all-options
      const defaultMarkerClusterOptions = {
        showCoverageOnHover: true, // When you mouse over a cluster it shows the bounds of its markers.
        zoomToBoundsOnClick: true, // When you click a cluster we zoom to its bounds.
        spiderfyOnMaxZoom: true, // When you click a cluster at the bottom zoom level we spiderfy it, so you can see all of its markers. (Note: the spiderfy occurs at the current zoom level if all items within the cluster are still clustered at the maximum zoom level or at zoom specified by disableClusteringAtZoom option).
        removeOutsideVisibleBounds: true, // Clusters and markers too far from the viewport are removed from the map for performance.
        animate: true, // Smoothly split / merge cluster children when zooming and spiderfying. If L.DomUtil.TRANSITION is false, this option has no effect (no animation is possible).
        animateAddingMarkers: false, //  If set to true (and animate option is also true) then adding individual markers to the MarkerClusterGroup after it has been added to the map will add the marker and animate it into the cluster. Defaults to false as this gives better performance when bulk adding markers. addLayers does not support this, only addLayer with individual Markers.
        disableClusteringAtZoom: null, //  If set, at this zoom level and below, markers will not be clustered. This defaults to disabled. Note: you may be interested in disabling spiderfyOnMaxZoom option when using disableClusteringAtZoom.
        maxClusterRadius: 80, // The maximum radius that a cluster will cover from the central marker (in pixels). Default 80. Decreasing will make more, smaller clusters. You can also use a function that accepts the current map zoom and returns the maximum cluster radius in pixels.
        polygonOptions: null, // Options to pass when creating the L.Polygon(points, options) to show the bounds of a cluster. Defaults to empty, which lets Leaflet use the default https://leafletjs.com/reference.html#path-options.
        singleMarkerMode: false, // If set to true, overrides the icon for all added markers to make them appear as a 1 size cluster. Note: the markers are not replaced by cluster objects, only their icon is replaced. Hence, they still react to normal events, and option disableClusteringAtZoom does not restore their previous icon.
        spiderLegPolylineOptions: { weight: 1.5, color: '#222', opacity: 0.5 }, // Allows you to specify PolylineOptions to style spider legs. By default, they are { weight: 1.5, color: '#222', opacity: 0.5 }.
        spiderfyDistanceMultiplier: 1, // Increase from 1 to increase the distance away from the center that spiderfied markers are placed. Use if you are using big marker icons (Default: 1).
        chunkedLoading: true, // Boolean to split the addLayers processing in to small intervals so that the page does not freeze.
        chunkInterval: 200, // Time interval (in ms) during which addLayers works before pausing to let the rest of the page process. In particular, this prevents the page from freezing while adding a lot of markers. Defaults to 200ms.
        chunkDelay: 50, // Time delay (in ms) between consecutive periods of processing for addLayers. Default to 50ms.
        chunkProgress: null // Callback function that is called at the end of each chunkInterval. Typically used to implement a progress indicator, e.g. code in RealWorld 50k. Defaults to null. Arguments:
        // 1. Number of processed markers
        // 2. Total number of markers being added
        // 3. Elapsed time (in ms)
      }

      const mergedFitBoundsOptions = this.mergeOptions(config.fitBoundsOptions, defaultFitBoundsOptions)
      const mergedMarkerClusterOptions = this.mergeOptions(config.markerClusterOptions, defaultMarkerClusterOptions)

      return {
        markers: config.markers || [], // Markers configuration
        icons: config.icons || {}, // Icons configuration
        keepExisting: config.keepExisting ? config.keepExisting === true : false, // Keep existing markers if needed (default is false)
        fitMarkers: typeof config.fitMarkers === 'boolean' ? config.fitMarkers === true : true, // Fit map to display all markers at the same time (default is true)
        fitBoundsOptions: mergedFitBoundsOptions,
        useClustering: config.useClustering !== undefined ? config.useClustering : true,
        markerClusterOptions: mergedMarkerClusterOptions
      }
    },

    mergeOptions (userOptions, defaultOptions) {
      return userOptions ? { ...defaultOptions, ...userOptions } : defaultOptions
    },

    generatePopupContent (popup) {
      if (popup && Array.isArray(popup.popupData)) {
        const popupTextColors = popup && popup.popupTextColors ? popup.popupTextColors : []

        return popup.popupData.map((popupObject, dataIndex) => {
          if (Array.isArray(popupObject.tooltipData)) {
            return `<div :key="index: ${dataIndex}" class="pa-1">
              ${popupObject.tooltipData.map((item, popupIndex) => {
              return `<v-row
                  :key="popup_content: ${item.label} & index: ${popupIndex}"
                  style="color: ${this.getPopupTextColor(popupObject.datasetIndex, popupTextColors)};"
                  >
                    <v-col cols="4">
                      ${item.label}
                    </v-col>
                    <v-col cols="4">
                      :
                    </v-col>
                    <v-col cols="4">
                      ${item.value}
                    </v-col>
                  </v-row>`
            }).join('<br>')}
              </div>`
          }
          else if (typeof popupObject === 'string') {
            return `<div :key="index: ${dataIndex}" style="text-align: center;">
              ${popupObject}
            </div>`
          }
          else if (popupObject && typeof popupObject === 'object' && popupObject.label && popupObject.value) {
            return `<div :key="index: ${dataIndex}" class="pa-1">
              <v-row
                :key="popup_content: ${popupObject.label + ' : ' + popupObject.value} & index: ${dataIndex}"
              >
                <v-col cols="4">
                  ${popupObject.label}
                </v-col>
                <v-col cols="4">
                  :
                </v-col>
                <v-col cols="4">
                  ${popupObject.value}
                </v-col>
              </v-row>
             </div>`
          }
          else {
            return `<div :key="wrong_popup_data_format" style="text-align: center;">
              Wrong popup data format
            </div>`
          }
        }).join('')
      }
      else if (typeof popup.popupData === 'string') {
        return `<div :key="simple_string" style="text-align: center;">
          ${popup.popupData}
        </div>`
      }
      else {
        return `<div :key="empty_popup_data" style="text-align: center;">
          Popup data didn't provided
        </div>`
      }
    },

    generatePopupContentFromCallback (content) {
      if (Array.isArray(content)) {
        return content.map((popupObject, dataIndex) => {
          if (typeof popupObject === 'string') {
            return `<div :key="index: ${dataIndex}" style="text-align: center;">
              ${popupObject}
            </div>`
          }
          else if (popupObject && typeof popupObject === 'object' && popupObject.label && popupObject.value) {
            return `<div :key="index: ${dataIndex}" >
              <v-row
                :key="popup_content: ${popupObject.label + ' : ' + popupObject.value} & index: ${dataIndex}"
              >
                <v-col cols="4">${popupObject.label}</v-col>
                <v-col cols="4">:</v-col>
                <v-col cols="4">${popupObject.value}</v-col>
              </v-row>
            </div>`
          }
          else {
            return `<div :key="wrong_popup_data_format" style="text-align: center;">
              Wrong popup data format
            </div>`
          }
        }).join('')
      }
      else if (typeof content === 'string') {
        return `<div :key="simple_string" style="text-align: center;">
          ${content}
        </div>`
      }
      else {
        return `<div :key="empty_popup_data" style="text-align: center;">
          Popup data didn't provided
        </div>`
      }
    },

    getPopupTextColor (index = 0, tooltipTextColors = null) {
      return tooltipTextColors ? Array.isArray(tooltipTextColors) && tooltipTextColors.length ? tooltipTextColors[index] : typeof tooltipTextColors === 'string' ? tooltipTextColors : 'black' : 'black'
    },

    // Generate unique markers layer (cluster group) on which markers will be located. This is good approach, because with that we have full markers control. (show/hide all markers is one of more advantages using this cluster group)
    getOrGenerateMarkersClusterGroup (markersConfig) {
      let markersClusterGroup

      if (isEmpty(this.markersClusterGroup)) {
        if (markersConfig.useClustering) {
          markersClusterGroup = new L.MarkerClusterGroup(markersConfig.markerClusterOptions)

          this.markersClusterGroup = markersClusterGroup
        }
        else {
          markersClusterGroup = new L.FeatureGroup()
          this.markersClusterGroup = markersClusterGroup
        }
      }
      else {
        markersClusterGroup = this.markersClusterGroup
      }
      return markersClusterGroup
    },

    /**
     * Main function for polyline generation.
     * @param config

     * Poly lines with their corresponding markers are located in the same polyline group, this.polylineGroup, for their better management and control.
     * A minimum of 2 points are necessary for polyline creation!

     * Polyline with it's initially markers, for example both start and end marker, are generated by calling {@link #generatePolylines} method.

     * Markers are divided into 2 groups:
     * 1) Base markers - markers that are created initially together within their corresponding poly lines.
     * 2) Additional markers - markers that were added subsequently to the poly lines as needed. Additional markers are added by calling {@link #addMarkersToPolyline} method.

     * When generating markers on polyline, they must have defined id (string) in their options and that id must contain id of the corresponding polyline + id of marker. Example for marker id: polylineId + '_' + markerID
     * This is necessary to facilitate marker retrieval at a later stage. All markers associated with the polyline can be accessed either by their corresponding polyline ID or directly by their marker ID.

     * Example of polyline creation with their corresponding markers:
     const polylineConfig = {
     polylines: [],
     markers: []
     }

     polylineArray.forEach(polyline => {
     polylineConfig.polylines.push({
     coordinates: polyline.coordinates,
     options: {
     id: polyline.key,
     color: polyline.color ? polyline.color : '#3388ff',
     weight: 10
     },
     patterns: this.patterns,
     polylineClick: () => this.handleRouteClick(polyline),
     polylineDecoratorClick: () => this.handleRouteClick(polyline),
     polylineMouseOver: (e) => this.mouseOverRoute(e, polyline),
     polylineMouseOut: (e) => this.mouseOutRoute(e),
     polylineDecoratorMouseOver: (e) => this.mouseOverRoute(e, polyline),
     polylineDecoratorMouseOut: (e) => this.mouseOutRoute(e)
     })
     polylineConfig.markers.push({
     route: polyline,
     coordinates: {
     lat: route.startLat,
     lng: route.startLng
     },
     options: {
     icon: this.icons.startPoint,
     id: polyline.key + '_startMarker'
     }
     }
     }

     if (polylineConfig.polylines.length) {
     this.generatePolylines(polylineConfig)
     }
     */
    generatePolylines (config) {
      const existingPolylines = new Set()
      const existingMarkers = new Set()
      const polylineConfig = this.getPolylineConfig(config)

      if (this.polylineGroup && polylineConfig.keepExisting === false) {
        this.polylineGroup.removeFrom(this.$refs?.map?.mapObject)
        this.polylineGroup = null
      }
      this.polylineGroup = this.getOrGeneratePolylineGroup()

      // Retrieve the coordinates of existing polylines on the map to facilitate checking for duplicates when adding new ones later
      if (polylineConfig.polylines && polylineConfig.polylines.length) {
        this.polylineGroup.eachLayer((layer) => {
          if (layer instanceof L.Polyline) {
            const latLngs = layer.getLatLngs()
            existingPolylines.add(latLngs)
          }
        })

        // Integrate new incoming polylines along with their decorators
        polylineConfig.polylines.forEach(polyline => {
          if (polylineConfig.ignoreExisting || !existingPolylines.has(polyline.coordinates)) {
            const newPolylineLayer = L.polyline(polyline.coordinates, polyline.options)

            this.attachPolylineEvents(newPolylineLayer, polyline)

            this.handlePolylineObjectsTooltips(newPolylineLayer, polyline)

            this.polylineGroup.addLayer(newPolylineLayer)

            if (polylineConfig.patterns && polylineConfig.patterns.length) {
              const newPolyLineDecoratorLayer = L.polylineDecorator(polyline.coordinates, {
                patterns: polylineConfig.patterns,
                id: polyline.options.id
              })

              this.attachPolylineDecoratorEvents(newPolyLineDecoratorLayer, polyline)

              this.handlePolylineObjectsTooltips(newPolyLineDecoratorLayer, polyline)

              this.polylineGroup.addLayer(newPolyLineDecoratorLayer)
            }
          }
        })

        if (polylineConfig.markers && polylineConfig.markers.length) {
          // Retrieve the coordinates of existing polyline markers to enable checking for duplicates when adding new ones later
          this.polylineGroup.eachLayer((layer) => {
            if (layer instanceof L.CircleMarker) {
              const latLng = layer.getLatLng()
              existingMarkers.add(latLng)
            }
          })

          // Integrate new incoming polyline markers
          polylineConfig.markers.forEach(marker => {
            if (polylineConfig.ignoreExisting || !existingMarkers.has(marker.coordinates)) {
              let newMarker
              if (marker.type && marker.type === 'circle') {
                newMarker = L.circleMarker(marker.coordinates, marker.options)
              }
              else {
                newMarker = L.marker(marker.coordinates, marker.options)
              }
              this.handlePolylineObjectsTooltips(newMarker, marker)
              this.polylineGroup.addLayer(newMarker)
            }

            // If marker has a radius then create circle around that marker
            if (marker.radius && marker.radius.coordinates) {
              const newCircle = L.circle(marker.radius.coordinates, marker.radius.radius)
              this.handlePolylineObjectsTooltips(newCircle, marker.radius)
              this.polylineGroup.addLayer(newCircle)
            }
          })
        }

        // Add the polyline group to the map and adjust the map bounds to fit the polyline group
        if (this.polylineGroup) {
          this.$refs?.map?.mapObject?.addLayer(this.polylineGroup)
          this.fitMapBoundsToPolylines(polylineConfig.fitPolylines)
        }
      }
    },

    removeAllPolylines () {
      if (this.polylineGroup) {
        this.polylineGroup.removeFrom(this.$refs?.map?.mapObject)
        this.polylineGroup = null
      }
    },

    fitMapBoundsToPolylines (shouldFit = true) {
      const polylinesBounds = this.polylineGroup?.getBounds()
      if (polylinesBounds && !isEmpty(polylinesBounds) && shouldFit) {
        this.$refs?.map?.mapObject?.fitBounds(polylinesBounds)
      }
    },

    handlePolylineObjectsTooltips (layer, object) {
      if (object.popup && object.popup.popupData && typeof object.popup.popupData === 'function') {
        let shouldOpenTooltip = true
        layer.on('mouseover', async (e) => {
          shouldOpenTooltip = true
          if (!layer.getTooltip()) {
            let popup = L.tooltip().setContent('<div class="loader" />')
            if (object.popup.popupOptions) {
              popup = L.tooltip(object.popup.popupOptions).setContent('<div class="loader" />')
            }

            layer.bindTooltip(popup).openTooltip()
            const popupContent = await object.popup.popupData(e)
            if (popupContent) {
              layer.unbindTooltip().closeTooltip()
              let popup = L.tooltip().setContent(popupContent)
              if (object.popup.popupOptions) {
                popup = L.tooltip(object.popup.popupOptions).setContent(popupContent)
              }
              if (shouldOpenTooltip) {
                layer.bindTooltip(popup).openTooltip()
              }
            }
          }
          else {
            layer.getTooltip().openTooltip()
          }
        })
        layer.on('mouseout', () => {
          shouldOpenTooltip = false
          layer.closeTooltip()
        })
      }
    },

    attachPolylineEvents (layer, object) {
      if (object.polylineClick && typeof object.polylineClick === 'function') {
        layer.on('click', async () => {
          await object.polylineClick()
        })
      }
      if (object.polylineMouseOver && typeof object.polylineMouseOver === 'function') {
        layer.on('mouseover', async (e) => {
          await object.polylineMouseOver(e)
        })
      }
      if (object.polylineMouseOut && typeof object.polylineMouseOut === 'function') {
        layer.on('mouseout', async (e) => {
          await object.polylineMouseOut(e)
        })
      }
    },

    attachPolylineDecoratorEvents (layer, object) {
      if (object.polylineDecoratorClick && typeof object.polylineDecoratorClick === 'function') {
        layer.on('click', async () => {
          await object.polylineDecoratorClick()
        })
      }
      if (object.polylineDecoratorMouseOver && typeof object.polylineDecoratorMouseOver === 'function') {
        layer.on('mouseover', async (e) => {
          await object.polylineDecoratorMouseOver(e)
        })
      }
      if (object.polylineDecoratorMouseOut && typeof object.polylineDecoratorMouseOut === 'function') {
        layer.on('mouseout', async (e) => {
          await object.polylineDecoratorMouseOut(e)
        })
      }
    },

    // Add markers to the polyline
    addMarkersToPolyline (markers) {
      if (!markers || !markers.length) return
      const filteredMarkers = this.filterExistMarkers(markers)

      filteredMarkers.forEach(marker => {
        const { coordinates, options, type } = marker
        const newMarker = type && type === 'circle' ? L.circleMarker(coordinates, options) : L.marker(coordinates, options)
        if (newMarker) {
          this.handlePolylineObjectsTooltips(newMarker, marker)
          this.polylineGroup.addLayer(newMarker)
        }
      })
    },

    // Update the polyline with new markers, optionally deleting old markers
    updatePolylineMarkers (newMarkers, keepOldMarkers = false) {
      if (this.polylineGroup) {
        const polylineLayers = this.polylineGroup.getLayers()
        // We can get polyline id from marker id, where marker id is a string and should be like this: 'routeKey_markerId' (routeKey is a key of marker corresponding route)
        const polylineId = newMarkers && newMarkers.length ? newMarkers[0]?.options?.id?.toString().split('_')[0] : null

        if (polylineLayers && polylineLayers.length) {
          let polylineMarkers

          // Delete all polyline markers by default. If second parameter is provided and if it's true, then do not delete old markers
          if (!keepOldMarkers) {
            if (polylineId && polylineId !== '') {
              polylineMarkers = polylineLayers.filter(layer => {
                if (layer instanceof L.Marker || layer instanceof L.CircleMarker) {
                  return layer.options.id.toString().includes(polylineId.toString())
                }
                return false
              })
            }

            if (polylineMarkers && polylineMarkers.length) {
              polylineMarkers.forEach(marker => {
                this.polylineGroup.removeLayer(marker)
              })
            }
          }

          // Update route with new markers
          if (newMarkers && newMarkers.length) {
            newMarkers.forEach(marker => {
              const { coordinates, options, type } = marker
              const newMarker = type && type === 'circle' ? L.circleMarker(coordinates, options) : L.marker(coordinates, options)
              if (newMarker) {
                this.handlePolylineObjectsTooltips(newMarker, marker)
                this.polylineGroup.addLayer(newMarker)
              }
            })
          }
        }
      }
    },

    filterExistMarkers (markers) {
      if (!markers || !markers.length) return []

      let filteredMarkers = markers.slice()

      if (this.polylineGroup) {
        const mapMarkers = this.polylineGroup.getLayers().filter(layer => layer instanceof L.Marker || layer instanceof L.CircleMarker)

        if (mapMarkers && mapMarkers.length) {
          filteredMarkers = filteredMarkers.filter(marker => {
            const markerExists = mapMarkers.some(mapMarker => {
              return mapMarker.options.id.toString() === marker.options.id.toString()
            })

            return !markerExists
          })
        }
      }

      return filteredMarkers
    },

    removeMarkersFromPolyline (markerIds) {
      if (!markerIds || !markerIds.length) return

      markerIds.forEach(markerId => {
        const existingMarkers = this.polylineGroup.getLayers().filter(layer => (layer instanceof L.Marker || layer instanceof L.CircleMarker) && (layer.options.id === markerId || layer.options.id.toString().includes(markerId)))
        if (existingMarkers && existingMarkers.length && this.polylineGroup) {
          existingMarkers.forEach(marker => {
            this.polylineGroup.removeLayer(marker)
          })
        }
      })
    },

    checkIfMarkersExistOnPolyline (markerIds) {
      if (!markerIds || !markerIds.length) return false
      let existingMarker = null
      markerIds.forEach(markerId => {
        const foundExistingMarker = this.polylineGroup.getLayers().find(layer => (layer instanceof L.Marker || layer instanceof L.CircleMarker) && (layer.options.id === markerId || layer.options.id.toString().includes(markerId)))
        if (foundExistingMarker) {
          existingMarker = foundExistingMarker
        }
      })
      return !!existingMarker
    },

    changeAllCircleMarkersRadius (radius) {
      if (this.polylineGroup) {
        const existingCircleMarkers = this.polylineGroup.getLayers().filter(layer => layer instanceof L.CircleMarker && !(layer instanceof L.Circle))

        if (existingCircleMarkers && existingCircleMarkers.length) {
          existingCircleMarkers.forEach(marker => marker.setRadius(radius))
        }
        this.polylineGroup.removeFrom(this.$refs?.map?.mapObject)
        this.polylineGroup.addTo(this.$refs?.map?.mapObject)
      }
    },

    getExistingPolylines () {
      let result = []
      if (this.polylineGroup) {
        result = this.polylineGroup.getLayers().filter(layer => layer instanceof L.Polyline)
      }
      return result
    },

    // Delete polylines, optionally adjusting the map to fit the remaining poly lines
    deletePolylines (polylineIds, shouldFit = true) {
      if (polylineIds && polylineIds.length) {
        polylineIds.forEach(polylineId => {
          const existingPolylines = this.polylineGroup.getLayers().filter(layer => {
            if (layer instanceof L.Polyline) {
              return layer.options.id.toString().includes(polylineId.toString())
            }
            return false
          })
          const existingPolylineDecorators = this.polylineGroup.getLayers().filter(layer => {
            if (layer instanceof L.PolylineDecorator) {
              return layer.options.id.toString().includes(polylineId.toString())
            }
            return false
          })
          if (existingPolylines) {
            let markersInsidePolyline = []
            markersInsidePolyline = this.polylineGroup.getLayers().filter(layer => {
              if (layer instanceof L.Marker || layer instanceof L.CircleMarker) {
                return layer.options.id.toString().includes(polylineId.toString())
              }
              return false
            })

            if (markersInsidePolyline && markersInsidePolyline.length) {
              markersInsidePolyline.forEach(marker => {
                this.polylineGroup.removeLayer(marker)
              })
            }

            // Remove polyline/s
            if (existingPolylines && existingPolylines.length) {
              existingPolylines.forEach(polyline => {
                this.polylineGroup.removeLayer(polyline)
              })
            }
          }

          if (existingPolylineDecorators && existingPolylineDecorators.length) {
            existingPolylineDecorators.forEach(decorator => {
              this.polylineGroup.removeLayer(decorator)
            })
          }
        })
        this.fitMapBoundsToPolylines(shouldFit)
      }
    },

    // Polyline configuration
    getPolylineConfig (config) {
      const defaultPolylineConfig = {
        polylines: [], // Array of polyline objects. Each polyline object has structure described bellow. Mandatory fields are signed with (*)
        /*
        Example of single polyline object with full options with their default values:
          {
        (*) coordinates: [], // Array of LatLng points. You can also pass a multidimensional array to represent a MultiPolyline shape.
            options: {
              smoothFactor: 1.0, // How much to simplify the polyline on each zoom level. More means better performance and smoother look, and fewer means more accurate representation.
              noClip: false, // Disable polyline clipping.
              stroke: true, // Whether to draw stroke along the path. Set it to false if you want to disable borders on polygons or circles.
              color: '#3388ff', // Stroke color
              weight: 3, // Stroke width in pixels
              opacity: 1.0, // Stroke opacity
              lineCap: 'round', // A string that defines shape to be used at the end of the stroke, possible values are: butt, round or square. Found here: https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/stroke-linecap
              lineJoin: 'round', // A string that defines shape to be used at the corners of the stroke, possible values are: arcs, bevel, miter, miter-clip or round. Found here: https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/stroke-linejoin
              dashArray: null, // A string that defines the stroke dash pattern. Doesn't work on Canvas-powered layers in some old browsers. A list of comma and/or white space separated <length>s and <percentage>s that specify the lengths of alternating dashes and gaps. If an odd number of values is provided, then the list of values is repeated to yield an even number of values. Thus, 5,3,2 is equivalent to 5,3,2,5,3,2. Found here: https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/stroke-dasharray
              dashOffset: null, // A string that defines the distance into the dash pattern to start the dash. Doesn't work on Canvas-powered layers in some old browsers. The offset is usually expressed in user units resolved against the pathLength but if a <percentage> is used, the value is resolved as a percentage of the current viewport. Found here: https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/stroke-dashoffset
              fill: true, // Whether to fill the path with color. Set it to false if you want to disable filling on polygons or circles.
              fillColor: '#3388ff', // Fill color. Defaults to the value of the color option
              fillOpacity: 0.2, // Fill opacity.
              fillRule: 'evenodd', // A string that defines how the inside of a shape is determined, possible values are: nonzero and evenodd. Found here: https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/fill-rule
              bubblingMouseEvents: true, // When true, a mouse event on this path will trigger the same event on the map (unless L.DomEvent.stopPropagation is used).
              className: null, // Custom class name set on an element. Only for SVG renderer.
              interactive: true, // If false, the layer will not emit mouse events and will act as a part of the underlying map.
              pane: 'overlayPane', // By default, the layer will be added to the map's overlay pane. Overriding this option will cause the layer to be placed on another pane by default.
              attribution: null // String to be shown in the attribution control, e.g. "© OpenStreetMap contributors". It describes the layer data and is often a legal obligation towards copyright holders and tile providers.
            },
            patterns: [] // Patterns that will be applied to the polyline. Contains pattern objects, each object can have:
            1) offset -> Offset of the first pattern symbol, from the start point of the line. Default: 0.
            2) endOffset -> Minimum offset of the last pattern symbol, from the end point of the line. Default: 0.
        (*) 3) repeat -> Repetition interval of the pattern symbols. Defines the distance between each consecutive symbol's anchor point.
        (*) 4) symbol -> Instance of a symbol factory class. (*)

            Fields 3 and 4 are mandatory only if you want to use patterns for polyline.
            offset, endOffset and repeat can be each defined as a number, in pixels, or in percentage of the line's length, as a string (ex: '10%').
          },
        */
        markers: [], // Array of marker objects. Each marker object has structure described bellow. Mandatory fields are signed with (*)
        /*
        Example of single marker object with their default values:
          {
            type: null, // String that defines marker type, possible values: 'circle' | null. If this field is omitted, marker type will be base marker (instance of L.Marker), otherwise will be circle marker (instance of L.CircleMarker).
        (*) coordinates: { lat-lng } ,// Object that contains lat-lng coordinates. This field is mandatory and doesn't have default value
            popupData: [],
            popupOptions: {}
          }
        */
        // This parameter defaults to true and means that a map will fit its bounds to all drawn poly lines. Otherwise, map won't fit bounds to them.
        fitPolylines: true,
        // Patterns are optional
        patterns: [
          {
            offset: 25,
            repeat: 50,
            symbol: L.Symbol.arrowHead({
              pixelSize: 12,
              headAngle: 30,
              pathOptions: {
                color: '#FFF',
                weight: 0,
                fillOpacity: 0.5,
                interactive: false // It's crucial to default this property to false. By default, this property is true. When it's set to true, if a marker on the polyline shares the same position as a pattern symbol (resulting in overlapping layers), the symbol consistently appears above the marker. Consequently, any mouse events associated with the marker won't trigger as the marker remains obscured by the pattern symbol. Setting this property to false mitigates these unexpected behaviors
              }
            })
          }
        ],
        // If this parameter is set to true, it allows poly lines to be drawn over one another. Conversely, if it's false, overlapping poly lines are prohibited and will be disregarded
        ignoreExisting: false
      }

      if (config.polylines && config.polylines.length > 0) {
        const mergedConfig = {
          ...defaultPolylineConfig,
          ...config
        }

        if (config.patterns) {
          mergedConfig.patterns = config.patterns
        }

        return mergedConfig
      }
      else {
        return defaultPolylineConfig
      }
    },

    getOrGeneratePolylineGroup () {
      let polylineGroup
      if (isEmpty(this.polylineGroup)) {
        polylineGroup = new L.FeatureGroup()

        this.polylineGroup = polylineGroup
      }
      else {
        polylineGroup = this.polylineGroup
      }

      return polylineGroup
    },

    // Disable all map interactions.
    lockMap () {
      this.getMapObject()?._handlers?.forEach(function (handler) {
        handler.disable()
      })
    },

    // Enable all map interactions.
    unlockMap () {
      this.getMapObject()?._handlers?.forEach(function (handler) {
        handler.enable()
      })
    },

    // Remove all markers from map.
    removeMarkers () {
      if (!isEmpty(this.markersClusterGroup)) {
        this.markersClusterGroup.removeFrom(this.getMapObject())
        this.markersClusterGroup = null
      }
    },

    // Find marker on map by id. (id must be passed in generation marker function as a part of markers objects and set in L.Marker options).
    findMarkerById (markerId) {
      let foundMarker = null

      if (this.markersClusterGroup) {
        this.markersClusterGroup.eachLayer((markerLayer) => {
          if (markerLayer.options && markerLayer.options.id === markerId) {
            foundMarker = markerLayer
          }
        })
      }
      return foundMarker
    },

    // Update marker's popup content.
    updateMarkerPopupContent (markerId, popupContent) {
      if (this.markersClusterGroup) {
        this.markersClusterGroup.eachLayer((markerLayer) => {
          if (markerLayer.options && markerLayer.options.id === markerId) {
            const tempVar = {
              popupData: popupContent
            }
            const newContent = this.generatePopupContent(tempVar)
            markerLayer.setPopupContent(newContent)
          }
        })
      }
    },

    generatePolygons (config) {
      const polygonConfig = this.getPolygonConfig(config)

      if (this.polygonGroup && polygonConfig.keepExisting === false) {
        this.polygonGroup.removeFrom(this.$refs?.map?.mapObject)
        this.polygonGroup = null
      }
      this.polygonGroup = this.getOrGeneratePolygonGroup()
      const existingPolygons = new Set()

      if (polygonConfig.polygons && polygonConfig.polygons.length) {
        this.polygonGroup.eachLayer((layer) => {
          if (layer instanceof L.Polygon) {
            const latLngs = layer.getLatLngs()
            existingPolygons.add(latLngs)
          }
        })

        polygonConfig.polygons.forEach(polygon => {
          if (!existingPolygons.has(polygon.coordinates)) {
            const newPolygonLayer = L.polygon(polygon.coordinates, polygon.options)
            this.polygonGroup.addLayer(newPolygonLayer)
            newPolygonLayer.on('mouseover', () => {
              newPolygonLayer.bindTooltip(polygon.label, { sticky: true }).openTooltip()
            })
          }
        })

        // Add the polygon group to the map and adjust the map bounds to fit the polygon group
        if (this.polygonGroup) {
          this.getMapObject()?.addLayer(this.polygonGroup)
          this.$nextTick(() => {
            this.fitMapBoundsToPolygons(polygonConfig.fitPolygons)
          })
        }
      }
    },

    // Polygon configuration
    getPolygonConfig (config) {
      const defaultPolygonConfig = {
        polygons: [],
        keepExisting: false,
        fitPolygons: true
      }

      if (config.polygons && config.polygons.length > 0) {
        return {
          ...defaultPolygonConfig,
          ...config
        }
      }
      else {
        return defaultPolygonConfig
      }
    },

    getOrGeneratePolygonGroup () {
      let polygonGroup
      if (isEmpty(this.polygonGroup)) {
        polygonGroup = new L.FeatureGroup()

        this.polygonGroup = polygonGroup
      }
      else {
        polygonGroup = this.polygonGroup
      }

      return polygonGroup
    },

    fitMapBoundsToPolygons (shouldFit = true) {
      const polygonsBounds = this.polygonGroup?.getBounds()
      if (polygonsBounds && !isEmpty(polygonsBounds) && shouldFit) {
        this.getMapObject()?.fitBounds(polygonsBounds)
      }
    },

    removeAllPolygons () {
      if (!isEmpty(this.polygonGroup)) {
        this.polygonGroup.removeFrom(this.getMapObject())
        this.polygonGroup = null
      }
    },

    // Retrieve all current markers present on the map (visible and not visible, all).
    getMarkers () {
      const foundMarkers = []

      if (this.markersClusterGroup) {
        this.markersClusterGroup.eachLayer((markerLayer) => {
          foundMarkers.push(markerLayer)
        })
      }
      return foundMarkers
    },

    // Retrieve current map bounds.
    getCurrentMapBounds () {
      return this.getMapObject()?.getBounds()
    },

    // Retrieve current map zoom level.
    getCurrentMapZoom () {
      return this.getMapObject()?.getZoom()
    },

    // Reset map view to default center and zoom.
    resetMapView () {
      this.setCenteringPositions()
    }
  }
}
</script>
<style lang="scss">
#copy-icon {
  color: gray;
  padding: 8px;
  border: 2px solid rgba(0,0,0,0.2);
  border-radius: 5px;
  background-color: whitesmoke;
  font-size: 15px;
}
#copy-icon:hover {
  color: black;
}

.custom-tooltip {
  font-size: 14px;
  background-color: gray;
  color: white;
}
.custom-tooltip::before {
  content: none;
}

.loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid black;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.tooltip-container {
  position: relative;
  margin-top: 5px;
  margin-bottom: 4px;
  cursor: pointer;
  display: inline-block;
}

.tooltip {
  position: fixed;
  top: 50%;
  left: 100%;
  white-space: nowrap;
  transform: translate(-2%, -50%);
  background-color: white;
  color: black;
  padding: 5px;
  border: 1px solid #333;
  display: block;
  font-size: 14px;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.map-loader {
  z-index: 400 !important;
  position: absolute;
}
.leaflet-tooltip {
  z-index: 9999 !important;
}
</style>
