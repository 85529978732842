import { api } from '@/global/services/api'

const state = {
  showItem: {
    item: {
      logo: null
    },
    fields: []
  },
  viewConfig: {},
  locations: [],
  locationsViewConfig: {}
}

const getters = {
  showItem: state => state.showItem,
  viewConfig: state => state.viewConfig,
  locations: state => state.locations,
  locationsViewConfig: state => state.locationsViewConfig
}

const actions = {
  async fetch ({ commit, state }, id) {
    try {
      const { config, data } = await api().administration.get(`partners/${id}/details`)

      commit('SET_SHOW_ITEM', data)
      commit('SET_VIEW_CONFIG', config)
    }
    catch (e) {
      console.dir(e)
    }
  },

  async fetchLocations ({ commit, state }, id) {
    try {
      const { config, data } = await api().administration.get(`partners/${id}/locations`)

      commit('SET_LOCATIONS', data)
      commit('SET_LOCATIONS_VIEW_CONFIG', config)
    }
    catch (e) {
      console.dir(e)
    }
  }

}

const mutations = {
  SET_SHOW_ITEM: (state, payload) => (state.showItem = payload),
  SET_VIEW_CONFIG: (state, payload) => (state.viewConfig = payload),
  SET_LOCATIONS: (state, payload) => (state.locations = payload),
  SET_LOCATIONS_VIEW_CONFIG: (state, payload) => (state.locationsViewConfig = payload)
}

export default {
  state,
  getters,
  actions,
  mutations
}
