import { api } from '@/global/services/api'

const state = {
  items: [],
  pagination: {
    count: 0,
    currentPage: 1,
    perPage: 10,
    totalPages: 1,
    total: 0
  },
  sort: {}
}

const getters = {
  list: state => state.items,
  pagination: state => state.pagination,
  sort: state => state.sort
}

const actions = {

  async fetch ({ commit, state }, options) {
    try {
      const params = {
        pagination: options.pagination,
        sort: options.sort,
        street: options.street
      }
      const {
        data,
        pagination,
        sort
      } = await api('road-maintenance').get('search-by-street', params)
      commit('SET_ITEMS', data)
      commit('SET_PAGINATION', pagination)
      commit('SET_SORT', sort)
    }
    catch (e) {
      console.dir(e)
    }
  },

  setEmptyItems ({ commit }) {
    commit('SET_ITEMS', [])
    const pagination = {
      count: 0,
      currentPage: 1,
      perPage: 10,
      totalPages: 1,
      total: 0
    }
    commit('SET_PAGINATION', pagination)
  }

}

const mutations = {

  SET_ITEMS: (state, payload) => (state.items = payload),

  SET_PAGINATION ({ pagination }, payload) {
    const { count, currentPage, perPage, totalPages, total } = payload

    pagination.count = count || 0
    pagination.currentPage = currentPage || 1
    pagination.perPage = perPage || 0
    pagination.totalPages = totalPages || 1
    pagination.total = total || 0
  },

  SET_SORT: (state, payload) => (state.sort = payload)
}

export default {
  state,
  getters,
  actions,
  mutations
}
