<template>
  <v-container
    class="py-8"
  >
    <!-- Column headers -->
    <v-row
      v-if="valuesColumnHeader || keysColumnHeader"
      class="mb-2"
    >
      <v-col
        class="px-1 py-0"
      >
        <v-row
          class="flex-nowrap"
          no-gutters
        >
          <v-col v-if="keysColumnHeader">
            <div>{{ keysColumnHeader }}</div>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        class="px-1 py-0"
      >
        <v-row
          class="flex-nowrap"
          no-gutters
        >
          <v-col v-if="valuesColumnHeader">
            <div>{{ valuesColumnHeader }}</div>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        class="pa-0"
        cols="1"
      />
    </v-row>
    <!-- Input fields -->
    <template
      v-for="(object, index) in formData"
      class="mt-1"
    >
      <v-row :key="`dis-data-${index + 1}`">
        <v-col
          class="px-1 py-0"
        >
          <v-row
            class="flex-nowrap"
            no-gutters
          >
            <v-col>
              <v-text-field
                :value="object.key"
                :type="keysInputType"
                :label="keyLabel"
                :error="checkForError(object.key)"
                outlined
                dense
                min="0"
                @input="updateFormDataKey(index, $event); resetErrorMessage(object.key)"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          class="px-1 py-0"
        >
          <v-row
            class="flex-nowrap"
            no-gutters
          >
            <v-col>
              <v-text-field
                ref="values"
                :value="existingData[object.key]"
                :append-icon="icon"
                :type="valuesInputType"
                outlined
                dense
                min="0"
                @click:append="clickedRowIcon(index)"
                @input="updateFormDataValue(index, $event)"
              />
            </v-col>
          </v-row>
        </v-col>
        <!-- Delete row button -->
        <v-col
          class="pa-0"
          cols="1"
        >
          <v-btn
            text
            x-small
            class="mt-3 mx-2 pa-0 no-background-hover"
            elevation="0"
            @click="removeCurrentRow(index)"
          >
            <v-icon
              color="grey darken-1"
              size="1.8rem"
            >
              mdi-close
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-divider
        v-if="isMobileView"
        :key="`dis-data-divider-${index + 1}`"
        class="mb-7"
      />
    </template>
    <!-- Add new row button -->
    <v-row
      class="mt-0"
    >
      <template>
        <v-row
          no-gutters
        >
          <v-col class="px-1 pt-0">
            <v-btn
              ref="new-row-button"
              class="ml-0 mt-1 di-add-item"
              outlined
              @click="addNewRow"
            >
              <v-icon
                left
                color="success"
              >
                mdi-plus-circle
              </v-icon>
              {{ $t('base.add_dis') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <v-col
        class="pl-0 py-0"
        cols="1"
      />
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'DynamicKeyValueFields',

  props: {
    existingData: {
      type: Object,
      default: null
    },

    existingDataKeys: {
      type: Array,
      default: null
    },

    keysColumnHeader: {
      type: String,
      default: null
    },

    valuesColumnHeader: {
      type: String,
      default: null
    },

    keysInputType: {
      type: String,
      default: 'text'
    },

    valuesInputType: {
      type: String,
      default: 'text'
    },

    keyLabel: {
      type: String,
      default: null
    },

    icon: {
      type: String,
      default: null
    },

    attributeName: {
      type: String,
      required: true
    },

    errorMessages: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      formData: [],
      lastSkippedKey: null
    }
  },

  computed: {
    isMobileView () {
      return this.$vuetify.breakpoint.name === 'xs'
    }
  },

  watch: {},

  mounted () {
    this.setFormData()
  },

  methods: {
    clickedRowIcon (index) {
      this.$emit('icon-click', this.formData[index].key)
      this.$refs.values[index].$refs.input.focus()
    },

    addNewRow () {
      this.formData.push({
        key: '',
        value: 0
      })
      this.scrollOnAddNewRow()
    },

    removeCurrentRow (index) {
      const formDataIndex = this.formData[index]
      if (this.checkKeyExists(formDataIndex.key) && !this.checkItemIsDuplicate(index, formDataIndex.key)) {
        this.$delete(this.existingData, formDataIndex.key)
      }
      this.formData.splice(index, 1)
    },

    updateFormDataKey (index, newKey) {
      if (this.checkKeyNotEmpty(newKey)) {
        const formDataIndex = this.formData[index]

        if (!this.checkKeyExists(newKey)) {
          this.$set(this.existingData, newKey, formDataIndex.value)

          if (this.lastSkippedKey !== formDataIndex.key) {
            this.$delete(this.existingData, formDataIndex.key)
          }
        }
        else {
          this.lastSkippedKey = newKey
          if (!this.checkItemIsDuplicate(index, formDataIndex.key)) {
            this.$delete(this.existingData, formDataIndex.key)
          }
        }

        if (formDataIndex.key !== newKey) {
          formDataIndex.key = newKey
        }
      }
    },

    updateFormDataValue (index, newData) {
      const key = this.formData[index].key
      this.formData[index].value = newData

      if (this.checkKeyNotEmpty(key) && !this.checkItemIsDuplicate(index, key)) {
        this.$set(this.existingData, key, newData)
      }
    },

    checkKeyNotEmpty (key) {
      return key.length > 0
    },

    checkKeyExists (newKey) {
      return this.existingDataKeys.find(key => key === String(newKey))
    },

    checkItemIsDuplicate (index, key) {
      return this.formData.find((item, num) => {
        if (num !== index) {
          return item.key === String(key)
        }
      })
    },

    setFormData () {
      if (this.existingDataKeys.length > 0) {
        this.existingDataKeys.forEach((key) => {
          const data = {
            key: key,
            value: this.existingData[key]
          }

          this.formData.push(data)
        })
      }
    },

    checkForError (fieldKey) {
      const errorKey = `${this.attributeName}.${fieldKey}`
      return errorKey in this.errorMessages && this.errorMessages[errorKey].length > 0
    },

    resetErrorMessage (fieldKey) {
      this.$emit('reset-error-message', fieldKey)
    },

    scrollOnAddNewRow () {
      const ref = this.$refs['new-row-button']

      if (ref) {
        ref.$el.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }
}
</script>

<style scoped lang="scss">
button > span > i,
p {
  line-height: 1;
  font-size: 0.8rem;
  &.hide-content {
    visibility: hidden;
  }
}
</style>
