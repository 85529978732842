export default {
  path: 'excavation-service/excavation-settings/purposes_of_excavation',
  name: 'ExcavationSettingsPurposesOfExcavation',
  meta: {
    title: 'road-maintenance/dropdown-types.excavation_reason',
    create_title: 'road-maintenance/excavation-dropdown-type.create_excavation_reason',
    edit_title: 'road-maintenance/excavation-dropdown-type.edit_excavation_reason',
    delete_title: 'road-maintenance/excavation-dropdown-type.delete_excavation_reason',
    confirm_delete: 'road-maintenance/excavation-dropdown-type.confirm_delete_excavation_reason',
    import_title: 'road-maintenance/excavation-dropdown-type.import_excavation_reason',
    name_label: 'road-maintenance/dropdown-types.excavation_reason',
    name: 'EXCAVATION_REASON'
  },
  component: () => import(/* webpackChunkName: "road-maintenance" */ '../views/ExcavationSettingsIndex')
}
