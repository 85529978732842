export default {
  path: '/profile',
  name: 'ProfileUserOption',
  component: () => import(/* webpackChunkName: "base/login" */ '../views/ProfileView.vue'),
  meta: {
    // title: 'base.profile',
    exclude: false,
    requiresAuth: true,
    needsPermission: true
  }
}
