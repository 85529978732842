<template>
  <div
    class="side-nav"
    :class="{ 'expanded': sideNavExpanded }"
    @mouseenter="mouseEnteredSideNav"
    @mouseleave="mouseLeftSideNav"
  >
    <v-btn
      color="white"
      class="text--primary remove-right-shadow"
      @click="toggle()"
    >
      <svg viewBox="0 0 24 24">
        <path fill="#869DB6" d="M8 12.14V2H6V12.14C4.28 12.59 3 14.14 3 16S4.28 19.41 6 19.86V22H8V19.86C9.72 19.41 11 17.86 11 16S9.72 12.59 8 12.14M7 14C8.1 14 9 14.9 9 16S8.1 18 7 18C5.9 18 5 17.1 5 16S5.9 14 7 14M18 2H16V4.14C14.28 4.59 13 6.14 13 8S14.28 11.41 16 11.86V22H18V11.86C19.72 11.41 21 9.86 21 8S19.72 4.59 18 4.14V2M17 6C18.1 6 19 6.9 19 8S18.1 10 17 10C15.9 10 15 9.1 15 8S15.9 6 17 6Z" />
      </svg>
    </v-btn>
    <div
      class="content px-3 py-1"
      :class="{ 'hidden': !sideNavExpanded }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import { delay } from 'lodash/function'
import { createNamespacedHelpers } from 'vuex'
const {
  mapGetters: mapGettersTracking
} = createNamespacedHelpers('satellite-tracking/live-tracking')

export default {
  name: 'ExpendableSideNav',

  props: {
    initiallyExpended: {
      type: Boolean,
      default: true
    },
    keepExpanded: {
      type: Boolean,
      default: false
    },
    ignoreInternalSelection: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      sideNavExpanded: true,
      sideNavHovered: false,
      collapseTimout: 15,
      timer: 0
    }
  },

  computed: {
    ...mapGettersTracking([
      'getSelectedVehicles'
    ]),

    navbarHasSelection: {
      get () {
        if (this.ignoreInternalSelection) {
          return true
        }

        return !!this.getSelectedVehicles.length
      }
    }
  },

  created () {
    this.sideNavExpanded = this.initiallyExpended
  },

  mounted () {
    this.shouldCloseNavBar()
  },

  methods: {
    shouldCloseNavBar () {
      if (!this.sideNavExpanded) {
        return
      }

      if (!this.sideNavHovered && !this.keepExpanded && this.navbarHasSelection) {
        this.timer++

        if (this.timer === this.collapseTimout) {
          this.collapseNavBar()
        }
      }
      else {
        this.restartTimer()
      }

      delay(() => (this.shouldCloseNavBar()), 1000)
    },

    collapseNavBar () {
      this.sideNavExpanded = false
      this.restartTimer()
    },

    restartTimer () {
      this.timer = 0
    },

    startCollapseTimer () {
      this.sideNavHovered = false
    },

    stopCollapseTimer () {
      this.sideNavHovered = true
    },

    mouseEnteredSideNav () {
      this.stopCollapseTimer()
    },

    mouseLeftSideNav () {
      this.startCollapseTimer()
    },

    toggle () {
      this.sideNavExpanded = !this.sideNavExpanded
      if (this.sideNavExpanded) {
        this.shouldCloseNavBar()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.side-nav {
  position: absolute;
  top: 0.25rem;
  right: 0;
  z-index: 500;
  width: 0;
  -webkit-transition: width 0.3s; /* Safari */
  transition: width 0.3s;
  cursor: pointer;

  .v-btn {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-96%);
    margin: 0 !important;
    border: none;
    z-index: inherit;
    height: 3rem;
    width: 3rem;
    border-top-left-radius: 0.4rem;
    border-bottom-left-radius: 0.4rem;

    svg {
      width: 30px;
      height: 30px;
      padding: 2px;
      border: 2px solid #869DB6;
      border-radius: 4px;
    }

    // removes right shadow
    &.remove-right-shadow {
      clip-path: inset(-2px 2px -3px -2px);
    }

    &:focus::after {
      opacity: 0 !important;
    }
  }

  &.expanded {
    width: 450px;
  }

  .content {
    box-shadow: 2px 2px 6px var(--v-primary-base);
    background: var(--v-white-base);
    min-height: 8rem;
    max-height: 75vh;
    overflow-y: auto;
    transition: transform 0.3s;

    &.hidden {
      transform: translateX(100%);
    }
  }
}

@media screen and (max-width: 500px) {
  .side-nav {
    &.expanded {
      width: 300px;
    }
  }
}
</style>
