<template>
  <div style="text-align: center">
    <speedometer v-bind="properties" />
  </div>
</template>

<script>
import Speedometer from './Speedometer.vue'

export default {
  name: 'CanBusSpeedometer',

  components: { Speedometer },

  props: {
    namespace: {
      type: String,
      required: true
    }
  },

  computed: {
    item () {
      return this.$store.getters[this.namespace + '/showItem'].item
    },
    properties () {
      // Gas level is sent as a percentage, the speedometer component requires it as a fraction
      const gasLevel = (this.item.speedometer_fuel_level ?? 0) / 100
      return {
        // Maximum speed value on the speedometer component is 200 km/h
        speed: (this.item.speedometer_speed ?? 0) / 200,
        // Maximum RPM value on the speedometer component is 8000 RPM
        tacho: (this.item.speedometer_rpm ?? 0) / 8000,
        mileage: Math.round((this.item.speedometer_mileage ?? 0) / 1000),
        gasLevel,
        gas: gasLevel < 0.1 ? (gasLevel < 0.05 ? 2 : 1) : 0,
        doors: this.item.speedometer_doors ? 2 : 0,
        battery: this.item.speedometer_voltage_diff < -1 ? (this.item.speedometer_voltage_diff < -2 ? 2 : 1) : 0,
        engineTemp: this.item.speedometer_engine_temp > 95 ? (this.item.speedometer_engine_temp > 100 ? 2 : 1) : 0,
        speedUnit: this.item.speedometer_speed_unit,
        mileageUnit: this.item.speedometer_mileage_unit,
        dippedBeam: this.item.speedometer_dipped_headlights,
        engineFail: this.item.speedometer_engine_warning,
        brake: this.item.speedometer_handbrake,
        highBeam: this.item.speedometer_full_beam_headlights,
        seatBelt: this.item.speedometer_driver_seatbelt
      }
    }
  }
}
</script>
