var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{directives:[{name:"stripped",rawName:"v-stripped"}],staticClass:"elevation-0",attrs:{"headers":_vm.getHeaders(_vm.tab.table.columns),"items":Object.keys(_vm.dataTableData).length ? _vm.dataTableData[_vm.tab.table.model].resources : [],"options":_vm.options,"loading":_vm.fetchingData,"show-select":false},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.canDelete)?_c('DeleteModal',{attrs:{"view-config":_vm.tab.table,"delete-dialog":_vm.deleteDialog,"delete-id":_vm.deleteId},on:{"delete-confirmed":function($event){return _vm.deleteConfirmed(_vm.dataTableData[_vm.tab.table.model].fetch)},"close-dialog":_vm.closeDeleteDialog}}):_vm._e()]},proxy:true},_vm._l((_vm.tab.table.columns),function(data,column){return {key:_vm.getItemSlot(column),fn:function(ref){
var item = ref.item;
return [_c('span',{key:column},[_vm._v(" "+_vm._s(_vm.getCellDisplayValue(item, column, _vm.tab.table.columns))+" ")])]}}}),{key:"item.active",fn:function(ref){
var item = ref.item;
return [(_vm.itemIsDeactivated(item))?_c('v-icon',{staticClass:"py-3",attrs:{"large":"","color":"success"}},[_vm._v(" mdi-check-circle ")]):_c('v-icon',{staticClass:"py-3",attrs:{"large":"","color":"red"}},[_vm._v(" mdi-close-circle ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","offset-overflow":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.canSeeActions(item))?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical ")]):_vm._e()]}}],null,true)},[_c('v-list',{staticClass:"pa-0"},[(_vm.canBeDeleted(item))?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.openDeleteDialog(item, _vm.tab.table.route_string)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.dataTableData[_vm.tab.table.model].viewConfig.actions.delete.icon || '')+" ")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.dataTableData[_vm.tab.table.model].viewConfig.actions.delete.text || ''))])],1):_vm._e()],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }