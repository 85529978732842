<template>
  <div class="NotFound">
    <v-container>
      <v-layout
        row
        wrap
      >
        <v-flex
          xs12
        >
          <v-card
            color="transparent"
            flat
            class="px-6 pt-3 pb-7 grey lighten-4 text-center rounded-lg elevation-10"
          >
            <v-card-title
              class="justify-center"
            >
              <h2>Redovno održavanje</h2>
            </v-card-title>
            <div
              class="flex justify-space-between"
            >
              <p class=" text-center">
                Poštovani/a, molimo Vas za kratko strpljenje, u tijeku je redovno održavanje aplikacije.
              </p>
              <img
                src="/img/icons/maintanance.gif"
                alt="Maintenance"
                class="my-6"
                style="max-width: 100%"
              >
              <div
                class="my-2 title text-decoration-underline blue--text text--darken-3"
                style="cursor: pointer"
                @click="$router.go(-1)"
              >
                Pokušajte ponovno pristupiti
              </div>
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'MaintenanceView',
  created () {
    setTimeout(() => {
      window.location = localStorage.getItem('LAST_URL')
    }, 30000)
  }
}
</script>
