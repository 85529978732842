<template>
  <v-snackbars
    bottom
    right
    :messages.sync="notificationMessages"
    timeout="4000"
    transition="fade-transition"
  >
    <template #default="{ message }">
      <span v-html="message.message"></span>
    </template>

    <template #action="{ close }">
      <v-btn
        color="primary"
        text
        @click="close()"
      >
        {{ $t('base.close') }}
      </v-btn>
    </template>
  </v-snackbars>
</template>

<script>
import VSnackbars from 'v-snackbars'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('base/notifications')

export default {
  name: 'ShowNotifications',

  components: {
    'v-snackbars': VSnackbars
  },

  computed: {
    ...mapGetters(['messages']),

    notificationMessages: {
      get () {
        return this.messages
      },
      set (value) {
        this.updateMessages(value)
      }
    }
  },

  methods: {
    ...mapActions(['updateMessages'])
  }
}
</script>

<style>
.v-snack {
  bottom: 0.5rem;
  right: 0.5rem;
}

.v-snack__wrapper.theme--dark {
  background-color: #333333 !important;
  color: rgba(255, 255, 255, 0.87);
}
</style>
