import Vue from 'vue'
import Vuex from 'vuex'
import modules from '@/global/loaders/stores'
import FormFieldsStore from '@/global/components/form-fields/store'
import MapTypeStore from '@/global/components/map/tile-layers/store'
import DependantAutocompletesStore from '@/global/components/autocompletes/dependant-autocompletes/store'

const componentsModules = {
  'form-fields': FormFieldsStore,
  'map-type': MapTypeStore,
  'dependant-autocompletes': DependantAutocompletesStore
}

Vue.use(Vuex)

export const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',

  state: {
    isMenuNavOpen: false
  },
  mutations: {
    toggleMenuNav (state, status) {
      state.isMenuNavOpen = status
    }
  },
  actions: {},
  modules: {
    ...modules,
    ...componentsModules
  }
})

export default store
