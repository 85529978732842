<template>
  <div>
    <v-list-item
      exact
      link
      @click="openModal"
    >
      <v-list-item-icon class="mr-3">
        <v-icon size="18">
          {{ menu.icon }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-title class="avatar-menu-text">
        {{ menu.name }}
      </v-list-item-title>
    </v-list-item>
    <component
      :is="currentComponent"
      :menu="menu"
      :open-modal="modalOpened"
      @close-dialog="closeModal"
    />
  </div>
</template>

<script>
export default {
  components: {
    BugReport: () => import('@/modules/base-module/bug-report/views/BugReportView')
  },

  props: {
    menu: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      currentComponent: '',
      modalOpened: false
    }
  },

  mounted () {
    this.setActionComponent()
  },

  methods: {
    setActionComponent () {
      this.currentComponent = this.menu.vue_name
    },

    openModal () {
      this.modalOpened = true
    },

    closeModal () {
      this.modalOpened = false
    }
  }
}
</script>

<style>
.v-dialog {
  max-height: 100% !important;
}
</style>
