<template>
  <v-navigation-drawer
    v-model="drawer"
    :clipped="$vuetify.breakpoint.lgAndUp"
    class="primary-darken-4"
    app
    touchless
  >
    <nested-menu :menu-items="menu" />
  </v-navigation-drawer>
</template>

<script>
import NestedMenu from './MenuItem.vue'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('base/config')

export default {
  name: 'TheNavigationDrawer',

  components: {
    NestedMenu
  },

  data: () => ({
    drawer: null
  }),

  computed: {
    ...mapGetters({
      menu: 'menu',
      user: 'user'
    })
  },

  methods: {
    toggle () {
      this.drawer = !this.drawer
    }
  }
}
</script>
