export default {
  path: '',
  redirect: {
    name: 'NotFound',
    params: ['/locations']
  },
  name: 'Locations',
  component: () => import(/* webpackChunkName: "locations" */ '../views/LocationsIndexView.vue'),
  children: [
    {
      path: 'city',
      name: 'Locations/City',
      component: () => import(/* webpackChunkName: "locations" */ '../views/LocationsIndexView.vue')
    },
    {
      path: 'region',
      name: 'Locations/Region',
      component: () => import(/* webpackChunkName: "locations" */ '../views/LocationsIndexView.vue')
    },
    {
      path: 'country',
      name: 'Locations/Country',
      component: () => import(/* webpackChunkName: "locations" */ '../views/LocationsIndexView.vue')
    },
    {
      path: 'user_defined',
      name: 'Locations/User',
      component: () => import(/* webpackChunkName: "locations" */ '../views/LocationsIndexView.vue')
    },
    {
      path: 'city_district',
      name: 'Locations/City_district',
      component: () => import(/* webpackChunkName: "locations" */ '../views/LocationsIndexView.vue')
    }
  ]
}
