<template>
  <v-row class="align-center">
    <filter-dialog
      :opened.sync="dialog"
      :show-filters="showFilters"
      :date-range="dateRange"
      @set-show-filters="event => $emit('set-show-filters', event)"
      @set-default-filters="setDefaultFilters"
    >
      <filter-dialog-item
        v-for="(value, name) in internalFilters"
        :key="filterKeys[name]"
        :value="value.value"
        :label="value.label"
        :name="name"
        :dependants="value.dependants"
        :reset-data="value.resetData"
        :type="getFilterType(name)"
        :items.sync="value.selectionList"
        :date-range="dateRange"
        :options="value.options"
        :column-config="getColumnConfig(name)"
        @input="event => setValue(value, event)"
        @set-datepicker-filter="event => setDatepickerFilter(event, name, getColumnConfig(name))"
      />
    </filter-dialog>

    <v-chip-group
      :value="activeFilters"
      class="ml-5"
      column
      multiple
    >
      <filter-display-item
        v-for="(value, name) in internalFilters"
        v-show="showDisplayItem(value.value)"
        :key="name"
        :label="value.label"
        :name="name"
        :type="getFilterType(name)"
        :value="value.value"
        :active-status="value.activeStatus"
        :possible-values="value.selectionList"
        :is-default-filter="isDefaultFilter(name)"
        @update:value="event => handleUpdateValue(name, value, event, getColumnConfig(name))"
        @status-update="updateFilterStatus"
        @reset-dates="resetDates"
        @reset-default-filters="resetDefaultFilters"
        @reset-component-data="resetComponentInternalData(name)"
        @set-show-filters="event => $emit('set-show-filters', event)"
        @set-active-status="event => setActiveStatus(event)"
        @input="event => setValue(name, event)"
      />
    </v-chip-group>
  </v-row>
</template>

<script>
import FilterDialogItem from '@/global/components/filters/dialog-filters/FilterDialogItem'
import FilterDisplayItem from '@/global/components/filters/dialog-filters/FilterDisplayItem'
import FilterDialog from '@/global/components/filters/dialog-filters/FilterDialog'
import {
  cloneDeep,
  has,
  isEmpty,
  isObject,
  mapValues
} from 'lodash'
import dayjs from 'dayjs'
import { createNamespacedHelpers } from 'vuex'
import ReconnectingWebSocket from 'reconnecting-websocket'
import filtersMixin from '@/global/components/filters/filtersMixin'
import withoutWatchersMixin from '@/global/mixins/withoutWatchersMixin'

const {
  mapGetters: mapGettersBaseConfig,
  mapActions: mapActionsBaseConfig
} = createNamespacedHelpers('base/config')
const {
  mapState: mapStateDependantAutocompletes
} = createNamespacedHelpers('dependant-autocompletes')

const typesMap = {
  free: 'text',
  icon_tooltip: 'text',
  selection: 'select',
  checkbox: 'checkbox',
  date: 'date',
  datepicker: 'datepicker',
  datepicker_range: 'datepicker_range',
  autocomplete: 'autocomplete',
  many2many_autocomplete: 'autocomplete',
  vehicle_picker_with_list: 'autocomplete',
  person_picker_with_list: 'autocomplete',
  autocomplete_combobox: 'autocomplete_combobox',
  dependant_autocompletes: 'dependant_autocompletes'
}

export default {
  name: 'DialogFilters',

  components: {
    FilterDialogItem,
    FilterDisplayItem,
    FilterDialog
  },

  mixins: [
    filtersMixin,
    withoutWatchersMixin
  ],

  props: {
    viewConfig: {
      type: Object,
      required: true
    },

    showFilters: {
      type: Boolean,
      default: false
    },

    initialFilters: {
      type: Object,
      default: null
    },

    filtersCache: {
      type: Object,
      default: null
    },

    setFiltersCacheKey: {
      type: Function,
      default: null
    },

    resetFiltersCache: {
      type: Function,
      default: null
    },

    filters: {
      type: Object,
      required: true,
      validator: (values) => {
        return Object.values(values).every(filter => {
          // Required
          const isValidType = has(filter, 'type') &&
            Object.keys(typesMap).indexOf(filter.type) !== -1
          // For dependant autocompletes labels are nested inside the dependants object
          const hasLabel = filter.type === 'dependant_autocompletes'
            ? true
            : has(filter, 'label')

          // Optional
          const hasSelectionList = !has(filter, 'selectionList') ? true : (
            filter.selectionList.every(item => has(item, 'value') && has(item, 'text'))
          )

          const hasOptionsIfTypeAutocomplete = !has(filter, 'options') ? true : (
            has(filter.options, 'module') && has(filter.options, 'route')
          )

          return isValidType && hasLabel && hasSelectionList && hasOptionsIfTypeAutocomplete
        })
      }
    }
  },

  data () {
    return {
      activeFilters: [],
      internalFilters: {},
      filterKeys: {},
      dialog: false,
      dateRange: [],
      traccar: null,
      connection: null,
      deviceIgnitionStatuses: {},
      fetchAllowed: false
    }
  },

  computed: {
    ...mapGettersBaseConfig(['traccarToken', 'isCompanyScopeUpdated']),
    ...mapStateDependantAutocompletes({
      depIds: state => state.filters.depIds
    })
  },

  watch: {
    fetchAllowed (fetchAllowed) {
      if (fetchAllowed && this.filtersCache) {
        if (this.filtersCache.activeFilters.length > 0) {
          this.activeFilters = [...this.filtersCache.activeFilters]
        }
        if (!isEmpty(this.filtersCache.internalFilters)) {
          this.internalFilters = { ...this.filtersCache.internalFilters }
          this.triggerChange()
        }
      }
    },

    activeFilters: {
      deep: true,
      handler (activeFilters) {
        if (!activeFilters.length) {
          this.updateInternalFiltersActiveStatuses()

          if (!this.isDefaultFilter) {
            this.$emit('set-show-filters', true)
          }
        }

        if (this.filtersCache) {
          const filtersCache = {
            key: 'activeFilters',
            value: [...activeFilters]
          }
          this.setFiltersCacheKey(filtersCache)
        }
      }
    },

    showFilters: {
      immediate: true,
      handler (showFilters) {
        if (showFilters) this.dialog = showFilters
      }
    },

    depIds: {
      deep: true,
      handler (depIds) {
        for (const key in this.internalFilters) {
          if (this.internalFilters[key].type === 'dependant_autocompletes') {
            this.$set(this.internalFilters[key], 'value', depIds)
          }
        }
      }
    },

    filters: {
      immediate: true,
      deep: true,
      handler (newFilters) {
        if (this.filtersCache && !isEmpty(this.filtersCache.internalFilters)) {
          this.internalFilters = { ...this.filtersCache.internalFilters }
        }
        else {
          const filters = cloneDeep(newFilters)

          this.internalFilters = mapValues(filters, (filter, key) => {
            let value = null
            if (!isEmpty(this.initialFilters) && key in this.initialFilters) {
              if (filter.type === 'datepicker_range') {
                value = {
                  from_date: this.initialFilters[key].from_date,
                  to_date: this.initialFilters[key].to_date
                }
              }
            }

            return {
              ...filter,
              value,
              resetData: false,
              activeStatus: value !== null
            }
          })
        }

        const date = new Date()
        // We generate filter keys object with filter name as the key, and filter name
        // plus the current timestamp for the value. We than use value from the filters key
        // object as the "key" prop when looping over filters, ensuring that they will rerender correctly
        this.filterKeys = Object.keys(this.internalFilters).reduce((object, item) => {
          object[item] = item + '_' + date.getTime()
          return object
        }, {})
      }
    },

    initialFilters: {
      deep: true,
      immediate: true,
      handler (initialFilters) {
        const filters = cloneDeep(this.filters)
        this.internalFilters = mapValues(filters, filter => {
          return {
            ...filter,
            value: null,
            resetData: false,
            activeStatus: true
          }
        })

        const date = new Date()
        // We generate filter keys object with filter name as the key, and filter name
        // plus the current timestamp for the value. We than use value from the filters key
        // object as the "key" prop when looping over filters, ensuring that they will rerender correctly
        this.filterKeys = Object.keys(this.internalFilters).reduce((object, item) => {
          object[item] = item + '_' + date.getTime()
          return object
        }, {})

        this.setDefaultFilters()
      }
    },

    internalFilters: {
      deep: true,
      handler (internalFilters, oldInternalFilters) {
        if (this.filtersCache && !isEmpty(oldInternalFilters)) {
          if (this.filtersCache.activeFilters.length > 0) {
            for (const key in internalFilters) {
              if (this.filtersCache.activeFilters.includes(key)) {
                const internalFilter = internalFilters[key]
                const oldInternalFilter = oldInternalFilters[key]
                if (isEmpty(internalFilter.value) && !!oldInternalFilter.value) {
                  this.$withoutWatchers(() => (this.internalFilters[key] = { ...oldInternalFilter }))
                }
              }
            }
          }
          if (this.filtersCache) {
            const filtersCache = {
              key: 'internalFilters',
              value: cloneDeep(internalFilters)
            }
            this.setFiltersCacheKey(filtersCache)
          }
        }
      }
    },

    dialog (dialog) {
      !dialog && this.triggerChange()
    },

    async isCompanyScopeUpdated () {
      await this.fetchTraccarToken()
      this.unsetCompanyScopeUpdated()
    }
  },

  async created () {
    this.fetchAllowed = true

    window.addEventListener('unload', this.resetFiltersCache)
  },

  beforeDestroy () {
    if (this.filtersCache) {
      window.removeEventListener('unload', this.resetFiltersCache)
    }
  },

  methods: {
    ...mapActionsBaseConfig([
      'fetchTraccarToken',
      'unsetCompanyScopeUpdated'
    ]),

    updateInternalFiltersActiveStatuses () {
      const internalFilters = cloneDeep(this.internalFilters)

      for (const key in internalFilters) {
        const internalFilter = internalFilters[key]
        if (internalFilter.activeStatus) {
          internalFilter.activeStatus = false
        }
        if (internalFilter.value !== null) {
          internalFilter.value = null
        }
      }

      this.internalFilters = Object.assign({}, internalFilters)
    },

    setValue (value, event) {
      value.value = event
      if (event !== null) {
        this.$set(value, 'value', event)
        this.$set(value, 'activeStatus', true)
      }
    },

    setActiveStatus ({ name, activeStatus }) {
      const internalFilter = { ...this.internalFilters[name] }
      internalFilter.activeStatus = activeStatus
      this.$set(this.internalFilters, name, internalFilter)
    },

    handleUpdateValue (name, value, event, columnConfig) {
      if (!event) {
        if (this.filtersCache && this.filtersCache.activeFilters.includes(name)) {
          this.activeFilters = this.activeFilters.filter(filter => filter !== name)
          const filtersCache = {
            key: 'activeFilters',
            value: [...this.activeFilters]
          }
          this.setFiltersCacheKey(filtersCache)
        }

        const isInitialFilter = !!this.initialFilters && (name in this.initialFilters)
        if (!isInitialFilter) {
          value.value = event
          if (columnConfig.type === 'datepicker_range') {
            this.dateRange.splice(0)
          }
        }
        else {
          if (name === 'datetime') {
            this.dateRange.splice(0)
            this.setDefaultFilters()
          }
        }

        return
      }

      if (!isEmpty(value)) {
        if (!('value' in value)) return null

        if (name === 'datetime') {
          if (!value) this.dateRange.splice(0)
          return
        }

        return value.value
      }
    },

    setDefaultFilters () {
      if (!isEmpty(this.internalFilters)) {
        const internalFilters = { ...this.internalFilters }
        for (const key in internalFilters) {
          if (this.initialFilters && key in this.initialFilters) {
            if (internalFilters[key].type === 'datepicker_range') {
              const obj = {
                from_date: this.initialFilters[key].from_date,
                to_date: this.initialFilters[key].to_date
              }
              internalFilters[key].value = Object.assign({}, obj)
              if (!this.activeFilters.includes(key)) this.activeFilters.push(key)
            }
          }
        }

        this.internalFilters = { ...internalFilters }
      }
    },

    updateFilterStatus ({ name, isActive }) {
      if (isActive) {
        if (!this.activeFilters.includes(name)) {
          this.activeFilters.push(name)
        }
      }
      else {
        this.activeFilters = this.activeFilters.filter(filter => filter !== name)
      }

      this.triggerChange()
    },

    getFilterType (filter) {
      return typesMap[this.internalFilters[filter].type]
    },

    // Calculates whether filter should be visible or not.
    showDisplayItem (value) {
      // If value is object check if each its value is empty.
      if (isObject(value)) {
        return !Object.values(value).every(val => [null, undefined].includes(val))
      }
      return ![null, undefined].includes(value)
    },

    triggerChange () {
      if (this.dialog) return

      const payload = this.getActiveFiltersPayload()

      this.$emit('change', payload)
    },

    // Add additional `resetData` field to the filter.
    setResetData (key, resetData) {
      const updateValue = { ...this.internalFilters[key], resetData: resetData }
      this.$set(this.internalFilters, key, updateValue)
    },

    setDatepickerFilter (dateRange, columnName, columnConfig) {
      this.dateRange = [...dateRange]

      if ('date' in this.internalFilters) {
        this.updateFilterStatus({ name: 'date', isActive: true })
      }

      if (columnConfig.allow_one_date && dateRange.length === 1) {
        this.internalFilters[columnName].value = { [columnConfig.date_range_type]: dateRange[0] }
      }
      else {
        this.internalFilters[columnName].value = this.getDateRangeValues(dateRange)
      }
    },

    getDateRangeValues (dateRange) {
      let fromIndex, toIndex
      if (dateRange.length === 1) {
        fromIndex = 0
      }
      else if (dateRange.length === 2) {
        [fromIndex, toIndex] = dayjs(dateRange[0]).isBefore(dayjs(dateRange[1]))
          ? [0, 1]
          : [1, 0]
      }

      return {
        from_date: dateRange[fromIndex],
        to_date: dateRange[toIndex]
      }
    },

    resetDates () {
      this.dateRange = []
    },

    resetDefaultFilters () {
      this.setDefaultFilters()
      this.triggerChange()
    },

    resetComponentInternalData (name) {
      const updateValue = { ...this.internalFilters[name], resetData: true }
      this.$set(this.internalFilters, name, updateValue)
    },

    openSocket () {
      // Replace the http protocol with ws
      const websocketOrigin = window.location.origin.replace('http', 'ws')

      this.connection = new ReconnectingWebSocket(async () => {
        // Make sure the session is refreshed before any connection attempt
        await this.traccar.openSession()
        return websocketOrigin + '/api/tracking/socket'
      })

      this.connection.onmessage = (evt) => {
        let message
        try {
          message = JSON.parse(evt.data)
        }
        catch (e) {
          message = ''
        }

        if (message.positions) {
          // Get device statuses from positions in following format: { deviceId: status }
          this.deviceIgnitionStatuses = message.positions.reduce((result, position) => {
            // Check if position is outdated (older than 15 minutes)
            const outdated = dayjs().isAfter(
              dayjs(position.deviceTime).add(15, 'minutes')
            )

            // Assign correct device status based on outdated value and ignition attribute
            let status = ''
            if (outdated) {
              status = 'unknown'
            }
            else {
              switch (position.attributes.ignition ?? null) {
                case true:
                  status = 'on'
                  break
                case false:
                  status = 'off'
                  break
                default:
                  status = 'unknown'
              }
            }

            // Add device status to result object
            return {
              ...result,
              [position.deviceId]: status
            }
          }, {})

          this.fetchAllowed = true

          // Close websocket connection after processing device statuses
          this.closeSocket()
        }
      }

      this.connection.onerror = (error) => {
        console.log('WebSocket error: ' + error)
      }
    },

    closeSocket () {
      this.traccar.closeSession()
      if (this.connection) this.connection.close()
    },

    getColumnConfig (columnName) {
      return this.viewConfig && this.viewConfig.columns && this.viewConfig.columns[columnName] ? this.viewConfig.columns[columnName] : {}
    },

    isDefaultFilter (name) {
      return this.initialFilters && name in this.initialFilters
    }
  }
}
</script>
