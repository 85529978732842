<template>
  <v-list
    dense
    nav
  >
    <template
      v-for="item in menuItems"
    >
      <template
        v-if="hasChildren(item)"
      >
        <v-list-group
          :key="item.route"
          :group="item.route"
          class="v-drawer-text-style"
          no-action
        >
          <template #prependIcon>
            <v-icon class="v-drawer-icon-color">
              {{ item.icon }}
            </v-icon>
          </template>
          <template #activator>
            <v-list-item-content>
              <v-list-item-title class="v-drawer-text-style">
                {{ item.name }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template #appendIcon>
            <v-icon class="v-drawer-icon-color">
              mdi-chevron-down
            </v-icon>
          </template>

          <nested-menu
            :menu-items="item.children"
          />
        </v-list-group>
      </template>
      <template
        v-else
      >
        <v-list-item
          :key="item.route"
          :exact="item.route === '/'"
          class="v-drawer-text-style"
          color="white"
          :to="{ name: item.vue_name }"
          link
          @click="navigationDrawerItemClicked(item)"
        >
          <v-list-item-icon>
            <v-icon class="v-drawer-icon-color">
              {{ item.icon }}
            </v-icon>
            <v-badge
              v-if="item.vue_name === 'Home' && unreadNewsCount"
              color="red"
              :content="unreadNewsCount"
              overlap
            />
          </v-list-item-icon>

          <v-list-item-title class="v-drawer-text-style">
            {{ item.name }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </template>
  </v-list>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('base/config')
const {
  mapActions: mapActionsSendSms
} = createNamespacedHelpers('sms/send-sms')

export default {
  name: 'NestedMenu',
  props: {
    menuItems: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      menu: 'menu',
      user: 'user'
    }),
    unreadNewsCount () {
      return this.user.unread_news?.unread_news_count ? this.user.unread_news.unread_news_count : 0
    }
  },
  methods: {
    ...mapActionsSendSms({
      setDialogSendSms: 'setDialog'
    }),

    ...mapActions({
      resetUserReadNewsCount: 'resetUserReadNewsCount'
    }),

    navigationDrawerItemClicked (item) {
      if (item.route === '/') {
        this.resetUserReadNewsCount()
      }
      this.childItemClicked(item)
    },

    hasChildren (item) {
      return !isEmpty(item.children)
    },

    childItemClicked (child) {
      if (child.type === 'modal') {
        this.openModal(child)
      }

      if (child.vue_name === this.$route.name) {
        return
      }

      this.toggleMenuNav()
    },

    openModal (child) {
      const setDialogMethod = `setDialog${child.vue_name}`
      this[setDialogMethod](true)
    },

    toggleMenuNav () {
      if (this.$store.state.isMenuNavOpen) {
        setTimeout(() => this.$store.commit('toggleMenuNav', false), 80)
      }
    }
  },
  components: {
    NestedMenu: this
  }
}
</script>

<style scoped>
.v-drawer-text-style{
  color: #65778C;
  font-size: 12px !important;
  font-weight: 500;
  line-height: 1rem;
}

.v-drawer-icon-color{
  color: #65778C;
}

.v-list-item--active .v-drawer-text-style, .v-list-item--active .v-drawer-icon-color{
  color: white !important;
}
</style>
