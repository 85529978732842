<template>
  <div style="display: inline-block">
    <canvas
      ref="canvas"
      width="500"
      height="250"
    />
    <img
      ref="icons"
      src="/img/speedometer-icons.svg"
      style="display: none"
      @load="draw"
    >
  </div>
</template>

<script>
import { draw as drawSpeedometer } from '@/modules/fleet-module/components/speedometer.js'

export default {
  name: 'Speedometer',

  props: {
    // Arrow indicators
    speed: {
      type: Number,
      default: 0
    },
    tacho: {
      type: Number,
      default: 0
    },
    gasLevel: {
      type: Number,
      default: 0
    },
    // Mileage indicator
    mileage: {
      type: Number,
      default: 0
    },
    // Turn signals
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    // Icons - center
    dippedBeam: {
      type: Number,
      default: 0
    },
    brake: {
      type: Number,
      default: 0
    },
    drift: {
      type: Number,
      default: 0
    },
    highBeam: {
      type: Number,
      default: 0
    },
    lock: {
      type: Number,
      default: 0
    },
    seatBelt: {
      type: Number,
      default: 0
    },
    engineTemp: {
      type: Number,
      default: 0
    },
    stab: {
      type: Number,
      default: 0
    },
    abs: {
      type: Number,
      default: 0
    },
    // Icons - right
    gas: {
      type: Number,
      default: 0
    },
    trunk: {
      type: Number,
      default: 0
    },
    bonnet: {
      type: Number,
      default: 0
    },
    doors: {
      type: Number,
      default: 0
    },
    // Icons - left
    battery: {
      type: Number,
      default: 0
    },
    oil: {
      type: Number,
      default: 0
    },
    engineFail: {
      type: Number,
      default: 0
    },
    speedUnit: {
      type: String,
      default: 'km/h'
    },
    mileageUnit: {
      type: String,
      default: 'Km'
    }
  },

  computed: {
    values () {
      return [
        this.speed,
        this.tacho,
        this.gasLevel,
        this.mileage
      ]
    },
    turnSignals () {
      return {
        left: this.left,
        right: this.right
      }
    },
    icons () {
      return {
        dippedBeam: this.dippedBeam,
        brake: this.brake,
        drift: this.drift,
        highBeam: this.highBeam,
        lock: this.lock,
        seatBelt: this.seatBelt,
        engineTemp: this.engineTemp,
        stab: this.stab,
        abs: this.abs,
        gas: this.gas,
        trunk: this.trunk,
        bonnet: this.bonnet,
        doors: this.doors,
        battery: this.battery,
        oil: this.oil,
        engineFail: this.engineFail
      }
    }
  },

  watch: {
    values () {
      this.draw()
    },
    turnSignals () {
      this.draw()
    },
    icons () {
      this.draw()
    }
  },

  mounted () {
    this.draw()
  },

  methods: {
    draw () {
      drawSpeedometer(
        this.$refs.canvas,
        this.$refs.icons,
        this.speed,
        this.tacho,
        this.gasLevel,
        this.mileage,
        this.turnSignals,
        this.icons,
        this.speedUnit,
        this.mileageUnit
      )
    }
  }
}
</script>
