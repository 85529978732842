<template>
  <div class="d-flex" :class="{ 'justify-center': center }">
    <input
      :id="uniqueName"
      type="checkbox"
      :checked="checkedStatus"
      @click.stop
    >
    <label
      :for="uniqueName"
      class="check-trail"
      :class="{ 'disabled': disabled || loading || pending }"
      @click.stop="toggle"
    >
      <span class="check-handler" v-if="!disabled && !loading && !pending" />
      <v-progress-circular
        v-if="loading || pending"
        class="mx-auto"
        indeterminate
        width="1"
        size="10"
      />
    </label>
  </div>
</template>

<script>
import { api } from '@/global/services/api'

export default {
  name: 'IconToggleField',

  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    name: {
      type: String,
      required: true
    },
    config: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: null
    },
    center: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      loading: false
    }
  },

  computed: {
    uniqueName () {
      return `${this.name}_${this.item.id}`
    },

    checkedStatus () {
      return !!this.item[this.config.field]
    },

    disabled () {
      return this.item[this.config.field] === null || this.item[this.config.field] === undefined
    },

    pending () {
      return this.item[this.config.field] === 'pending'
    }
  },

  methods: {
    async toggle () {
      this.loading = true

      await api()[this.config.toggle_action.module]
        .post(this.config.toggle_action.route.replace(':id', this.item.id))
      this.loading = false
    }
  }
}
</script>

<style scoped lang="scss">
/* Hide the input */
input[type="checkbox"] {
  display: none;
  opacity: 0;
  z-index: -1;
}

.check-trail {
  display: flex;
  align-items: center;
  width: 2.8em;
  height: 1.2em;
  background: $icon-toggle-inactive-bg;
  border-radius: 2.1em;
  transition: $icon-toggle-transition;
  cursor: pointer;

  &.disabled {
    background: $icon-toggle-disabled-bg !important;
    pointer-events: none !important;
    cursor: default !important;
  }
}

.check-handler {
  display: flex;
  margin-left: 0.2em;
  justify-content: center;
  align-items: center;
  width: 0.9em;
  height: 0.9em;
  background: $icon-toggle-handler-bg;
  border-radius: 50%;
  transition: $icon-toggle-transition;
  box-shadow: 0 0 8px rgba(0,0,0,0.3);
}

input[type="checkbox"]:checked + .check-trail {
  background: $icon-toggle-active-bg;

  .check-handler {
    margin-left: 1.3rem;
    background: $icon-toggle-handler-bg;
  }
}
</style>
