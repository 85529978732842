const temperatureSensorsData = [
  {
    sensorName: 'Senzor 1',
    sensorData: [
      {
        x: '2021-10-15 00:00:00',
        y: 8,
        vehicleSpeed: 50,
        lat: 45.815,
        lng: 15.918
      },
      {
        x: '2021-10-15 01:00:00',
        y: 8,
        vehicleSpeed: 50,
        lat: 45.915,
        lng: 16.018
      },
      {
        x: '2021-10-15 02:00:00',
        y: 8,
        vehicleSpeed: 50,
        lat: 46.015,
        lng: 16.118
      },
      {
        x: '2021-10-15 03:00:00',
        y: 8,
        vehicleSpeed: 50,
        lat: 46.115,
        lng: 16.218
      },
      {
        x: '2021-10-15 04:00:00',
        y: 8,
        vehicleSpeed: 50,
        lat: 46.215,
        lng: 16.318
      },
      {
        x: '2021-10-15 05:00:00',
        y: 8,
        veh9eSpeed: 50,
        lat: 46.315,
        lng: 16.418
      },
      {
        x: '2021-10-15 06:00:00',
        y: 9,
        vehicleSpeed: 50,
        lat: 46.415,
        lng: 16.518
      },
      {
        x: '2021-10-15 07:00:00',
        y: 9,
        vehicleSpeed: 50,
        lat: 46.515,
        lng: 16.618
      },
      {
        x: '2021-10-15 08:00:00',
        y: 9,
        vehicleSpeed: 50,
        lat: 46.615,
        lng: 16.718
      },
      {
        x: '2021-10-15 09:00:00',
        y: 9,
        vehicleSpeed: 50,
        lat: 46.715,
        lng: 16.818
      },
      {
        x: '2021-10-15 10:00:00',
        y: 9,
        veh15eSpeed: 50,
        lat: 46.815,
        lng: 16.918
      },
      {
        x: '2021-10-15 11:00:00',
        y: 15,
        vehicleSpeed: 50,
        lat: 46.915,
        lng: 17.018
      },
      {
        x: '2021-10-15 12:00:00',
        y: 15,
        vehicleSpeed: 50,
        lat: 47.015,
        lng: 17.118
      },
      {
        x: '2021-10-15 13:00:00',
        y: 15,
        vehicleSpeed: 63,
        lat: 47.115,
        lng: 17.218
      },
      {
        x: '2021-10-15 14:00:00',
        y: 15,
        vehicleSpeed: 63,
        lat: 47.215,
        lng: 17.318
      },
      {
        x: '2021-10-15 15:00:00',
        y: 15,
        veh15eSpeed: 63,
        lat: 47.315,
        lng: 17.418
      },
      {
        x: '2021-10-15 16:00:00',
        y: 15,
        vehicleSpeed: 63,
        lat: 47.415,
        lng: 17.518
      },
      {
        x: '2021-10-15 17:00:00',
        y: 15,
        vehicleSpeed: 63,
        lat: 47.515,
        lng: 17.618
      },
      {
        x: '2021-10-15 18:00:00',
        y: 15,
        veh14leSpeed: 63,
        lat: 47.615,
        lng: 17.718
      },
      {
        x: '2021-10-15 19:00:00',
        y: 14,
        vehicleSpeed: 63,
        lat: 47.715,
        lng: 17.818
      },
      {
        x: '2021-10-15 20:00:00',
        y: 14,
        vehicleSpeed: 63,
        lat: 47.815,
        lng: 17.918
      },
      {
        x: '2021-10-15 21:00:00',
        y: 12,
        vehicleSpeed: 63,
        lat: 47.915,
        lng: 18.018
      },
      {
        x: '2021-10-15 22:00:00',
        y: 12,
        vehicleSpeed: 63,
        lat: 48.015,
        lng: 18.118
      },
      {
        x: '2021-10-15 23:00:00',
        y: 10,
        vehicleSpeed: 63,
        lat: 48.115,
        lng: 18.218
      },
      {
        x: '2021-10-15 24:00:00',
        y: 10,
        vehicleSpeed: 63,
        lat: 48.215,
        lng: 18.318
      },
      {
        x: '2021-10-16 00:00:00',
        y: 7,
        vehicleSpeed: 63,
        lat: 48.315,
        lng: 18.418
      },
      {
        x: '2021-10-16 01:00:00',
        y: 7,
        vehicleSpeed: 70,
        lat: 48.415,
        lng: 18.518
      },
      {
        x: '2021-10-16 02:00:00',
        y: 6,
        vehicleSpeed: 70,
        lat: 48.515,
        lng: 18.618
      },
      {
        x: '2021-10-16 03:00:00',
        y: 6,
        vehicleSpeed: 70,
        lat: 48.615,
        lng: 18.718
      },
      {
        x: '2021-10-16 04:00:00',
        y: 5,
        vehicleSpeed: 70,
        lat: 48.715,
        lng: 18.818
      },
      {
        x: '2021-10-16 05:00:00',
        y: 5,
        vehicleSpeed: 70,
        lat: 48.815,
        lng: 18.918
      },
      {
        x: '2021-10-16 06:00:00',
        y: 5,
        vehicleSpeed: 70,
        lat: 48.915,
        lng: 19.018
      },
      {
        x: '2021-10-16 07:00:00',
        y: 5,
        vehicleSpeed: 45,
        lat: 49.015,
        lng: 19.118
      },
      {
        x: '2021-10-16 08:00:00',
        y: 6,
        vehicleSpeed: 45,
        lat: 49.115,
        lng: 19.218
      },
      {
        x: '2021-10-16 09:00:00',
        y: 6,
        vehicleSpeed: 45,
        lat: 49.215,
        lng: 19.318
      },
      {
        x: '2021-10-16 10:00:00',
        y: 6,
        vehicleSpeed: 45,
        lat: 49.315,
        lng: 19.418
      },
      {
        x: '2021-10-16 11:00:00',
        y: 6,
        vehicleSpeed: 45,
        lat: 49.415,
        lng: 19.518
      },
      {
        x: '2021-10-16 12:00:00',
        y: 7,
        vehicleSpeed: 45,
        lat: 49.515,
        lng: 19.618
      },
      {
        x: '2021-10-16 13:00:00',
        y: 7,
        vehicleSpeed: 50,
        lat: 49.615,
        lng: 19.718
      },
      {
        x: '2021-10-16 14:00:00',
        y: 7,
        vehicleSpeed: 50,
        lat: 49.715,
        lng: 19.818
      },
      {
        x: '2021-10-16 15:00:00',
        y: 7,
        vehicleSpeed: 50,
        lat: 49.815,
        lng: 19.918
      },
      {
        x: '2021-10-16 16:00:00',
        y: 9,
        vehicleSpeed: 50,
        lat: 49.915,
        lng: 20.018
      },
      {
        x: '2021-10-16 17:00:00',
        y: 9,
        vehicleSpeed: 50,
        lat: 50.015,
        lng: 20.118
      },
      {
        x: '2021-10-16 18:00:00',
        y: 9,
        vehicleSpeed: 50,
        lat: 50.115,
        lng: 20.218
      },
      {
        x: '2021-10-16 19:00:00',
        y: 9,
        vehicleSpeed: 48,
        lat: 50.215,
        lng: 20.318
      },
      {
        x: '2021-10-16 20:00:00',
        y: 8,
        vehicleSpeed: 48,
        lat: 50.315,
        lng: 20.418
      },
      {
        x: '2021-10-16 21:00:00',
        y: 8,
        vehicleSpeed: 48,
        lat: 50.415,
        lng: 20.518
      },
      {
        x: '2021-10-16 22:00:00',
        y: 8,
        vehicleSpeed: 48,
        lat: 50.515,
        lng: 20.618
      },
      {
        x: '2021-10-16 23:00:00',
        y: 8,
        vehicleSpeed: 48,
        lat: 50.615,
        lng: 20.718
      },
      {
        x: '2021-10-16 24:00:00',
        y: 7,
        vehicleSpeed: 48,
        lat: 50.715,
        lng: 20.818
      },
      {
        x: '2021-10-17 00:00:00',
        y: 7,
        vehicleSpeed: 65,
        lat: 50.815,
        lng: 20.918
      },
      {
        x: '2021-10-17 01:00:00',
        y: 7,
        vehicleSpeed: 65,
        lat: 50.915,
        lng: 21.018
      },
      {
        x: '2021-10-17 02:00:00',
        y: 7,
        vehicleSpeed: 65,
        lat: 51.015,
        lng: 21.118
      },
      {
        x: '2021-10-17 03:00:00',
        y: 6,
        vehicleSpeed: 65,
        lat: 51.115,
        lng: 21.218
      },
      {
        x: '2021-10-17 04:00:00',
        y: 6,
        vehicleSpeed: 65,
        lat: 51.215,
        lng: 21.318
      },
      {
        x: '2021-10-17 05:00:00',
        y: 6,
        vehicleSpeed: 65,
        lat: 51.315,
        lng: 21.418
      },
      {
        x: '2021-10-17 06:00:00',
        y: 6,
        vehicleSpeed: 70,
        lat: 51.415,
        lng: 21.518
      },
      {
        x: '2021-10-17 07:00:00',
        y: 8,
        vehicleSpeed: 70,
        lat: 51.515,
        lng: 21.618
      },
      {
        x: '2021-10-17 08:00:00',
        y: 8,
        vehicleSpeed: 70,
        lat: 51.615,
        lng: 21.718
      },
      {
        x: '2021-10-17 09:00:00',
        y: 8,
        vehicleSpeed: 70,
        lat: 51.715,
        lng: 21.818
      },
      {
        x: '2021-10-17 10:00:00',
        y: 8,
        vehicleSpeed: 70,
        lat: 51.815,
        lng: 21.918
      },
      {
        x: '2021-10-17 11:00:00',
        y: 11,
        vehicleSpeed: 70,
        lat: 51.915,
        lng: 22.018
      },
      {
        x: '2021-10-17 12:00:00',
        y: 11,
        vehicleSpeed: 92,
        lat: 52.015,
        lng: 22.118
      },
      {
        x: '2021-10-17 13:00:00',
        y: 11,
        vehicleSpeed: 92,
        lat: 52.115,
        lng: 22.218
      },
      {
        x: '2021-10-17 14:00:00',
        y: 11,
        vehicleSpeed: 92,
        lat: 52.215,
        lng: 22.318
      },
      {
        x: '2021-10-17 15:00:00',
        y: 12,
        vehicleSpeed: 92,
        lat: 52.315,
        lng: 22.418
      },
      {
        x: '2021-10-17 16:00:00',
        y: 12,
        vehicleSpeed: 92,
        lat: 52.415,
        lng: 22.518
      },
      {
        x: '2021-10-17 17:00:00',
        y: 12,
        vehicleSpeed: 92,
        lat: 52.515,
        lng: 22.618
      },
      {
        x: '2021-10-17 18:00:00',
        y: 12,
        vehicleSpeed: 92,
        lat: 52.615,
        lng: 22.718
      },
      {
        x: '2021-10-17 19:00:00',
        y: 9,
        vehicleSpeed: 92,
        lat: 52.715,
        lng: 22.818
      },
      {
        x: '2021-10-17 20:00:00',
        y: 9,
        vehicleSpeed: 92,
        lat: 52.815,
        lng: 22.918
      },
      {
        x: '2021-10-17 21:00:00',
        y: 9,
        vehicleSpeed: 92,
        lat: 52.915,
        lng: 23.018
      },
      {
        x: '2021-10-17 22:00:00',
        y: 9,
        vehicleSpeed: 92,
        lat: 53.015,
        lng: 23.118
      },
      {
        x: '2021-10-17 23:00:00',
        y: 7,
        vehicleSpeed: 92,
        lat: 53.115,
        lng: 23.218
      },
      {
        x: '2021-10-17 24:00:00',
        y: 7,
        vehicleSpeed: 40,
        lat: 53.215,
        lng: 23.318
      }
    ]
  },
  {
    sensorName: 'Senzor mjerenja temperature u kabini',
    sensorData: [
      {
        x: '2021-10-15 00:00:00',
        y: 24,
        vehicleSpeed: 50,
        lat: 45.815,
        lng: 15.918
      },
      {
        x: '2021-10-15 01:00:00',
        y: 24,
        vehicleSpeed: 50,
        lat: 45.915,
        lng: 16.018
      },
      {
        x: '2021-10-15 02:00:00',
        y: 24,
        vehicleSpeed: 50,
        lat: 46.015,
        lng: 16.118
      },
      {
        x: '2021-10-15 03:00:00',
        y: 24,
        vehicleSpeed: 50,
        lat: 46.115,
        lng: 16.218
      },
      {
        x: '2021-10-15 04:00:00',
        y: 24,
        vehicleSpeed: 50,
        lat: 46.215,
        lng: 16.318
      },
      {
        x: '2021-10-15 05:00:00',
        y: 24,
        veh9eSpeed: 50,
        lat: 46.315,
        lng: 16.418
      },
      {
        x: '2021-10-15 06:00:00',
        y: 26,
        vehicleSpeed: 50,
        lat: 46.415,
        lng: 16.518
      },
      {
        x: '2021-10-15 07:00:00',
        y: 26,
        vehicleSpeed: 50,
        lat: 46.515,
        lng: 16.618
      },
      {
        x: '2021-10-15 08:00:00',
        y: 26,
        vehicleSpeed: 50,
        lat: 46.615,
        lng: 16.718
      },
      {
        x: '2021-10-15 09:00:00',
        y: 26,
        vehicleSpeed: 50,
        lat: 46.715,
        lng: 16.818
      },
      {
        x: '2021-10-15 10:00:00',
        y: 26,
        veh15eSpeed: 50,
        lat: 46.815,
        lng: 16.918
      },
      {
        x: '2021-10-15 11:00:00',
        y: 30,
        vehicleSpeed: 50,
        lat: 46.915,
        lng: 17.018
      },
      {
        x: '2021-10-15 12:00:00',
        y: 30,
        vehicleSpeed: 50,
        lat: 47.015,
        lng: 17.118
      },
      {
        x: '2021-10-15 13:00:00',
        y: 30,
        vehicleSpeed: 63,
        lat: 47.115,
        lng: 17.218
      },
      {
        x: '2021-10-15 14:00:00',
        y: 30,
        vehicleSpeed: 63,
        lat: 47.215,
        lng: 17.318
      },
      {
        x: '2021-10-15 15:00:00',
        y: 30,
        veh15eSpeed: 63,
        lat: 47.315,
        lng: 17.418
      },
      {
        x: '2021-10-15 16:00:00',
        y: 30,
        vehicleSpeed: 63,
        lat: 47.415,
        lng: 17.518
      },
      {
        x: '2021-10-15 17:00:00',
        y: 30,
        vehicleSpeed: 63,
        lat: 47.515,
        lng: 17.618
      },
      {
        x: '2021-10-15 18:00:00',
        y: 30,
        veh14leSpeed: 63,
        lat: 47.615,
        lng: 17.718
      },
      {
        x: '2021-10-15 19:00:00',
        y: 29,
        vehicleSpeed: 63,
        lat: 47.715,
        lng: 17.818
      },
      {
        x: '2021-10-15 20:00:00',
        y: 29,
        vehicleSpeed: 63,
        lat: 47.815,
        lng: 17.918
      },
      {
        x: '2021-10-15 21:00:00',
        y: 25,
        vehicleSpeed: 63,
        lat: 47.915,
        lng: 18.018
      },
      {
        x: '2021-10-15 22:00:00',
        y: 25,
        vehicleSpeed: 63,
        lat: 48.015,
        lng: 18.118
      },
      {
        x: '2021-10-15 23:00:00',
        y: 25,
        vehicleSpeed: 63,
        lat: 48.115,
        lng: 18.218
      },
      {
        x: '2021-10-15 24:00:00',
        y: 25,
        vehicleSpeed: 63,
        lat: 48.215,
        lng: 18.318
      },
      {
        x: '2021-10-16 00:00:00',
        y: 22,
        vehicleSpeed: 63,
        lat: 48.315,
        lng: 18.418
      },
      {
        x: '2021-10-16 01:00:00',
        y: 22,
        vehicleSpeed: 70,
        lat: 48.415,
        lng: 18.518
      },
      {
        x: '2021-10-16 02:00:00',
        y: 21,
        vehicleSpeed: 70,
        lat: 48.515,
        lng: 18.618
      },
      {
        x: '2021-10-16 03:00:00',
        y: 21,
        vehicleSpeed: 70,
        lat: 48.615,
        lng: 18.718
      },
      {
        x: '2021-10-16 04:00:00',
        y: 20,
        vehicleSpeed: 70,
        lat: 48.715,
        lng: 18.818
      },
      {
        x: '2021-10-16 05:00:00',
        y: 20,
        vehicleSpeed: 70,
        lat: 48.815,
        lng: 18.918
      },
      {
        x: '2021-10-16 06:00:00',
        y: 20,
        vehicleSpeed: 70,
        lat: 48.915,
        lng: 19.018
      },
      {
        x: '2021-10-16 07:00:00',
        y: 20,
        vehicleSpeed: 45,
        lat: 49.015,
        lng: 19.118
      },
      {
        x: '2021-10-16 08:00:00',
        y: 26,
        vehicleSpeed: 45,
        lat: 49.115,
        lng: 19.218
      },
      {
        x: '2021-10-16 09:00:00',
        y: 26,
        vehicleSpeed: 45,
        lat: 49.215,
        lng: 19.318
      },
      {
        x: '2021-10-16 10:00:00',
        y: 25,
        vehicleSpeed: 45,
        lat: 49.315,
        lng: 19.418
      },
      {
        x: '2021-10-16 11:00:00',
        y: 26,
        vehicleSpeed: 45,
        lat: 49.415,
        lng: 19.518
      },
      {
        x: '2021-10-16 12:00:00',
        y: 27,
        vehicleSpeed: 45,
        lat: 49.515,
        lng: 19.618
      },
      {
        x: '2021-10-16 13:00:00',
        y: 27,
        vehicleSpeed: 50,
        lat: 49.615,
        lng: 19.718
      },
      {
        x: '2021-10-16 14:00:00',
        y: 27,
        vehicleSpeed: 50,
        lat: 49.715,
        lng: 19.818
      },
      {
        x: '2021-10-16 15:00:00',
        y: 27,
        vehicleSpeed: 50,
        lat: 49.815,
        lng: 19.918
      },
      {
        x: '2021-10-16 16:00:00',
        y: 26,
        vehicleSpeed: 50,
        lat: 49.915,
        lng: 20.018
      },
      {
        x: '2021-10-16 17:00:00',
        y: 26,
        vehicleSpeed: 50,
        lat: 50.015,
        lng: 20.118
      },
      {
        x: '2021-10-16 18:00:00',
        y: 26,
        vehicleSpeed: 50,
        lat: 50.115,
        lng: 20.218
      },
      {
        x: '2021-10-16 19:00:00',
        y: 26,
        vehicleSpeed: 48,
        lat: 50.215,
        lng: 20.318
      },
      {
        x: '2021-10-16 20:00:00',
        y: 24,
        vehicleSpeed: 48,
        lat: 50.315,
        lng: 20.418
      },
      {
        x: '2021-10-16 21:00:00',
        y: 24,
        vehicleSpeed: 48,
        lat: 50.415,
        lng: 20.518
      },
      {
        x: '2021-10-16 22:00:00',
        y: 24,
        vehicleSpeed: 48,
        lat: 50.515,
        lng: 20.618
      },
      {
        x: '2021-10-16 23:00:00',
        y: 24,
        vehicleSpeed: 48,
        lat: 50.615,
        lng: 20.718
      },
      {
        x: '2021-10-16 24:00:00',
        y: 27,
        vehicleSpeed: 48,
        lat: 50.715,
        lng: 20.818
      },
      {
        x: '2021-10-17 00:00:00',
        y: 27,
        vehicleSpeed: 65,
        lat: 50.815,
        lng: 20.918
      },
      {
        x: '2021-10-17 01:00:00',
        y: 27,
        vehicleSpeed: 65,
        lat: 50.915,
        lng: 21.018
      },
      {
        x: '2021-10-17 02:00:00',
        y: 27,
        vehicleSpeed: 65,
        lat: 51.015,
        lng: 21.118
      },
      {
        x: '2021-10-17 03:00:00',
        y: 26,
        vehicleSpeed: 65,
        lat: 51.115,
        lng: 21.218
      },
      {
        x: '2021-10-17 04:00:00',
        y: 26,
        vehicleSpeed: 65,
        lat: 51.215,
        lng: 21.318
      },
      {
        x: '2021-10-17 05:00:00',
        y: 26,
        vehicleSpeed: 65,
        lat: 51.315,
        lng: 21.418
      },
      {
        x: '2021-10-17 06:00:00',
        y: 26,
        vehicleSpeed: 70,
        lat: 51.415,
        lng: 21.518
      },
      {
        x: '2021-10-17 07:00:00',
        y: 24,
        vehicleSpeed: 70,
        lat: 51.515,
        lng: 21.618
      },
      {
        x: '2021-10-17 08:00:00',
        y: 24,
        vehicleSpeed: 70,
        lat: 51.615,
        lng: 21.718
      },
      {
        x: '2021-10-17 09:00:00',
        y: 24,
        vehicleSpeed: 70,
        lat: 51.715,
        lng: 21.818
      },
      {
        x: '2021-10-17 10:00:00',
        y: 24,
        vehicleSpeed: 70,
        lat: 51.815,
        lng: 21.918
      },
      {
        x: '2021-10-17 11:00:00',
        y: 28,
        vehicleSpeed: 70,
        lat: 51.915,
        lng: 22.018
      },
      {
        x: '2021-10-17 12:00:00',
        y: 28,
        vehicleSpeed: 92,
        lat: 52.015,
        lng: 22.118
      },
      {
        x: '2021-10-17 13:00:00',
        y: 28,
        vehicleSpeed: 92,
        lat: 52.115,
        lng: 22.218
      },
      {
        x: '2021-10-17 14:00:00',
        y: 28,
        vehicleSpeed: 92,
        lat: 52.215,
        lng: 22.318
      },
      {
        x: '2021-10-17 15:00:00',
        y: 25,
        vehicleSpeed: 92,
        lat: 52.315,
        lng: 22.418
      },
      {
        x: '2021-10-17 16:00:00',
        y: 25,
        vehicleSpeed: 92,
        lat: 52.415,
        lng: 22.518
      },
      {
        x: '2021-10-17 17:00:00',
        y: 25,
        vehicleSpeed: 92,
        lat: 52.515,
        lng: 22.618
      },
      {
        x: '2021-10-17 18:00:00',
        y: 25,
        vehicleSpeed: 92,
        lat: 52.615,
        lng: 22.718
      },
      {
        x: '2021-10-17 19:00:00',
        y: 26,
        vehicleSpeed: 92,
        lat: 52.715,
        lng: 22.818
      },
      {
        x: '2021-10-17 20:00:00',
        y: 26,
        vehicleSpeed: 92,
        lat: 52.815,
        lng: 22.918
      },
      {
        x: '2021-10-17 21:00:00',
        y: 26,
        vehicleSpeed: 92,
        lat: 52.915,
        lng: 23.018
      },
      {
        x: '2021-10-17 22:00:00',
        y: 26,
        vehicleSpeed: 92,
        lat: 53.015,
        lng: 23.118
      },
      {
        x: '2021-10-17 23:00:00',
        y: 27,
        vehicleSpeed: 92,
        lat: 53.115,
        lng: 23.218
      },
      {
        x: '2021-10-17 24:00:00',
        y: 27,
        vehicleSpeed: 40,
        lat: 53.215,
        lng: 23.318
      }
    ]
  },
  {
    sensorName: 'Senzor hladnjače',
    sensorData: [
      {
        x: '2021-10-15 00:00:00',
        y: 34,
        vehicleSpeed: 50,
        lat: 45.815,
        lng: 15.918
      },
      {
        x: '2021-10-15 01:00:00',
        y: 32,
        vehicleSpeed: 50,
        lat: 45.915,
        lng: 16.018
      },
      {
        x: '2021-10-15 02:00:00',
        y: 30,
        vehicleSpeed: 50,
        lat: 46.015,
        lng: 16.118
      },
      {
        x: '2021-10-15 03:00:00',
        y: 30,
        vehicleSpeed: 50,
        lat: 46.115,
        lng: 16.218
      },
      {
        x: '2021-10-15 04:00:00',
        y: 33,
        vehicleSpeed: 50,
        lat: 46.215,
        lng: 16.318
      },
      {
        x: '2021-10-15 05:00:00',
        y: 34,
        veh9eSpeed: 50,
        lat: 46.315,
        lng: 16.418
      },
      {
        x: '2021-10-15 06:00:00',
        y: 29,
        vehicleSpeed: 50,
        lat: 46.415,
        lng: 16.518
      },
      {
        x: '2021-10-15 07:00:00',
        y: 30,
        vehicleSpeed: 50,
        lat: 46.515,
        lng: 16.618
      },
      {
        x: '2021-10-15 08:00:00',
        y: 31,
        vehicleSpeed: 50,
        lat: 46.615,
        lng: 16.718
      },
      {
        x: '2021-10-15 09:00:00',
        y: 28,
        vehicleSpeed: 50,
        lat: 46.715,
        lng: 16.818
      },
      {
        x: '2021-10-15 10:00:00',
        y: 36,
        veh15eSpeed: 50,
        lat: 46.815,
        lng: 16.918
      },
      {
        x: '2021-10-15 11:00:00',
        y: 33,
        vehicleSpeed: 50,
        lat: 46.915,
        lng: 17.018
      },
      {
        x: '2021-10-15 12:00:00',
        y: 33,
        vehicleSpeed: 50,
        lat: 47.015,
        lng: 17.118
      },
      {
        x: '2021-10-15 13:00:00',
        y: 33,
        vehicleSpeed: 63,
        lat: 47.115,
        lng: 17.218
      },
      {
        x: '2021-10-15 14:00:00',
        y: 34,
        vehicleSpeed: 63,
        lat: 47.215,
        lng: 17.318
      },
      {
        x: '2021-10-15 15:00:00',
        y: 34,
        veh15eSpeed: 63,
        lat: 47.315,
        lng: 17.418
      },
      {
        x: '2021-10-15 16:00:00',
        y: 33,
        vehicleSpeed: 63,
        lat: 47.415,
        lng: 17.518
      },
      {
        x: '2021-10-15 17:00:00',
        y: 31,
        vehicleSpeed: 63,
        lat: 47.515,
        lng: 17.618
      },
      {
        x: '2021-10-15 18:00:00',
        y: 33,
        veh14leSpeed: 63,
        lat: 47.615,
        lng: 17.718
      },
      {
        x: '2021-10-15 19:00:00',
        y: 36,
        vehicleSpeed: 63,
        lat: 47.715,
        lng: 17.818
      },
      {
        x: '2021-10-15 20:00:00',
        y: 36,
        vehicleSpeed: 63,
        lat: 47.815,
        lng: 17.918
      },
      {
        x: '2021-10-15 21:00:00',
        y: 31,
        vehicleSpeed: 63,
        lat: 47.915,
        lng: 18.018
      },
      {
        x: '2021-10-15 22:00:00',
        y: 31,
        vehicleSpeed: 63,
        lat: 48.015,
        lng: 18.118
      },
      {
        x: '2021-10-15 23:00:00',
        y: 31,
        vehicleSpeed: 63,
        lat: 48.115,
        lng: 18.218
      },
      {
        x: '2021-10-15 24:00:00',
        y: 31,
        vehicleSpeed: 63,
        lat: 48.215,
        lng: 18.318
      },
      {
        x: '2021-10-16 00:00:00',
        y: 30,
        vehicleSpeed: 63,
        lat: 48.315,
        lng: 18.418
      },
      {
        x: '2021-10-16 01:00:00',
        y: 30,
        vehicleSpeed: 70,
        lat: 48.415,
        lng: 18.518
      },
      {
        x: '2021-10-16 02:00:00',
        y: 29,
        vehicleSpeed: 70,
        lat: 48.515,
        lng: 18.618
      },
      {
        x: '2021-10-16 03:00:00',
        y: 29,
        vehicleSpeed: 70,
        lat: 48.615,
        lng: 18.718
      },
      {
        x: '2021-10-16 04:00:00',
        y: 30,
        vehicleSpeed: 70,
        lat: 48.715,
        lng: 18.818
      },
      {
        x: '2021-10-16 05:00:00',
        y: 30,
        vehicleSpeed: 70,
        lat: 48.815,
        lng: 18.918
      },
      {
        x: '2021-10-16 06:00:00',
        y: 31,
        vehicleSpeed: 70,
        lat: 48.915,
        lng: 19.018
      },
      {
        x: '2021-10-16 07:00:00',
        y: 32,
        vehicleSpeed: 45,
        lat: 49.015,
        lng: 19.118
      },
      {
        x: '2021-10-16 08:00:00',
        y: 36,
        vehicleSpeed: 45,
        lat: 49.115,
        lng: 19.218
      },
      {
        x: '2021-10-16 09:00:00',
        y: 36,
        vehicleSpeed: 45,
        lat: 49.215,
        lng: 19.318
      },
      {
        x: '2021-10-16 10:00:00',
        y: 31,
        vehicleSpeed: 45,
        lat: 49.315,
        lng: 19.418
      },
      {
        x: '2021-10-16 11:00:00',
        y: 35,
        vehicleSpeed: 45,
        lat: 49.415,
        lng: 19.518
      },
      {
        x: '2021-10-16 12:00:00',
        y: 30,
        vehicleSpeed: 45,
        lat: 49.515,
        lng: 19.618
      },
      {
        x: '2021-10-16 13:00:00',
        y: 28,
        vehicleSpeed: 50,
        lat: 49.615,
        lng: 19.718
      },
      {
        x: '2021-10-16 14:00:00',
        y: 31,
        vehicleSpeed: 50,
        lat: 49.715,
        lng: 19.818
      },
      {
        x: '2021-10-16 15:00:00',
        y: 30,
        vehicleSpeed: 50,
        lat: 49.815,
        lng: 19.918
      },
      {
        x: '2021-10-16 16:00:00',
        y: 28,
        vehicleSpeed: 50,
        lat: 49.915,
        lng: 20.018
      },
      {
        x: '2021-10-16 17:00:00',
        y: 28,
        vehicleSpeed: 50,
        lat: 50.015,
        lng: 20.118
      },
      {
        x: '2021-10-16 18:00:00',
        y: 28,
        vehicleSpeed: 50,
        lat: 50.115,
        lng: 20.218
      },
      {
        x: '2021-10-16 19:00:00',
        y: 28,
        vehicleSpeed: 48,
        lat: 50.215,
        lng: 20.318
      },
      {
        x: '2021-10-16 20:00:00',
        y: 33,
        vehicleSpeed: 48,
        lat: 50.315,
        lng: 20.418
      },
      {
        x: '2021-10-16 21:00:00',
        y: 33,
        vehicleSpeed: 48,
        lat: 50.415,
        lng: 20.518
      },
      {
        x: '2021-10-16 22:00:00',
        y: 33,
        vehicleSpeed: 48,
        lat: 50.515,
        lng: 20.618
      },
      {
        x: '2021-10-16 23:00:00',
        y: 33,
        vehicleSpeed: 48,
        lat: 50.615,
        lng: 20.718
      },
      {
        x: '2021-10-16 24:00:00',
        y: 37,
        vehicleSpeed: 48,
        lat: 50.715,
        lng: 20.818
      },
      {
        x: '2021-10-17 00:00:00',
        y: 30,
        vehicleSpeed: 65,
        lat: 50.815,
        lng: 20.918
      },
      {
        x: '2021-10-17 01:00:00',
        y: 31,
        vehicleSpeed: 65,
        lat: 50.915,
        lng: 21.018
      },
      {
        x: '2021-10-17 02:00:00',
        y: 28,
        vehicleSpeed: 65,
        lat: 51.015,
        lng: 21.118
      },
      {
        x: '2021-10-17 03:00:00',
        y: 28,
        vehicleSpeed: 65,
        lat: 51.115,
        lng: 21.218
      },
      {
        x: '2021-10-17 04:00:00',
        y: 28,
        vehicleSpeed: 65,
        lat: 51.215,
        lng: 21.318
      },
      {
        x: '2021-10-17 05:00:00',
        y: 28,
        vehicleSpeed: 65,
        lat: 51.315,
        lng: 21.418
      },
      {
        x: '2021-10-17 06:00:00',
        y: 28,
        vehicleSpeed: 70,
        lat: 51.415,
        lng: 21.518
      },
      {
        x: '2021-10-17 07:00:00',
        y: 33,
        vehicleSpeed: 70,
        lat: 51.515,
        lng: 21.618
      },
      {
        x: '2021-10-17 08:00:00',
        y: 33,
        vehicleSpeed: 70,
        lat: 51.615,
        lng: 21.718
      },
      {
        x: '2021-10-17 09:00:00',
        y: 33,
        vehicleSpeed: 70,
        lat: 51.715,
        lng: 21.818
      },
      {
        x: '2021-10-17 10:00:00',
        y: 33,
        vehicleSpeed: 70,
        lat: 51.815,
        lng: 21.918
      },
      {
        x: '2021-10-17 11:00:00',
        y: 37,
        vehicleSpeed: 70,
        lat: 51.915,
        lng: 22.018
      },
      {
        x: '2021-10-17 12:00:00',
        y: 34,
        vehicleSpeed: 92,
        lat: 52.015,
        lng: 22.118
      },
      {
        x: '2021-10-17 13:00:00',
        y: 36,
        vehicleSpeed: 92,
        lat: 52.115,
        lng: 22.218
      },
      {
        x: '2021-10-17 14:00:00',
        y: 32,
        vehicleSpeed: 92,
        lat: 52.215,
        lng: 22.318
      },
      {
        x: '2021-10-17 15:00:00',
        y: 31,
        vehicleSpeed: 92,
        lat: 52.315,
        lng: 22.418
      },
      {
        x: '2021-10-17 16:00:00',
        y: 31,
        vehicleSpeed: 92,
        lat: 52.415,
        lng: 22.518
      },
      {
        x: '2021-10-17 17:00:00',
        y: 31,
        vehicleSpeed: 92,
        lat: 52.515,
        lng: 22.618
      },
      {
        x: '2021-10-17 18:00:00',
        y: 31,
        vehicleSpeed: 92,
        lat: 52.615,
        lng: 22.718
      },
      {
        x: '2021-10-17 19:00:00',
        y: 35,
        vehicleSpeed: 92,
        lat: 52.715,
        lng: 22.818
      },
      {
        x: '2021-10-17 20:00:00',
        y: 35,
        vehicleSpeed: 92,
        lat: 52.815,
        lng: 22.918
      },
      {
        x: '2021-10-17 21:00:00',
        y: 35,
        vehicleSpeed: 92,
        lat: 52.915,
        lng: 23.018
      },
      {
        x: '2021-10-17 22:00:00',
        y: 31,
        vehicleSpeed: 92,
        lat: 53.015,
        lng: 23.118
      },
      {
        x: '2021-10-17 23:00:00',
        y: 31,
        vehicleSpeed: 92,
        lat: 53.115,
        lng: 23.218
      },
      {
        x: '2021-10-17 24:00:00',
        y: 30,
        vehicleSpeed: 40,
        lat: 53.215,
        lng: 23.318
      }
    ]
  },
  {
    sensorName: 'Senzor sa nekim dužim imenom',
    sensorData: [
      {
        x: '2021-10-15 00:00:00',
        y: 42,
        vehicleSpeed: 50,
        lat: 45.815,
        lng: 15.918
      },
      {
        x: '2021-10-15 01:00:00',
        y: 42,
        vehicleSpeed: 50,
        lat: 45.915,
        lng: 16.018
      },
      {
        x: '2021-10-15 02:00:00',
        y: 42,
        vehicleSpeed: 50,
        lat: 46.015,
        lng: 16.118
      },
      {
        x: '2021-10-15 03:00:00',
        y: 41,
        vehicleSpeed: 50,
        lat: 46.115,
        lng: 16.218
      },
      {
        x: '2021-10-15 04:00:00',
        y: 41,
        vehicleSpeed: 50,
        lat: 46.215,
        lng: 16.318
      },
      {
        x: '2021-10-15 05:00:00',
        y: 41,
        veh9eSpeed: 50,
        lat: 46.315,
        lng: 16.418
      },
      {
        x: '2021-10-15 06:00:00',
        y: 40,
        vehicleSpeed: 50,
        lat: 46.415,
        lng: 16.518
      },
      {
        x: '2021-10-15 07:00:00',
        y: 40,
        vehicleSpeed: 50,
        lat: 46.515,
        lng: 16.618
      },
      {
        x: '2021-10-15 08:00:00',
        y: 40,
        vehicleSpeed: 50,
        lat: 46.615,
        lng: 16.718
      },
      {
        x: '2021-10-15 09:00:00',
        y: 40.5,
        vehicleSpeed: 50,
        lat: 46.715,
        lng: 16.818
      },
      {
        x: '2021-10-15 10:00:00',
        y: 40.5,
        veh15eSpeed: 50,
        lat: 46.815,
        lng: 16.918
      },
      {
        x: '2021-10-15 11:00:00',
        y: 40.5,
        vehicleSpeed: 50,
        lat: 46.915,
        lng: 17.018
      },
      {
        x: '2021-10-15 12:00:00',
        y: 42,
        vehicleSpeed: 50,
        lat: 47.015,
        lng: 17.118
      },
      {
        x: '2021-10-15 13:00:00',
        y: 42,
        vehicleSpeed: 63,
        lat: 47.115,
        lng: 17.218
      },
      {
        x: '2021-10-15 14:00:00',
        y: 42,
        vehicleSpeed: 63,
        lat: 47.215,
        lng: 17.318
      },
      {
        x: '2021-10-15 15:00:00',
        y: 43,
        veh15eSpeed: 63,
        lat: 47.315,
        lng: 17.418
      },
      {
        x: '2021-10-15 16:00:00',
        y: 43,
        vehicleSpeed: 63,
        lat: 47.415,
        lng: 17.518
      },
      {
        x: '2021-10-15 17:00:00',
        y: 43,
        vehicleSpeed: 63,
        lat: 47.515,
        lng: 17.618
      },
      {
        x: '2021-10-15 18:00:00',
        y: 41,
        veh14leSpeed: 63,
        lat: 47.615,
        lng: 17.718
      },
      {
        x: '2021-10-15 19:00:00',
        y: 41,
        vehicleSpeed: 63,
        lat: 47.715,
        lng: 17.818
      },
      {
        x: '2021-10-15 20:00:00',
        y: 41,
        vehicleSpeed: 63,
        lat: 47.815,
        lng: 17.918
      },
      {
        x: '2021-10-15 21:00:00',
        y: 39,
        vehicleSpeed: 63,
        lat: 47.915,
        lng: 18.018
      },
      {
        x: '2021-10-15 22:00:00',
        y: 39,
        vehicleSpeed: 63,
        lat: 48.015,
        lng: 18.118
      },
      {
        x: '2021-10-15 23:00:00',
        y: 39,
        vehicleSpeed: 63,
        lat: 48.115,
        lng: 18.218
      },
      {
        x: '2021-10-15 24:00:00',
        y: 40,
        vehicleSpeed: 63,
        lat: 48.215,
        lng: 18.318
      },
      {
        x: '2021-10-16 00:00:00',
        y: 40,
        vehicleSpeed: 63,
        lat: 48.315,
        lng: 18.418
      },
      {
        x: '2021-10-16 01:00:00',
        y: 40,
        vehicleSpeed: 70,
        lat: 48.415,
        lng: 18.518
      },
      {
        x: '2021-10-16 02:00:00',
        y: 41,
        vehicleSpeed: 70,
        lat: 48.515,
        lng: 18.618
      },
      {
        x: '2021-10-16 03:00:00',
        y: 41,
        vehicleSpeed: 70,
        lat: 48.615,
        lng: 18.718
      },
      {
        x: '2021-10-16 04:00:00',
        y: 41,
        vehicleSpeed: 70,
        lat: 48.715,
        lng: 18.818
      },
      {
        x: '2021-10-16 05:00:00',
        y: 42,
        vehicleSpeed: 70,
        lat: 48.815,
        lng: 18.918
      },
      {
        x: '2021-10-16 06:00:00',
        y: 42,
        vehicleSpeed: 70,
        lat: 48.915,
        lng: 19.018
      },
      {
        x: '2021-10-16 07:00:00',
        y: 42,
        vehicleSpeed: 45,
        lat: 49.015,
        lng: 19.118
      },
      {
        x: '2021-10-16 08:00:00',
        y: 44,
        vehicleSpeed: 45,
        lat: 49.115,
        lng: 19.218
      },
      {
        x: '2021-10-16 09:00:00',
        y: 44,
        vehicleSpeed: 45,
        lat: 49.215,
        lng: 19.318
      },
      {
        x: '2021-10-16 10:00:00',
        y: 44,
        vehicleSpeed: 45,
        lat: 49.315,
        lng: 19.418
      },
      {
        x: '2021-10-16 11:00:00',
        y: 43,
        vehicleSpeed: 45,
        lat: 49.415,
        lng: 19.518
      },
      {
        x: '2021-10-16 12:00:00',
        y: 43,
        vehicleSpeed: 45,
        lat: 49.515,
        lng: 19.618
      },
      {
        x: '2021-10-16 13:00:00',
        y: 43,
        vehicleSpeed: 50,
        lat: 49.615,
        lng: 19.718
      },
      {
        x: '2021-10-16 14:00:00',
        y: 43,
        vehicleSpeed: 50,
        lat: 49.715,
        lng: 19.818
      },
      {
        x: '2021-10-16 15:00:00',
        y: 41,
        vehicleSpeed: 50,
        lat: 49.815,
        lng: 19.918
      },
      {
        x: '2021-10-16 16:00:00',
        y: 41,
        vehicleSpeed: 50,
        lat: 49.915,
        lng: 20.018
      },
      {
        x: '2021-10-16 17:00:00',
        y: 41,
        vehicleSpeed: 50,
        lat: 50.015,
        lng: 20.118
      },
      {
        x: '2021-10-16 18:00:00',
        y: 41,
        vehicleSpeed: 50,
        lat: 50.115,
        lng: 20.218
      },
      {
        x: '2021-10-16 19:00:00',
        y: 40,
        vehicleSpeed: 48,
        lat: 50.215,
        lng: 20.318
      },
      {
        x: '2021-10-16 20:00:00',
        y: 40,
        vehicleSpeed: 48,
        lat: 50.315,
        lng: 20.418
      },
      {
        x: '2021-10-16 21:00:00',
        y: 40,
        vehicleSpeed: 48,
        lat: 50.415,
        lng: 20.518
      },
      {
        x: '2021-10-16 22:00:00',
        y: 40,
        vehicleSpeed: 48,
        lat: 50.515,
        lng: 20.618
      },
      {
        x: '2021-10-16 23:00:00',
        y: 41,
        vehicleSpeed: 48,
        lat: 50.615,
        lng: 20.718
      },
      {
        x: '2021-10-16 24:00:00',
        y: 41,
        vehicleSpeed: 48,
        lat: 50.715,
        lng: 20.818
      },
      {
        x: '2021-10-17 00:00:00',
        y: 41,
        vehicleSpeed: 65,
        lat: 50.815,
        lng: 20.918
      },
      {
        x: '2021-10-17 01:00:00',
        y: 41,
        vehicleSpeed: 65,
        lat: 50.915,
        lng: 21.018
      },
      {
        x: '2021-10-17 02:00:00',
        y: 39,
        vehicleSpeed: 65,
        lat: 51.015,
        lng: 21.118
      },
      {
        x: '2021-10-17 03:00:00',
        y: 39,
        vehicleSpeed: 65,
        lat: 51.115,
        lng: 21.218
      },
      {
        x: '2021-10-17 04:00:00',
        y: 39,
        vehicleSpeed: 65,
        lat: 51.215,
        lng: 21.318
      },
      {
        x: '2021-10-17 05:00:00',
        y: 39,
        vehicleSpeed: 65,
        lat: 51.315,
        lng: 21.418
      },
      {
        x: '2021-10-17 06:00:00',
        y: 41,
        vehicleSpeed: 70,
        lat: 51.415,
        lng: 21.518
      },
      {
        x: '2021-10-17 07:00:00',
        y: 41,
        vehicleSpeed: 70,
        lat: 51.515,
        lng: 21.618
      },
      {
        x: '2021-10-17 08:00:00',
        y: 41,
        vehicleSpeed: 70,
        lat: 51.615,
        lng: 21.718
      },
      {
        x: '2021-10-17 09:00:00',
        y: 41,
        vehicleSpeed: 70,
        lat: 51.715,
        lng: 21.818
      },
      {
        x: '2021-10-17 10:00:00',
        y: 42,
        vehicleSpeed: 70,
        lat: 51.815,
        lng: 21.918
      },
      {
        x: '2021-10-17 11:00:00',
        y: 42,
        vehicleSpeed: 70,
        lat: 51.915,
        lng: 22.018
      },
      {
        x: '2021-10-17 12:00:00',
        y: 42,
        vehicleSpeed: 92,
        lat: 52.015,
        lng: 22.118
      },
      {
        x: '2021-10-17 13:00:00',
        y: 42,
        vehicleSpeed: 92,
        lat: 52.115,
        lng: 22.218
      },
      {
        x: '2021-10-17 14:00:00',
        y: 43,
        vehicleSpeed: 92,
        lat: 52.215,
        lng: 22.318
      },
      {
        x: '2021-10-17 15:00:00',
        y: 43,
        vehicleSpeed: 92,
        lat: 52.315,
        lng: 22.418
      },
      {
        x: '2021-10-17 16:00:00',
        y: 43,
        vehicleSpeed: 92,
        lat: 52.415,
        lng: 22.518
      },
      {
        x: '2021-10-17 17:00:00',
        y: 43,
        vehicleSpeed: 92,
        lat: 52.515,
        lng: 22.618
      },
      {
        x: '2021-10-17 18:00:00',
        y: 41,
        vehicleSpeed: 92,
        lat: 52.615,
        lng: 22.718
      },
      {
        x: '2021-10-17 19:00:00',
        y: 41,
        vehicleSpeed: 92,
        lat: 52.715,
        lng: 22.818
      },
      {
        x: '2021-10-17 20:00:00',
        y: 41,
        vehicleSpeed: 92,
        lat: 52.815,
        lng: 22.918
      },
      {
        x: '2021-10-17 21:00:00',
        y: 41,
        vehicleSpeed: 92,
        lat: 52.915,
        lng: 23.018
      },
      {
        x: '2021-10-17 22:00:00',
        y: 40,
        vehicleSpeed: 92,
        lat: 53.015,
        lng: 23.118
      },
      {
        x: '2021-10-17 23:00:00',
        y: 43,
        vehicleSpeed: 92,
        lat: 53.115,
        lng: 23.218
      },
      {
        x: '2021-10-17 24:00:00',
        y: 43,
        vehicleSpeed: 40,
        lat: 53.215,
        lng: 23.318
      }
    ]
  }
]

export { temperatureSensorsData }
