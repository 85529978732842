export default {
  path: '/password/set/:token/:langCode',
  name: 'SetPassword',
  component: () => import(/* webpackChunkName: "base/login" */ '../views/SetPasswordView.vue'),
  meta: {
    // title: 'base.profile',
    layout: 'login',
    exclude: true,
    requiresAuth: false,
    needsPermission: false
  }
}
