<template>
  <div />
</template>

<script>
import { GeoSearchControl } from 'leaflet-geosearch'
import { SearchProvider } from '@/global/common/searchProvider'
import L from 'leaflet'

export default {
  name: 'MapGeoSearch',

  props: {
    mapType: {
      type: String,
      default: 'osm'
    },
    customOptions: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      searchControl: null,
      defaultOptions: {
        showMarker: true,
        showPopup: false,
        autoClose: true,
        keepResult: true,
        retainZoomLevel: false,
        animateZoom: false,
        marker: {
          icon: new L.Icon({
            iconUrl: '/img/icons/red_x_icon.svg.png',
            iconSize: [20, 20],
            key: 'searchMarkerKey'
          }),
          draggable: false
        }
      }
    }
  },

  computed: {
    options () {
      return {
        ...this.defaultOptions,
        ...this.customOptions
      }
    }
  },

  watch: {
    mapType: {
      handler () {
        this.remove()
        this.setGeosearchOptions()
        this.add()
      }
    }
  },

  mounted () {
    this.setGeosearchOptions()
    this.add()
  },

  beforeDestroy () {
    this.remove()
  },

  methods: {
    setGeosearchOptions () {
      let provider = null
      provider = new SearchProvider({
        mapType: this.mapType
      })
      this.$set(this.options, 'provider', provider)
    },

    deferredMountedTo (parent) {
      if (this.searchControl) parent?.removeControl(this.searchControl)
      this.searchControl = new GeoSearchControl(this.options)
      parent?.addControl(this.searchControl)
    },

    remove () {
      if (this.markerCluster) this.$parent?.removeLayer(this.markerCluster)
    },

    add () {
      if (this.$parent?._isMounted) this.deferredMountedTo(this.$parent?.mapObject)
    }
  }
}
</script>
