<template>
  <v-container
    v-show="isNotEmpty(dis.formDataFields)"
    class="mt-4"
  >
    <!-- Label on desktop -->
    <v-row>
      <v-col
        v-if="shouldShowLabel"
        class="font-weight-bold col-12 px-0"
        align-self="center"
      >
        <p class="text-left mb-2">
          {{ label }}
        </p>
      </v-col>
    </v-row>
    <!-- Column titles -->
    <v-row
      v-if="disData.length && !isMobileView"
      class="mt-1"
    >
      <v-col
        v-for="(disFormDataField, ind) in dis.formDataFields"
        :key="`dis-form-data-${ind + 1}`"
        class="px-1 py-0"
        :class="getAdditionalClassesBasedOnField(dis.key, disFormDataField.type)"
        :cols="getGroupColForCertainField(dis.key, ind)"
      >
        <v-row
          class="flex-nowrap"
          no-gutters
        >
          <v-col
            class="font-weight-bold"
            :cols="getLabelColForCertainField(dis.key)"
            align-self="center"
          >
            <!-- we have to use 'mb' class because of input(text-field). Error messages expanding text-field height and align-content-center wont show desired result  -->
            <p class="">
              {{ disFormDataField.label }}:
            </p>
          </v-col>
        </v-row>
      </v-col>
      <!-- This column occupies the place of the delete row button -->
      <v-col
        v-if="!readonly"
        class="pa-0"
        cols="1"
      />
    </v-row>
    <!-- Input fields -->
    <template
      v-for="(disDataObject, index) in disData"
      class="mt-1"
    >
      <v-row :key="`dis-data-${index + 1}`">
        <v-col
          v-for="(disFormDataField, ind) in dis.formDataFields"
          :key="`dis-form-data-${ind + 1}`"
          class="px-1 py-0"
          :class="getAdditionalClassesBasedOnField(dis.key, disFormDataField.type)"
          :cols="getGroupColForCertainField(dis.key, ind)"
        >
          <v-row
            class="flex-nowrap"
            no-gutters
          >
            <v-col
              v-if="disFormDataField.type === 'string'"
            >
              <v-text-field
                v-model="disDataObject[ind]"
                outlined
                dense
                :label="isMobileView ? disFormDataField.label : ''"
                :readonly="readonly || !canEdit(disDataObject)"
                :error-messages="getErrorMessages(index, ind)"
                :hint="getHintMessage()"
                @input="resetErrorMessage(index, ind)"
              />
            </v-col>
            <v-col
              v-if="disFormDataField.type === 'checkbox'"
              class="justify-center d-flex align-center"
            >
              <v-checkbox
                :input-value="disDataObject[ind]"
                v-model="disDataObject[ind]"
                :label="isMobileView ? disFormDataField.label : ''"
                :readonly="readonly || !canEdit(disDataObject)"
                :error-messages="getErrorMessages(index, ind)"
                @input="resetErrorMessage(index, ind)"
                class="align-center"
              />
            </v-col>
            <v-col
              v-if="disFormDataField.type === 'foreign_key'"
            >
              <v-select
                v-model="disDataObject[ind]"
                :style="getSelectStyleForCertainField(dis.key)"
                outlined
                :label="isMobileView ? disFormDataField.label : ''"
                dense
                :items="internalItems"
                :clearable="clearable && !readonly && canEdit(disDataObject)"
                :readonly="readonly || !canEdit(disDataObject)"
                :search-input.sync="itemSearch"
                :error-messages="getErrorMessages(index, ind)"
                :loading="fetchingData"
                @input="resetErrorMessage(index, ind)"
              >
                <template #append-item>
                  <v-list-item
                    v-if="hasMoreItems"
                    class="has-more-items"
                    @click="makeSearch('', true)"
                  >
                    <v-list-item-title class="font-italic">
                      {{ $t('base.got_more_items') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col
              v-if="disFormDataField.type === 'foreign_key_autocomplete'"
              :cols="getColForCertainField(dis.key)"
            >
              <multi-foreign-key-autocomplete
                v-model="disDataObject[ind]"
                :label="isMobileView ? disFormDataField.label : ''"
                :error-messages="getErrorMessages(index, ind)"
                :clearable="clearable && !readonly"
                :items.sync="autocompleteForeignKeyList"
                @input="resetErrorMessage(index, ind)"
              />
            </v-col>
            <v-col
              v-if="disFormDataField.type === 'datepicker'"
              :cols="getColForCertainField(dis.key)"
            >
              <!-- DATEPICKER START -->
              <v-menu
                :ref="`dynamic_datepicker_${index}_${ind}`"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="disDataObject[`formatted_${ind}`]"
                    :style="getDatepickerStyleForCertainField(dis.key)"
                    dense
                    :label="isMobileView ? disFormDataField.label : ''"
                    append-icon="mdi-calendar"
                    :clearable="canEdit(disDataObject)"
                    outlined
                    :error-messages="getErrorMessages(index, ind)"
                    readonly
                    v-bind="attrs"
                    v-on="canEdit(disDataObject) ? on : null"
                    @click:clear="disDataObject[ind] = null"
                  />
                </template>
                <v-date-picker
                  v-model="disDataObject[ind]"
                  first-day-of-week="1"
                  full-width
                  @input="resetErrorMessage(index, ind)"
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="$refs[`dynamic_datepicker_${index}_${ind}`][0].save(disDataObject[ind])"
                  >
                    {{ $t('base.ok') }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <!-- DATEPICKER END -->
            </v-col>
            <v-col
              v-if="disFormDataField.type === 'datetimepicker'"
              :cols="getColForCertainField(dis.key)"
            >
              <!-- DATETIMEPICKER START -->
              <v-menu
                :ref="`dynamic_datetimepicker_${index}_${ind}`"
                v-model="dateMenuObject[index]"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="disDataObject[`formatted_${ind}`]"
                    :style="getDatepickerStyleForCertainField(dis.key)"
                    dense
                    append-icon="mdi-calendar-clock"
                    :clearable="canEdit(disDataObject)"
                    outlined
                    :error-messages="getErrorMessages(index, ind)"
                    readonly
                    v-bind="attrs"
                    v-on="canEdit(disDataObject) ? on : null"
                    @click:clear="disDataObject[ind] = null"
                  />
                </template>
                <datetime-picker
                  v-model="disDataObject[ind]"
                  :emit-default-time="true"
                  :default-time="'00:00:00'"
                  :reset-tabs="dateMenuObject[index]"
                  @input="resetErrorMessage(index, ind)"
                  @close-picker="dateMenuObject[index] = false"
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="$refs[`dynamic_datetimepicker_${index}_${ind}`][0].save(disDataObject[ind])"
                  >
                    {{ $t('base.ok') }}
                  </v-btn>
                </datetime-picker>
              </v-menu>
              <!-- DATETIMEPICKER END -->
            </v-col>
          </v-row>
        </v-col>
        <!-- Get temperature sensor name button -->
        <v-col
          v-if="dis.key === 'temperature_sensors'"
          class="pa-1"
          cols="1"
        >
          <v-btn
            v-if="shouldShowTempSensNameFetchButton(index)"
            icon
            class="ml-2"
            @click="fetchCurrentTemperatureSensorValue(index)"
          >
            <v-icon
              color="accent"
              class="sync-icon"
            >
              mdi-sync
            </v-icon>
          </v-btn>
        </v-col>
        <!-- ./Get temperature sensor name button -->
        <!-- Delete row button -->
        <v-col
          v-if="!readonly && !canRemove(disDataObject)"
          class="pa-0"
          cols="1"
        >
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                text
                x-small
                class="mt-3 mx-2 pa-0 no-background-hover"
                elevation="0"
                v-bind="attrs"
                v-on="on"
                @click="removeDisDataObject(index)"
              >
                <v-icon
                  color="grey darken-1"
                  size="1.8rem"
                >
                  mdi-close
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('base.remove_dis_data') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-divider
        v-if="isMobileView"
        :key="`dis-data-divider-${index + 1}`"
        class="mb-7"
      />
    </template>
    <!-- The structure of this row is same as for input fields in order to achieve + button alignment with first input field in above row -->
    <v-row
      v-if="!readonly"
      class="mt-0"
    >
      <template v-if="dis">
        <v-row
          no-gutters
        >
          <v-col class="px-1 pt-0">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  class="ml-0 mt-1 di-add-item"
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  @click="addEmptyDisDataObject"
                >
                  <v-icon
                    left
                    color="success"
                  >
                    mdi-plus-circle
                  </v-icon>
                  {{ $t('base.add_dis') }}
                </v-btn>
              </template>
              <span>{{ $t('base.add_dis_data') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
      <v-col
        v-if="!readonly"
        class="pl-0 py-0"
        cols="1"
      />
    </v-row>
  </v-container>
</template>

<script>
import { formatSqlDate, formatSqlDateTime } from '@/global/services/helpers/dates'
import {
  debounce,
  has,
  isEmpty,
  map,
  pickBy
} from 'lodash'
import { api } from '@/global/services/api'
import MultiForeignKeyAutocomplete from '@/modules/base-module/multi-foreign-key-autocomplete/MultiForeignKeyAutocomplete'
import DatetimePicker from '@/global/components/DatetimePicker.vue'
import store from '@/global/store'
import dayjs from 'dayjs'

export default {
  name: 'DynamicInputSelect',

  components: {
    MultiForeignKeyAutocomplete,
    DatetimePicker
  },

  props: {
    modelId: {
      type: Number,
      default: null
    },

    dis: {
      type: Object,
      default: () => ({})
    },

    existingDisData: {
      type: Array,
      default: () => []
    },

    label: {
      type: String,
      required: true
    },

    showLabel: {
      type: Boolean,
      default: false
    },

    showLabelOnMobile: {
      type: Boolean,
      default: false
    },

    options: {
      type: Object,
      required: true,
      validator: (options) => {
        return has(options, 'module') && has(options, 'route')
      }
    },

    clearable: {
      type: Boolean,
      default: true
    },

    readonly: {
      type: Boolean,
      default: false
    },

    items: {
      type: Array,
      default: () => []
    },

    errorMessages: {
      type: Object,
      default: () => {}
    },

    dialogClosed: {
      type: Boolean,
      default: true
    },

    removeExistingEntries: {
      type: Boolean,
      default: true
    },

    editExistingEntries: {
      type: Boolean,
      default: true
    },

    maxDinInputs: {
      type: Number,
      default: null
    },

    editItemExtractedData: {
      type: Object,
      default: () => null
    },

    activeStatusConditionForAddEmptyDisDataObj: {
      type: Boolean,
      default: false
    },
    hintMessage: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      itemSearch: null,
      autocompleteForeignKeyList: [],
      fetchingData: false,
      hasMoreItems: false,
      disData: [],
      dateMenuObject: {}
    }
  },

  computed: {
    isMobileView () {
      return this.$vuetify.breakpoint.name === 'xs'
    },

    shouldShowLabel () {
      return this.showLabel && (this.showLabelOnMobile || this.$vuetify.breakpoint.mdAndUp)
    },

    itemId: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value || null)
      }
    },

    internalItems: {
      get () {
        return this.items
      },

      set (value) {
        this.$emit('update:items', value)
      }
    }
  },

  watch: {
    itemSearch: debounce(function (newVal) {
      this.fetchingData = true
      this.makeSearch(newVal)
    }, 1000),

    modelId () {
      this.fetchingData = true
      this.makeSearch('')
    },

    existingDisData: {
      immediate: true,
      deep: true,
      handler (existingDisData) {
        if (existingDisData?.length) {
          this.disData = existingDisData
          // Get keys of the date and datetime picker fields.
          const dateFieldsKeys = Object.keys(
            pickBy(this.dis.formDataFields, item => (item.type === 'datepicker'))
          )
          const datetimeFieldsKeys = Object.keys(
            pickBy(this.dis.formDataFields, item => (item.type === 'datetimepicker'))
          )
          // If there are any `datepicker` fields (sometimes there are none), proceed.
          if (dateFieldsKeys.length || datetimeFieldsKeys.length) {
            this.disData = this.disData.map(item => {
              // For all datepicker and datetimepicker fields, create a formatted version
              // that is prefixed with the `formatted_` key
              // and is shown in the date pickers `v-text-field` field.
              dateFieldsKeys.forEach(dateFieldKey =>
                (item[`formatted_${dateFieldKey}`] = item[dateFieldKey] ? formatSqlDate(item[dateFieldKey]) : '')
              )
              datetimeFieldsKeys.forEach(datetimeFieldKey =>
                (item[`formatted_${datetimeFieldKey}`] = item[datetimeFieldKey] ? formatSqlDateTime(item[datetimeFieldKey]) : '')
              )
              return item
            })
          }
        }
        else this.disData = []
      }
    },

    disData: {
      deep: true,
      handler (disData) {
        disData.forEach(item => {
          if (
            'datetime' in item &&
            'formatted_datetime' in item &&
            !item.datetime &&
            !item.formatted_datetime
          ) {
            const startOfToday = dayjs().startOf('day')
            item.datetime = startOfToday.toISOString()
            item.formatted_datetime = formatSqlDateTime(startOfToday)
          }
        })
      }
    }
  },

  async created () {
    try {
      this.fetchingData = true
      await this.makeSearch('')
    }
    finally {
      this.fetchingData = false
    }
  },

  mounted () {
    this.setExistingDisData()
  },

  methods: {
    shouldShowTempSensNameFetchButton (index) {
      // TODO: it's hardcoded for first four types for now but remove if real-life condition changes
      return [1, 2, 3, 4].includes(this.disData[index]?.temperature_sensor_type_id)
    },

    fetchCurrentTemperatureSensorValue (index) {
      if (this.editItemExtractedData.traccarId) {
        api('base').get('current-temperature-sensor-value', {
          deviceId: this.editItemExtractedData.traccarId,
          type: this.disData[index].temperature_sensor_type_id,
          modelName: this.editItemExtractedData.modelName
        })
          .then(data => {
            if ('value' in data) {
              this.disData[index].name = data.value
            }
            else {
              store.dispatch('base/notifications/push',
                this.$t('base/tracker.temperature_sensor_name_no_data')
              )
            }
          })
      }
      else {
        store.dispatch(
          'base/notifications/push',
          this.$t('base/tracker.no_device_id')
        )
      }
    },
    canEdit (disDataObject) {
      return this.editExistingEntries ? true : !disDataObject.id
    },

    canRemove (disDataObject) {
      return this.removeExistingEntries ? false : !!disDataObject.id
    },

    // column that holds label and input/select fields
    getGroupColForCertainField (key, fieldIndex = null) {
      switch (key) {
        case 'cards':
        case 'contacts':
          return this.isMobileView ? '10' : '5'
        case 'person_documents':
          if (fieldIndex === 'person_document_id') {
            return this.isMobileView ? '10' : '3'
          }
          else {
            return this.isMobileView ? '9' : '2'
          }
        case 'service_intervals':
        case 'readouts':
          return this.isMobileView ? '10' : ''
        default:
          return ''
      }
    },
    getLabelColForCertainField (key) {
      switch (key) {
        case 'cards':
          return '6'
        case 'contacts':
          return '6'
        case 'person_documents':
          return '6'
        default:
          return ''
      }
    },
    getColForCertainField (key) {
      switch (key) {
        case 'cards':
          return '12'
        case 'contacts':
          return '9'
        case 'person_documents':
          return '15'
        default:
          return ''
      }
    },
    getSelectStyleForCertainField (key) {
      switch (key) {
        case 'person_documents':
        default:
          return ''
      }
    },
    getDatepickerStyleForCertainField (key) {
      switch (key) {
        case 'person_documents':
        default:
          return ''
      }
    },
    getAdditionalClassesBasedOnField (key, fieldType) {
      switch (key) {
        case 'person_documents':
          if (fieldType === 'datepicker' && !this.$vuetify.breakpoint.xs) {
            return ''
          }
          else return ''
        default:
          return ''
      }
    },
    setExistingDisData () {
      if (this.existingDisData?.length) {
        this.existingDisData.forEach(disDataObject => {
          this.disData.push(this.disFieldFormatted(disDataObject, true))
        })
      }
      else this.disData = []
    },

    disFieldFormatted (disDataObject, formData = false) {
      const fields = formData ? this.dis.formDataFields : this.dis.fields
      const obj = {}
      for (const [key, value] of Object.entries(fields)) {
        switch (value.type) {
          case 'primary_key':
            obj[key] = parseInt(disDataObject[key])
            break
          case 'foreign_key':
            obj[key] = parseInt(disDataObject[key]) || parseInt(disDataObject.id)
            break
          case 'datepicker':
            this.$set(disDataObject, `formatted_${key}`, formatSqlDate(disDataObject[key]))
            obj[key] = disDataObject[key]
            break
          case 'datetimepicker':
            this.$set(disDataObject, `formatted_${key}`, formatSqlDateTime(disDataObject[key]))
            obj[key] = disDataObject[key]
            break
          case 'string':
            obj[key] = disDataObject[key]
            break
        }
      }
      return obj
    },

    addEmptyDisDataObject () {
      if (this.maxDinInputs && this.disData.length >= this.maxDinInputs) {
        store.dispatch(
          'base/notifications/push',
          this.$t('base/tracker.temperature_sensor_selection_limit') +
          ' ' +
          this.maxDinInputs
        )
      }
      else if (this.dis.key === 'temperature_sensors' && !this.activeStatusConditionForAddEmptyDisDataObj) {
        store.dispatch(
          'base/notifications/push',
          this.$t('base/tracker.temperature_sensor_status_active_condition')
        )
      }
      else {
        const obj = {}
        Object.keys(this.dis.formDataFields).forEach(key => (obj[key] = null))
        this.disData.push(obj)

        this.$emit('update-dis-data', {
          dis_key: this.dis.key,
          data: this.disData
        })
      }
    },

    removeDisDataObject (index) {
      if (index in this.dateMenuObject) delete this.dateMenuObject[index]

      this.disData.splice(index, 1)

      this.$emit('update-dis-data', {
        dis_key: this.dis.key,
        data: this.disData
      })
    },

    makeSearch (query, allItems = false) {
      // Passing model id for fetching relation data to that particular model to be included in list
      // (because fetch returns only non-related data, if no data fetched for that model, there would be no data in autocomplete edit mode)
      const params = {
        query: query ?? '',
        pickedId: this.value ?? null,
        modelId: this.modelId,
        includeAll: allItems
      }

      api()[this.options.module].get(this.options.route, params).then(response => {
        const {
          data,
          has_more: hasMore = false
        } = response

        if (this.options.model !== 'id-card') {
          this.internalItems = map(data, ({ id, name }) => ({
            value: parseInt(id),
            selected: true,
            text: name
          }))
        }
        else {
          this.autocompleteForeignKeyList = map(data, ({ id, name, card_type: cardType }) => ({
            value: parseInt(id),
            selected: true,
            text: name + (cardType ? ' (' + cardType + ')' : '')
          }))
        }

        this.hasMoreItems = hasMore
      }).finally(() => (this.fetchingData = false))
    },

    getErrorMessages (index, field) {
      return this.errorMessages[`${this.dis.key}.${index}.${field}`]
    },

    getHintMessage () {
      return this.hintMessage
    },

    resetErrorMessage (index, key) {
      // When the user selects the date from the date-picker,
      // emit an event for updating disData in the parent component.
      this.$emit('update-dis-data', {
        dis_key: this.dis.key,
        data: this.disData
      })
      const errorKey = `${this.dis.key}.${index}.${key}`
      this.$emit('reset-error-message', errorKey)
    },

    isNotEmpty (value) {
      return !isEmpty(value)
    }
  }
}
</script>

<style scoped lang="scss">
.has-more-items {
  cursor: pointer !important;
}

.di-add-item {
  font-size: 0.8rem;
  height: 2.2rem !important;
  text-transform: none;
  color: var(--v-primary-darken-5);
  border-color: var(--v-primary-base);
  >span {
    >i {
      font-size: 24px !important;
    }
  }
}
button > span > i,
p {
  line-height: 1;
  font-size: 0.8rem;
  &.hide-content {
    visibility: hidden
  }
}
</style>
