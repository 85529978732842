<template>
  <v-row>
    <v-col
      class="font-weight-bold"
      align-self="center"
      :cols="6"
    >
      <p class="text-left d-inline">
        {{ label }}
      </p>
    </v-col>
    <v-col cols="6" class="pb-0 pt-1">
      <v-autocomplete
        v-model="itemId"
        ref="autoComplete"
        :items="internalItems"
        :disabled="disabled"
        :clearable="clearable"
        :error-messages="errorMessages"
        :no-filter="true"
        :menu-props="{ top: true, offsetY: true, maxHeight: 250 }"
        outlined
        dense
        multiple
        @change="handleChangeEvent"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'MultiAutocomplete',

  props: {
    value: {
      type: Array,
      default: () => ([])
    },

    label: {
      type: String,
      required: true
    },

    clearable: {
      type: Boolean,
      default: true
    },

    items: {
      type: Array,
      default: () => ([])
    },

    errorMessages: {
      type: Array,
      default: () => ([])
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {}
  },

  computed: {
    itemId: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value || null)
      }
    },

    internalItems: {
      get () {
        return this.items
      },

      set (value) {
        this.$emit('update:items', value)
      }
    }
  },
  methods: {
    handleChangeEvent (index) {
      this.$refs.autoComplete.setMenuIndex(index)
    }
  }
}
</script>
