const store = {
  namespaced: true,

  state: {
    dynamicAttributes: [],
    dynamicArrays: []
  },

  getters: {
    dynamicAttributes: state => state.dynamicAttributes,
    dynamicArrays: state => state.dynamicArrays,
    dynamicArrayByKey: state => keysObject => {
      return state.dynamicArrays[keysObject.array][keysObject.name][keysObject.attribute]
    }
  },

  mutations: {
    SET_DYNAMIC_ATTRIBUTES (state, payload) {
      state.dynamicAttributes = payload
      state.dynamicAttributes.forEach(attribute => {
        const dynamicSettings = attribute.dynamic_settings
        const array = dynamicSettings.array
        const name = dynamicSettings.name
        const attr = dynamicSettings.attribute
        const defaultValue = dynamicSettings.default_value
        if (!(array in state.dynamicArrays)) {
          state.dynamicArrays[array] = {}
        }
        if (!(name in state.dynamicArrays[array])) {
          state.dynamicArrays[array][name] = {}
        }
        if (!(attr in state.dynamicArrays[array][name])) {
          state.dynamicArrays[array][name][attr] = defaultValue
        }
      })
    },

    SET_DYNAMIC_ARRAY_VALUE (state, { keysArray, value }) {
      const array = keysArray[0]
      const name = keysArray[1]
      const attribute = keysArray[3]
      state.dynamicArrays[array][name][attribute] = value
    }
  },

  actions: {
    setDynamicAttributes ({ commit }, payload) {
      commit('SET_DYNAMIC_ATTRIBUTES', payload)
    },

    setDynamicArrayValue ({ commit }, payload) {
      commit('SET_DYNAMIC_ARRAY_VALUE', payload)
    },

    resetDynamicArrays ({ state, commit }) {
      state.dynamicAttributes.forEach(attribute => {
        const dynamicSettings = attribute.dynamic_settings
        const defaultValue = dynamicSettings.default_value
        const payload = {
          keysArray: attribute.field.split('.'),
          value: defaultValue
        }
        commit('SET_DYNAMIC_ARRAY_VALUE', payload)
      })
    }
  }
}

export default store
