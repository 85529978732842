<template>
  <div
    class="text-wrap"
  >
    <p
      class="px-0 my-auto"
      :class="$vuetify.breakpoint.xs ? 'text-right' : 'text-left'"
    >
      {{ formatSqlDateTime(item.value) }}
    </p>
  </div>
</template>

<script>
import { formatSqlDateTime } from '@/global/services/helpers/dates'

export default {
  name: 'TextField',
  props: {
    item: {
      type: Object,
      require: true,
      default: () => ({ value: 'n/a' })
    }
  },
  methods: {
    formatSqlDateTime
  }
}
</script>
