const randomColorsMixin = {
  data () {
    return {
      defaultColors: [
        'red',
        'orange',
        'yellow',
        'lime',
        'green',
        'cyan',
        'indigo',
        'purple',
        'pink',
        'brown'
      ],
      colors: [],
      colorIdMap: {}
    }
  },

  methods: {
    getRandomColor (item, idField) {
      const randomIndex = Math.floor(Math.random() * this.colors.length)
      const id = item[idField] ?? item.value
      const color = this.colorIdMap[id.toString()]
      if (color) {
        return color
      }

      this.colorIdMap[id.toString()] = this.colors[randomIndex]

      this.colors = this.colors.filter((color, index) => (index !== randomIndex))
      if (!this.colors.length) {
        this.colors = [...this.defaultColors]
      }
    }
  },

  created () {
    this.colors = [...this.defaultColors]
  }
}

export default randomColorsMixin
