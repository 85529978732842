<template>
  <v-app
    :class="{ 'primary-background': backgroundPrimary === true }"
  >
    <v-main>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            :md="$route.name === 'Cookies' ? '6' : '4'"
          >
            <slot />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'CenteredLayout',
  props: {
    backgroundPrimary: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.primary-background {
  background-color: var(--v-primary-base) !important;
}
</style>
