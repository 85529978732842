<template>
  <component
    :is="currentComponent"
    v-slot="slots"
  >
    <v-list-item
      exact
      link
      v-bind="slotProps(slots, 'bind')"
      v-on="slotProps(slots, 'event')"
    >
      <v-list-item-icon class="mr-3">
        <v-progress-circular
          v-if="slots.fetching"
          indeterminate
          size="18"
        />
        <v-icon
          v-else
          size="18"
        >
          {{ menu.icon }}
        </v-icon>
      </v-list-item-icon>

      <v-list-item-title class="avatar-menu-text">
        {{ menu.name }}
      </v-list-item-title>
    </v-list-item>
  </component>
</template>

<script>
export default {
  components: {
    Logout: () => import('@/modules/base-module/logout/components/Logout')
  },

  props: {
    menu: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      bindAttributes: [],
      eventAttributes: [],
      currentComponent: ''
    }
  },

  computed: {
    attributeTypes () {
      return this.menu.attributes.map(attribute => attribute.type)
    }
  },

  mounted () {
    this.setActionComponent()
    this.setAttributes()
  },

  methods: {
    setActionComponent () {
      this.currentComponent = this.menu.action_component
    },

    setAttributes () {
      this.attributeTypes.forEach(attribute => {
        this.menu.attributes.filter(attr => attr.type === attribute)
        this[`${attribute}Attributes`].push(...this.menu.attributes.filter(attr => attr.type === attribute))
      })
    },

    slotProps (slots, type) {
      const obj = {}
      this[`${type}Attributes`].forEach(attribute => {
        obj[attribute.type_name] = slots[attribute.name]
      })

      return obj
    }
  }
}
</script>
