<template>
  <div class="d-inline mr-4">
    <v-dialog
      v-model="dialog"
      @click:outside="closeDialog"
      @keydown.esc="closeDialog"
      max-width="800px"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          <span class="headline">
            {{ dialogTitle }}
          </span>
          <v-spacer />
          <v-btn
            fab
            x-small
            class="pa-0 dialog-close-button no-background-hover"
            style="background: transparent;"
            elevation="0"
            @click="closeDialog"
          >
            <v-icon
              color="grey darken-2"
              size="1.8rem"
            >
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="margin: 0; padding: 0; border-top: 2px solid lightslategray; border-bottom: 2px solid lightslategray;">
          <v-container style="margin: 0; padding: 0;">
            <v-row style="margin: 0; padding: 0;">
              <v-col
                cols="12"
                style="margin: 0; padding: 0;"
              >
                <div style="height: 600px; margin: 0; padding: 0;"
                >
                  <map-base
                    ref="mapBase"
                    :map-options="{ doubleClickZoom: false }"
                  />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="grey lighten-2">
          <v-spacer />
          <v-btn
            color="blue-grey darken-1"
            dark
            @click="closeDialog"
          >
            {{ $t('base.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import MapBase from '../map/MapBase.vue'
import { isEmpty } from 'lodash'
import { api } from '../../services/api'

export default {
  name: 'LocationMapModal',

  components: {
    MapBase
  },

  emits: ['close-dialog'],

  props: {
    locationDialog: {
      type: Boolean,
      required: true
    },

    dialogTitle: {
      type: String,
      required: true
    },

    geolocation: {
      type: Object,
      default: () => ({})
    },

    vehicleId: {
      type: Number,
      default: null
    },

    showAddressOnMarkerHover: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      dialog: false
    }
  },

  watch: {
    locationDialog: function (locationDialog) {
      this.dialog = locationDialog
    },
    geolocation: {
      deep: true,
      immediate: true,
      handler (location) {
        if (!isEmpty(location)) {
          const markerConfig = {
            markers: [{
              lat: location.lat,
              lon: location.lng,
              popup: {
                popupData: () => this.resolveAddress(location),
                popupOptions: {
                  closeButton: false
                }
              }
            }],
            fitBoundsOptions: {
              maxZoom: 15
            }
          }
          this.$nextTick(() => {
            if (!this.showAddressOnMarkerHover) {
              delete markerConfig.markers[0].popup
            }
            this.$refs?.mapBase?.onMapReady((map) => {
              map.generateMarkers(markerConfig)
            })
          })
        }
      }
    }
  },

  methods: {
    closeDialog () {
      this.$emit('close-dialog')
    },

    async resolveAddress (location) {
      try {
        if (location && !isEmpty(location) && location.lat && location.lng) {
          if (this.vehicleId && !isEmpty(this.vehicleId)) {
            location.vehicleId = this.vehicleId
          }
          const { data } = await api()['satellite-tracking'].get('reverse-geocode', location)
          return data && data.address ? data.address : this.$t('people/person.no_data_available')
        }
        else {
          console.log('wrong data format')
          return this.$t('people/person.no_data_available')
        }
      }
      catch (error) {
        console.error(error)
        return this.$t('people/person.no_data_available')
      }
    }
  }
}
</script>
