<template>
  <div v-if="hasMapDialog" class="d-flex justify-center">
    <v-btn
      icon
      class="d-block"
      @click.stop="openDialog"
    >
      <v-icon>{{ icon }}</v-icon>
    </v-btn>
  </div>
  <component
    :is="href ? 'v-btn' : 'div'"
    v-else
    :href="href"
    icon
    @click.stop
  >
    <v-icon
      :color="color"
      :size="size"
    >
      {{ icon }}
    </v-icon>
  </component>
</template>

<script>
import { VBtn } from 'vuetify/lib'

export default {
  name: 'IconField',

  components: {
    VBtn
  },

  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    config: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: null
    }
  },

  computed: {
    icon () {
      return this.config.icon ?? this.item[this.config.icon_field]
    },
    href () {
      return this.config.href ?? this.item[this.config.href_field]
    },
    color () {
      return this.config.color ?? this.item[this.config.color_field]
    },
    hasMapDialog () {
      return !!this.config.open_map_dialog
    },
    dialogTitle () {
      return this.config.dialog_title
    },
    markerData () {
      return {
        latitude: +this.item?.geolocation?.lat,
        longitude: +this.item?.geolocation?.lng
      }
    }
  },

  methods: {
    openDialog () {
      this.$emit('open-map-overview', {
        data: this.markerData,
        title: this.dialogTitle
      })
    }
  }
}
</script>
