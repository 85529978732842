<template>
  <v-dialog
    v-model="internalOpened"
    content-class="elevation-0"
    :width="filterDialogSize[$vuetify.breakpoint.name]"
  >
    <v-card>
      <v-row class="flex justify-space-between ma-0">
        <v-card-title>{{ title }}</v-card-title>

        <v-btn
          text
          x-small
          class="mt-3 mr-2 pa-0 no-background-hover"
          elevation="0"
          @click="close"
        >
          <v-icon
            color="grey darken-1"
            size="1.8rem"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </v-row>

      <v-card-text class="mt-6 pb-0">
        <v-row
          v-for="(fieldOptions, fieldName) in fields"
          :key="fieldName"
        >
          <v-col
            class="font-weight-bold"
            align-self="start"
          >
            {{ fieldOptions.label }}
          </v-col>
          <v-col class="py-0 pl-0">
            <v-text-field
              v-if="fieldOptions.type === 'string'"
              v-model="formData[fieldName]"
              outlined
              dense
              :label="isMobileView ? fieldOptions.label : ''"
            />
            <v-menu
              v-if="fieldOptions.type === 'datepicker'"
              :ref="`dynamic_datepicker_${fieldName}`"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="formData[`formatted_${fieldName}`]"
                  dense
                  :label="isMobileView ? fieldOptions.label : ''"
                  append-icon="mdi-calendar"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="formData[fieldName] = null"
                />
              </template>
              <v-date-picker
                v-model="formData[fieldName]"
                first-day-of-week="1"
                full-width
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="$refs[`dynamic_datepicker_${fieldName}`][0].save(formData[fieldName])"
                >
                  {{ $t('base.ok') }}
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="primary"
          text
          @click="confirm"
        >
          {{ $t('base.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatSqlDate } from '@/global/services/helpers/dates'

export default {
  name: 'ConfirmServiceIntervalDialog',

  props: {
    opened: {
      type: Boolean,
      required: true,
      default: false
    },

    title: {
      type: String,
      required: true
    },

    fields: {
      type: Object,
      required: true
    },

    data: {
      type: Object,
      default: () => ({})
    },

    confirmServiceInterval: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      formData: {},
      filterDialogSize: {
        xl: '33.33%',
        lg: '33.33%',
        md: '50%',
        sm: '66.66%',
        xs: '100%'
      }
    }
  },

  computed: {
    isMobileView () {
      return this.$vuetify.breakpoint.name === 'xs'
    },

    internalOpened: {
      get: function () {
        return this.opened
      },
      set: function (value) {
        this.$emit('update:opened', value)
      }
    }
  },

  watch: {
    internalOpened (value) {
      if (value) {
        this.formData = this.data
      }
      else {
        this.formData = {}
      }
    },

    formData: {
      deep: true,
      immediate: true,
      handler (formData) {
        const formattedPrefix = 'formatted_'
        const formattedDateColumns = Object.keys(formData)
          .filter(columnKey => columnKey.startsWith(formattedPrefix))

        formattedDateColumns.forEach(formattedDateColumn => {
          const defaultDateColumn = formattedDateColumn.replace(formattedPrefix, '')
          this.formData[formattedDateColumn] = formatSqlDate(formData[defaultDateColumn])
        })
      }
    }
  },

  methods: {
    close () {
      this.formData = {}
      this.internalOpened = false
    },

    confirm () {
      this.$emit('confirm', {
        ...this.confirmServiceInterval,
        ...this.formData,
        confirmed: true // Only used as a flag to add row with confirmed data
      })
      this.internalOpened = false
    }
  }
}
</script>
