import sendSms from '@/modules/sms-module/send-sms/routes/index.child'

export default {
  path: '/sms',
  redirect: {
    name: 'NotFound',
    params: ['/sms']
  },
  component: () => import(/* webpackChunkName: "sms" */ '../views/SmsView.vue'),
  meta: {
    exclude: false,
    requiresAuth: true
  },
  children: [
    sendSms
  ]
}
