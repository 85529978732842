export default {
  path: '/password/reset/*',
  name: 'PasswordReset',
  component: () => import(/* webpackChunkName: "base/login" */ '../views/PasswordReset.vue'),
  meta: {
    title: 'base.passwordReset',
    exclude: true,
    layout: 'login',
    requiresAuth: false
  }
}
