import { api } from '@/global/services/api'

const state = {
  items: [],
  cityDistricts: [],
  permissions: [],
  pagination: {
    count: 0,
    currentPage: 1,
    perPage: 5,
    totalPages: 1,
    total: 0
  },
  sort: {},
  dropdownType: null,
  viewConfig: {}
}

const getters = {
  list: state => state.items,
  pagination: state => state.pagination,
  sort: state => state.sort,
  dropdownType: state => state.dropdownType,
  cityDistricts: state => state.cityDistricts,
  viewConfig: state => state.viewConfig,
  permissions: state => state.permissions
}

const actions = {

  async fetch ({ commit, state }, options) {
    try {
      const params = {
        pagination: options.pagination,
        sort: options.sort,
        dropdownType: state.dropdownType,
        filters: options.filters
      }
      const {
        data,
        pagination,
        sort
      } = await api('road-maintenance').get('excavation-settings', params)
      commit('SET_ITEMS', data)
      commit('SET_PAGINATION', pagination)
      commit('SET_SORT', sort)
      commit('SET_VIEW_CONFIG', options.viewConfig)
    }
    catch (e) {
      console.dir(e)
    }
  },

  async fetchPermissions ({ commit, dispatch }) {
    try {
      const permissions = await api('road-maintenance').get('excavation-settings/permissions')

      commit('SET_PERMISSIONS', permissions)
    }
    catch (e) {
      console.dir(e)
    }
  },

  async fetchCityDistricts ({ commit, dispatch }) {
    try {
      const { data } = await api('locations').get('locations/city-districts')

      commit('SET_CITY_DISTRICTS', data)
    }
    catch (e) {
      console.dir(e)
    }
  },

  setDropdownType ({ commit }, payload) {
    commit('SET_DROPDOWN_TYPE', payload)
  },

  setViewConfig ({ commit }, payload) {
    commit('SET_VIEW_CONFIG', payload)
  }
}

const mutations = {
  SET_ITEMS: (state, payload) => (state.items = payload),
  SET_CITY_DISTRICTS: (state, payload) => (state.cityDistricts = payload),
  SET_PAGINATION ({ pagination }, payload) {
    const { count, currentPage, perPage, totalPages, total } = payload
    pagination.count = count || 0
    pagination.currentPage = currentPage || 1
    pagination.perPage = perPage || 0
    pagination.totalPages = totalPages || 1
    pagination.total = total || 0
  },
  SET_SORT: (state, payload) => (state.sort = payload),
  SET_DROPDOWN_TYPE: (state, payload) => {
    state.dropdownType = payload
  },
  SET_VIEW_CONFIG: (state, payload) => (state.viewConfig = payload),
  SET_PERMISSIONS: (state, payload) => {
    state.permissions = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
