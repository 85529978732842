export default {
  path: '/cookies',
  name: 'Cookies',
  component: () => import(/* webpackChunkName: "base/login" */ '../views/CookiesView.vue'),
  meta: {
    // title: 'base.company',
    layout: 'centered',
    exclude: true,
    requiresAuth: false,
    needsPermission: false
  }
}
