import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import hr from 'vuetify/es5/locale/hr'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  lang: {
    locales: { hr },
    current: 'hr'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#869DB6',
        'primary-lighten-1': '#C0C7CF',
        'primary-lighten-2': '#DFE4EA',
        'primary-lighten-3': '#F6F8FA',
        'primary-darken-1': '#65778C',
        'primary-darken-2': '#485968',
        'primary-darken-3': '#323C44',
        'primary-darken-4': '#212b36',
        'primary-darken-5': '#171E27',
        secondary: '#575756',
        success: '#65AD45',
        accent: '#66a730',
        orange: '#E47B26',
        'yellow-muted': '#E8BA4E',
        'red-muted': '#E54E4E',
        'green-accent-2': '#6ECD7B',
        'dark-black': '#222222',
        'dark-grey': '#969696',
        'light-grey': '#EEEEEE',
        'grey-lighten-1': '#DDDDDD',
        white: '#FFFFFF',
        'cyan-darken-1': '#33a2b8',
        blue: '#2196F3'
      }
    }
  }
})
