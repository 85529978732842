<template>
  <v-app>
    <v-container
      class="justify-center"
      fluid
      fill-height
    >
      <Spinner />
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'LoadingView'
}
</script>
