import { api } from '@/global/services/api'

const state = {
  viewConfig: {},
  vehicleDetailsCheckboxes: {},
  selectedVehicles: [],
  markerIcons: {},
  tooltipComponentKey: 0,
  loading: false,
  controlsLoading: false,
  liveRoutesLoading: false,
  inputTypes: null,
  singleTooltipId: null,
  clickedVehicleFromVehiclePickerList: null
}

const getters = {
  viewConfig: state => state.viewConfig,
  vehicleDetailsCheckboxes: state => state.vehicleDetailsCheckboxes,
  getSelectedVehicles: state => state.selectedVehicles,
  getClickedVehicleFromVehiclePickerList: state => state.clickedVehicleFromVehiclePickerList,
  getInputTypes: state => state.inputTypes,
  getSingleTooltipId: state => state.singleTooltipId,
  markerIcons: state => state.markerIcons,
  tooltipComponentKey: state => state.tooltipComponentKey,
  loading: state => state.loading,
  controlsLoading: state => state.controlsLoading,
  liveRoutesLoading: state => state.liveRoutesLoading
}

const actions = {
  async fetch ({ commit, dispatch }) {
    commit('SET_LOADING', true)

    try {
      const { config } = await api()['satellite-tracking'].get('live-tracking')
      const { vehicleDetailsCheckboxes, markerIcons } = config

      commit('SET_VIEW_CONFIG', config)
      dispatch('map-type/setMapType', config.mapTypeToggler.find(item => item.key === config.selected_map_type) || config.mapTypeToggler[0], { root: true })
      commit('SET_VEHICLE_DETAILS_CHECKBOXES', vehicleDetailsCheckboxes)
      commit('SET_MARKER_ICONS', markerIcons)
    }
    catch (e) {
      console.dir(e)
    }
    finally {
      commit('SET_LOADING', false)
    }
  },

  async updateMapConfig ({ commit, state }, payload) {
    try {
      await api()['satellite-tracking'].post('live-tracking/map-config', {
        map_type: payload.key
      })
    }
    catch (e) {
      console.dir(e)
    }
  },

  async updateMapOptions ({ state }) {
    try {
      const options = state.vehicleDetailsCheckboxes

      const legendFilters = options.legend[0].items.reduce((filters, item) => {
        return {
          ...filters,
          [item.filterName]: item.filterStatus
        }
      }, {})

      const lastPositionChangeDateTimeValue = options.marker.filter(function (el) {
        return el.key === 'lastPositionChangeDateTime'
      })

      await api()['satellite-tracking'].post('map-options', {
        uiView: 'live_tracking',
        map_options: {
          auto_center: options.centering[0].showInTooltip,
          show_registration: options.vehicle[0].showInTooltip,
          show_speed: options.marker[0].showInTooltip,
          show_voltage: options.marker[1].showInTooltip,
          show_details: options.details[0].showInTooltip,
          show_last_position_change_date_time: lastPositionChangeDateTimeValue[0]?.showInTooltip,
          show_live_routes: options.live_route[0].showInTooltip,
          show_user_locations: options.user_locations[0].showInTooltip,
          show_partner_locations: options.partner_locations[0].showInTooltip,
          disable_clustering: options.disable_clustering[0].showInTooltip,
          show_legend: options.legend[0].showInTooltip,
          // Filter options shown in the legend
          filters: legendFilters
        }
      })
    }
    catch (e) {
      console.dir(e)
    }
  },

  updateVehicleDetailsCheckboxes ({ commit, dispatch }, vehicleDetailsCheckboxes) {
    commit('SET_VEHICLE_DETAILS_CHECKBOXES', vehicleDetailsCheckboxes)
    dispatch('updateMapOptions')
  },

  setMapType ({ commit, dispatch }, payload) {
    dispatch('map-type/setMapType', payload, { root: true })
    dispatch('updateMapConfig', payload)
  },

  setSelectedVehicles ({ commit }, vehicles) {
    commit('SET_SELECTED_VEHICLES', vehicles)
  },

  setLoading ({ commit }, loading) {
    commit('SET_LOADING', loading)
  },

  setControlsLoading ({ commit }, loading) {
    commit('SET_CONTROLS_LOADING', loading)
  },

  setLiveRoutesLoading ({ commit }, loading) {
    commit('SET_LIVE_ROUTES_LOADING', loading)
  },

  setInputTypes ({ commit }, inputTypes) {
    commit('SET_INPUT_TYPES', inputTypes)
  },

  setSingleTooltipId ({ commit }, deviceId) {
    commit('SET_SINGLE_TOOLTIP_ID', deviceId)
  },

  updateTooltipComponentKey ({ commit, dispatch }, componentKey) {
    commit('SET_TOOLTIP_COMPONENT_KEY', componentKey)
  },

  setClickedVehicleFromVehiclePickerList ({ commit }, vehicle) {
    commit('SET_CLICKED_VEHICLE_FROM_VEHICLE_PICKER_LIST', vehicle)
  }
}

const mutations = {

  SET_VIEW_CONFIG: (state, payload) => (state.viewConfig = payload),

  SET_VEHICLE_DETAILS_CHECKBOXES: (state, payload) => (state.vehicleDetailsCheckboxes = payload),

  SET_SELECTED_VEHICLES: (state, payload) => (state.selectedVehicles = payload),

  SET_MARKER_ICONS: (state, payload) => (state.markerIcons = payload),

  SET_TOOLTIP_COMPONENT_KEY: (state, payload) => (state.tooltipComponentKey = payload),

  SET_LOADING: (state, payload) => (state.loading = payload),

  SET_CONTROLS_LOADING: (state, payload) => (state.controlsLoading = payload),

  SET_LIVE_ROUTES_LOADING: (state, payload) => (state.liveRoutesLoading = payload),

  SET_INPUT_TYPES: (state, payload) => (state.inputTypes = payload),

  SET_SINGLE_TOOLTIP_ID: (state, payload) => (state.singleTooltipId = payload),

  SET_CLICKED_VEHICLE_FROM_VEHICLE_PICKER_LIST: (state, payload) => (state.clickedVehicleFromVehiclePickerList = payload)

}

export default {
  state,
  getters,
  actions,
  mutations
}
