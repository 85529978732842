export default {
  path: '/company',
  name: 'CompanyOption',
  component: () => import(/* webpackChunkName: "base/login" */ '../views/CompanyView.vue'),
  meta: {
    // title: 'base.company',
    exclude: false,
    requiresAuth: true,
    needsPermission: true
  }
}
