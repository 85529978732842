<template>
  <div class="dependant-autocompletes-wrapper">
    <v-autocomplete
      v-model="dependsOnId"
      :hide-details="hideDetails"
      :items="dependsOnInternalItems"
      :label="viewLabel ? dependsOn.label : ''"
      :disabled="dependsOnDisabled"
      :clearable="dependsOn.clearable && !dependsOnDisabled"
      :append-icon="!dependsOnDisabled ? '$dropdown' : ''"
      :search-input.sync="dependsOnItemSearch"
      :loading="dependsOnDisabled"
      :no-filter="true"
      outlined
      :class="[hideDetails ? 'py-3' : '']"
      dense
    >
      <template #append-item>
        <v-list-item
          v-if="dependsOn.hasMoreItems"
          class="has-more-items"
          @click="makeSearch('', dependsOn, true)"
        >
          <v-list-item-title class="font-italic text-decoration-underline">
            {{ $t('base.got_more_items') }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-autocomplete>
    <v-autocomplete
      v-model="dependantId"
      :hide-details="hideDetails"
      :items="dependantInternalItems"
      :label="viewLabel ? dependant.label : ''"
      :disabled="dependantDisabled"
      :clearable="dependant.clearable && !dependantDisabled"
      :append-icon="!dependantDisabled ? '$dropdown' : ''"
      :search-input.sync="dependantItemSearch"
      :error-messages="errorMessages"
      :loading="dependantDisabled"
      :no-filter="true"
      outlined
      :class="[hideDetails ? 'py-3' : '']"
      dense
    >
      <template #append-item>
        <v-list-item
          v-if="dependant.hasMoreItems"
          class="has-more-items"
          @click="makeSearch('', dependant, true)"
        >
          <v-list-item-title class="font-italic text-decoration-underline">
            {{ $t('base.got_more_items') }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { isEmpty, map } from 'lodash'
import { api } from '@/global/services/api'
import { createNamespacedHelpers } from 'vuex'

const {
  mapActions: mapActionsDependantAutocompletes,
  mapState: mapStateDependantAutocompletes
} = createNamespacedHelpers('dependant-autocompletes')

const getComputedProperties = (mapStateDependantAutocompletes, storeSuffix) => {
  return {
    ...mapStateDependantAutocompletes({
      dependsOn: state => state[storeSuffix].dependsOn,
      dependsOnInternalItems: state => state[storeSuffix].dependsOnInternalItems,
      dependsOnDisabled: state => state[storeSuffix].dependsOnDisabled,
      dependant: state => state[storeSuffix].dependant,
      dependantInternalItems: state => state[storeSuffix].dependantInternalItems,
      dependantDisabled: state => state[storeSuffix].dependantDisabled,
      depIds: state => state[storeSuffix].depIds,
      dependsOnText: state => state[storeSuffix].dependsOnText,
      dependantText: state => state[storeSuffix].dependantText,
      label: state => state[storeSuffix].label
    }),

    dependantIds: {
      get () {
        return {
          depends_on: this.dependsOnId,
          dependant: this.dependantId
        }
      }
    },

    dependantId: {
      get () {
        return this.depIds.dependantId
      },

      set (dependantId) {
        this.setDepIdsKey({
          key: 'dependantId',
          value: dependantId,
          storeSuffix: this.$options.propsData.storeSuffix
        })
      }
    }
  }
}

const getMethods = (mapActionsDependantAutocompletes, storeSuffix) => {
  return {
    ...mapActionsDependantAutocompletes([
      'setDepIds',
      'setDepIdsKey',
      'resetDepIds',
      'setDependsOn',
      'setDependant',
      'setDependantKey',
      'setDependsOnKey',
      'setDependantInternalItems',
      'setDependsOnInternalItems',
      'setDependsOnText',
      'setDependantText',
      'setLabel',
      'setDependantDisabled'
    ]),

    /**
     * Makes an API call for that return values for the autocompletes.
     * @param query
     * @param allItems
     * @param dependantObject
     */
    async makeSearch (query, dependantObject, allItems = false) {
      const options = dependantObject.autocomplete_options
      const params = {
        query: query ?? '',
        // Extract id for either dependsOn or dependant object depending on its `dependantType` value.
        pickedId: this.dependantIds[dependantObject.dependantType] ?? null,
        includeAll: allItems
      }

      const route = dependantObject.dependantType === 'depends_on' ? options.route : options.modifiedRoute

      if (!route) return

      const {
        data,
        has_more: hasMore = false
      } = await api()[options.module].get(route, params)

      const internalItems = map(data, ({ id, name }) => ({
        value: id.toString(),
        text: name
      }))

      // If passed object is `depends_on` and value in it is selected
      // proceed with making a search for values in a `dependant` autocomplete.
      if (dependantObject.dependantType === 'depends_on' && this.dependantIds[dependantObject.dependantType]) {
        const options = this.dependant.autocomplete_options
        const dependsOnModel = this.dependsOn.autocomplete_options.model
        const id = this.dependantIds[dependantObject.dependantType]
        const dependantRoute = this.generateRoute(options, dependsOnModel, id)
        this.setRoute(this.dependant, dependantRoute)
        await this.makeSearch('', this.dependant)
      }
      // Set autocompletes `items` and `hasMore`
      if (dependantObject.dependantType === 'dependant') {
        this.setDependantInternalItems({
          value: internalItems,
          storeSuffix
        })
        this.setDependantKey({
          key: 'hasMoreItems',
          value: hasMore,
          storeSuffix
        })
      }
      if (dependantObject.dependantType === 'depends_on') {
        this.setDependsOnInternalItems({
          value: internalItems,
          storeSuffix
        })
        this.setDependsOnKey({
          key: 'hasMoreItems',
          value: hasMore,
          storeSuffix
        })
      }
    },

    /**
     * If route contains model string as route parameter then replace it with ID.
     * Otherwise, just return it because route already contains ID.
     * @param options
     * @param dependsOnModel
     * @param id
     * @returns string
     */
    generateRoute (options, dependsOnModel, id) {
      const dependantRoute = options.route
      if (dependantRoute.includes(`:${dependsOnModel}`)) {
        return dependantRoute.replace(`:${dependsOnModel}`, id.toString())
      }

      return dependantRoute
    },

    /**
     * Updates the dependant object with new route.
     * @param dependantObject
     * @param dependantRoute
     */
    setRoute (dependantObject, dependantRoute) {
      const dependantOptions = { ...dependantObject.autocomplete_options }
      dependantOptions.modifiedRoute = dependantRoute
      this.setDependantKey({
        key: 'autocomplete_options',
        value: dependantOptions,
        storeSuffix: this.$options.propsData.storeSuffix
      })
    },

    getDependsOnAndDependantRouteParams (dependsOnKey, dependantKey) {
      let dependsOnRouteParam
      let dependantRouteParam

      if (this.dependsOn.route_param_type === 'foreignKey') {
        dependsOnRouteParam = `${dependantKey}_id`
      }
      else {
        dependsOnRouteParam = dependsOnKey
      }

      if (this.dependant.route_param_type === 'foreignKey') {
        dependantRouteParam = 'morph_model_map' in this.dependant
          ? this.dependant.morph_model_map[this.dependsOnId].foreign_key
          : `${dependantKey}_id`
      }
      else {
        dependantRouteParam = dependantKey
      }

      return [dependsOnRouteParam, dependantRouteParam]
    }
  }
}

export default {
  name: 'DependantAutocompletes',

  props: {
    value: {
      type: [Number, Object],
      default: null
    },

    errorMessages: {
      type: Array,
      default: () => ([])
    },

    dependants: {
      type: Object,
      required: true
    },

    depIdsProp: {
      type: Object,
      default: () => ({}),
      validator: (depIds) => {
        // Validate expected keys for the object
        const validKeys = Object.keys(depIds)
          .every(depKey => ['dependsOnId', 'dependantId'].includes(depKey))
        // Validate that values are numeric or null
        const validValues = Object.values(depIds)
          .every(depValue => depValue ? !isNaN(depValue) : true)

        return validKeys && validValues
      }
    },

    viewLabel: {
      type: Boolean,
      default: false
    },

    hideDetails: {
      type: Boolean,
      default: false
    },

    storeSuffix: {
      type: String,
      required: true,
      validator: storeSuffix => ['filters', 'form-fields'].includes(storeSuffix)
    }
  },

  data () {
    return {
      dependsOnItemSearch: null,
      dependantItemSearch: null,
      dependsOnId: null,
      storeName: ''
    }
  },

  watch: {
    dependants: {
      deep: true,
      immediate: true,
      handler (dependants) {
        // const setDependsOn = this.getStoreMethod('setDependsOn', '_actions')[0]
        this.setDependsOn({
          value: {
            ...dependants.depends_on,
            dependantType: 'depends_on'
          },
          storeSuffix: this.$options.propsData.storeSuffix
        })
        this.setDependant({
          value: {
            ...dependants.dependant,
            dependantType: 'dependant'
          },
          storeSuffix: this.$options.propsData.storeSuffix
        })
      }
    },

    depIdsProp: {
      deep: true,
      immediate: true,
      handler (depIdsProp) {
        if (!isEmpty(depIdsProp)) {
          this.setDepIds({
            value: depIdsProp,
            storeSuffix: this.$options.propsData.storeSuffix
          })
        }
      }
    },

    depIds: {
      deep: true,
      immediate: true,
      handler (depIds) {
        // Update dependsOnId and dependantId on deIds object change.
        this.dependsOnId = depIds.dependsOnId ? depIds.dependsOnId.toString() : null
        this.dependantId = depIds.dependantId ? depIds.dependantId.toString() : null
      }
    },

    dependsOnId (dependsOnId, oldDependsOnId) {
      // If user doesn't press close button, but enters a new valid value in dependsOn autocomplete.
      if (oldDependsOnId && dependsOnId !== oldDependsOnId) {
        this.dependantId = null

        const defaultRoute = this.dependant.autocomplete_options.route
        this.setRoute(this.dependant, defaultRoute)

        this.setDependantInternalItems({
          value: [],
          storeSuffix: this.$options.propsData.storeSuffix
        })
        this.setDependantKey({
          key: 'hasMoreItems',
          value: 0,
          storeSuffix: this.$options.propsData.storeSuffix
        })
      }
      // If user clicks on close icon on dependsOn autocomplete,
      // reset both dependsOn and dependant autocompletes.
      else if (!dependsOnId) {
        this.dependantId = null

        // Reset route
        const defaultRoute = this.dependant.autocomplete_options.route
        // Set the newly generated default route.
        this.setRoute(this.dependant, defaultRoute)

        this.resetDepIds(this.$options.propsData.storeSuffix)

        // Reset internalItems and hasMoreItems keys of dependant and dependsOn in this component.
        this.setDependantInternalItems({
          value: [],
          storeSuffix: this.$options.propsData.storeSuffix
        })
        this.setDependantKey({
          key: 'hasMoreItems',
          value: 0,
          storeSuffix: this.$options.propsData.storeSuffix
        })
        this.setDependsOnInternalItems({
          value: [],
          storeSuffix: this.$options.propsData.storeSuffix
        })
        this.setDependsOnKey({
          key: 'hasMoreItems',
          value: 0,
          storeSuffix: this.$options.propsData.storeSuffix
        })
        this.setDependsOnText({
          value: null,
          storeSuffix: this.$options.propsData.storeSuffix
        })
        this.setDependantText({
          value: null,
          storeSuffix: this.$options.propsData.storeSuffix
        })
        this.setLabel({
          value: null,
          storeSuffix: this.$options.propsData.storeSuffix
        })
        return
      }

      const selectedDependsOn = this.dependsOnInternalItems
        ?.find(item => item.value === dependsOnId)
      const selectedDependant = this.dependantInternalItems
        ?.find(item => item.value === this.dependantId)

      const label = this.dependsOnId
        ? this.dependsOn.label
        : null

      this.setDepIdsKey({
        key: 'dependsOnId',
        value: dependsOnId,
        storeSuffix: this.$options.propsData.storeSuffix
      })
      this.setDependsOnText({
        value: selectedDependsOn?.text,
        storeSuffix: this.$options.propsData.storeSuffix
      })
      this.setDependantText({
        value: selectedDependant?.text,
        storeSuffix: this.$options.propsData.storeSuffix
      })
      this.setLabel({
        value: label,
        storeSuffix: this.$options.propsData.storeSuffix
      })

      const inputObject = {
        [this.dependsOn.field]: dependsOnId,
        [this.dependant.field]: this.dependantId
      }
      this.$emit('input', inputObject || null)
    },

    /**
     * If user enters changes value in dependant autocomplete, emit object with new ID for it.
     */
    dependantId (dependantId) {
      const selectedDependsOn = this.dependsOnInternalItems
        .find(item => item.value === this.dependsOnId)
      const selectedDependant = this.dependantInternalItems
        .find(item => item.value === dependantId)
      const label = dependantId
        ? this.dependant.label
        : this.dependsOnId
          ? this.dependsOn.label
          : null

      this.setDependsOnText({
        value: selectedDependsOn?.text,
        storeSuffix: this.$options.propsData.storeSuffix
      })
      this.setDependantText({
        value: selectedDependant?.text,
        storeSuffix: this.$options.propsData.storeSuffix
      })
      this.setLabel({
        value: label,
        storeSuffix: this.$options.propsData.storeSuffix
      })

      const inputObject = {
        [this.dependsOn.field]: this.dependsOnId,
        [this.dependant.field]: dependantId
      }

      this.$emit('input', inputObject || null)
    },

    /**
     * Search for values on typing some text in dependsOn autocomplete.
     */
    async dependsOnItemSearch () {
      this.setDependantDisabled({
        value: true,
        storeSuffix: this.$options.propsData.storeSuffix
      })
      this.makeSearch(this.dependsOnItemSearch, this.dependsOn)
        .finally(() => (this.setDependantDisabled({
          value: false,
          storeSuffix: this.$options.propsData.storeSuffix
        })))
    },

    /**
     * Search for values on typing some text in dependant autocomplete.
     */
    async dependantItemSearch () {
      await this.makeSearch(this.dependantItemSearch, this.dependant)
    }
  },

  beforeCreate () {
    // Props are not available when defining computed properties, so they are set dynamically here.
    const storeSuffix = this.$options.propsData.storeSuffix
    this.$options.computed = getComputedProperties(mapStateDependantAutocompletes, storeSuffix)
    this.$options.methods = getMethods(mapActionsDependantAutocompletes, storeSuffix)
  },

  async created () {
    try {
      await this.makeSearch('', this.dependsOn)
    }
    catch (e) {
      console.log(e)
    }
  }
}
</script>

<style scoped lang="scss">
  .dependant-autocompletes-wrapper {
    padding: 0 !important;
    .has-more-items {
      cursor: pointer !important;
    }
  }
</style>
