<template>
  <div class="mt-4">
    <p class="font-weight-bold">{{ label }}</p>
    <autocomplete
      label=""
      :error-messages="errorMessages"
      :items.sync="internalItems"
      :options="options"
      :value="selectedItem"
      :edit-ids="editItem ? value : null"
      @input="handleSelectItem"
      @fetch-edit-items="handleFetchEditItems"
    />
    <v-card v-if="listItems.length">
      <v-list dense class="py-0">
        <div v-for="(item, index) in listItems" :key="item.value">
          <v-list-item>
            <v-list-item-content class="d-flex justify-space-between align-center">
              <v-list-item-title class="flex-fill">{{ item.text }}</v-list-item-title>
              <v-btn
                icon
                @click="removeItem(index)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="index < items.length - 1" />
        </div>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import Autocomplete from '@/modules/base-module/autocomplete/components/Autocomplete'

import { has } from 'lodash'

export default {
  name: 'Many2manyAutocompleteList',

  components: {
    Autocomplete
  },

  props: {
    value: {
      type: Array,
      default: () => []
    },

    label: {
      type: String,
      required: true
    },

    options: {
      type: Object,
      required: true,
      validator: (options) => {
        return has(options, 'module') && has(options, 'route')
      }
    },

    clearable: {
      type: Boolean,
      default: true
    },

    items: {
      type: Array,
      default: () => ([])
    },

    errorMessages: {
      type: Array,
      default: () => ([])
    },

    editItem: {
      type: Object,
      default: () => null
    }
  },

  data () {
    return {
      selectedItem: null,
      listItems: []
    }
  },

  computed: {
    selectedItems: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value || [])
      }
    },

    internalItems: {
      get () {
        return this.items
      },

      set (value) {
        this.$emit('update:items', value)
      }
    }
  },

  watch: {
    value: function (newVal) {
      if (!newVal) {
        this.listItems = []
      }
    }
  },

  methods: {
    handleSelectItem (data) {
      if (data) {
        const index = this.listItems.indexOf(item => item.value === data)
        if (index === -1) {
          this.listItems.push(this.internalItems.find(item => item.value === data))
        }

        this.selectedItems = [...new Set([...(this.selectedItems || []), data])]
        this.selectedItem = null
      }
    },

    removeItem (index) {
      const removedItem = this.listItems.splice(index, 1)
      this.selectedItems = this.selectedItems.filter(item => item !== removedItem[0].value)
    },

    handleFetchEditItems (items) {
      this.listItems = items
    }
  }
}
</script>
