<template v-if="type === 'autocomplete_combobox'">
  <v-container
    fluid
    class="px-0 py-0"
  >
    <v-row>
      <v-col cols="12">
        <v-combobox
          v-model="selected"
          :error-messages="errorMessages"
          :hide-details="hideDetails"
          :items="internalItems"
          :label="label"
          :no-filter="true"
          :search-input.sync="itemSearch"
          :append-icon="!readonly ? '$dropdown' : ''"
          chips
          clearable
          hide-selected
          multiple
          :outlined="outlined"
          :dense="dense"
          :solo="solo"
          :flat="flat"
          :readonly="readonly"
        >
          <template #selection="{ attrs, item, parent, selectedValue }">
            <v-chip
              v-if="item === Object(item)"
              v-bind="attrs"
              :color="`${useRandomColors ? getRandomColor(item, 'id') : ''} lighten-3`"
              :input-value="selectedValue"
              label
              small
            >
              <span class="pr-2">
                {{ item.text }}
              </span>
              <v-icon
                small
                @click="parent.selectItem(item)"
              >
                $delete
              </v-icon>
            </v-chip>
          </template>
          <template #append-item>
            <v-list-item
              v-if="hasMoreItems"
              class="has-more-items"
              @click="makeSearch('', true)"
            >
              <v-list-item-title class="font-italic text-decoration-underline">
                {{ $t('base.got_more_items') }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-combobox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { debounce, has, map } from 'lodash'
import { api } from '@/global/services/api'
import randomColorsMixin from '@/global/mixins/randomColorsMixin'

export default {
  name: 'AutocompleteCombobox',

  mixins: [randomColorsMixin],

  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    solo: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => ([])
    },

    options: {
      type: Object,
      required: true,
      validator: (options) => {
        return has(options, 'module') && has(options, 'route')
      }
    },

    errorMessages: {
      type: Array,
      default: () => ([])
    },

    label: {
      type: String,
      default: ''
    },

    hideDetails: {
      type: Boolean,
      default: true
    },

    items: {
      type: Array,
      default: () => ([])
    },

    resetData: {
      type: Boolean,
      default: false
    },

    useRandomColors: {
      type: Boolean,
      default: false
    },

    includeAll: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      itemSearch: null,
      fetchingData: false,
      hasMoreItems: false,
      selected: []
    }
  },

  computed: {
    internalItems: {
      get () {
        return this.items || []
      },

      set (value) {
        this.$emit('update:items', value)
      }
    }
  },

  watch: {
    value: {
      deep: true,
      handler (value) {
        this.selected = value
      }
    },

    itemSearch: debounce(function (itemSearch) {
      this.fetchingData = true
      this.makeSearch(itemSearch, this.includeAll).finally(() => (this.fetchingData = false))
    }, 1000),

    selected: {
      deep: true,
      handler (selected) {
        if (selected) {
          this.$emit('input', selected.length ? selected : null)
          this.itemSearch = null
        }
      }
    },

    resetData (resetData) {
      if (resetData) {
        if (resetData) {
          this.selected = []
        }
      }
    },

    includeAll: {
      immediate: true,
      handler (includeAll) {
        if (includeAll) {
          this.makeSearch('', this.includeAll)
        }
      }
    }
  },

  async created () {
    try {
      this.fetchingData = true
      await this.makeSearch('', this.includeAll)
    }
    finally {
      this.fetchingData = false
    }
  },

  methods: {
    async makeSearch (query, allItems = false) {
      const params = {
        query: query ?? '',
        pickedId: this.value ? this.value.map(item => item.value) : null,
        includeAll: allItems
      }

      const {
        data,
        has_more: hasMore = false
      } = await api()[this.options.module].get(this.options.route, params)

      if (this.companyScope) this.$emit('fetch', data)

      this.internalItems = map(data, ({ id, name }) => ({
        value: parseInt(id),
        text: name
      }))

      this.hasMoreItems = hasMore
    }
  }
}
</script>

<style scoped lang="scss">
.has-more-items {
  cursor: pointer !important;
}
</style>
