<template>
  <v-container v-if="isNotEmpty(formDataFields)">
    <v-row class="mt-2 mb-3">
      <v-col class="pa-0">
        <h2 class="mb-0">{{ options.scheduled_section_title }}</h2>
      </v-col>
    </v-row>
    <!-- Column titles -->
    <v-row
      v-if="!isMobileView"
      class="mt-1"
    >
      <v-col
        v-for="(disFormDataField, ind) in formDataFields"
        :key="`dis-form-data-${ind + 1}`"
        class="px-1 py-0"
        :cols="getGroupColForCertainField"
      >
        <v-row
          class="flex-nowrap"
          no-gutters
        >
          <v-col
            class="font-weight-bold"
            align-self="center"
          >
            <!-- we have to use 'mb' class because of input(text-field). Error messages expanding text-field height and align-content-center wont show desired result  -->
            <p class="mr-1">
              {{ disFormDataField.label }}:
            </p>
          </v-col>
        </v-row>
      </v-col>
      <!-- This column occupies the place of the delete row button -->
      <v-col
        v-if="!readonly"
        class="pa-0"
        cols="2"
      />
    </v-row>
    <!-- Input fields -->
    <template
      v-for="(disDataObject, index) in disData"
      class="mt-1"
    >
      <v-row :key="`dis-data-${index + 1}`">
        <v-col
          v-for="(disFormDataField, ind) in formDataFields"
          :key="`dis-form-data-${ind + 1}`"
          class="px-1 py-0"
          :cols="getGroupColForCertainField"
        >
          <v-row
            class="flex-nowrap"
            no-gutters
          >
            <v-col
              v-if="disFormDataField.type === 'string'"
            >
              <v-text-field
                v-model="disDataObject[ind]"
                outlined
                dense
                :label="isMobileView ? disFormDataField.label : ''"
                :readonly="readonly || !canEdit(disDataObject)"
                :error-messages="getErrorMessages(index, ind)"
                @input="resetErrorMessage(index, ind)"
              />
            </v-col>
            <v-col
              v-if="disFormDataField.type === 'datepicker'"
            >
              <!-- DATEPICKER START -->
              <v-menu
                :ref="`dynamic_datepicker_${index}_${ind}`"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="disDataObject[`formatted_${ind}`]"
                    dense
                    :label="isMobileView ? disFormDataField.label : ''"
                    append-icon="mdi-calendar"
                    outlined
                    :error-messages="getErrorMessages(index, ind)"
                    :clearable="canEdit(disDataObject)"
                    readonly
                    v-bind="attrs"
                    v-on="canEdit(disDataObject) ? on : null"
                    @click:clear="disDataObject[ind] = null"
                  />
                </template>
                <v-date-picker
                  v-model="disDataObject[ind]"
                  first-day-of-week="1"
                  full-width
                  @input="resetErrorMessage(index, ind)"
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="$refs[`dynamic_datepicker_${index}_${ind}`][0].save(disDataObject[ind])"
                  >
                    {{ $t('base.ok') }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <!-- DATEPICKER END -->
            </v-col>
          </v-row>
        </v-col>
        <!-- Delete row button -->
        <v-col
          v-if="disDataObject.id && !disDataObject.confirmed"
          class="pa-0"
          cols="2"
        >
          <v-btn
            class="mt-1 ml-3"
            dark
            color="primary"
            @click="toggleConfirmInterval(index)"
          >
            {{ options.completed_btn_text }}
          </v-btn>
        </v-col>
        <v-col
          v-else-if="!readonly && !canRemove(disDataObject) && !disDataObject.id"
          class="pa-0"
          cols="2"
        >
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                text
                x-small
                class="mt-3 mx-2 pa-0 no-background-hover"
                elevation="0"
                v-bind="attrs"
                v-on="on"
                @click="removeDisDataObject(index)"
              >
                <v-icon
                  color="grey darken-1"
                  size="1.8rem"
                >
                  mdi-close
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('base.remove_dis_data') }}</span>
          </v-tooltip>
        </v-col>
        <v-col
          v-else
          class="pa-0"
          cols="2"
        />
      </v-row>

      <!-- Row with confirmed service interval data -->
      <v-row
        v-if="disDataObject.confirmed"
        :key="`completed-data-${index + 1}`"
      >
        <v-col
          v-for="(disFormDataField, ind) in confirmServiceIntervalFields"
          :key="`dis-form-data-${ind + 1}`"
          class="px-1 py-0"
          :cols="getGroupColForCertainField"
        >
          <v-row
            class="flex-nowrap"
            no-gutters
          >
            <v-col>
              <v-text-field
                :value="getDisplayValue(disFormDataField.type, disDataObject, ind)"
                outlined
                dense
                readonly
                :label="isMobileView ? disFormDataField.label : ''"
                :error-messages="getErrorMessages(index, ind)"
                @click="toggleConfirmInterval(index)"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          class="pa-0"
          cols="2"
        >
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                text
                x-small
                class="mt-3 mx-2 pa-0 no-background-hover"
                elevation="0"
                v-bind="attrs"
                v-on="on"
                @click="removeConfirmData(index)"
              >
                <v-icon
                  color="grey darken-1"
                  size="1.8rem"
                >
                  mdi-close
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('base.remove_dis_data') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-divider
        v-if="disData.length > 1 && index < disData.length - 1"
        :key="`dis-data-divider-${index + 1}`"
        class="mb-7"
      />
    </template>
    <!-- The structure of this row is same as for input fields in order to achieve + button alignment with first input field in above row -->
    <v-row
      v-if="!readonly"
      class="mt-0"
    >
      <template v-if="dis">
        <v-row
          no-gutters
        >
          <v-col class="px-1 pt-0">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  class="ml-0 mt-1 di-add-item"
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  @click="addEmptyDisDataObject"
                >
                  <v-icon
                    left
                    color="success"
                  >
                    mdi-plus-circle
                  </v-icon>
                  {{ $t('base.add_dis') }}
                </v-btn>
              </template>
              <span>{{ $t('base.add_dis_data') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
      <v-col
        v-if="!readonly"
        class="pl-0 py-0"
        cols="1"
      />
    </v-row>

    <confirm-service-interval-dialog
      :opened.sync="dialogOpen"
      :title="options.completed_modal_title"
      :data="confirmData"
      :confirm-service-interval="confirmServiceInterval"
      :fields="confirmServiceIntervalFields"
      @confirm="handleConfirm"
    />

    <completed-services-table
      v-if="confirmedServices.length"
      class="mt-14"
      :title="options.completed_section_title"
      :data="confirmedServices"
      :fields="confirmServiceIntervalFields"
    />
  </v-container>
</template>

<script>
import dayjs from 'dayjs'
import { pickBy, isEmpty } from 'lodash'
import { formatSqlDate, formatSqlDateTime } from '@/global/services/helpers/dates'
import CompletedServicesTable from '@/modules/fleet-module/components/CompletedServicesTable'
import ConfirmServiceIntervalDialog from '@/modules/fleet-module/components/ConfirmServiceIntervalDialog'

export default {
  name: 'ServiceIntervalsInput',

  components: {
    CompletedServicesTable,
    ConfirmServiceIntervalDialog
  },

  props: {
    dis: {
      type: Object,
      default: () => ({})
    },

    existingDisData: {
      type: Array,
      default: () => ([])
    },

    editItem: {
      type: Object,
      default: () => ({})
    },

    options: {
      type: Object,
      default: () => ({})
    },

    readonly: {
      type: Boolean,
      default: false
    },

    items: {
      type: Array,
      default: () => []
    },

    errorMessages: {
      type: Object,
      default: () => {}
    },

    dialogClosed: {
      type: Boolean,
      default: true
    },

    removeExistingEntries: {
      type: Boolean,
      default: true
    },

    editExistingEntries: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      disData: [],
      confirmedServices: [],
      confirmData: {},
      confirmServiceInterval: {},
      dialogOpen: false
    }
  },

  computed: {
    isMobileView () {
      return this.$vuetify.breakpoint.name === 'xs'
    },

    getGroupColForCertainField () {
      return this.isMobileView ? '10' : ''
    },

    formDataFields () {
      const fields = {}
      for (const key in this.dis.formDataFields) {
        if (this.dis.formDataFields[key].create) {
          fields[key] = this.dis.formDataFields[key]
        }
      }
      return fields
    },

    confirmServiceIntervalFields () {
      const fields = {}
      for (const key in this.dis.formDataFields) {
        if (this.dis.formDataFields[key].confirmation) {
          fields[key] = this.dis.formDataFields[key]
        }
      }
      return fields
    }
  },

  watch: {
    dialogOpen: function (value) {
      // Reset variables after closing confirm dialog
      if (!value) {
        this.confirmData = {}
        this.confirmServiceInterval = {}
      }
    },

    editItem: function (value) {
      this.confirmedServices = value
        ? value.service_intervals.filter(item => item.datetime)
        : []
    },

    existingDisData: {
      immediate: true,
      deep: true,
      handler: function (existingDisData) {
        if (existingDisData?.length) {
          this.disData = existingDisData.filter((item) => {
            // If there is datetime field defined, make sure it is null or that item is confirmed
            return 'datetime' in item
              ? item.datetime === null || item.confirmed
              : true
          })

          // Get keys of the date and datetime picker fields.
          const dateFieldsKeys = Object.keys(
            pickBy(this.dis.formDataFields, item => (item.type === 'datepicker'))
          )
          const datetimeFieldsKeys = Object.keys(
            pickBy(this.dis.formDataFields, item => (item.type === 'datetimepicker'))
          )
          // If there are any `datepicker` fields (sometimes there are none), proceed.
          if (dateFieldsKeys.length || datetimeFieldsKeys.length) {
            this.disData = this.disData.map(item => {
              // For all datepicker and datetimepicker fields, create a formatted version
              // that is prefixed with the `formatted_` key
              // and is shown in the date pickers `v-text-field` field.
              dateFieldsKeys.forEach(dateFieldKey =>
                (item[`formatted_${dateFieldKey}`] = item[dateFieldKey] ? formatSqlDate(item[dateFieldKey]) : '')
              )
              datetimeFieldsKeys.forEach(datetimeFieldKey =>
                (item[`formatted_${datetimeFieldKey}`] = item[datetimeFieldKey] ? formatSqlDateTime(item[datetimeFieldKey]) : '')
              )
              return item
            })
          }
        }
        else this.disData = []
      }
    }
  },

  mounted () {
    this.setExistingDisData()
  },

  methods: {
    canEdit (disDataObject) {
      return this.editExistingEntries ? true : !disDataObject.id
    },

    canRemove (disDataObject) {
      return this.removeExistingEntries ? false : !!disDataObject.id
    },

    setExistingDisData () {
      if (this.existingDisData?.length) {
        this.existingDisData.forEach(disDataObject => {
          this.disData.push(this.disFieldFormatted(disDataObject, true))
        })
      }
      else this.disData = []
    },

    disFieldFormatted (disDataObject, formData = false) {
      const fields = formData ? this.dis.formDataFields : this.dis.fields
      const obj = {}
      for (const [key, value] of Object.entries(fields)) {
        switch (value.type) {
          case 'primary_key':
            obj[key] = parseInt(disDataObject[key])
            break
          case 'foreign_key':
            obj[key] = parseInt(disDataObject[key]) || parseInt(disDataObject.id)
            break
          case 'datepicker':
            this.$set(disDataObject, `formatted_${key}`, formatSqlDate(disDataObject[key]))
            obj[key] = disDataObject[key]
            break
          case 'datetimepicker':
            this.$set(disDataObject, `formatted_${key}`, formatSqlDateTime(disDataObject[key]))
            obj[key] = disDataObject[key]
            break
          case 'string':
            obj[key] = disDataObject[key]
            break
        }
      }
      return obj
    },

    getDisplayValue (type, disDataObject, key) {
      return type === 'datepicker' ? disDataObject[`formatted_${key}`] : disDataObject[key]
    },

    toggleConfirmInterval (index) {
      this.confirmServiceInterval = this.disData[index]

      if (this.confirmServiceInterval.confirmed) {
        for (const key in this.confirmServiceIntervalFields) {
          this.confirmData[key] = this.confirmServiceInterval[key]
          if (this.confirmServiceIntervalFields[key].type === 'datepicker') {
            this.confirmData[`formatted_${key}`] = formatSqlDate(this.confirmServiceInterval[key])
          }
        }
      }
      else {
        const date = dayjs().format('YYYY-MM-DD')

        this.confirmData = {
          completed_description: this.confirmServiceInterval.description,
          datetime: date,
          formatted_datetime: formatSqlDate(date)
        }
      }

      this.dialogOpen = true
    },

    addEmptyDisDataObject () {
      const obj = {}
      for (const key in this.formDataFields) {
        // Only add fields which should be in create form
        if (this.formDataFields[key].create) {
          obj[key] = null
        }
      }

      this.disData.push(obj)

      this.$emit('update-dis-data', {
        dis_key: this.dis.key,
        data: this.disData
      })
    },

    removeConfirmData (index) {
      const serviceInterval = this.disData[index]
      // Remove confirmed flag which is used to display row with confirmed data
      delete serviceInterval.confirmed

      // Remove confirmation fields and optional formatted fields from service interval
      for (const key in this.confirmServiceIntervalFields) {
        delete serviceInterval[key]
        if (`formatted_${key}` in serviceInterval) {
          delete serviceInterval[`formatted_${key}`]
        }
      }

      // Replace service interval at given index
      this.disData.splice(index, 1, serviceInterval)

      this.$emit('update-dis-data', {
        dis_key: this.dis.key,
        data: this.disData
      })
    },

    removeDisDataObject (index) {
      this.disData.splice(index, 1)

      this.$emit('update-dis-data', {
        dis_key: this.dis.key,
        data: this.disData
      })
    },

    handleConfirm (serviceInterval) {
      const index = this.disData.findIndex(item => item.id === serviceInterval.id)
      this.disData.splice(index, 1, serviceInterval)

      for (const key in this.confirmServiceIntervalFields) {
        this.resetErrorMessage(index, key)
      }

      this.$emit('update-dis-data', {
        dis_key: this.dis.key,
        data: this.disData
      })
    },

    getErrorMessages (index, field) {
      return this.errorMessages[`${this.dis.key}.${index}.${field}`]
    },

    resetErrorMessage (index, key) {
      // When the user selects the date from the date-picker,
      // emit an event for updating disData in the parent component.
      this.$emit('update-dis-data', {
        dis_key: this.dis.key,
        data: this.disData
      })
      const errorKey = `${this.dis.key}.${index}.${key}`
      this.$emit('reset-error-message', errorKey)
    },

    isNotEmpty (value) {
      return !isEmpty(value)
    }
  }
}
</script>

<style scoped lang="scss">
.di-add-item {
  font-size: 0.8rem;
  height: 2.2rem !important;
  text-transform: none;
  color: var(--v-primary-darken-5);
  border-color: var(--v-primary-base);

  > span {
    > i {
      font-size: 24px !important;
    }
  }
}

button > span > i
p {
  line-height: 1;
  font-size: 0.8rem;

  &.hide-content {
    visibility: hidden
  }
}
</style>
