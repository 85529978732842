import { api } from '@/global/services/api'

const state = {
  items: [],
  viewConfig: {},
  pagination: {
    count: 0,
    currentPage: 1,
    perPage: 15,
    totalPages: 1,
    total: 0
  },
  sort: {}
}

const getters = {
  list: state => state.items,
  pagination: state => state.pagination,
  sort: state => state.sort,
  viewConfig: state => state.viewConfig
}

const actions = {

  async fetch ({ commit, state }, options) {
    try {
      const params = {
        pagination: options.pagination,
        sort: options.sort,
        filters: options.filters,
        company_scope_id: options.company_scope_id
      }

      const {
        config,
        data,
        pagination,
        sort
      } = await api().base.get('user', params)

      commit('SET_ITEMS', data)
      commit('SET_PAGINATION', pagination)
      commit('SET_SORT', sort)
      commit('SET_VIEW_CONFIG', config)
    }
    catch (e) {
      console.dir(e)
    }
  }

}

const mutations = {
  SET_ITEMS: (state, payload) => (state.items = payload),

  SET_VIEW_CONFIG: (state, payload) => (state.viewConfig = payload),

  SET_PAGINATION ({ pagination }, payload) {
    const { count, currentPage, perPage, totalPages, total } = payload

    pagination.count = count || 0
    pagination.currentPage = currentPage || 1
    pagination.perPage = perPage || 0
    pagination.totalPages = totalPages || 1
    pagination.total = total || 0
  },

  SET_SORT: (state, payload) => (state.sort = payload)
}

export default {
  state,
  getters,
  actions,
  mutations
}
