<template>
  <div class="mb-4">
    <v-simple-table
      v-stripped
      dense
    >
      <template #default>
        <thead>
          <tr>
            <th class="custom-width text-left">
              {{ $t('people/person.week_day') }}
            </th>
            <th class="custom-width text-left">
              {{ $t('people/person.beginning') }}
              -
              {{ $t('people/person.end') }}
            </th>
            <th class="custom-width text-left">
              {{ $t('people/person.break') }}
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="data in item.working_hours"
            :key="data.day"
          >
            <td>{{ viewConfig.edit_form.form.working_hours.days[data.day] }}</td>
            <td>{{ getTime(data.from) }} - {{ getTime(data.to) }}</td>
            <td>{{ getTime(data.break_from) }} - {{ getTime(data.break_to) }}</td>
            <td />
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: 'WorkingHoursField',
  props: {
    namespace: {
      type: String,
      required: true
    }
  },

  computed: {
    viewConfig () {
      return this.$store.getters[this.namespace + '/viewConfig']
    },
    item () {
      return this.$store.getters[this.namespace + '/showItem'].item
    }
  },

  methods: {
    getTime (data) {
      return data || ' --:-- '
    }
  }
}
</script>

<style scoped lang="scss">

tr{
  white-space: nowrap;
  height: 45px;
  > th {
    padding-left: 0.2rem !important;
   &.custom-width {
      white-space: nowrap;
      min-width: 7%;
      width: 10%;
    }
  }
  td {
    padding-left: 0.2rem !important;
  }
}

</style>
