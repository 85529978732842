<template>
  <v-row
    class="mt-4"
    align="center"
    v-if="!isDinContact"
  >
    <v-col :cols="2">
      <p class="mb-0 font-weight-bold">
        {{ label }}
      </p>
    </v-col>
    <v-col :cols="$vuetify.breakpoint.xs ? 10 : 4">
      <p
        v-if="isDinOff"
        class="mb-0 font-weight-bold text-center"
        :style="{ lineHeight: 2.5 }"
      >
        Off
      </p>
      <v-autocomplete
        v-else
        v-model="formData.selectedType"
        :items="digitalInputTypes"
        label=""
        append-icon="$dropdown"
        :error-messages="errorMessages"
        :no-filter="true"
        hide-details
        clearable
        outlined
        dense
      />
    </v-col>
    <v-col
      v-if="showActiveStatusFields"
      :cols="$vuetify.breakpoint.xs ? 5 : 3"
    >
      <p class="mb-0 font-weight-bold text-right">
        {{ activeStatusLabel }}
      </p>
    </v-col>
    <v-col
      v-if="showActiveStatusFields"
      :cols="$vuetify.breakpoint.xs ? 7 : 3"
    >
      <v-autocomplete
        v-model="formData.selectedActiveStatus"
        :items="activeStatusOptions"
        label=""
        append-icon="$dropdown"
        :error-messages="errorMessages"
        :no-filter="true"
        :disabled="!formData.selectedType"
        hide-details
        clearable
        outlined
        dense
      />
    </v-col>
  </v-row>
</template>

<script>
import { has } from 'lodash'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters } = createNamespacedHelpers('base/config')

export default {
  name: 'DinInput',

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    label: {
      type: String,
      required: true
    },

    attributeName: {
      type: String,
      required: true
    },

    options: {
      type: Object,
      required: true,
      validator: (options) => {
        return has(options, 'digital_input_types') &&
          has(options, 'active_status_label') &&
          has(options, 'active_status_options')
      }
    },

    items: {
      type: Array,
      default: () => ([])
    },

    errorMessages: {
      type: Array,
      default: () => ([])
    },

    editItem: {
      type: Object,
      default: () => null
    }
  },

  data () {
    return {
      formData: {
        selectedType: null,
        selectedActiveStatus: null
      }
    }
  },

  computed: {
    ...mapGetters(['digitalSensorsConfig']),

    isDinOff () {
      return this.digitalSensorsConfig && this.digitalSensorsConfig[this.attributeName] === 'off'
    },

    isDinContact () {
      return this.digitalSensorsConfig && this.digitalSensorsConfig[this.attributeName] === 'contact'
    },

    showActiveStatusFields () {
      return !this.isDinOff
    },

    digitalInputTypes () {
      return this.options.digital_input_types
        .map(item => {
          return {
            text: item.name,
            value: item.id
          }
        })
    },

    activeStatusLabel () {
      return this.options.active_status_label
    },

    activeStatusOptions () {
      const options = []

      for (const key in this.options.active_status_options) {
        options.push({
          text: this.options.active_status_options[key],
          value: key
        })
      }

      return options
    }
  },

  watch: {
    value: {
      deep: true,
      immediate: true,
      handler: function (newVal, oldVal) {
        if (
          newVal && this.editItem &&
          this.checkForNullValues(this.formData) &&
          !this.checkForNullValues(newVal)
        ) {
          this.formData.selectedType = newVal.digital_input_type
          this.formData.selectedActiveStatus = newVal.active_status?.toString()
        }

        if (!newVal || this.checkForNullValues(newVal)) {
          this.resetFields()
        }

        // We need to reset fields and emit input event in case when edit form is being reset,
        // that will cause field to be null, and with this we make it into valid object
        if (oldVal && !newVal) {
          this.resetFields()
          this.$emit('input', this.getDinData())
        }
      }
    },

    formData: {
      deep: true,
      handler (value) {
        if (value.selectedType === null) {
          this.formData.selectedActiveStatus = null
        }

        this.$emit('input', this.getDinData())
      }
    },

    isDinOff (value) {
      if (value) {
        this.resetFields()

        // We manually emit input event here because the watcher is never called if din is off
        this.$emit('input', this.getDinData())
      }
    },

    digitalSensorsConfig (value) {
      if (!value && !this.editItem) {
        this.resetFields()
      }
    }
  },

  mounted () {
    this.$emit('input', this.getDinData())
  },

  methods: {
    getDinData () {
      return {
        digital_input_type: this.formData.selectedType,
        active_status: this.formData.selectedActiveStatus
          ? +this.formData.selectedActiveStatus
          : null
      }
    },

    checkForNullValues (object) {
      return Object.values(object).every(item => item === null)
    },

    resetFields () {
      this.formData.selectedType = null
      this.formData.selectedActiveStatus = null
    }
  }
}
</script>
