import { defaultMapSetup } from '@/global/common/mapConfig'

const store = {
  namespaced: true,

  state: {
    mapType: {},
    tileOptions: {
      maxZoom: defaultMapSetup.maxZoom,
      maxNativeZoom: defaultMapSetup.maxNativeZoom
    }
  },

  getters: {
    getMapType: state => state.mapType,
    getTileOptions: state => state.tileOptions
  },

  actions: {
    setMapType ({ commit }, payload) {
      commit('SET_MAP_TYPE', payload)
    },

    setTileOptions ({ commit }, payload) {
      commit('SET_TILE_OPTIONS', payload)
    }
  },

  mutations: {
    SET_MAP_TYPE: (state, payload) => (state.mapType = payload),

    SET_TILE_OPTIONS: (state, payload) => (state.tileOptions = payload)
  }
}

export default store
