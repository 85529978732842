export default class File {
  fullPath = ''
  content = null

  constructor (fullPath) {
    this.fullPath = fullPath
  }

  getName () {
    return this.fullPath.match(/\/([a-zA-Z-]*)\.js/)[1]
  }

  getFullPath () {
    return this.fullPath
  }

  getModuleName () {
    return this.fullPath.match(/([a-zA-Z-]*)-module/)[1] // [1] is first capturing group, corresponds to match of ([a-zA-Z-]*)
  }

  getContent () {
    return this.content
  }

  setContent (content) {
    this.content = content
  }
}
