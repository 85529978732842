<template>
  <div class="d-inline mr-4">
    <v-dialog
      v-model="dialog"
      max-width="800px"
      eager
      @click:outside="closeDialog"
      @keydown.esc="closeDialog"
    >
      <v-card style="margin: 0; padding: 0;">
        <v-card-title class="headline grey lighten-2">
          <span class="headline">
            {{ dialogTitle }}
          </span>
          <v-spacer />
          <v-btn
            fab
            x-small
            class="pa-0 dialog-close-button no-background-hover"
            style="background: transparent;"
            elevation="0"
            @click="closeDialog"
          >
            <v-icon
              color="grey darken-2"
              size="1.8rem"
            >
              mdi-close
            </v-icon>
          </v-btn>

        </v-card-title>
        <v-card-text style="margin: 0; padding: 0; border-top: 2px solid lightslategray; border-bottom: 2px solid lightslategray;">
          <v-container style="margin: 0; padding: 0;">
            <v-row style="margin: 0; padding: 0;">
              <v-col
                cols="12"
                style="margin: 0; padding: 0;"
              >
                <div
                  style="height: 600px; margin: 0; padding: 0;"
                >
                  <map-base
                    v-if="dialog"
                    ref="mapBase"
                    :invalidate-map-size="dialog"
                    :map-class="mapClass"
                    @handleClickEvent="addMarker"
                  />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="grey lighten-2">
          <v-spacer />
          <v-btn
            color="grey"
            dark
            @click="closeDialog"
          >
            {{ $t('base.cancel') }}
          </v-btn>
          <v-btn
            color="blue-grey darken-1"
            dark
            @click="saveGeolocation"
          >
            {{ $t('base.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import MapBase from '@/global/components/map/MapBase.vue'
import { isEmpty } from 'lodash'

export default {
  name: 'GeolocationModal',

  components: {
    MapBase
  },

  props: {
    geolocationDialog: {
      type: Boolean,
      required: true
    },

    dialogTitle: {
      type: String,
      required: true
    },

    geolocation: {
      type: Object,
      required: true
    },

    mapClass: {
      type: String,
      default: 'geolocation-modal'
    }
  },

  emits: ['set-geolocation', 'close-dialog'],

  data () {
    return {
      dialog: false,
      savedGeolocation: {}
    }
  },

  watch: {
    geolocationDialog: function (geolocationDialog) {
      this.dialog = geolocationDialog
      this.$nextTick(() => {
        if (geolocationDialog && this.geolocationIsPresentAndValid(this.geolocation)) {
          const markerConfig = {
            markers: [{
              lat: this.geolocation.lat,
              lon: this.geolocation.lng,
              draggable: true,
              drag: (e) => {
                this.onMarkerDrag(e)
              }
            }],
            fitBoundsOptions: {
              maxZoom: 15
            }
          }
          this.$refs?.mapBase?.onMapReady(function (map) {
            map.generateMarkers(markerConfig)
          })
        }
        else if (geolocationDialog) {
          this.$refs?.mapBase?.onMapReady(function (map) {
            map.removeMarkers()
            map.resetMapView()
          })
        }
      })
    }
  },

  methods: {
    closeDialog () {
      this.savedGeolocation = {}
      this.$emit('close-dialog')
    },

    saveGeolocation () {
      if (!isEmpty(this.savedGeolocation)) {
        this.$emit('set-geolocation', this.savedGeolocation)
      }
      this.$emit('close-dialog')
    },

    addMarker (event) {
      this.$nextTick(() => {
        this.savedGeolocation = {
          lat: Number.parseFloat(event.latlng.lat).toFixed(9),
          lng: Number.parseFloat(event.latlng.lng).toFixed(9)
        }
        const markerConfig = {
          markers: [{
            lat: this.savedGeolocation.lat,
            lon: this.savedGeolocation.lng,
            draggable: true,
            drag: (e) => {
              this.onMarkerDrag(e)
            }
          }],
          fitBoundsOptions: {
            maxZoom: 15
          },
          fitMarkers: false
        }
        this.$refs?.mapBase?.onMapReady(function (map) {
          map.generateMarkers(markerConfig)
        })
      })
    },

    geolocationIsPresentAndValid (geolocation) {
      return !isEmpty(geolocation) && geolocation.lat && geolocation.lng
    },

    onMarkerDrag (e) {
      // Update markers coordinates
      this.savedGeolocation = {
        lat: Number.parseFloat(e.target._latlng.lat).toFixed(9),
        lng: Number.parseFloat(e.target._latlng.lng).toFixed(9)
      }
    }
  }
}
</script>
