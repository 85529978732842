<template>
  <v-app>
    <TheAppBar @hamburger-toggle="toggleDrawer" :refetch="refetch" />

    <TheNavigationDrawer
      id="navigation-drawer"
      ref="drawer"
    />

    <v-main @avatar-updated="avatarUpdated">
      <slot />
    </v-main>
  </v-app>
</template>

<script>
import TheAppBar from '@/modules/base-module/app-bar/components/TheAppBar'
import TheNavigationDrawer from '@/modules/base-module/navigation/components/TheNavigationDrawer'

export default {
  name: 'DefaultLayout',

  components: {
    TheAppBar,
    TheNavigationDrawer
  },

  data () {
    return {
      refetch: false
    }
  },

  methods: {
    toggleDrawer () {
      this.$refs.drawer.toggle()
    },

    avatarUpdated () {
      this.refetch = true
      this.$nextTick(() => (this.refetch = false))
    }
  }
}
</script>
