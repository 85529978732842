<template>
  <div class="mb-4">
    <v-simple-table
      v-stripped
      dense
    >
      <template #default>
        <thead>
          <tr>
            <th
              v-for="(field, key) in tab.fields"
              :key="key + '_th' "
              class="custom-width text-left"
            >
              {{ field.label }}
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          <template v-if="item[getRelationField(tab)].length > 0">
            <tr
              v-for="row in item[getRelationField(tab)]"
              :key="row.id"
            >
              <template
                v-for="(field, field_name, index) in tab.fields"
              >
                <td
                  v-if="field.type === 'text'"
                  :key="field_name + '_td_' + index"
                >
                  {{ row[field_name] }}
                </td>
                <td
                  v-if="field.type === 'relation'"
                  :key="field_name + '_td_' + index"
                >
                  {{ getNestedFieldByRelationString(row, field_name) }}
                </td>
                <td
                  v-if="field.type === 'date' && row[field_name]"
                  :key="field_name + '_td_' + index"
                >
                  {{ formatSqlDate(row[field_name]) }}
                </td>
                <td
                  v-if="field.type === 'datetime'"
                  :key="field_name + '_td_' + index"
                >
                  {{ formatSqlDateTime(row[field_name]) }}
                </td>
                <td
                  v-if="field.type === 'boolean'"
                  :key="field_name + '_td_' + index"
                  class="text-center"

                >
                  <v-icon
                    v-if="row[field_name]"
                    size="23px"
                    color="success"
                  >
                    mdi-check-circle
                  </v-icon>
                  <v-icon
                    v-else
                    size="23px"
                    color="red"
                  >
                    mdi-close-circle
                  </v-icon>
                </td>
              </template>
              <td :key="'_filler_' + row.id" />
            </tr>
          </template>
          <template v-else>
            <tr>
              <td
                class="text-center"
                :colspan="Object.keys(tab.fields).length + 1"
              >
                {{ $t('people/person.no_data_available') }}
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { formatSqlDate, formatSqlDateTime } from '@/global/services/helpers/dates'
import get from 'lodash/get'

export default {
  name: 'SimpleTableField',
  props: {
    namespace: {
      type: String,
      required: true
    },
    tab: {
      type: Object,
      required: true
    }
  },

  computed: {
    viewConfig () {
      return this.$store.getters[this.namespace + '/viewConfig']
    },
    item () {
      return this.$store.getters[this.namespace + '/showItem'].item
    }
  },

  methods: {
    formatSqlDate,
    formatSqlDateTime,

    getRelationField (tab) {
      // key's are defined in config on backend with hyphen(-) if contain more than one word as they are used as keys when creating components. Also, should be named as relations (see documentation for explanation)
      return tab.key.replace('-', '_')
    },

    getNestedFieldByRelationString (row, relationField) {
      return get(row, relationField)
    }

  }
}
</script>

<style scoped lang="scss">
tr {
  white-space: nowrap;
  height: 45px;
  > th {
    padding-left: 0.2rem !important;
    &.custom-width {
      white-space: nowrap;
      min-width: 7%;
      width: 10%;
    }
  }
  td {
    padding-left: 0.2rem !important;
  }
}
</style>
