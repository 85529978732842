const tempFromDateToDateData = [
  {
    sensorName: 'Senzor 1',
    temperaturesData: [
      {
        date: '24.11.2021.',
        time: '15:00',
        temperature: 17.3,
        lat: 45.8150,
        lng: 16.726
      },
      {
        date: '25.11.2021.',
        time: '14:30',
        temperature: 12,
        lat: 45.5150,
        lng: 16.999
      },
      {
        date: '26.11.2021.',
        time: '16:20',
        temperature: 8,
        lat: 45.5150,
        lng: 17.222
      },
      {
        date: '27.11.2021.',
        time: '15:00',
        temperature: 11.5,
        lat: 45.5450,
        lng: 17.322
      },
      {
        date: '28.11.2021.',
        time: '15:15',
        temperature: 15.6,
        lat: 45.8150,
        lng: 15.918
      },
      {
        date: '29.11.2021.',
        time: '16:20',
        temperature: 12,
        lat: 45.9160,
        lng: 16.118
      },
      {
        date: '30.11.2021.',
        time: '15:00',
        temperature: 11.5,
        lat: 45.8150,
        lng: 16.226
      },
      {
        date: '01.12.2021.',
        time: '15:15',
        temperature: 23,
        lat: 45.8150,
        lng: 16.426
      }
    ]
  },
  {
    sensorName: 'Senzor mjerenja temperature u kabini',
    temperaturesData: [
      {
        date: '24.11.2021.',
        time: '15:00',
        temperature: 24,
        lat: 45.8150,
        lng: 15.918
      },
      {
        date: '25.11.2021.',
        time: '14:30',
        temperature: 26,
        lat: 45.9160,
        lng: 16.118
      },
      {
        date: '26.11.2021.',
        time: '16:20',
        temperature: 26,
        lat: 45.8150,
        lng: 16.226
      },
      {
        date: '27.11.2021.',
        time: '15:00',
        temperature: 28,
        lat: 45.8150,
        lng: 16.426
      },
      {
        date: '28.11.2021.',
        time: '15:15',
        temperature: 22,
        lat: 45.8150,
        lng: 16.726
      },
      {
        date: '29.11.2021.',
        time: '16:20',
        temperature: 23,
        lat: 45.5150,
        lng: 16.999
      },
      {
        date: '30.11.2021.',
        time: '15:00',
        temperature: 25,
        lat: 45.5150,
        lng: 17.222
      },
      {
        date: '01.12.2021.',
        time: '15:15',
        temperature: 23,
        lat: 45.5450,
        lng: 17.322
      }
    ]
  },
  {
    sensorName: 'Senzor hladnjače',
    temperaturesData: [
      {
        date: '24.11.2021.',
        time: '15:00',
        temperature: 30,
        lat: 45.8150,
        lng: 16.726
      },
      {
        date: '25.11.2021.',
        time: '14:30',
        temperature: 30,
        lat: 45.8150,
        lng: 15.918
      },
      {
        date: '26.11.2021.',
        time: '16:20',
        temperature: 29,
        lat: 45.5150,
        lng: 16.999
      },
      {
        date: '27.11.2021.',
        time: '15:00',
        temperature: 27,
        lat: 45.9160,
        lng: 16.118
      },
      {
        date: '28.11.2021.',
        time: '15:15',
        temperature: 24,
        lat: 45.5150,
        lng: 17.222
      },
      {
        date: '29.11.2021.',
        time: '16:20',
        temperature: 35,
        lat: 45.8150,
        lng: 16.226
      },
      {
        date: '30.11.2021.',
        time: '15:00',
        temperature: 36,
        lat: 45.5450,
        lng: 17.322
      },
      {
        date: '01.12.2021.',
        time: '15:15',
        temperature: 33,
        lat: 45.8150,
        lng: 16.426
      }
    ]
  },
  {
    sensorName: 'Senzor sa nekim dužim imenom',
    temperaturesData: [
      {
        date: '24.11.2021.',
        time: '15:00',
        temperature: 40,
        lat: 45.8150,
        lng: 15.918
      },
      {
        date: '25.11.2021.',
        time: '14:30',
        temperature: 38,
        lat: 45.9160,
        lng: 16.118
      },
      {
        date: '26.11.2021.',
        time: '16:20',
        temperature: 38,
        lat: 45.8150,
        lng: 16.726
      },
      {
        date: '27.11.2021.',
        time: '15:00',
        temperature: 40,
        lat: 45.5150,
        lng: 16.999
      },
      {
        date: '28.11.2021.',
        time: '15:15',
        temperature: 44,
        lat: 45.8150,
        lng: 16.226
      },
      {
        date: '29.11.2021.',
        time: '16:20',
        temperature: 48,
        lat: 45.8150,
        lng: 16.426
      },
      {
        date: '30.11.2021.',
        time: '15:00',
        temperature: 46,
        lat: 45.5150,
        lng: 17.222
      },
      {
        date: '01.12.2021.',
        time: '15:15',
        temperature: 42,
        lat: 45.5450,
        lng: 17.322
      }
    ]
  }
]

export { tempFromDateToDateData }
