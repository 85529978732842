<template>
  <v-dialog
    v-model="internalOpened"
    content-class="elevation-0"
    :width="filterDialogSize[$vuetify.breakpoint.name]"
    :persistent="showFilters"
    @keydown.enter.prevent="close"
    @keydown.esc="close"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          color="#869DB6"
        >mdi-filter-variant</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-row class="flex justify-space-between ma-0">
        <v-card-title>{{ $t('base.filters') }}</v-card-title>

        <v-btn
          text
          x-small
          class="mt-3 mr-2 pa-0 no-background-hover"
          elevation="0"
          @click="close"
        >
          <v-icon
            color="grey darken-1"
            size="1.8rem"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </v-row>

      <v-card-text>
        <v-form ref="filtersForm">
          <slot />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="primary"
          text
          @click="close"
        >
          {{ $t('base.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'FilterDialog',

  props: {
    showFilters: {
      type: Boolean,
      default: false
    },

    dateRange: {
      type: Array,
      default: () => ([])
    },

    opened: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  data () {
    return {
      filterDialogSize: {
        xl: '33.33%',
        lg: '33.33%',
        md: '50%',
        sm: '66.66%',
        xs: '100%'
      }
    }
  },

  computed: {
    internalOpened: {
      get () {
        return this.opened
      },

      set (value) {
        this.$emit('update:opened', value)
      }
    }
  },

  methods: {
    close () {
      this.internalOpened = false

      if (!this.dateRange.length) {
        this.$emit('set-default-filters')
      }
    }
  }
}
</script>
