<template>
  <v-autocomplete
    v-model="itemId"
    :items="internalItems"
    :label="label"
    :clearable="clearable"
    :error-messages="errorMessages"
    outlined
    dense
  />
</template>

<script>
export default {
  name: 'MultiForeignKeyAutocomplete',

  props: {
    value: {
      type: Number,
      default: null
    },

    label: {
      type: String,
      required: true
    },

    clearable: {
      type: Boolean,
      default: true
    },

    items: {
      type: Array,
      default: () => ([])
    },

    errorMessages: {
      type: Array,
      default: () => ([])
    }
  },

  data () {
    return {}
  },

  computed: {
    itemId: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value || null)
      }
    },

    internalItems: {
      get () {
        return this.items
      },

      set (value) {
        this.$emit('update:items', value)
      }
    }
  }
}
</script>
