<template>
  <div>
    <!-- Calibration fields -->
    <v-row>
      <v-col
        sm="3"
        class="py-4"
        align-self="top"
      >
        <p class="mb-0 font-weight-bold">
          {{ $t('base/tracker.max_liters') }}:
        </p>
      </v-col>
      <v-col
        sm="5"
        class="py-1"
        align-self="center"
      >
        <v-text-field
          v-model="formData['max']"
          :disabled="!selectedType"
          :error-messages="errorMessages[`${attributeName}.max`]"
          type="number"
          step="1"
          outlined
          dense
        />
      </v-col>
    </v-row>
    <dynamic-key-value-fields
      :existing-data="formData"
      :existing-data-keys="filteredFieldKeys"
      :keys-column-header="label"
      keys-input-type="number"
      values-input-type="number"
      icon="mdi-clipboard-arrow-left-outline"
      :attribute-name="attributeName"
      :error-messages="errorMessages"
      @icon-click="key => copyCurrentValue(key)"
      @reset-error-message="errorKey => resetErrorMessage(errorKey)"
    />
    <!-- Current value -->
    <v-row>
      <v-col
        sm="3"
        class="py-4"
        align-self="center"
      >
        <p class="mb-0 font-weight-bold flex-shrink-0">
          {{ options.current_value_text }}
        </p>
      </v-col>
      <v-col
        sm="5"
        class="my-4 py-1"
        align-self="center"
      >
        <v-text-field
          :value="currentValueInput"
          class="ml-2"
          readonly
          outlined
          dense
          hide-details
        />
      </v-col>
      <v-col
        sm="1"
        class="py-1"
        align-self="center"
      >
        <v-btn
          v-if="selectedType"
          icon
          class="ml-2"
          @click="fetchCurrentValue"
        >
          <v-icon
            color="accent"
            class="sync-icon"
          >
            mdi-sync
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <!-- Validation errors -->
    <div
      v-if="Object.keys(errorMessages).length"
      class="mt-7 v-messages theme--light error--text"
      role="alert"
    >
      <div class="v-messages__wrapper">
        <div
          v-for="(messages, field) in errorMessages"
          :key="field"
          class="v-messages__message"
        >
          {{ messages[0] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '@/global/services/api'
import DynamicKeyValueFields from '@/global/components/form-fields/DynamicKeyValueFields.vue'

export default {
  name: 'CalibrationValuesInput',
  components: { DynamicKeyValueFields },

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    label: {
      type: String,
      required: true
    },

    attributeName: {
      type: String,
      required: true
    },

    options: {
      type: Object,
      default: () => ({})
    },

    items: {
      type: Array,
      default: () => ([])
    },

    errorMessages: {
      type: Object,
      default: () => ({})
    },

    editItem: {
      type: Object,
      default: () => null
    },

    selectedType: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
      formData: {},
      currentValue: null,
      currentValueDate: null
    }
  },

  computed: {
    currentValueInput () {
      return this.currentValue && this.currentValueDate
        ? `${this.currentValue}      ${this.currentValueDate}`
        : null
    },

    filteredFieldKeys () {
      return Object.keys(this.formData).filter(a => a !== 'max')
    }
  },

  watch: {
    selectedType: {
      immediate: true,
      handler: function (value) {
        value ? this.fetchCurrentValue() : this.resetCurrentValue()
      }
    },

    value: {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (value) {
          this.formData = value
        }
      }
    },

    formData: {
      deep: true,
      handler: function (value) {
        Object.keys(value).forEach(key => this.resetErrorMessage(key))
        this.$emit('input', value)
      }
    }
  },

  methods: {
    copyCurrentValue (rowKey) {
      if (rowKey !== '') {
        this.$set(this.formData, rowKey, this.currentValue)
      }
    },

    fetchCurrentValue () {
      if (this.selectedType) {
        const trackerId = this.editItem.id || null
        const probeNumber = this.attributeName.charAt(this.attributeName.length - 1)
        api('base').get('current-fuel-probe-value', { trackerId, probeNumber: probeNumber })
          .then(data => {
            if (data) {
              this.currentValue = data.value
              this.currentValueDate = data.datetime
            }
            else {
              this.resetCurrentValue()
            }
          })
      }
    },

    resetCurrentValue () {
      this.currentValue = null
      this.currentValueDate = null
    },

    resetErrorMessage (key) {
      const errorKey = `${this.attributeName}.${key}`
      this.$emit('reset-error-message', errorKey)
    },

    checkForError (fieldKey) {
      const errorKey = `${this.attributeName}.${fieldKey}`
      return errorKey in this.errorMessages && this.errorMessages[errorKey].length > 0
    }
  }
}
</script>

<style scoped>
.sync-icon {
  transform: rotate(-90deg);
}
</style>
