export default {
  path: 'excavation-service/excavation-settings/stakeholders',
  name: 'ExcavationSettingsStakeholders',
  meta: {
    title: 'road-maintenance/dropdown-types.stakeholder',
    create_title: 'road-maintenance/excavation-dropdown-type.create_stakeholder',
    edit_title: 'road-maintenance/excavation-dropdown-type.edit_stakeholder',
    delete_title: 'road-maintenance/excavation-dropdown-type.delete_stakeholder',
    confirm_delete: 'road-maintenance/excavation-dropdown-type.confirm_delete_stakeholder',
    import_title: 'road-maintenance/excavation-dropdown-type.import_stakeholder',
    name_label: 'road-maintenance/excavation-dropdown-type.name',
    name: 'STAKEHOLDER'
  },
  component: () => import(/* webpackChunkName: "road-maintenance" */ '../views/ExcavationSettingsIndex')
}
