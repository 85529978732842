<template>
  <div>
    <v-text-field
      v-model="selectedLocationNames"
      :label="formField ? '' : label"
      :prepend-inner-icon="prependIcon"
      :append-icon="formField ? 'mdi-map-marker-multiple' : ''"
      :error-messages="errorMessages"
      readonly
      :outlined="formField"
      :dense="formField"
      @mousedown.stop="dialog = true"
    />
    <v-dialog
      v-model="dialog"
      max-width="30em"
      scrollable
    >
      <v-card
        :loading="loading"
      >
        <v-row
          class="flex ma-0"
          :class="[title ? 'justify-space-between' : 'justify-end']"
        >
          <v-card-title v-if="title">
            {{ title }}
          </v-card-title>

          <v-btn
            text
            x-small
            class="mt-3 mr-2 pa-0 no-background-hover"
            elevation="0"
            @click="dialog = false"
          >
            <v-icon
              color="grey darken-1"
              size="1.8rem"
            >
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>

        <v-card-subtitle v-if="subtitle">
          {{ subtitle }}
        </v-card-subtitle>
        <v-card-text>
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            clearable
          />
          <v-row
            v-if="!maxSelect"
            class="mb-3"
          >
            <v-col class="py-0">
              <v-checkbox
                v-model="selectAll"
                :label="$t('base.select_all')"
                color="accent"
                hide-details
              />
            </v-col>
          </v-row>
          <v-treeview
            v-model="selectedLocations"
            :items="locationTree"
            :search="search"
            :filter="filter"
            :item-text="locationIdentifier"
            :open.sync="openAll"
            selection-type="independent"
            return-object
            selectable
            open-on-click
            activatable
            :active="active"
            @update:active="selectOnActive"
          >
            <template #append="{ item }">
              <span v-if="item.registration"> ({{ item.registration }})</span>
              <span v-if="item.driver"> {{ item.driver }}</span>
            </template>
          </v-treeview>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="dialog = false"
          >
            {{ confirmSelection }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import { api } from '@/global/services/api'
import store from '@/global/store'
import withoutWatchersMixin from '@/global/mixins/withoutWatchersMixin'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('base/config')

export default {
  name: 'LocationPicker',
  mixins: [withoutWatchersMixin],
  props: {
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: function () {
        return this.$t('satellite-tracking/report.geolocations_picker_label')
      }
    },
    confirmSelection: {
      type: String,
      default: function () {
        return this.$t('base.ok')
      }
    },
    prependIcon: {
      type: String,
      default: 'mdi-map-marker-multiple'
    },
    startOpen: {
      type: Boolean,
      default: false
    },
    formField: {
      type: Boolean,
      default: false
    },
    maxSelect: {
      type: Number,
      default: null
    },
    scopeId: {
      type: Number,
      default: null
    },
    errorMessages: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    },
    refreshLocations: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialog: false,
      selectAll: false,
      openAll: [],
      loading: true,
      search: null,
      active: [],
      locations: [],
      locationTree: [],
      selectedLocations: [],
      companyScopeId: null,
      descendantCompanies: [],
      locationIdentifier: 'name',
      firstCountryInTheList: 'Hrvatska',
      locationRootTypeKeys: ['COUNTRY', 'USER_DEFINED', 'PARTNER_LOCATION']
    }
  },
  computed: {
    ...mapGetters({
      globalCompanyScope: 'companyScope',
      isCompanyScopeUpdated: 'isCompanyScopeUpdated'
    }),

    // Display human-readable names instead of location IDs
    selectedLocationNames () {
      return this.selectedLocations
        .map(location => location[this.locationIdentifier])
        .join(', ')
    }
  },
  watch: {
    dialog (value) {
      // Apply model changes after the dialog is closed
      if (value === false) {
        this.$emit('input', this.selectedLocations)
      }
    },
    search (value) {
      this.openAll = value ? [...this.locations, ...this.locationTree] : []
    },
    value () {
      this.populateSelectedLocations()
    },
    selectAll (value) {
      this.selectedLocations = value ? this.locations : []
      this.openAll = value ? [...this.locations, ...this.locationTree] : []
    },
    selectedLocations (newValue) {
      if (this.maxSelect && newValue.length > this.maxSelect) {
        this.$withoutWatchers(() => {
          // Remove first array item if max limit is set to 1 and user try to select another item
          if (this.maxSelect === 1) {
            this.selectedLocations.shift()
          }
          else {
            this.selectedLocations = this.selectedLocations.slice(0, this.maxSelect)
            store.dispatch(
              'base/notifications/push',
              this.$t('satellite-tracking/report.locations_selection_limit') + ' ' + this.maxSelect
            )
          }
        })
      }
      // Update select all status based on number of selected locations, without triggering watcher
      this.$withoutWatchers(() => {
        this.selectAll = this.selectedLocations.length === this.locations.length
      })
    },
    companyScopeId: async function () {
      await this.resetLocations()
    },
    scopeId: async function () {
      await this.resetLocations()
    },
    isCompanyScopeUpdated: async function () {
      await this.resetLocations()
      this.unsetCompanyScopeUpdated()
    },
    refreshLocations (refreshLocations) {
      if (refreshLocations) {
        this.resetLocations()
        this.$emit('reset-refresh-locations')
      }
    }
  },
  async created () {
    await this.getLocations()
  },
  async mounted () {
    this.dialog = this.startOpen
  },
  methods: {
    ...mapActions(['unsetCompanyScopeUpdated']),
    isEmpty,
    async getLocations () {
      const companyScopeid = this.companyScopeId || this.scopeId
      this.loading = true
      const locations = await api('locations').get(
        '/locations/picker-options',
        companyScopeid && { company_scope_id: companyScopeid }
      )
      this.locations = locations.data
      this.populateSelectedLocations()
      this.buildLocationTree()
      this.loading = false
    },
    async resetLocations () {
      this.selectedLocations = []
      await this.getLocations()
    },
    filter (item, queryText) {
      return item.name.toLowerCase().indexOf(queryText.toLowerCase()) > -1
    },
    // Goes through the list of location and builds a group-based tree for the treeview component
    buildLocationTree () {
      const groups = []
      const independent = []
      // If picker is set to only show locations which have trackers, filter out those that don't
      for (const location of this.locations) {
        if (this.locationRootTypeKeys.includes(location.type.key)) {
          let group = groups.find(group => group.id === 'group-' + location.type.id)
          if (!group) {
            group = {
              id: 'group-' + location.type.id,
              [this.locationIdentifier]: location.type.name,
              children: []
            }
            groups.push(group)
          }
          if (location.type.key === 'COUNTRY') {
            const locationId = parseInt(location.id?.split('-').pop())
            const children = this.getChildrenLocations(locationId)
            if (children?.length > 0) {
              location.children = children
            }
          }
          if (location.name === this.firstCountryInTheList) {
            group.children.unshift(location)
          }
          else {
            group.children.push(location)
          }
        }
        else {
          if (!location.parent_id) {
            independent.push(location)
          }
        }
      }
      this.locationTree = [
        ...groups,
        ...independent
      ]
    },
    getChildrenLocations (id) {
      const children = this.locations.filter(location => location.parent_id === id)
      if (children?.length > 0) {
        for (const child of children) {
          const childId = parseInt(child?.id?.split('-').pop())
          const grandchildren = this.getChildrenLocations(childId)
          if (grandchildren?.length > 0) {
            child.children = grandchildren
          }
        }
        return children
      }
      return null
    },
    selectOnActive ([location]) {
      if (location) {
        // Don't actually allow the user to mark locations as active
        this.active = []
        // Toggle the selected status of the activated location
        if (this.selectedLocations.includes(location)) {
          this.selectedLocations.splice(this.selectedLocations.indexOf(location), 1)
        }
        else {
          this.selectedLocations.push(location)
        }
      }
    },
    populateSelectedLocations () {
      if (this.value) {
        this.selectedLocations = this.locations.filter(location => {
          return this.value.includes(this.formField ? location.id : location)
        })
      }
    }
  }
}
</script>
