import { api } from '@/global/services/api'
import { convertTimestampToDate } from '@/global/services/helpers/timeConversion'
import { latLng } from 'leaflet'

function initialState () {
  return {
    fuelProbeGraphGeolocation: {},
    loading: false,
    mapLoading: false,
    fuelTanksData: [
      { number: 1 },
      { number: 2 }
    ],
    summaryData: [],
    fuelProbesData: [],
    refuelsAndLeaksData: [],
    refuelingDispensingGeolocation: {},
    dataSource: null,
    selectedVehicle: null,
    selectedDateFrom: null,
    selectedDateTo: null,
    colors: {
      probe1: 'rgb(126,88,98)',
      probe2: 'rgb(96,139,169)'
    },
    backgroundColors: {
      probe1: 'rgba(126,88,98,0.2)',
      probe2: 'rgba(96,139,169,0.2)'
    },
    labelTextColors: {
      probe1: 'rgb(250,135,159)',
      probe2: 'rgb(143,207,255)'
    },
    tooltipTextColors: {
      probe1: 'rgb(147,67,88)',
      probe2: 'rgb(61,120,157)'
    },
    viewConfig: {},
    chartLineToggleStatuses: {
      probe1: true,
      probe2: true
    }
  }
}

const getters = {
  fuelProbeGraphGeolocation: state => state.fuelProbeGraphGeolocation,
  getSelectedVehicle: state => state.selectedVehicle,
  getDataSource: state => state.dataSource,
  getSelectedDateFrom: state => state.selectedDateFrom,
  getSelectedDateTo: state => state.selectedDateTo,
  getColors: state => state.colors,
  getBackgroundColors: state => state.backgroundColors,
  getLabelTextColors: state => state.labelTextColors,
  getTooltipTextColors: state => state.tooltipTextColors,
  refuelingDispensingGeolocation: state => state.refuelingDispensingGeolocation,
  fuelTanksData: state => state.fuelTanksData,
  summaryData: state => state.summaryData,
  fuelProbesData: state => state.fuelProbesData,
  refuelsAndLeaksData: state => state.refuelsAndLeaksData,
  getChartLineToggleStatuses: state => state.chartLineToggleStatuses,
  viewConfig: state => state.viewConfig,
  loading: state => state.loading,
  mapLoading: state => state.mapLoading
}

let fetchAbortController

const actions = {
  async fetchConfig ({ commit, dispatch }) {
    try {
      const { config } = await api()['satellite-tracking'].get('fuel-probe-graph/config')

      commit('SET_VIEW_CONFIG', config)
      dispatch('map-type/setMapType', config.mapTypeToggler.find(item => item.key === config.selected_map_type) || config.mapTypeToggler[0], { root: true })
      commit('SET_DATA_SOURCE', config.dataSource.options[0].value) // Set first `dataSource` option.
    }
    catch (e) {
      console.dir(e)
    }
  },

  async fetchFuelTanksData ({ commit, state }, loading = true) {
    if (loading) commit('SET_LOADING', true)

    try {
      const { data } = await api()['satellite-tracking'].get('fuel-probes/current-fuel-data', {
        vehicle_id: state.selectedVehicle[0].id,
        data_source: state.dataSource
      })

      commit('SET_FUEL_TANKS_DATA', data)
    }
    catch (e) {
      console.dir(e)
    }
    finally {
      if (loading) commit('SET_LOADING', false)
    }
  },

  async fetchFuelProbesData ({ commit, state }) {
    commit('SET_LOADING', true)

    try {
      const { data } = await api()['satellite-tracking'].get('fuel-probes/report-data', {
        vehicle_id: state.selectedVehicle[0].id,
        date_from: state.selectedDateFrom,
        date_to: state.selectedDateTo,
        data_source: state.dataSource
      })

      commit('SET_SUMMARY_DATA', data.summary_data)
      commit('SET_FUEL_PROBES_DATA', data.chart_data)
      commit('SET_REFUELS_AND_LEAKS_DATA', data.refuels_leaks)
    }
    catch (e) {
      console.dir(e)
    }
    finally {
      commit('SET_LOADING', false)
    }
  },

  async fetchFuelLevelReadoutDetails ({ commit, state }, timestamp) {
    commit('SET_MAP_LOADING', true)
    if (typeof fetchAbortController !== 'undefined') {
      fetchAbortController.abort()
      fetchAbortController = new AbortController()
    }
    else {
      fetchAbortController = new AbortController()
    }
    const signal = fetchAbortController.signal

    try {
      const { data } = await api()['satellite-tracking'].get('fuel-probes/fuel-level-readout-details', {
        vehicle_id: state.selectedVehicle[0].id,
        timestamp: timestamp
      }, { signal: signal })
      const date = convertTimestampToDate(data.timestamp)

      const readoutData = {
        geolocation: latLng(data.latitude, data.longitude),
        tooltipData: [
          {
            label: 'Vrijeme',
            value: date.fullTime
          },
          {
            label: 'Datum',
            value: date.fullDate
          }
        ]
      }

      data.labels.forEach(labelItem => {
        readoutData.tooltipData.push({
          label: labelItem.name,
          value: labelItem.value
        })
      })

      return readoutData
    }
    catch (e) {
      if (signal.aborted) {
        console.log('Request canceled')
      }
      else {
        console.dir(e)
      }
    }
    finally {
      if (!signal.aborted) {
        commit('SET_MAP_LOADING', false)
      }
    }
  },

  setMapType ({ dispatch }, payload) {
    dispatch('map-type/setMapType', payload, { root: true })
  },

  setRefuelingDispensingGeolocation ({ commit }, payload) {
    commit('SET_FUEL_PROBE_GRAPH_GEOLOCATION', {})
    commit('SET_REFUELING_DISPENSING_GEOLOCATION', payload)
  },

  setFuelProbeGraphGeolocation ({ commit }, payload) {
    commit('SET_REFUELING_DISPENSING_GEOLOCATION', {})
    commit('SET_FUEL_PROBE_GRAPH_GEOLOCATION', payload)
  },

  setSelectedVehicle ({ commit }, vehicle) {
    commit('SET_SELECTED_VEHICLE', vehicle)
  },

  setDataSource ({ commit }, dataSource) {
    commit('SET_DATA_SOURCE', dataSource)
  },

  setSelectedDateFrom ({ commit }, vehicle) {
    commit('SET_SELECTED_DATE_FROM', vehicle)
  },

  setSelectedDateTo ({ commit }, vehicle) {
    commit('SET_SELECTED_DATE_TO', vehicle)
  },

  setChartLineToggleStatuses ({ commit }, data) {
    commit('SET_CHART_LINE_TOGGLE_STATUSES', data)
  },

  resetState ({ commit }) {
    commit('RESET_STATE')
  }
}

const mutations = {
  SET_VIEW_CONFIG: (state, payload) => (state.viewConfig = payload),

  SET_FUEL_TANKS_DATA: (state, payload) => (state.fuelTanksData = payload),

  SET_SUMMARY_DATA: (state, payload) => (state.summaryData = payload),

  SET_FUEL_PROBES_DATA: (state, payload) => (state.fuelProbesData = payload),

  SET_REFUELS_AND_LEAKS_DATA: (state, payload) => (state.refuelsAndLeaksData = payload),

  SET_LOADING: (state, payload) => (state.loading = payload),

  SET_MAP_LOADING: (state, payload) => (state.mapLoading = payload),

  SET_REFUELING_DISPENSING_GEOLOCATION: (state, payload) => (state.refuelingDispensingGeolocation = payload),

  SET_FUEL_PROBE_GRAPH_GEOLOCATION: (state, payload) => (state.fuelProbeGraphGeolocation = payload),

  SET_SELECTED_VEHICLE: (state, payload) => (state.selectedVehicle = payload),

  SET_DATA_SOURCE: (state, payload) => (state.dataSource = payload),

  SET_SELECTED_DATE_FROM: (state, payload) => (state.selectedDateFrom = payload),

  SET_SELECTED_DATE_TO: (state, payload) => (state.selectedDateTo = payload),

  SET_CHART_LINE_TOGGLE_STATUSES: (state, payload) => (state.chartLineToggleStatuses[payload.key] = payload.value),

  RESET_STATE: (state) => Object.assign(state, initialState())
}

export default {
  state: initialState(),
  getters,
  actions,
  mutations
}
