<template>
  <v-dialog
    v-model="dialog"
    content-class="elevation-0"
    :width="defaultDialogSize[$vuetify.breakpoint.name]"
    persistent
  >
    <v-card>
      <v-row class="flex justify-space-between ma-0">
        <v-card-title>{{ viewConfig.actions.delete.confirmation_title || '' }}</v-card-title>

        <v-btn
          text
          x-small
          class="mt-3 mr-2 pa-0 no-background-hover"
          elevation="0"
          @click="closeDialog"
        >
          <v-icon
            color="grey darken-1"
            size="1.8rem"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </v-row>

      <v-card-text>{{ viewConfig.actions.delete.confirmation_message || '' }}</v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="grey darken-1"
          text
          @click="closeDialog"
        >
          {{ viewConfig.actions.delete.confirmation_cancel }}
        </v-btn>

        <v-btn
          color="red"
          text
          @click="deleteConfirmed"
        >
          {{ viewConfig.actions.delete.confirmation_confirm || '' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteModal',

  props: {
    viewConfig: {
      type: Object,
      required: true
    },

    deleteDialog: {
      type: Boolean,
      required: true
    },

    deleteId: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      dialog: false,
      id: -1,
      defaultDialogSize: { xl: '33.33%', lg: '33.33%', md: '50%', sm: '66.66%', xs: '100%' }
    }
  },

  emits: ['close-dialog', 'delete-confirmed'],

  watch: {
    deleteDialog: function (deleteDialog) {
      this.dialog = deleteDialog
    },

    deleteId: function (deleteId) {
      this.id = deleteId
    }
  },

  methods: {
    closeDialog () {
      this.$emit('close-dialog')
    },

    deleteConfirmed () {
      this.$emit('delete-confirmed')
    }
  }
}
</script>
