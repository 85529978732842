<template>
  <expandable-side-nav
    :initiallyExpended="pickerConfig &&
    !isEmpty(pickerConfig) &&
    pickerConfig.initiallyExpended !== undefined ?
    pickerConfig.initiallyExpended : true"
    @is-map-options-picker-opened="state => this.$emit('is-map-options-picker-opened', state)"
  >
    <v-tabs
      v-if="pickerConfig && !isEmpty(pickerConfig) && pickerConfig.content && pickerConfig.content.length"
      :class="{ 'single-tab': pickerConfig.content.length === 1 }"
      fixed-tabs
      show-arrows
    >
      <template
        v-for="(tab, tabKey) in pickerConfig.content"
      >
        <v-tab
          :key="tabKey"
        >
          {{ tab.title }}
        </v-tab>
        <v-tab-item
          v-if="tab.items && tab.items.length"
          :key="tabKey + '_content'"
          class="py-2 px-2"
          eager
        >
          <v-row
            no-gutters
          >
            <v-col class="ml-2">
              <template
                v-for="item in tab.items"
              >
<!--                Base checkbox-->
                <div
                  v-if="!item.type"
                  :key="item.id"
                  style="width: fit-content;"
                  :class="{ 'hide-details': item.hideDetails ? item.hideDetails : true }"
                >
                  <v-checkbox
                    v-model="pickerItems[item.id]"
                    :dense="item.dense ? item.dense : true"
                    :solo="item.solo ? item.solo : false"
                    :flat ="item.flat ? item.flat : false"
                    :ripple="item.ripple ? item.ripple : false"
                    :color="item.color ? item.color : 'info'"
                    :hide-details="item.hideDetails ? item.hideDetails : true"
                  >
                    <template #label>
                      <div class="d-flex align-center ml-n1">
                        <p class="mb-0 checkbox-label-style">
                          {{ item.label }}
                        </p>
                        <v-progress-circular
                          v-if="mapOptionsShowLoadingIndicators &&
                          mapOptionsShowLoadingIndicators.length &&
                          mapOptionsShowLoadingIndicators.includes(item.id) &&
                          pickerItems[item.id]
                          "
                          class="ml-2"
                          indeterminate
                          width="2"
                          size="15"
                        />
                      </div>
                    </template>
                  </v-checkbox>
                </div>
<!--                Checkbox with dropdown menu-->
                <div
                  v-if="item.type === 'checkbox_dropdown'"
                  :key="item.id"
                  :class="{ 'hide-details': item.hideDetails ? item.hideDetails : true }"
                >
                  <v-row class="checkbox-dropdown-row">
                    <v-col :cols="$vuetify.breakpoint.xs ? '5' : '4'">
                      <v-checkbox
                        v-model="pickerItems[item.id]"
                        :dense="item.dense ? item.dense : true"
                        :solo="item.solo ? item.solo : false"
                        :flat ="item.flat ? item.flat : false"
                        :ripple="item.ripple ? item.ripple : false"
                        :color="item.color ? item.color : 'info'"
                        :hide-details="item.hideDetails ? item.hideDetails : true"
                      >
                        <template #label>
                          <div class="d-flex align-center ml-n1">
                            <p class="mb-0 checkbox-label-style">
                              {{ item.label }}
                            </p>
                            <v-progress-circular
                              v-if="mapOptionsShowLoadingIndicators &&
                              mapOptionsShowLoadingIndicators.length &&
                              mapOptionsShowLoadingIndicators.includes(item.id) &&
                              pickerItems[item.id]
                              "
                              class="ml-2"
                              indeterminate
                              width="2"
                              size="15"
                            />
                          </div>
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.xs ? '7' : '8'" class="checkbox-col">
                      <v-select
                        v-model="pickerItems[item.drop_down_id]"
                        :items="item.drop_down_items ? item.drop_down_items : []"
                        class="dense-select"
                        label=""
                        dense
                        solo
                        flat
                        clearable
                        hide-details
                        height="25"
                      />
                    </v-col>
                  </v-row>
                </div>
              </template>
            </v-col>
          </v-row>
        </v-tab-item>
      </template>
    </v-tabs>
    <div
      v-else
      class="empty-content-div"
    >
      No content
    </div>
  </expandable-side-nav>
</template>

<script>
import ExpandableSideNav from './ExpandableSideNav.vue'
import { isEmpty } from 'lodash'

export default {
  name: 'MapPickerOptions',

  components: {
    ExpandableSideNav
  },

  props: {
    mapOptionsPickerConfig: {
      type: Object,
      required: true
    },
    mapOptionsShowLoadingIndicators: {
      type: Array,
      default: () => []
    },
    adjustMapOptionsFields: {
      type: Array,
      default: () => []
    },
    initiallyExpended: {
      type: Boolean,
      default: true
    },
    initialMapOptionValues: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      pickerItems: {}
    }
  },

  computed: {
    pickerConfig () {
      return this.mapOptionsPickerConfig
    }
  },

  emits: ['is-map-options-picker-opened', 'map-options-updated'],

  watch: {
    pickerItems: {
      deep: true,
      handler (updatedOptions) {
        this.$emit('map-options-updated', updatedOptions)
      }
    },
    // This prop is used for adjusting fields for provided tabs. For each tab its possible to add/remove fields optionally.
    adjustMapOptionsFields: {
      deep: true,
      handler (newFields) {
        if (newFields && newFields.length) {
          newFields.forEach(tabRef => {
            // Add new fields to provided tab
            if (tabRef && tabRef.add && tabRef.add.length) {
              tabRef.add.forEach(fieldToAdd => {
                if (this.pickerConfig && !isEmpty(this.pickerConfig) && this.pickerConfig.content && this.pickerConfig.content.length && fieldToAdd && !isEmpty(fieldToAdd)) {
                  this.pickerConfig.content.forEach(tabItem => {
                    if (tabItem && tabItem.items && tabItem.items.length) {
                      const foundExistingField = tabItem.items.find(field => field.id === fieldToAdd.id)
                      if (!foundExistingField) {
                        if (tabItem.title === tabRef.tabTitle) {
                          tabItem.items.push(fieldToAdd)
                        }
                      }
                    }
                  })
                }
              })
            }
            // Remove existing fields from provided tab
            else if (tabRef && tabRef.remove && tabRef.remove.length) {
              tabRef.remove.forEach(fieldToRemove => {
                if (this.pickerConfig && !isEmpty(this.pickerConfig) && this.pickerConfig.content && this.pickerConfig.content.length && fieldToRemove) {
                  this.pickerConfig.content.forEach(tabItem => {
                    if (tabItem && tabItem.items && tabItem.items.length) {
                      tabItem.items = tabItem.items.filter(field => field.id !== fieldToRemove && field.drop_down_id !== fieldToRemove)
                      this.$delete(this.pickerItems, fieldToRemove)
                    }
                  })
                }
              })
            }
          })
        }
      }
    },
    initialMapOptionValues: {
      immediate: true,
      handler (initialValues) {
        this.$nextTick(() => {
          if (initialValues && initialValues.length) {
            initialValues.forEach(obj => {
              if (obj && !isEmpty(obj) && obj.id && obj.value) {
                this.$set(this.pickerItems, obj.id, obj.value)
              }
            })
          }
        })
      }
    }
  },

  methods: {
    isEmpty
  }
}
</script>

<style scoped lang="scss">
.empty-content-div {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 8rem;
}

::v-deep .v-tabs-slider-wrapper {
  .v-tabs-slider {
    background-color: #6aac49;
  }
}
.checkbox-label-style {
  font-size: 0.82rem;
}

::v-deep .v-tabs-bar .v-tab {
  min-width: 1.7rem;
  width: 100% !important;
  max-width: unset !important;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.2rem;
  letter-spacing: normal;
  text-transform: none;
}

::v-deep {
  .v-tabs-bar {
    border-bottom: 0.02rem solid #869DB6;
  }
  .v-tabs-items {
    margin-top: 0.2rem;
  }

  .v-slide-group__prev {
    background-color: #e8e8e8;
  }

  .v-slide-group__next {
    background-color: #e8e8e8;
  }

  .v-slide-group__prev--disabled {
    cursor: not-allowed !important;
    pointer-events: unset !important;
  }

  .v-slide-group__next--disabled {
    cursor: not-allowed !important;
    pointer-events: unset !important;
  }
}
.hide-details {
  ::v-deep .v-input--hide-details {
    margin: 0 !important;
    padding: 0 !important;
  }
  padding-bottom: 0.4rem;
}

.single-tab {
  ::v-deep .v-tabs-slider-wrapper {
    width: 100% !important;
    height: 0.25rem !important;
    left: 0 !important;

    .v-tabs-slider {
      width: 100% !important;
      background-color: #6aac49;
    }
  }
  ::v-deep {
    .v-tab--active {
      pointer-events: none !important;
    }
  }
}

::v-deep .v-input__control {
  min-height: 20px !important;
}

.dense-select {
  max-width: 14rem;
  margin-left: 0.5rem !important;
  border: 1px solid var(--v-primary-base);
}

.dense-select >>> .v-input__control {
  min-height: 25px !important;
}

.dense-select >>> .v-input__control > .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}

.dense-select >>> .v-select__selection {
  font-size: 14px;
}

@media screen and (max-width: 600px) {
  .checkbox-label-style {
    font-size: 0.75rem;
  }
  ::v-deep {
    .v-tabs-bar .v-tab {
      font-size: 0.80rem;
    }
  }
}
</style>
