var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"d-flex align-center justify-space-between mb-8"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""},on:{"click":_vm.duplicateValues}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-duplicate")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('people/person.working_hours_copy_tooltip')))])])],1),_vm._l((_vm.days),function(day,num,index){return _c('v-row',{key:num,staticClass:"align-baseline",class:{'mb-n12': _vm.mediumOrHigherScreen },attrs:{"align":"center"}},[_c('v-col',{staticClass:"col-12 col-sm-3 col-md"},[_vm._v(" "+_vm._s(day)+": ")]),_vm._l((_vm.fields),function(field,name){return _c('v-col',{key:name,staticClass:"col-12 col-md-2",class:{ 'col-sm-3': field.type === 'label',
                'col-sm-4': field.type !== 'label',
                'py-0' : _vm.$vuetify.breakpoint.sm || (field.type !== 'label' && _vm.$vuetify.breakpoint.xs),
                'pt-0' : _vm.$vuetify.breakpoint.xs,

      }},[(field.type === 'label')?_c('v-col',{staticClass:"pa-0",class:{'text-right': _vm.mediumOrHigherScreen}},[_vm._v(" "+_vm._s(field.label)+": ")]):_vm._e(),(field.type === 'timepicker')?_c('v-menu',{ref:("timepicker_" + index + "_" + name),refInFor:true,attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"error-messages":_vm.validationErrors[("working_hours." + index + "." + name)],"readonly":"","clearable":"","outlined":"","dense":""},model:{value:(_vm.formData[index][name]),callback:function ($$v) {_vm.$set(_vm.formData[index], name, $$v)},expression:"formData[index][name]"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},on:{"input":function($event){return _vm.handleTimepickerInput(index, name)}},model:{value:(_vm.formData[index][name]),callback:function ($$v) {_vm.$set(_vm.formData[index], name, $$v)},expression:"formData[index][name]"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs[("timepicker_" + index + "_" + name)][0].save(_vm.formData[index][name])}}},[_vm._v(" "+_vm._s(_vm.$t('base.ok'))+" ")])],1)],1):_vm._e()],1)})],2)}),(_vm.errorMessages.length)?_c('div',{staticClass:"mt-7 v-messages theme--light error--text",attrs:{"role":"alert"}},[_c('div',{staticClass:"v-messages__wrapper"},[_c('div',{staticClass:"v-messages__message"},[_vm._v(" "+_vm._s(_vm.errorMessages[0])+" ")])])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }