import { api } from '@/global/services/api'
import L from 'leaflet'

const commonIconDataObject = {
  iconSize: [12, 12],
  iconAnchor: [6, 6]
}

const commonTrafficLightIconDataObject = {
  iconSize: [10, 18],
  iconAnchor: [4, 9]
}

const state = {
  items: [],
  mapItems: [],
  historyMapItems: [],
  pagination: {
    count: 0,
    currentPage: 1,
    perPage: 5,
    totalPages: 1,
    total: 0
  },
  active: true,
  min_lat: false,
  min_lon: false,
  max_lat: false,
  max_lon: false,
  hazard_level: [],
  subject_report: [],
  cityDistricts: [],
  from: 0,
  to: 0,
  address: '',
  sort: {},
  icons: {
    red_circle: L.divIcon({
      ...commonIconDataObject,
      className: 'red circle'
    }),
    yellow_circle: L.divIcon({
      ...commonIconDataObject,
      className: 'yellow circle'
    }),
    green_circle: L.divIcon({
      ...commonIconDataObject,
      className: 'green circle'
    }),
    red_square: L.divIcon({
      ...commonIconDataObject,
      className: 'red'
    }),
    yellow_square: L.divIcon({
      ...commonIconDataObject,
      className: 'yellow'
    }),
    green_square: L.divIcon({
      ...commonIconDataObject,
      className: 'green'
    }),
    white_circle: L.divIcon({
      ...commonIconDataObject,
      className: 'white circle'
    }),
    white_square: L.divIcon({
      ...commonIconDataObject,
      className: 'white'
    }),
    red_traffic_light: L.divIcon({
      ...commonTrafficLightIconDataObject,
      className: 'red_traffic_light'
    }),
    yellow_traffic_light: L.divIcon({
      ...commonTrafficLightIconDataObject,
      className: 'yellow_traffic_light'
    }),
    green_traffic_light: L.divIcon({
      ...commonTrafficLightIconDataObject,
      className: 'green_traffic_light'
    }),
    white_traffic_light: L.divIcon({
      ...commonTrafficLightIconDataObject,
      className: 'white_traffic_light'
    }),
    red_triangle: L.divIcon({
      ...commonIconDataObject,
      className: 'red_triangle'
    }),
    yellow_triangle: L.divIcon({
      ...commonIconDataObject,
      className: 'yellow_triangle'
    }),
    green_triangle: L.divIcon({
      ...commonIconDataObject,
      className: 'green_triangle'
    }),
    white_triangle: L.divIcon({
      ...commonIconDataObject,
      className: 'white_triangle'
    })
  }
}

const getters = {
  list: state => state.items,
  pagination: state => state.pagination,
  icons: state => state.icons,
  mapItems: state => state.mapItems,
  historyMapItems: state => state.historyMapItems,
  cityDistricts: state => state.cityDistricts,
  sort: state => state.sort
}

const actions = {

  async getIcons ({ commit, state }, options) {
    try {
      const params = {
        active: options.active,
        min_lat: options.min_lat,
        min_lon: options.min_lon,
        max_lat: options.max_lat,
        max_lon: options.max_lon,
        from: options.from,
        to: options.to,
        hazard_level: options.hazard_level.toString(),
        subject_report: options.subject_report.toString(),
        address: options.address ?? '',
        tipNumber: options.tipNumber ?? ''
      }

      const {
        data
      } = await api('road-maintenance').get('work-orders-icons', params)
      if (options.min_lat && options.min_lon && options.max_lat && options.max_lon) {
        commit('SET_HISTORY_MAP_ITEMS', data)
      }
      else {
        commit('SET_MAP_ITEMS', data)
      }
    }
    catch (e) {
      console.dir(e)
    }
  },

  async fetch ({ commit, state }, options) {
    try {
      const params = {
        pagination: options.pagination,
        sort: options.sort,
        active: options.active,
        hazard_level: options.hazard_level.toString(),
        subject_report: options.subject_report.toString(),
        from: options.from,
        to: options.to,
        address: options.address,
        tipNumber: options.tipNumber
      }
      const {
        data,
        pagination,
        sort
      } = await api('road-maintenance').get('work-orders', params)

      commit('SET_ITEMS', data)
      commit('SET_PAGINATION', pagination)
      commit('SET_SORT', sort)
    }
    catch (e) {
      console.dir(e)
    }
  },

  async fetchCityDistricts ({ commit, state }, options) {
    try {
      const { data } = await api('locations').get('locations/city-districts', { withChildren: true })
      commit('SET_CITY_DISTRICTS', data)
    }
    catch (e) {
      console.dir(e)
    }
  }
}

const mutations = {
  SET_ITEMS: (state, payload) => (state.items = payload),
  SET_CITY_DISTRICTS: (state, payload) => (state.cityDistricts = payload),
  SET_PAGINATION ({ pagination }, payload) {
    const { count, currentPage, perPage, totalPages, total } = payload
    pagination.count = count || 0
    pagination.currentPage = currentPage || 1
    pagination.perPage = perPage || 0
    pagination.totalPages = totalPages || 1
    pagination.total = total || 0
  },
  SET_SORT: (state, payload) => (state.sort = payload),
  SET_MAP_ITEMS: (state, payload) => (state.mapItems = payload),
  SET_HISTORY_MAP_ITEMS: (state, payload) => (state.historyMapItems = payload)
}

export default {
  state,
  getters,
  actions,
  mutations
}
