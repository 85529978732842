<template>
  <v-card-text>
    <v-dialog
      v-model="showCreateOrEditDialog"
      content-class="elevation-0"
      :width="vDialogSize[$vuetify.breakpoint.name]"
      persistent
    >
      <v-card class="edit-dialog-card">
        <v-row class="flex justify-space-between ma-0 light-grey">
          <v-card-title>
            {{ dialogTitle }}
          </v-card-title>
          <v-btn
            text
            x-small
            class="mt-3 mr-2 pa-0 no-background-hover"
            elevation="0"
            @click="closeCreateEditDialog"
          >
            <v-icon
              color="grey darken-1"
              size="1.8rem"
            >
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
        <div class="tabs-wrapper">
          <template v-if="hasTabsConfig && !isSmallOrXSmallScreen">
            <form @submit.prevent="saveConfirmed">
              <v-tabs
                v-model="currentTab"
                vertical
              >
                <v-tab
                  v-for="tab in viewConfig.form_tabs.tabs"
                  :key="tab.key"
                  class="justify-start"
                >
                  {{ tab.label }}
                  <v-icon
                    v-if="checkForValidationErrors(tab)"
                    class="ml-1 red-muted--text"
                  >
                    mdi-alert-circle-outline
                  </v-icon>
                </v-tab>

                <v-tab-item
                  v-for="tab in viewConfig.form_tabs.tabs"
                  :key="tab.key + 'content'"
                  eager
                >
                  <v-card
                    flat
                    class="form-inputs-card"
                  >
                    <v-card-text style="height: 70vh; overflow-y: scroll">
                      <form-fields
                        :key="tab.key"
                        :module="module"
                        :model="model"
                        :view-config="viewConfig"
                        :form-type="formType"
                        :data="formData"
                        :container-dialog="showCreateOrEditDialog"
                        :fields="tab.fields"
                        :edit-item="editItem"
                        :validation-errors="validationErrors"
                        :create-date-key="createDateKey"
                        @update-data="updateTabFormData"
                        @update-create-date="createDate = $event"
                        @clear-errors="clearInputValidationErrors($event)"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
              <v-divider />
              <form-actions
                :view-config="viewConfig"
                :form-type="formType"
                class="light-grey"
                @close="closeCreateEditDialog"
              />
            </form>
          </template>
          <v-form
            v-else
            @submit.prevent="saveConfirmed"
          >
            <v-card-text
              v-if="viewConfig[formType]"
              style="height: 70vh; overflow-y: scroll"
            >
              <form-fields
                :module="module"
                :model="model"
                :view-config="viewConfig"
                :form-type="formType"
                :data="formData"
                :container-dialog="showCreateOrEditDialog"
                :edit-item="editItem"
                :validation-errors="validationErrors"
                @update-data="formData = $event"
                @update-create-date="createDate = $event"
                @clear-errors="clearInputValidationErrors($event)"
              />
            </v-card-text>
            <form-actions
              :view-config="viewConfig"
              :form-type="formType"
              class="light-grey"
              @close="closeCreateEditDialog"
            />
          </v-form>
        </div>
      </v-card>
    </v-dialog>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="60"
      />
    </v-overlay>
  </v-card-text>
</template>

<script>
import FormFields from '@/global/components/form-fields/FormFields'
import FormActions from '@/global/components/FormActions'
import { api } from '@/global/services/api'
import { forEach, has } from 'lodash'
import indexMethods from '@/global/mixins/indexMethods'

export default {
  name: 'CreateOrUpdateComponent',

  components: {
    FormFields,
    FormActions
  },

  mixins: [indexMethods],

  props: {
    module: {
      type: String,
      default: 'base',
      required: true
    },
    model: {
      type: String,
      required: true
    },
    itemId: {
      type: Number,
      required: true
    },
    viewConfig: {
      type: Object,
      required: true
    },
    vDialogSize: {
      type: Object,
      default: () => ({
        xl: '55%',
        lg: '65%',
        md: '90%',
        sm: '100%',
        xs: '100%'
      })
    },
    createOrUpdateDialog: {
      type: Boolean,
      default: false,
      required: true
    }
  },

  data () {
    return {
      editItem: null,
      validationErrors: {},
      createDateKey: null,
      currentTab: '',
      formData: {},
      loading: true
    }
  },
  computed: {
    editInProgress () {
      return this.itemId !== null
    },

    formType () {
      return this.editInProgress ? 'edit_form' : 'create_form'
    },
    showCreateOrEditDialog () {
      return this.createOrUpdateDialog
    },
    dialogTitle () {
      return this.viewConfig.edit_form ? this.viewConfig.edit_form.title : ''
    },

    formDefaults () {
      const data = {}

      forEach(this.viewConfig.columns, function (object, key) {
        data[key] = null
      })

      return data
    },

    hasTabsConfig () {
      return this.viewConfig.form_tabs?.tabs?.length
    },

    isSmallOrXSmallScreen () {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
    }
  },

  watch: {
    validationErrors: {
      deep: true,
      handler (value) {
        if (this.hasTabsConfig) {
          let firstErrorField = Object.keys(value)[0]
          firstErrorField = firstErrorField ? firstErrorField.split('.')[0] : firstErrorField
          this.currentTab = this.viewConfig.form_tabs.tabs.findIndex((tab) => {
            if (tab.fields.includes(firstErrorField)) return tab
          })
        }
      }
    }
  },

  created () {
    this.fetchItem()
  },

  methods: {

    checkForValidationErrors (tab) {
      const validationErrorKeysString = Object.keys(this.validationErrors).toString()
      return tab.fields.some((fieldName) => {
        return validationErrorKeysString.indexOf(fieldName) !== -1
      })
    },

    clearInputValidationErrors (key) {
      if (has(this.validationErrors, key)) {
        this.validationErrors[key] = []
      }
    },

    updateTabFormData (data) {
      this.formData = data
    },

    closeCreateEditDialog () {
      document.getElementsByClassName('v-card-text').scrollTop = 0
      this.formData = Object.assign({}, {})
      this.$emit('closeEditOrUpdateDialog')
    },

    async saveConfirmed () {
      this.validationErrors = {}

      const formData = {
        ...this.formData,
        // TODO: Refactor this to be dynamic and to handle situations where multiple dates are present in create form
        ...(this.createDate && { [this.createDateKey]: this.createDate })
      }

      this.convertTextValueToDefaultColumns(formData)

      try {
        await api()[this.module].patch((this.viewConfig.routeString || '') + '/' + this.editItem.id, formData)
        this.editItem = null
        this.validationErrors = {}
        this.$emit('itemSaved')
      }
      catch (e) {
        this.validationErrors = e.getValidationErrors()
      }
    },

    async fetchItem () {
      if (this.itemId) {
        const response = await api()[this.module].get((this.viewConfig.routeString || '') + '/' + this.itemId)

        this.formData = this.formDefaults

        this.$nextTick(() => {
          this.editItem = Object.assign({}, response.data) // In generic forms this should be _.cloneDeep
        })
        this.loading = false
      }
      this.loading = false
    }
  }
}
</script>
