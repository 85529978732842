const initialState = {
  depIds: {
    dependsOnId: null,
    dependantId: null
  },
  dependsOn: {},
  dependsOnInternalItems: [],
  dependsOnDisabled: false,
  dependant: {},
  dependantInternalItems: [],
  dependantDisabled: false,
  dependsOnText: null,
  dependantText: null,
  label: null
}

const store = {
  namespaced: true,

  state: {
    filters: { ...initialState },
    'form-fields': { ...initialState }
  },

  mutations: {
    SET_DEP_IDS (state, { value, storeSuffix }) {
      state[storeSuffix].depIds = Object.assign({}, value)
    },
    RESET_DEP_IDS (state, storeSuffix) {
      for (const key in state[storeSuffix].depIds) {
        state[storeSuffix].depIds[key] = null
      }
    },
    SET_DEPENDS_ON (state, { value, storeSuffix }) {
      state[storeSuffix].dependsOn = value
    },
    SET_DEPENDANT (state, { value, storeSuffix }) {
      state[storeSuffix].dependant = value
    },
    SET_DEPENDS_ON_KEY (state, { key, value, storeSuffix }) {
      state[storeSuffix].dependsOn[key] = value
    },
    SET_DEPENDANT_KEY (state, { key, value, storeSuffix }) {
      state[storeSuffix].dependant[key] = value
    },
    SET_DEP_IDS_KEY (state, { key, value, storeSuffix }) {
      state[storeSuffix].depIds[key] = value
    },
    SET_DEPENDANT_INTERNAL_ITEMS (state, { value, storeSuffix }) {
      state[storeSuffix].dependantInternalItems = value
    },
    SET_DEPENDS_ON_INTERNAL_ITEMS (state, { value, storeSuffix }) {
      state[storeSuffix].dependsOnInternalItems = value
    },
    SET_DEPENDS_ON_TEXT (state, { value, storeSuffix }) {
      state[storeSuffix].dependsOnText = value
    },
    SET_DEPENDANT_TEXT (state, { value, storeSuffix }) {
      state[storeSuffix].dependantText = value
    },
    SET_LABEL (state, { value, storeSuffix }) {
      state[storeSuffix].label = value
    },
    SET_DEPENDANT_DISABLED (state, { value, storeSuffix }) {
      state[storeSuffix].dependantDisabled = value
    }
  },

  actions: {
    setDepIds ({ commit }, payload) {
      commit('SET_DEP_IDS', payload)
    },
    resetDepIds ({ commit }, storeSuffix) {
      commit('RESET_DEP_IDS', storeSuffix)
    },
    setDepIdsKey ({ commit }, payload) {
      commit('SET_DEP_IDS_KEY', payload)
    },
    setDependsOn ({ commit }, payload) {
      commit('SET_DEPENDS_ON', payload)
    },
    setDependant ({ commit }, payload) {
      commit('SET_DEPENDANT', payload)
    },
    setDependsOnKey ({ commit }, payload) {
      commit('SET_DEPENDS_ON_KEY', payload)
    },
    setDependantKey ({ commit }, payload) {
      commit('SET_DEPENDANT_KEY', payload)
    },
    setDependsOnInternalItems ({ commit }, payload) {
      commit('SET_DEPENDS_ON_INTERNAL_ITEMS', payload)
    },
    setDependantInternalItems ({ commit }, payload) {
      commit('SET_DEPENDANT_INTERNAL_ITEMS', payload)
    },
    setDependsOnText ({ commit }, payload) {
      commit('SET_DEPENDS_ON_TEXT', payload)
    },
    setDependantText ({ commit }, payload) {
      commit('SET_DEPENDANT_TEXT', payload)
    },
    setLabel ({ commit }, payload) {
      commit('SET_LABEL', payload)
    },
    resetData ({ dispatch, commit }, storeSuffix) {
      dispatch('resetDepIds', storeSuffix)
      commit('SET_DEPENDS_ON_INTERNAL_ITEMS', { value: [], storeSuffix })
      commit('SET_DEPENDANT_INTERNAL_ITEMS', { value: [], storeSuffix })
      commit('SET_DEPENDS_ON_TEXT', { value: null, storeSuffix })
      commit('SET_DEPENDANT_TEXT', { value: null, storeSuffix })
      commit('SET_LABEL', { value: null, storeSuffix })
    },
    setDependantDisabled ({ commit }, payload) {
      commit('SET_DEPENDANT_DISABLED', payload)
    }
  }
}

export default store
