import { api } from '@/global/services/api'
import L from 'leaflet'

const commonIconDataObject = {
  iconSize: [10, 18],
  iconAnchor: [10, 18]
}

const state = {
  items: [],
  permissions: [],
  mapItems: [],
  viewConfig2: {},
  icons: {
    red: L.divIcon({
      ...commonIconDataObject,
      className: 'red_traffic_light'
    }),
    yellow: L.divIcon({
      ...commonIconDataObject,
      className: 'yellow_traffic_light'
    }),
    green: L.divIcon({
      ...commonIconDataObject,
      className: 'green_traffic_light'
    })
  }
}

const getters = {
  list: state => state.items,
  viewConfig2: state => state.viewConfig2,
  permissions: state => state.permissions,
  icons: state => state.icons,
  mapItems: state => state.mapItems
}

const actions = {

  async getIcons ({ commit, state }, options) {
    try {
      const params = {
        types: options.trafficLightType
      }

      const {
        data
      } = await api('road-maintenance').get('traffic-lights/get-icons', params)

      commit('SET_MAP_ITEMS', data)
    }
    catch (e) {
      console.dir(e)
    }
  },

  async fetchMapConfig ({ commit, dispatch }) {
    try {
      const { config } = await api()['satellite-tracking'].get('live-tracking')

      commit('SET_VIEW_CONFIG2', config)
    }
    catch (e) {
      console.dir(e)
    }
    finally {
    }
  },

  async fetchPermissions ({ commit, dispatch }) {
    try {
      const permissions = await api('road-maintenance').get('traffic-lights/permissions')

      commit('SET_PERMISSIONS', permissions)
    }
    catch (e) {
      console.dir(e)
    }
  }
}

const mutations = {
  SET_ITEMS: (state, payload) => (state.items = payload),
  SET_MAP_ITEMS: (state, payload) => (state.mapItems = payload),
  SET_VIEW_CONFIG2: (state, payload) => (state.viewConfig2 = payload),
  SET_PERMISSIONS: (state, payload) => {
    state.permissions = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
