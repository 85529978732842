import { isEmpty, isObject } from 'lodash'
import { api } from '@/global/services/api'

const state = {
  alarmEvents: [],
  viewConfig: {},
  pagination: {
    count: 0,
    currentPage: 1,
    perPage: 15,
    totalPages: 1,
    total: 0
  },
  sort: {},
  initialFilters: {},
  showFilters: false
}

const getters = {
  initialFilters: state => state.initialFilters,
  list: state => state.alarmEvents,
  pagination: state => state.pagination,
  showFilters: state => state.showFilters,
  sort: state => state.sort,
  viewConfig: state => state.viewConfig
}

const actions = {
  async fetch ({ commit, state }, options) {
    try {
      const params = {
        pagination: options.pagination,
        sort: options.sort,
        filters: options.filters,
        company_scope_id: options.company_scope_id
      }

      const {
        data,
        filters,
        pagination,
        sort
      } = await api()['satellite-tracking']
        .get('alarm-events', params)

      if (isEmpty(state.initialFilters)) {
        commit('SET_INITIAL_FILTERS', filters)
      }

      commit('SET_ALARM_EVENTS', data)
      commit('SET_PAGINATION', pagination)
      commit('SET_SORT', sort)
    }
    catch (e) {
      console.dir(e)
    }
  },

  async fetchConfig ({ commit }) {
    try {
      const { config } = await api()['satellite-tracking'].get('alarm-events/config')
      commit('SET_VIEW_CONFIG', config)
    }
    catch (e) {
      console.dir(e)
    }
  },

  setShowFilters ({ commit, state }, payload) {
    commit('SET_SHOW_FILTERS', payload)
  },

  setInitialFilter ({ commit }, payload) {
    commit('SET_INITIAL_FILTER', payload)
  },

  resetInitialFilters ({ commit }) {
    commit('RESET_INITIAL_FILTERS')
  },

  resetInitialFilterLoadedKeys ({ commit }) {
    commit('RESET_INITIAL_FILTER_LOADED_KEYS')
  }
}

const mutations = {
  SET_ALARM_EVENTS: (state, payload) => (state.alarmEvents = payload),

  SET_VIEW_CONFIG: (state, payload) => (state.viewConfig = payload),

  SET_PAGINATION ({ pagination }, payload) {
    const { count, currentPage, perPage, totalPages, total } = payload
    pagination.count = count || 0
    pagination.currentPage = currentPage || 1
    pagination.perPage = perPage || 0
    pagination.totalPages = totalPages || 1
    pagination.total = total || 0
  },

  SET_SORT: (state, payload) => (state.sort = payload),

  SET_SHOW_FILTERS: (state, showFilters) => (state.showFilters = showFilters),

  SET_INITIAL_FILTERS: (state, initialFilters) => {
    for (const filter in initialFilters) {
      if (isObject(initialFilters[filter])) {
        initialFilters[filter].loaded = false
      }
    }
    state.initialFilters = initialFilters
  },

  RESET_INITIAL_FILTERS: (state) => {
    for (const filter in state.initialFilters) {
      state.initialFilters[filter] = null
    }
  },

  SET_INITIAL_FILTER: (state, { key, value }) => {
    state.initialFilters[key] = value
  },

  RESET_INITIAL_FILTER_LOADED_KEYS: (state) => {
    for (const filter in state.initialFilters) {
      state.initialFilters[filter].loaded = false
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
