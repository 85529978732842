import { api } from '@/global/services/api'

const state = {
  apiToken: '',
  dialog: false,
  smsContent: '',
  phoneNumbers: [],
  viewConfig: {},
  validationErrors: {}
}

const getters = {
  dialog: state => state.dialog,
  smsContent: state => state.smsContent,
  phoneNumbers: state => state.phoneNumbers,
  viewConfig: state => state.viewConfig,
  validationErrors: state => state.validationErrors
}

const actions = {

  setDialog ({ commit }, payload) {
    commit('SET_DIALOG', payload)
  },

  resetDialog ({ commit }) {
    commit('SET_DIALOG', false)
    commit('SET_SMS_CONTENT', '')
    commit('SET_PHONE_NUMBERS', [])
    commit('SET_VALIDATION_ERRORS', [])
  },

  updateSmsContent ({ commit, state }, text) {
    commit('SET_SMS_CONTENT', text)
  },

  async sendSms ({ commit, state }) {
    const formData = {
      token: state.apiToken,
      sms_content: state.smsContent,
      phone_numbers: state.phoneNumbers
    }
    try {
      await api().sms.post(state.viewConfig.route, formData)
      commit('SET_DIALOG', false)
      commit('SET_SMS_CONTENT', '')
      commit('SET_PHONE_NUMBERS', [])
    }
    catch (e) {
      if (e.isValidationError) {
        commit('SET_VALIDATION_ERRORS', e.getValidationErrors())
      }
    }
  },

  async fetch ({ commit }) {
    try {
      const { config } = await api().sms.get('send-sms-config')
      const apiToken = config.api_token
      delete config.api_token

      commit('SET_API_TOKEN', apiToken)
      commit('SET_VIEW_CONFIG', config)
    }
    catch (e) {
      console.dir(e)
    }
  },

  addNumber ({ commit, state }, payload) {
    commit('ADD_NUMBER', payload)
  },

  updateNumber ({ commit, state }, payload) {
    commit('UPDATE_NUMBER', payload)
  },

  removeNumber ({ commit, state }, index) {
    commit('REMOVE_NUMBER', index)
    commit('RESET_PHONE_NUMBERS_VALIDATION_ERRORS', index)
  },

  resetValidationErrors ({ commit, state }, key) {
    commit('RESET_VALIDATION_ERRORS', key)
  },

  resetPhoneNumbersValidationErrors ({ commit, state }, index) {
    commit('RESET_PHONE_NUMBERS_VALIDATION_ERRORS', index)
  }

}

const mutations = {
  SET_VIEW_CONFIG: (state, payload) => (state.viewConfig = payload),

  SET_API_TOKEN: (state, payload) => (state.apiToken = payload),

  SET_DIALOG: (state, payload) => (state.dialog = payload),

  SET_SMS_CONTENT: (state, payload) => (state.smsContent = payload),

  SET_PHONE_NUMBERS: (state, payload) => (state.phoneNumbers = payload),

  SET_VALIDATION_ERRORS: (state, payload) => (state.validationErrors = payload),

  RESET_VALIDATION_ERRORS: (state, key) => (state.validationErrors[key] = []),

  RESET_PHONE_NUMBERS_VALIDATION_ERRORS: (state, index) => {
    state.validationErrors[`phone_numbers.${index}`] = []
  },

  ADD_NUMBER: (state, payload) => (state.phoneNumbers.push(payload)),

  UPDATE_NUMBER: (state, { index, value }) => (state.phoneNumbers[index] = value),

  REMOVE_NUMBER: (state, index) => (state.phoneNumbers.splice(index, 1))
}

export default {
  state,
  getters,
  actions,
  mutations
}
