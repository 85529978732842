<template>
  <div class="mb-4">
    <div class="mb-4">
      <data-column
        :fields="dataColumnFields"
        tight-column
        split-column
        :column-count="tab.column_count"
      />
    </div>
  </div>
</template>

<script>
import DataColumn from '@/global/components/show-details/fields/data-column/DataColumn'
import {
  formatSqlDate
} from '@/global/services/helpers/dates'

export default {
  name: 'DataColumnField',
  components: {
    DataColumn
  },
  props: {
    namespace: {
      type: String,
      required: true
    },
    module: {
      type: String,
      required: true
    },
    tab: {
      type: Object,
      required: true
    }
  },

  computed: {
    viewConfig () {
      return this.$store.getters[this.namespace + '/viewConfig']
    },
    item () {
      return this.$store.getters[this.namespace + '/showItem'].item
    },
    dataColumnFields () {
      return this.tab.columns.map(column => {
        const columnConfig = this.viewConfig.columns[column]

        let value = this.item[columnConfig.field] ?? this.item?.[column]?.name ?? ' - '
        if (columnConfig.field === 'extinguisher_expiry') {
          value = formatSqlDate(value)
        }
        return {
          key: columnConfig.label,
          value: value
        }
      })
    }
  }
}
</script>
