import { api } from '@/global/services/api'

function initialState () {
  return {
    fetchedReport: {},
    reportsMetaData: [],
    stopsDurationRange: [0, 1],
    lowVoltageValue: 10,
    selectedVehicles: [],
    selectedDeviceIdForReportFromVehicleMultiselect: null,
    vehicleAssignedTempSensBetweenDates: {},
    vehicleAssignedSensorsBetweenDates: {},
    temperatureRanges: {},
    selectedLocations: null,
    selectedDateFrom: null,
    selectedDateTo: null,
    reportParams: {},
    loading: false,
    showReferenceValues: false
  }
}

const getters = {
  fetchedReport: state => state.fetchedReport,
  getReportsMetaData: state => state.reportsMetaData,
  getReportParams: state => state.reportParams,
  getSelectedVehicles: state => state.selectedVehicles,
  getSelectedLocations: state => state.selectedLocations,
  getSelectedDeviceIdForReportFromVehicleMultiselect: state => state.selectedDeviceIdForReportFromVehicleMultiselect,
  getVehicleAssignedTempSensBetweenDates: state => state.vehicleAssignedTempSensBetweenDates,
  getVehicleAssignedSensorsBetweenDates: state => state.vehicleAssignedSensorsBetweenDates,
  getTemperatureRanges: state => state.temperatureRanges,
  getShowReferenceValues: state => state.showReferenceValues,
  loading: state => state.loading
}

const module = 'satellite-tracking'
const route = 'vehicle-report'

const actions = {

  async fetchReportsMetaData ({ commit }, options = {}) {
    commit('SET_LOADING', true)

    try {
      const res = await api()[module].get('vehicle-reports-options', options)
      commit('SET_REPORTS_META_DATA', res)
    }
    catch (e) {
      console.dir(e)
    }
    finally {
      commit('SET_LOADING', false)
    }
  },

  async fetchReport ({ commit, state }) {
    commit('SET_LOADING', true)

    try {
      const res = await api()[module].post(route, state.reportParams)
      commit('SET_FETCHED_REPORT', res)
    }
    catch (e) {
      console.dir(e)
    }
    finally {
      commit('SET_LOADING', false)
    }
  },

  async fetchVehicleAssignedTempSensBetweenDates ({ commit, state }, loading = true) {
    if (state.selectedVehicles.length) {
      if (loading) commit('SET_LOADING', true)
      try {
        const { data } = await api()[module]
          .get('temperature-sensor/assigned-sensors-between-dates-new', {
            vehicle_id: state.selectedVehicles[0].id,
            date_from: state.selectedDateFrom,
            date_to: state.selectedDateTo
          })
        commit('SET_VEHICLE_ASSIGNED_TEMP_SENS_BETWEEN_DATES', data)

        // this is used for binding sliders with values as sliders are dynamic
        // Also it is used in component TemperaturesFromDateToDate.vue
        // for checking conditions given by sliders
        Object.keys(data).forEach(sensorKey => {
          commit('SET_TEMPERATURE_RANGE', { key: sensorKey, value: [15, 42] })
        })
      }
      catch (e) {
        console.dir(e)
      }
      finally {
        if (loading) commit('SET_LOADING', false)
      }
    }
  },

  async fetchVehicleAssignedSensorsBetweenDates ({ commit, state }, loading = true) {
    if (state.selectedVehicles.length) {
      if (loading) commit('SET_LOADING', true)
      try {
        const { data } = await api()[module]
          .get('digital-sensor/assigned-sensors-between-dates', {
            vehicle_id: state.selectedVehicles[0].id,
            date_from: state.selectedDateFrom,
            date_to: state.selectedDateTo
          })
        commit('SET_VEHICLE_ASSIGNED_SENSORS_BETWEEN_DATES', data.sensors)
      }
      catch (e) {
        console.dir(e)
      }
      finally {
        if (loading) commit('SET_LOADING', false)
      }
    }
  },

  setTemperatureRange ({ commit }, data) {
    commit('SET_TEMPERATURE_RANGE', data)
  },

  setReportParams ({ commit }, params) {
    commit('SET_REPORT_PARAMS', params)
  },
  setSelectedVehicles ({ commit }, vehicles) {
    commit('SET_SELECTED_VEHICLES', vehicles)
  },
  setSelectedLocations ({ commit }, data) {
    commit('SET_SELECTED_LOCATIONS', data)
  },
  setReportChartParameterVisibility ({ commit }, columnName) {
    commit('SET_REPORT_CHART_PARAMETER_VISIBILITY', columnName)
  },
  setSelectedDeviceIdForReportFromVehicleMultiselect ({ commit }, value) {
    commit('SET_SELECTED_DEVICE_ID_FOR_REPORT_FROM_VEHICLE_MULTISELECT', value)
  },
  setSelectedDateFrom ({ commit }, vehicle) {
    commit('SET_SELECTED_DATE_FROM', vehicle)
  },
  setSelectedDateTo ({ commit }, vehicle) {
    commit('SET_SELECTED_DATE_TO', vehicle)
  },
  resetState ({ commit }) {
    commit('RESET_STATE')
  },
  setShowReferenceValues ({ commit }, payload) {
    commit('SET_SHOW_REFERENCE_VALUES', payload)
  }
}

const mutations = {

  SET_FETCHED_REPORT: (state, payload) => (state.fetchedReport = payload),
  SET_REPORTS_META_DATA: (state, payload) => (state.reportsMetaData = payload),
  SET_REPORT_PARAMS: (state, payload) => (state.reportParams = payload),
  SET_SELECTED_VEHICLES: (state, payload) => (state.selectedVehicles = payload),
  SET_SELECTED_LOCATIONS: (state, payload) => (state.selectedLocations = payload),
  SET_REPORT_CHART_PARAMETER_VISIBILITY: (state, payload) => (
    state.fetchedReport.reportColumnConfig[payload].showInChart = !state.fetchedReport.reportColumnConfig[payload].showInChart
  ),
  SET_SELECTED_DEVICE_ID_FOR_REPORT_FROM_VEHICLE_MULTISELECT: (
    state, payload) => (state.selectedDeviceIdForReportFromVehicleMultiselect = payload
  ),
  SET_VEHICLE_ASSIGNED_TEMP_SENS_BETWEEN_DATES: (state, payload) => (
    state.vehicleAssignedTempSensBetweenDates = payload
  ),

  SET_VEHICLE_ASSIGNED_SENSORS_BETWEEN_DATES: (state, payload) => (
    state.vehicleAssignedSensorsBetweenDates = payload
  ),

  SET_TEMPERATURE_RANGE: (state, payload) => (state.temperatureRanges[payload.key] = payload.value),
  SET_SELECTED_DATE_FROM: (state, payload) => (state.selectedDateFrom = payload),
  SET_SELECTED_DATE_TO: (state, payload) => (state.selectedDateTo = payload),
  SET_LOADING: (state, payload) => (state.loading = payload),
  RESET_STATE: (state) => Object.assign(state, initialState()),
  SET_SHOW_REFERENCE_VALUES: (state, payload) => (state.showReferenceValues = payload)
}

export default {
  state: initialState(),
  getters,
  actions,
  mutations
}
