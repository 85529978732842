<template>
  <div>
    <v-row
      class="d-flex justify-space-between"
    >
      <v-col>
        <v-skeleton-loader
          v-bind="attributes"
          type="button"
        />
      </v-col>
      <v-col class="d-flex justify-end">
        <v-skeleton-loader
          v-bind="attributes"
          type="button"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-skeleton-loader
          v-bind="attributes"
          type="heading"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <v-skeleton-loader
          v-bind="attributes"
          type="image"
        />
      </v-col>
      <v-col
        cols="4"
        class="mr-2"
      >
        <v-skeleton-loader
          v-bind="attributes"
          type="table-tbody"
          :types="{ 'table-tbody': 'table-row-divider@6' }"
        />
      </v-col>
      <v-col cols="4">
        <v-skeleton-loader
          v-bind="attributes"
          type="table-tbody"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1">
        <v-skeleton-loader
          v-bind="attributes"
          type="heading"
        />
      </v-col>
      <v-col cols="1">
        <v-skeleton-loader
          v-bind="attributes"
          type="heading"
        />
      </v-col>
      <v-col cols="1">
        <v-skeleton-loader
          v-bind="attributes"
          type="heading"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10">
        <v-skeleton-loader
          v-bind="attributes"
          type="table-tbody"
          :types="{ 'table-tbody': 'table-row-divider@3' }"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ShowPageSkeleton',

  data () {
    return {
      attributes: {
        class: 'mb-2',
        boilerplate: true
      }
    }
  }
}
</script>
