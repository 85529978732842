<template>
  <v-autocomplete
    v-model="selectedItems"
    :items="internalItems"
    :label="label"
    :clearable="clearable"
    :search-input.sync="itemSearch"
    :error-messages="errorMessages"
    :no-filter="true"
    :loading="fetchingData"
    outlined
    dense
    multiple
  >
    <template #append-item>
      <v-list-item
        v-if="hasMoreItems"
        class="has-more-items"
        @click="makeSearch('', true)"
      >
        <v-list-item-title class="font-italic">
          {{ $t('base.got_more_items') }}
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import { debounce, has, map } from 'lodash'
import { api } from '@/global/services/api'

export default {
  name: 'Many2manyAutocomplete',

  props: {
    value: {
      type: Array,
      default: () => []
    },

    label: {
      type: String,
      required: true
    },

    options: {
      type: Object,
      required: true,
      validator: (options) => {
        return has(options, 'module') && has(options, 'route')
      }
    },

    clearable: {
      type: Boolean,
      default: true
    },

    items: {
      type: Array,
      default: () => ([])
    },

    errorMessages: {
      type: Array,
      default: () => ([])
    }
  },

  data () {
    return {
      itemSearch: null,
      fetchingData: false,
      hasMoreItems: false
    }
  },

  computed: {
    selectedItems: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value || [])
      }
    },

    internalItems: {
      get () {
        return this.items
      },

      set (value) {
        this.$emit('update:items', value)
      }
    }
  },

  watch: {
    itemSearch: debounce(function (newVal) {
      this.fetchingData = true
      this.makeSearch(newVal).finally(() => (this.fetchingData = false))
    }, 1000),

    value () {
      this.makeSearch()
    }
  },

  async created () {
    try {
      this.fetchingData = true
      await this.makeSearch('')
    }
    finally {
      this.fetchingData = false
    }
  },

  methods: {
    async makeSearch (query, allItems = false) {
      let value = this.value
      // Check if value is array of objects, happens on first open of edit dialog,
      // and if so, map array of objects to array of ids
      if (value?.some(item => typeof item === 'object')) {
        value = value.map(item => item.id)
      }

      const params = {
        query: query ?? '',
        pickedId: value ?? null,
        includeAll: allItems
      }

      const {
        data,
        has_more: hasMore = false
      } = await api()[this.options.module].get(this.options.route, params)

      this.internalItems = map(data, ({ id, name }) => ({
        value: parseInt(id),
        text: name
      }))

      this.hasMoreItems = hasMore
    }
  }
}
</script>

<style>
.has-more-items {
  cursor: pointer !important;
}
</style>
