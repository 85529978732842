export default {
  path: '/forgot-password',
  name: 'ForgotPassword',
  component: () => import(/* webpackChunkName: "base/login" */ '../views/ForgotPassword.vue'),
  meta: {
    title: 'base.forgotPassword',
    exclude: true,
    layout: 'login',
    requiresAuth: false
  }
}
