<template>
  <v-row>
    <v-menu
      offset-y
      :close-on-content-click="false"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          color="grey"
          outlined
          v-bind="attrs"
          v-on="on"
        >
          {{ menuTitle }}
          <v-icon
            right
            dark
          >
            {{ attrs['aria-expanded'] === 'true' ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
        </v-btn>
      </template>
      <v-col
        v-for="item in items"
        :key="item.id"
        class="checkbox-background"
      >
        <v-checkbox
          v-model="item.value"
          :label="item.text"
          class="ma-0 pa-0"
          hide-details
        />
      </v-col>
    </v-menu>
  </v-row>
</template>

<script>
export default {
  name: 'CheckboxListMenu',

  props: {
    menuTitle: {
      type: String,
      default: 'Dropdown'
    },

    items: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>

<style scoped lang="scss">
.has-more-items {
  cursor: pointer !important;
}
.checkbox-background {
  background: #fff !important;
}
</style>
