import { api } from '@/global/services/api'

const state = {
  company: [],
  viewConfig: {}
}

const getters = {
  company: state => state.company,
  viewConfig: state => state.viewConfig
}

const actions = {
  async fetch ({ commit }) {
    try {
      const { config, data } = await api().base.get('company')

      commit('SET_VIEW_CONFIG', config)
      commit('SET_COMPANY', data)
    }
    catch (e) {
      console.dir(e)
    }
  },

  setCompanyGeolocation ({ commit }, value) {
    commit('SET_COMPANY_GEOLOCATION', value)
  },
  removeCompanyGeolocation ({ commit }, index) {
    commit('REMOVE_COMPANY_GEOLOCATION', index)
  }
}

const mutations = {
  SET_COMPANY: (state, payload) => (state.company = payload),
  SET_VIEW_CONFIG: (state, payload) => (state.viewConfig = payload),
  SET_COMPANY_GEOLOCATION (state, payload) {
    state.company.geolocation = []
    state.company.geolocation.push(payload)
  },
  REMOVE_COMPANY_GEOLOCATION (state, index) {
    state.company.geolocation.splice(index, 1)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
