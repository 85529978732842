import groups from '@/modules/fleet-module/groups/routes/index.child'
import vehicle from '@/modules/fleet-module/vehicles/routes/show.child'
import vehicleAlarms from '@/modules/fleet-module/vehicle-alarms/routes/index.child'
import vehicleTags from '@/modules/fleet-module/vehicle-tags/routes/index.child'
import vehicles from '@/modules/fleet-module/vehicles/routes/index.child'
import communication from '@/modules/fleet-module/communication/routes/index.child'
import prlCargo from '@/modules/fleet-module/prl-cargo/routes/index.child'
import prlCargoDetailsView from '@/modules/fleet-module/prl-cargo/routes/show.child'
import machineOperation from '@/modules/fleet-module/machine-operation/routes/index.child'
import disposition from '@/modules/fleet-module/disposition/routes/index.child'
import documentsForSignature from '@/modules/fleet-module/documents-for-signature/routes/index.child'
import department from '@/modules/fleet-module/department/routes/index.child'
import organizationalUnit from '@/modules/fleet-module/organizational-unit/routes/index.child'

export default {
  path: '/fleet',
  redirect: {
    name: 'NotFound',
    params: ['/fleet']
  },
  component: () => import(/* webpackChunkName: "fleet" */ '../views/FleetView.vue'),
  meta: {
    exclude: false,
    requiresAuth: true
  },
  children: [
    groups,
    vehicle,
    vehicleAlarms,
    vehicleTags,
    vehicles,
    communication,
    prlCargo,
    machineOperation,
    disposition,
    documentsForSignature,
    department,
    organizationalUnit,
    prlCargoDetailsView
  ]
}
