/**
 * @param {Object[]} messages
 * @param {string} messages[].message
 * @param {int} messages[].id
 *
 */

const state = {
  messages: [],
  messageId: 0 // Max notifications: Number.MAX_SAFE_INTEGER (9007199254740991) most likely users will never hit this number
}

const getters = {
  messages: (state) => state.messages,

  lastId: (state) => state.messageId
}

const actions = {

  push: function ({ getters, commit }, message) {
    commit('INCREMENT_ID')
    const lastId = getters.lastId
    commit('APPEND_NOTIFICATION', { message, lastId })

    return lastId
  },

  remove: function ({ commit }, messageId) {
    try {
      commit('REMOVE_NOTIFICATION', messageId)
      return true
    }
    catch (e) {
      return false
    }
  },

  updateMessages ({ commit }, messages) {
    commit('UPDATE_MESSAGES', messages)
  }

}

const mutations = {
  INCREMENT_ID: (state) => (state.messageId++),

  UPDATE_MESSAGES: (state, payload) => (state.messages = payload),

  APPEND_NOTIFICATION: (state, { message, lastId: id }) => state.messages.push({ message, id }),

  REMOVE_NOTIFICATION: (state, messageId) => (state.messages = state.messages.filter(message => message.id !== messageId))
}

export default {
  state,
  getters,
  actions,
  mutations
}
