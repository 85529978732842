<template>
  <component
    :is="inputType"
    v-bind="combinedAttrs"
    v-on="inputListeners"
    @change="handleChange"
  />
</template>

<script>
import {
  VCheckbox,
  VDatePicker,
  VSelect,
  VTextField
} from 'vuetify/lib'
import Autocomplete from '@/modules/base-module/autocomplete/components/Autocomplete'
import AutocompleteCombobox from '@/global/components/filters/AutocompleteCombobox'
import CustomDatePicker from '@/global/components/filters/CustomDatePicker'
import DatepickerRange from '@/global/components/dates/datepicker-range/DatepickerRange'
import DependantAutocompletes from '@/global/components/autocompletes/dependant-autocompletes/DependantAutocompletes'
import { supportedTypes, typesMap } from '@/global/components/filters/typesMap'

export default {
  name: 'FilterDialogItem',

  components: {
    Autocomplete,
    AutocompleteCombobox,
    CustomDatePicker,
    DatepickerRange,
    DependantAutocompletes,
    VCheckbox,
    VDatePicker,
    VSelect,
    VTextField
  },

  props: {
    clearable: {
      type: Boolean,
      default: true
    },

    type: {
      type: String,
      required: true,
      validator: (value) => supportedTypes.indexOf(value) !== -1
    },

    name: {
      type: String,
      required: true
    },

    dependants: {
      type: Object,
      default: () => ({})
    },

    dateRange: {
      type: Array,
      default: () => ([])
    },

    resetData: {
      type: Boolean,
      default: false
    },

    columnConfig: {
      type: Object,
      required: true
    }
  },

  computed: {
    combinedAttrs () {
      // TODO: Refactor this so that every component gets only parameters that are needed
      const props = {
        clearable: this.clearable,
        resetData: this.resetData,
        name: this.name,
        label: this.label,
        dependants: this.dependants,
        includeAll: true,
        viewLabel: true,
        hideDetails: true,
        storeSuffix: 'filters',
        outlined: true,
        dense: true,
        class: 'py-3'
      }

      if (this.type === 'datepicker_range' && this.columnConfig.allow_one_date) {
        props.allowOneDate = true
        props.dateRangeType = this.columnConfig.date_range_type
      }

      return Object.assign(props, this.$attrs)
    },

    inputType () {
      return typesMap[this.type]
    },

    inputListeners () {
      return Object.assign({},
        this.$listeners,
        {
          input: event => {
            this.$emit('input', event)
          },
          'set-datepicker-filter': event => {
            this.$emit('set-datepicker-filter', event)
          }
        }
      )
    }
  },

  watch: {
    resetData () {
      // Reset depIds object values when resetData is true for `dependant_autocompletes` components.
      if (this.type === 'dependant_autocompletes' && this.resetData) {
        this.$emit('reset-dep-ids')
      }
    }
  },

  methods: {
    handleChange (event) {
      if (this.type === 'checkbox') this.$emit('input', event)
    }
  }
}
</script>
