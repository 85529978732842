import partners from '@/modules/administration-module/partners/routes/index.child'
import partner from '@/modules/administration-module/partners/routes/show.child'
import partnerLocations from '@/modules/administration-module/partner-locations/routes/index.child'
import partnerGroups from '@/modules/administration-module/partner-groups/routes/index.child'
import expenses from '@/modules/administration-module/expenses/routes/index.child'

export default {
  path: '/administration',
  redirect: {
    name: 'NotFound',
    params: ['/administration']
  },
  component: () => import(/* webpackChunkName: "administration" */ '../views/AdministrationView.vue'),
  meta: {
    exclude: false,
    requiresAuth: true
  },
  children: [
    partners,
    partner,
    partnerLocations,
    partnerGroups,
    expenses
  ]
}
