export const convertTimestampToDate = (timestamp) => {
  const date = new Date(Number(timestamp))

  date.fullTime = `${date.getHours()}`.padStart(2, '0') + ':' + `${date.getMinutes()}`.padStart(2, '0') + ':' + `${date.getSeconds()}`.padStart(2, '0')
  date.fullDate = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`

  return date
}

export const convertDateTimeToTimestamp = (date, time) => {
  const splitDate = date.split('.')
  const properDate = `${splitDate[1]}/${splitDate[0]}/${splitDate[2]}`

  return Date.parse(`${properDate} ${time}`)
}

export const convertSecondsToTimestamp = (seconds) => {
  const date = new Date(Date.now() + (seconds * 1000))
  return date.getTime()
}

export const getPassedSecondsFromDate = (date) => {
  var dateTime = Date.parse(date)
  var now = new Date().getTime()
  return Math.abs((now - dateTime) / 1000)
}
