import { api } from '@/global/services/api'
import { cloneDeep } from 'lodash'

function initialState () {
  return {
    fetchedReport: {},
    reportsMetaData: [],
    selectedReportType: null,
    selectedPerson: null,
    selectedPersons: [],
    chartDataOptions: {
      autoCompletes: [null, null, null, null],
      showAutoCompletesInChart: [false, false, false, false]
    },
    chartType: 'line',
    reportParams: {},
    loading: false
  }
}

const getters = {
  fetchedReport: state => state.fetchedReport,
  getReportsMetaData: state => state.reportsMetaData,
  getReportParams: state => state.reportParams,
  getSelectedReportType: state => state.selectedReportType,
  getSelectedPerson: state => state.selectedPerson,
  getSelectedPersons: state => state.selectedPersons,
  getChartDataOptions: state => state.chartDataOptions,
  getChartType: state => state.chartType,
  loading: state => state.loading
}

const module = 'satellite-tracking'
const route = 'driver-report-new'

const actions = {

  async fetchReportsMetaData ({ commit }, options = {}) {
    commit('SET_LOADING', true)

    try {
      const res = await api()[module].get('drivers-report-meta-data', options)
      commit('SET_REPORTS_META_DATA', res)
    }
    catch (e) {
      console.dir(e)
    }
    finally {
      commit('SET_LOADING', false)
    }
  },
  async fetchReport ({ commit, state }) {
    commit('SET_LOADING', true)

    try {
      const res = await api()[module].get(route, state.reportParams)
      commit('SET_FETCHED_REPORT', res)
    }
    catch (e) {
      console.dir(e)
    }
    finally {
      commit('SET_LOADING', false)
    }
  },
  setReportParams ({ commit }, params) {
    commit('SET_REPORT_PARAMS', params)
  },
  setSelectedReportType ({ commit }, reportType) {
    commit('SET_SELECTED_REPORT_TYPE', reportType)
    commit('SET_FETCHED_REPORT', {})
  },
  setSelectedPerson ({ commit }, person) {
    commit('SET_SELECTED_PERSON', person)
  },
  setSelectedPersons ({ commit }, persons) {
    commit('SET_SELECTED_PERSONS', persons)
  },
  setReportChartParameterVisibility ({ commit }, columnName) {
    commit('SET_REPORT_CHART_PARAMETER_VISIBILITY', columnName)
  },
  setChartDataOptions ({ commit }, options) {
    commit('SET_CHART_DATA_OPTIONS', cloneDeep(options))
  },
  setChartType ({ commit }, value) {
    commit('SET_CHART_TYPE', value)
  },
  resetState ({ commit }) {
    commit('RESET_STATE')
  }
}

const mutations = {
  SET_FETCHED_REPORT: (state, payload) => (state.fetchedReport = payload),
  SET_REPORTS_META_DATA: (state, payload) => (state.reportsMetaData = payload),
  SET_REPORT_PARAMS: (state, payload) => (state.reportParams = payload),
  SET_SELECTED_REPORT_TYPE: (state, payload) => (state.selectedReportType = payload),
  SET_SELECTED_PERSON: (state, payload) => (state.selectedPerson = payload),
  SET_SELECTED_PERSONS: (state, payload) => (state.selectedPersons = payload),
  SET_REPORT_CHART_PARAMETER_VISIBILITY: (state, payload) => (state.fetchedReport.reportColumnConfig[payload].showInChart = !state.fetchedReport.reportColumnConfig[payload].showInChart),
  SET_CHART_DATA_OPTIONS: (state, payload) => (state.chartDataOptions = payload),
  SET_CHART_TYPE: (state, payload) => (state.chartType = payload),
  SET_LOADING: (state, payload) => (state.loading = payload),
  RESET_STATE: (state) => Object.assign(state, initialState())
}

export default {
  state: initialState(),
  getters,
  actions,
  mutations
}
