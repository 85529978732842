import { api } from '@/global/services/api'

const state = {
  item: {}
}

const getters = {
  item: state => state.item
}

const actions = {
  async fetchWorkOrder ({ commit, state }, id) {
    try {
      if (!id) {
        id = 0
      }
      const { data } = await api('road-maintenance').get(`work-orders/${id}`)

      commit('SET_ITEM', data)
    }
    catch (e) {
      console.dir(e)
    }
  }
}

const mutations = {
  SET_ITEM: (state, payload) => (state.item = payload)
}

export default {
  state,
  getters,
  actions,
  mutations
}
