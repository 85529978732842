<template>
  <div>
    <v-btn
      color="primary"
      class="white--text px-3"
      @click="openCompanyScopeDialog"
      v-text="toggleButtonText"
    />

    <v-dialog
      v-model="companyScopeDialogOpen"
      content-class="elevation-0"
      style="z-index: 600"
      :width="defaultDialogSize[$vuetify.breakpoint.name]"
      @keydown.enter.prevent="saveCompanyScope"
      @keydown.esc.prevent="closeCompanyScopeDialog"
    >
      <v-card
        @click:outside="closeCompanyScopeDialog"
      >
        <v-row class="flex justify-space-between ma-0">
          <v-card-title>
            {{ companyScope.dialog.title }}
          </v-card-title>

          <v-btn
            text
            x-small
            class="mt-3 mr-2 pa-0 no-background-hover"
            elevation="0"
            @click="closeCompanyScopeDialog"
          >
            <v-icon
              color="grey darken-1"
              size="1.8rem"
            >
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>

        <v-card-text
          v-if="companyScopeDialogOpen"
          class="mt-6"
        >
          <autocomplete
            v-if="companyScope.config"
            v-model="companyScopeId"
            company-scope
            :label="companyScope.config.label"
            :hide-details="true"
            :error-messages="validationErrors['companyScopeId']"
            :clearable="true"
            :items.sync="descendantCompanies"
            :options="companyScope.config.autocomplete_options"
            :autofocus="true"
            :openMenuAutomatically="true"
            @input="clearInputValidationErrors('companyScopeId')"
            @fetch="companyScopeResults = $event"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="grey darken-1"
            text
            @click="closeCompanyScopeDialog"
          >
            {{ companyScope.dialog.button_cancel }}
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="saveCompanyScope"
          >
            {{ companyScope.dialog.button_save }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { api } from '@/global/services/api'
import { createNamespacedHelpers } from 'vuex'
import Autocomplete from '@/modules/base-module/autocomplete/components/Autocomplete'
const { mapActions } = createNamespacedHelpers('base/config')

export default {
  name: 'CompanyScope',

  components: {
    Autocomplete
  },

  props: {
    companyScope: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      companyScopeId: null,
      companyScopeDialogOpen: false,
      validationErrors: {},
      descendantCompanies: [],
      companyScopeResults: [],
      defaultDialogSize: { xl: '55.1%', lg: '55.1%', md: '50%', sm: '66.66%', xs: '100%' }
    }
  },

  computed: {
    toggleButtonText () {
      return this.companyScope.company?.name || this.companyScope.dialog.button_toggle
    }
  },

  watch: {
    companyScope: {
      deep: true,
      immediate: true,
      handler (value) {
        if (value.company?.id) {
          this.companyScopeId = value.company.id
        }
        else {
          this.companyScopeId = null
        }
      }
    }
  },

  methods: {
    ...mapActions(['updateCompanyScope']),

    openCompanyScopeDialog () {
      this.companyScopeDialogOpen = true
    },

    closeCompanyScopeDialog () {
      this.companyScopeDialogOpen = false
    },

    async saveCompanyScope () {
      this.validationErrors = {}

      try {
        const data = {
          secondary_company_id: this.companyScopeId
        }

        await api('base').patch('company-scope', data)
          .then((data) => {
            this.closeCompanyScopeDialog()
            this.updateCompanyScope(data)
          })
      }
      catch (e) {
        this.validationErrors = e.getValidationErrors()
      }
    },

    clearInputValidationErrors (key) {
      if (this.validationErrors[key]) {
        this.validationErrors[key] = []
      }
    }
  }
}
</script>
