import File from './file'
import isEmpty from 'lodash/isEmpty'
import { isModuleInFinalBuild } from '@/global/moduleList'

// noinspection JSUnresolvedFunction
const requireContext = require.context('@/modules', true, /\.js$/)

export default class ModuleDirectoryScanner {
  files = []

  constructor () {
    this.files = requireContext
      .keys()
      .map(fileFullPath => this._createFileObject(fileFullPath))

    this._dropFilesOfModulesLeftOutFromFinalBuild()
    this._loadFileContents()
  }

  _only (callback) {
    this.files = this.files.filter(file => callback(file))
  }

  _createFileObject (fileFullPath) {
    return new File(fileFullPath)
  }

  _dropFilesOfModulesLeftOutFromFinalBuild () {
    this._only(file => isModuleInFinalBuild(file.getModuleName()))
  }

  _loadFileContents () {
    this.files.map(file => {
      const fileContent = requireContext(file.getFullPath())

      file.setContent(fileContent.default || fileContent)
    })
  }

  withoutEmptyFiles () {
    this._only(file => !isEmpty(file.getContent()))

    return this
  }

  getAllFiles () {
    return this.files
  }
}
