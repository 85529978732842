import ModuleDirectoryScanner from '../index'
import RouteFile from './route-file'

export default class RouteScanner extends ModuleDirectoryScanner {
  constructor () {
    super()

    this._routesOnly()
  }

  /** @override */
  _createFileObject (fileFullPath) {
    return new RouteFile(fileFullPath)
  }

  _routesOnly () {
    this._only(file => file.getFullPath().includes('routes/'))
  }

  withoutChildRoutes () {
    this._only(file => file.isChild() === false)

    return this
  }

  toFormatVueRouterUnderstands () {
    return this.files.map(file => file.getContent())
  }
}
