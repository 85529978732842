<template>
  <v-menu
    v-if="anyModifiableColumns"
    :close-on-content-click="false"
    offset-y
    offset-overflow
    left
  >
    <template #activator="{ on, attrs }">
      <v-icon
        link
        v-bind="attrs"
        v-on="on"
      >
        mdi-dots-vertical
      </v-icon>
    </template>

    <v-list
      subheader
      flat
    >
      <v-subheader>{{ $t('base.choose_columns') }}</v-subheader>

      <v-list-item-group
        v-model="visibleColumns"
        multiple
      >
        <v-list-item
          class="list-item-min-height"
          v-for="({label, alwaysVisible = false}, name) in columns"
          :key="name"
          :value="name"
          :disabled="alwaysVisible"
          @click.self="handleLabelClick(name)"
        >
          <template #default="{ active }">
            <v-list-item-action class="my-1 mr-4">
              <v-checkbox
                :input-value="active"
                :disabled="alwaysVisible"
                :ref="name"
                color="primary"
                @change="event => setColumnVisibility(name, event)"
              />
            </v-list-item-action>

            <v-list-item-title class="main-font-size" @click.self="handleLabelClick(name)">
              {{ label }}
            </v-list-item-title>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { has, pickBy } from 'lodash'
import { createNamespacedHelpers } from 'vuex'

const { mapActions: mapActionsConfig } = createNamespacedHelpers('base/config')

export default {
  name: 'ColumnVisibility',

  props: {
    columns: {
      type: Object,
      required: true,
      validator: (value) => {
        return Object.values(value).every(column => {
          // Required
          const hasLabel = has(column, 'label')

          // Optional
          const hasAlwaysVisible = !has(column, 'alwaysVisible') ? true : typeof column.alwaysVisible === 'boolean'
          const hasInitiallyVisible = !has(column, 'initiallyVisible') ? true : typeof column.initiallyVisible === 'boolean'

          return hasLabel && hasAlwaysVisible && hasInitiallyVisible
        })
      }
    },

    viewName: {
      type: String,
      required: true
    },

    oldActiveColumns: {
      type: Array,
      default: () => []
    },

    value: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  computed: {
    visibleColumns: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },

    initialColumns () {
      return Object.keys(
        pickBy(this.columns, column => column.alwaysVisible === true || column.initiallyVisible === true)
      )
    },

    anyModifiableColumns () {
      return Object.values(this.columns).some(column => !column.alwaysVisible)
    }
  },

  watch: {
    columns () {
      this.visibleColumns = [...this.initialColumns]
    },

    oldActiveColumns (newVal) {
      this.visibleColumns = [...newVal]
    }
  },

  created () {
    this.visibleColumns = [...this.initialColumns]
  },

  methods: {
    ...mapActionsConfig(['updateUserConfigKey']),

    setColumnVisibility (columnName, event) {
      const payload = {
        key: `${this.viewName}.columns.${columnName}.initially_visible`,
        value: event
      }
      this.updateUserConfigKey(payload)
    },

    handleLabelClick (columnName) {
      // Use small timeout because $nextTick did not work as expected
      setTimeout(() => {
        // Column name ref will be array because it is rendered in the loop, we need to grab first item
        this.setColumnVisibility(columnName, this.$refs[columnName][0].inputValue)
      }, 100)
    }
  }
}
</script>
