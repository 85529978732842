<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    dark
    class="background-gradient"
    app
  >
    <v-app-bar-nav-icon
      class="hamburger-menu"
      @click="hamburgerClicked"
    />

    <v-toolbar-title
      class="mr-12 d-inline-flex align-center"
      style="max-height: 100%"
    >
      <router-link
        v-if="$vuetify.breakpoint.name !== 'xs'"
        :to="{ path: userHomePagePath }"
        class="d-inline-flex align-center"
      >
        <img
          :src="logoSmall"
          alt="Logo"
          class="logo"
        >
      </router-link>
    </v-toolbar-title>

    <v-spacer />

    <CompanyScope
      v-if="companyScope"
      :company-scope="companyScope"
      class="mr-4"
    />

    <AvatarMenu
      v-slot="{ listeners, attributes, open: isOpen }"
      :user-menu="userMenu"
    >
      <v-btn
        class="text-capitalize px-3 py-6"
        text
        v-bind="attributes"
        v-on="listeners"
      >
        <img
          :src="publicImage(user.avatar_url)"
          style="height: 1.75rem"
          alt=""
        >
        <span class="d-inline-block mr-1 ml-2">
          <span class="hidden-sm-and-down">{{ user.first_name }} {{ user.last_name }}</span>
        </span>
        <v-icon class="hidden-sm-and-down">
          {{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </v-btn>
    </AvatarMenu>
  </v-app-bar>
</template>
<script>
import AvatarMenu from '@/modules/base-module/avatar-menu/components/AvatarMenu'
import CompanyScope from '@/modules/base-module/company-scope/components/CompanyScope'
import { createNamespacedHelpers } from 'vuex'
import { defaultAvatarUrl } from '@/global/common/imagePaths'

const { mapGetters, mapActions } = createNamespacedHelpers('base/config')

export default {
  name: 'TheAppBar',

  components: {
    AvatarMenu,
    CompanyScope
  },

  props: {
    refetch: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      userMenu: 'userMenu',
      logoSmall: 'logoSmall',
      companyScope: 'companyScope'
    }),

    userHomePagePath () {
      // When home page is equal to `/`, this will result in two slashes `//`. The regex inside
      // `replace` method converts multiple slashes into one to avoid navigation errors.
      return `/${this.user.home_page}`.replace(/\/\/+/g, '/')
    }
  },

  watch: {
    refetch (refetch) {
      if (refetch) {
        this.fetch()
      }
    }
  },

  methods: {
    ...mapActions(['fetch']),

    hamburgerClicked () {
      this.$emit('hamburger-toggle')
      if (!this.$store.state.isMenuNavOpen) {
        this.$store.commit('toggleMenuNav', true)
      }
      else {
        this.$store.commit('toggleMenuNav', false)
      }
    },

    publicImage (imageUrl) {
      return imageUrl ?? defaultAvatarUrl
    }
  }
}
</script>

<style scoped>
.logo {
  height: 40px;
  width: auto;
  object-fit: contain;
}

.background-gradient{
  background: linear-gradient(to right, #171E27, #323C44);
}

@media (max-width: 960px) {
  .logo {
    height: 35px;
  }
}
</style>
