import { formatSqlDate } from '@/global/services/helpers/dates'
import {
  cloneDeep,
  isArray,
  isEmpty, isObject,
  mapValues,
  pickBy
} from 'lodash'

const filtersMixin = {
  computed: {
    filterText () {
      // Return string only if this.hasValue is true.
      if (this.hasValue) {
        const label = this.label ? this.label : this.dependantLabel ?? ''
        const separator = ': '
        return `${label}${separator}${this.processedValue}`
      }

      return ''
    },

    hasValue () {
      if (isArray(this.value)) {
        return this.value.length > 0
      }
      if (isObject(this.value)) {
        return !Object.values(this.value).every(keyValue => [null, undefined].includes(keyValue))
      }

      return this.value === 0 ? true : !!this.value
    },

    processedValue () {
      let value = this.value

      if (!isEmpty(this.possibleValues) && !isArray(value)) {
        value = (this.possibleValues.find(({ value }) => value === this.value) || {}).text || ''
      }

      if (typeof value === 'boolean') {
        value = value ? this.$t('base.yes') : this.$t('base.no')
      }
      else if (isArray(value) && value.length) {
        value = value.map(value => value.text).join(', ')
      }
      else if (typeof value === 'object' && this.type === 'datepicker_range') {
        const dateFrom = value.from_date ? formatSqlDate(value.from_date) : null
        const dateTo = value.to_date ? formatSqlDate(value.to_date) : null
        if (dateFrom) {
          value = dateFrom
          if (dateTo) value += ` - ${dateTo}`
        }
        else {
          value = dateTo
        }
      }
      // For dependant autocompletes value is object with foreign ID and text fields.
      else if (typeof value === 'object' && this.type === 'dependant_autocompletes') {
        value = this.dependsOnText
        if (this.dependantText) value += ` / ${this.dependantText}`
      }
      else if (this.type === 'date') {
        value = formatSqlDate(value)
      }

      return value
    }
  },

  methods: {
    getActiveFiltersPayload () {
      const filters = cloneDeep(this.internalFilters)
      const activeFilters = pickBy(filters, (value, filter) => value.activeStatus)

      for (const activeFilter of Object.values(activeFilters)) {
        if (activeFilter.type === 'dependant_autocompletes') {
          activeFilter.value = this.depIds
        }
      }

      // If there are no filters reset internal data in
      // autocomplete_combobox and dependant autocompletes components
      if (!Object.values(activeFilters).length) {
        for (const [key, { type }] of Object.entries(this.internalFilters)) {
          if (['autocomplete_combobox'].includes(type)) {
            this.setResetData(key, true)
          }
        }
      }
      const payload = mapValues(activeFilters, ({ value }) => {
        if (Array.isArray(value)) value = value.map(item => item.value)
        return (typeof value === 'undefined' ? null : value)
      })
      // If status filter on vehicles is selected, we add device ignition statuses to payload
      if ('status_filter' in payload && payload.status_filter !== null) {
        payload.device_statuses = this.deviceIgnitionStatuses
      }

      return payload
    }
  }
}

export default filtersMixin
