<template>
  <v-sheet
    tile
    class="transparent d-flex align-self-stretch align-center"
  >
    <v-menu
      offset-y
      content-class="elevation-5"
      z-index="600"
      :close-on-content-click="false"
    >
      <template #activator="{ on, attrs, value }">
        <slot
          :listeners="on"
          :attributes="attrs"
          :open="value"
        />
      </template>

      <v-list>
        <span
          v-for="(menu, i) in userMenu"
          :key="`user-menu-option-${i + 1}`"
        >
          <v-divider v-if="checkForDivider(menu, 'before')" />

          <component
            :is="menu.type_component"
            :menu="menu"
          />

          <v-divider v-if="checkForDivider(menu,'after')" />
        </span>
      </v-list>
    </v-menu>
  </v-sheet>
</template>

<script>
import ActionOption from './ActionOption'
import SelectOption from './SelectOption'
import LinkOption from './LinkOption'
import ModalOption from './ModalOption'
import ExternalLinkOption from './ExternalLinkOption'

export default {
  name: 'AvatarMenu',

  components: {
    SelectOption,
    ActionOption,
    ModalOption,
    LinkOption,
    ExternalLinkOption
  },

  props: {
    userMenu: {
      type: Array,
      default: () => ([])
    }
  },

  data () {
    return {}
  },

  methods: {
    checkForDivider (menu, where) {
      return 'dividers' in menu && menu.dividers.includes(where)
    }
  }
}
</script>
