const moduleList = [
  'base',

////////////////////////////////////////////////////////
  'fleet',
////////////

/////////////////////////////////////////////////////////
  'people',
////////////

////////////////////////////////////////////////////////////
  'locations',
////////////

/////////////////////////////////////////////////////////////////////
  'satellite-tracking',
////////////

/////////////////////////////////////////////////////////////////
  'administration',
////////////

//////////////////////////////////////////////////////
  'sms',
////////////
  'road-maintenance'
]

export default moduleList

export function isModuleInFinalBuild (moduleName) {
  return moduleList.includes(moduleName)
}
