<template>
  <div>
    <v-tabs
      v-model="tab"
      grow
      hide-slider
    >
      <v-tab>
        <v-icon>mdi-calendar</v-icon>
      </v-tab>
      <v-tab>
        <v-icon>mdi-clock</v-icon>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <template #activator="{ on, attrs }">
          <v-text-field
            v-model="dateFormatted"
            readonly
            hide-details
            v-bind="attrs"
            v-on="on"
            @blur="parseDmyDate(dateFormatted)"
          />
        </template>
        <v-date-picker
          v-model="date"
          v-bind="$attrs"
          :min="minDate"
          :max="maxDate"
          @dblclick:date="onDoubleClick"
          class="rounded-0"
          first-day-of-week="1"
        />
      </v-tab-item>
      <v-tab-item>
        <v-time-picker
          v-if="resetTabs"
          v-model="timeComputed"
          v-bind="$attrs"
          :max="maxTime"
          class="rounded-0"
          format="24hr"
          scrollable
        />
      </v-tab-item>
    </v-tabs-items>
    <v-row no-gutters>
      <slot />
    </v-row>
  </div>
</template>

<script>
import {
  formatIsoDate,
  formatIsoString,
  formatIsoTime,
  formatSqlDateTime,
  parseDmyDate
} from '@/global/services/helpers/dates'
import dayjs from 'dayjs'
import withoutWatchersMixin from '@/global/mixins/withoutWatchersMixin'

export default {
  name: 'DatetimePicker',

  mixins: [withoutWatchersMixin],

  inheritAttrs: false,

  props: {
    value: {
      type: String,
      default: null
    },

    emitDefaultTime: {
      type: Boolean,
      default: false
    },

    defaultTime: {
      type: String,
      default: null
    },

    minDate: {
      type: String,
      default: undefined
    },

    maxDate: {
      type: String,
      default: undefined
    },

    maxTime: {
      type: String,
      default: undefined
    },

    resetTabs: {
      type: Boolean,
      default: false
    },

    onDoubleClick: {
      type: Function,
      default: function () {}
    }
  },

  data: () => ({
    tab: null,
    date: null,
    dateFormatted: null,
    time: null,
    datetime: null,
    escapeEnterButtonPressed: false
  }),

  computed: {
    timeComputed: {
      get: function () {
        return this.time
      },

      set: function (time) {
        if (this.maxTime) {
          const maxDateTime = dayjs(this.date + ' ' + this.maxTime)
          const newDateTime = dayjs(this.date + ' ' + time)
          this.time = newDateTime.isAfter(maxDateTime) ? this.maxTime : time
        }
        else {
          this.time = time
        }
      }
    }
  },

  watch: {
    escapeEnterButtonPressed (escapeEnterButtonPressed) {
      if (escapeEnterButtonPressed) {
        this.$emit('escape-enter-button-pressed')
      }
    },

    maxTime: {
      handler (maxTime) {
        this.time = maxTime
      }
    },

    resetTabs (resetTabs) {
      if (!resetTabs) this.tab = 0
    },

    defaultTime (defaultTime) {
      const dateTime = dayjs(this.date + ' ' + this.time)
      const defaultDateTime = dayjs(this.date + ' ' + defaultTime)
      if (defaultDateTime.isBefore(dateTime)) this.time = defaultTime
    },

    $props: {
      immediate: true,
      handler () {
        this.validateDefaultTime()
      }
    },

    value: {
      immediate: true,
      handler () {
        this.updatePickers()
      }
    },

    date (date) {
      this.dateFormatted = formatSqlDateTime(date)
      this.time = this.maxTime

      this.update()
    },

    time () {
      this.update()
    }
  },

  mounted () {
    document.addEventListener('keydown', this.enterAndEscapeEventHandler)

    this.updatePickers()
  },

  beforeDestroy () {
    document.removeEventListener('keydown', this.enterAndEscapeEventHandler)
  },

  methods: {
    parseDmyDate,

    update () {
      const dateAndTime = this.date && this.time
      const emitDateTimeCondition = this.emitDefaultTime ? !!this.date : dateAndTime
      this.datetime = emitDateTimeCondition ? formatIsoString(this.date + ' ' + this.time) : null
      if (this.datetime) this.$emit('input', this.datetime)
    },

    closePicker () {
      this.$emit('close-picker')
    },

    updatePickers () {
      if (this.value && Date.parse(this.value) !== Date.parse(this.datetime)) {
        this.date = formatIsoDate(this.value)
        this.time = formatIsoTime(this.value)
      }
      else {
        if (this.defaultTime && !this.time) this.time = this.defaultTime
      }
    },

    validateDefaultTime () {
      if (this.emitDefaultTime && !this.defaultTime) {
        console.error('default-time prop must be defined when emit-default-time prop is set to true.')
      }
    },

    enterAndEscapeEventHandler (event) {
      if (['Enter', 'Escape'].includes(event.key)) {
        this.closePicker()
        this.escapeEnterButtonPressed = true
      }
    }
  }
}
</script>
