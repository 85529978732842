<template>
  <v-progress-circular
    size="60"
    width="3"
    color="primary"
    indeterminate
  />
</template>

<script>
export default {
  name: 'Spinner'
}
</script>
