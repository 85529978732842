export default {
  path: 'excavation-service/excavation-settings/responsible_person_of_the_investor',
  name: 'ExcavationSettingsResponsiblePersonOfTheInvestor',
  meta: {
    title: 'road-maintenance/dropdown-types.investor_responsible_person',
    create_title: 'road-maintenance/excavation-dropdown-type.create_investor_responsible_person',
    edit_title: 'road-maintenance/excavation-dropdown-type.edit_investor_responsible_person',
    delete_title: 'road-maintenance/excavation-dropdown-type.delete_investor_responsible_person',
    confirm_delete: 'road-maintenance/excavation-dropdown-type.confirm_delete_investor_responsible_person',
    import_title: 'road-maintenance/excavation-dropdown-type.import_investor_responsible_person',
    name_label: 'road-maintenance/excavation-dropdown-type.name',
    name: 'INVESTOR_RESPONSIBLE_PERSON'
  },
  component: () => import(/* webpackChunkName: "road-maintenance" */ '../views/ExcavationSettingsIndex')
}
