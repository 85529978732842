export function hideDomElementsByClass (className) {
  document.querySelectorAll('.' + className).forEach(el => {
    el.style.visibility = 'hidden'
  })
}

export function showDomElementsByClass (className) {
  document.querySelectorAll('.' + className).forEach(el => {
    el.style.visibility = 'visible'
  })
}

export function changeDomElementBgColor (domId, color) {
  document.getElementById(domId).style.background = color
}

export function isDrawerOpen () {
  return document.getElementById('navigation-drawer').classList.contains('v-navigation-drawer--open')
}

export function removeNewsContainerPseudoElementBorder () {
  return document.getElementById('news-container').style.setProperty('--news-container-border-height', 0)
}

export function revertNewsContainerPseudoElementBorder () {
  return document.getElementById('news-container').style.setProperty('--news-container-border-height', '99%')
}
