import { api } from '@/global/services/api'

const state = {
  items: [],
  permissions: [],
  work_order_types: [],
  subject_types: [],
  categories: [],
  hazard_types: [],
  city_districts: [],
  pagination: {
    count: 0,
    currentPage: 1,
    perPage: 15,
    totalPages: 1,
    total: 0
  },
  sort: {}
}

const getters = {
  list: state => state.items,
  permissions: state => state.permissions,
  work_order_types: state => state.work_order_types,
  subject_types: state => state.subject_types,
  categories: state => state.categories,
  hazard_types: state => state.hazard_types,
  city_districts: state => state.city_districts,
  pagination: state => state.pagination,
  sort: state => state.sort
}

const actions = {
  async fetch ({ commit, state }, options) {
    try {
      const params = {
        pagination: options.pagination,
        sort: options.sort.by ? options.sort : { by: 'id', desc: true },
        company_scope_id: options.company_scope_id,
        active: true,
        tipNumber: options.tipNumber
      }
      const {
        data,
        pagination,
        sort
      } = await api('road-maintenance').get('work-orders', params)

      commit('SET_ITEMS', data)
      commit('SET_PAGINATION', pagination)
      commit('SET_SORT', sort)
    }
    catch (e) {
      console.dir(e)
    }
  },

  async fetchPermissions ({ commit, dispatch }) {
    try {
      const permissions = await api('road-maintenance').get('patrol-vehicle/permissions')

      commit('SET_PERMISSIONS', permissions)
    }
    catch (e) {
      console.dir(e)
    }
  },

  async fetchSelectOptions ({ commit, state }, options) {
    try {
      const data = await api('road-maintenance').get('patrol-vehicle/selectOptions')
      commit('SET_WORK_ORDER_TYPES', data[0])
      commit('SET_SUBJECT_TYPES', data[1])
      commit('SET_CATEGORIES', data[2])
      commit('SET_HAZARD_TYPES', data[3])
      commit('SET_CITY_DISTRICT', data[4])
    }
    catch (e) {
      console.dir(e)
    }
  }

}

const mutations = {

  SET_ITEMS: (state, payload) => {
    state.items = payload
    state.pagination.total = payload.length
    state.pagination.count = payload.length
  },

  SET_WORK_ORDER_TYPES: (state, payload) => {
    state.work_order_types = payload
  },

  SET_SUBJECT_TYPES: (state, payload) => {
    state.subject_types = payload
  },

  SET_CATEGORIES: (state, payload) => {
    state.categories = payload
  },

  SET_HAZARD_TYPES: (state, payload) => {
    state.hazard_types = payload
  },

  SET_CITY_DISTRICT: (state, payload) => {
    state.city_districts = payload
  },

  SET_PERMISSIONS: (state, payload) => {
    state.permissions = payload
  },

  SET_PAGINATION ({ pagination }, payload) {
    const { count, currentPage, perPage, totalPages, total } = payload

    pagination.count = count || 0
    pagination.currentPage = currentPage || 1
    pagination.perPage = perPage || 0
    pagination.totalPages = totalPages || 1
    pagination.total = total || 0
  },

  SET_SORT: (state, payload) => (state.sort = payload)
}

export default {
  state,
  getters,
  actions,
  mutations
}
