import ModuleDirectoryScanner from '../index'
import StoreFile from './store-file'

export default class StoreScanner extends ModuleDirectoryScanner {
  constructor () {
    super()

    this._storesOnly()
  }

  /** @override */
  _createFileObject (fileFullPath) {
    return new StoreFile(fileFullPath)
  }

  _storesOnly () {
    this._only(file => file.getFullPath().includes('stores/'))
  }

  makeAllStoresNamespaced () {
    this.files.forEach(store => store.markNamespaced())

    return this
  }

  toFormatVuexUnderstands () {
    return Object.fromEntries(
      this.files.map(store => [store.createNamespace(), store.getContent()])
    )
  }
}
