<template>
  <v-data-table
    v-stripped
    :headers="getHeaders(tab.table.columns)"
    :items="Object.keys(dataTableData).length ? dataTableData[tab.table.model].resources : []"
    :options.sync="options"
    :loading="fetchingData"
    :show-select="false"
    class="elevation-0"
  >
    <template #top>
      <DeleteModal
        v-if="canDelete"
        :view-config="tab.table"
        :delete-dialog="deleteDialog"
        :delete-id="deleteId"
        @delete-confirmed="deleteConfirmed(dataTableData[tab.table.model].fetch)"
        @close-dialog="closeDeleteDialog"
      />
    </template>

    <template
      v-for="(data, column) in tab.table.columns"
      #[getItemSlot(column)]="{ item }"
    >
      <span :key="column">
        {{ getCellDisplayValue(item, column, tab.table.columns) }}
      </span>
    </template>
    <template #[`item.active`]="{ item }">
      <v-icon
        v-if="itemIsDeactivated(item)"
        large
        color="success"
        class="py-3"
      >
        mdi-check-circle
      </v-icon>
      <v-icon
        v-else
        large
        color="red"
        class="py-3"
      >
        mdi-close-circle
      </v-icon>
    </template>
    <template
      #[`item.actions`]="{ item }"
    >
      <v-menu
        offset-y
        offset-overflow
        left
      >
        <template #activator="{ on, attrs }">
          <v-icon
            v-if="canSeeActions(item)"
            v-bind="attrs"
            v-on="on"
          >
            mdi-dots-vertical
          </v-icon>
        </template>

        <v-list class="pa-0">
          <v-list-item
            v-if="canBeDeleted(item)"
            link
            @click="openDeleteDialog(item, tab.table.route_string)"
          >
            <v-list-item-icon class="mr-2">
              <v-icon small>
                {{ dataTableData[tab.table.model].viewConfig.actions.delete.icon || '' }}
              </v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{ dataTableData[tab.table.model].viewConfig.actions.delete.text || '' }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
import { api } from '@/global/services/api'
import { formatSqlDate } from '@/global/services/helpers/dates'
import { forEach, has } from 'lodash'
import DeleteModal from '@/global/components/modals/DeleteModal'

export default {
  name: 'DataTableField',

  components: {
    DeleteModal
  },

  props: {
    tab: {
      type: Object,
      default: () => ({}),
      required: true
    },
    module: {
      type: String,
      required: true
    },
    dataTableData: {
      type: Object,
      default: () => ({})
    },
    fetchingData: {
      type: Boolean,
      default: false
    },
    namespace: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      deleteDialog: false,
      deleteId: -1,
      options: {
        page: 1,
        itemsPerPage: 15,
        sortBy: [],
        sortDesc: []
      }
    }
  },

  computed: {
    viewConfig () {
      return this.$store.getters[this.namespace + '/viewConfig']
    }
  },

  methods: {
    canDelete () {
      if ('actions' in this.viewConfig) {
        return 'delete' in this.viewConfig.actions
      }

      return false
    },
    canSeeActions (item) {
      if ('actions' in item) {
        return item.actions.length
      }

      return this.canDelete
    },

    canBeDeleted (item) {
      if ('actions' in item) {
        return this.canDelete && item.actions.includes('delete')
      }

      return this.canDelete
    },

    itemIsDeactivated (item) {
      return item.deactivated_at == null
    },

    openDeleteDialog (item, routeString) {
      this.deleteId = item.id
      this.deleteDialog = true
      this.routeString = routeString
    },

    async deleteConfirmed (fetch) {
      try {
        await api()[this.module].delete((this.routeString || '') + '/' + this.deleteId)
      }
      catch (e) {
        console.log(e)
      }

      fetch(this.$route.params.id)
      this.closeDeleteDialog()
    },

    getItemSlot (column) {
      return 'item.' + column
    },

    closeDeleteDialog () {
      this.deleteDialog = false
    },

    getCellDisplayValue (item, column, columns) {
      const value = item[column]
      if (value === null) return ''

      const {
        data_type: dataType,
        selected,
        autocomplete_options: autocompleteOptions
      } = columns[column]

      if (dataType === 'datetime') {
        return new Date(value).toLocaleString()
      }

      if (dataType === 'array') {
        const relationKey = autocompleteOptions[0].model

        return this.formatMultiautocompleteResourceDisplayField(
          item[relationKey], selected, autocompleteOptions
        )
      }

      if (dataType === 'json') {
        const availableValues = columns[column].selectionList

        if (!availableValues || !value) return ''

        return value.map(item => availableValues[item]).join(', ')
      }

      if (
        dataType === 'text' &&
        columns[column].type === 'datepicker'
      ) {
        return formatSqlDate(item[columns[column].field])
      }

      if (columns[column].type === 'input_location_picker') {
        return `${value.lat}, ${value.lng}`
      }

      if (typeof value === 'object') {
        const defaultColumn = columns[column].default_column

        return defaultColumn ? value[defaultColumn] : value.name
      }

      if (!has(item, column)) {
        const objPropertiesArray = columns[column].nested_value?.split('.')

        return objPropertiesArray?.reduce((acc, property) => acc[property], { ...item }) || ''
      }

      return value
    },

    getHeaders (columns) {
      const data = []

      forEach(columns, function (column, key) {
        if ('show_in_table' in column && !column.show_in_table) return

        data.push({
          text: column.label,
          value: key,
          sortable: column.sortable,
          class: 'primary-darken-1--text pl-1 pr-6',
          cellClass: 'pl-1 pr-6'
        })
      })

      /* TODO: Use action from viewConfig  : maybe */
      if (!this.noActions) {
        data.push(
          {
            text: this.$t('base.actions'),
            value: 'actions',
            width: '5%',
            align: 'center',
            sortable: false,
            class: 'primary-darken-1--text'
          }
        )
      }

      return data
    }
  }
}
</script>
