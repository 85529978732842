<template>
  <div
    :class="{'px-0' : $vuetify.breakpoint.xs}"
  >
    <v-simple-table>
      <tbody>
        <tr
          v-for="row in rows"
          :key="row"
        >
          <template
            v-for="column in columns"
          >
            <th
              v-if="field(row, column)"
              :key="`${row}-${column}-key`"
              :class="{'custom-width': tightColumn && !$vuetify.breakpoint.xs, 'text-wrap': $vuetify.breakpoint.xs}"
            >
              {{ field(row, column).key }}:
            </th>
            <th
              v-else
              :key="`${row}-${column}-key`"
            />
            <td
              v-if="field(row, column)"
              :key="`${row}-${column}-value`"
              :class="{'custom-width-td': tightColumn && !$vuetify.breakpoint.xs, 'text-right': $vuetify.breakpoint.xs}"
            >
              <icon-field
                v-if="field(row, column).type === 'icon'"
                :config="field(row, column)"
                size="1.25em"
              />
              <icon-image-field
                v-if="field(row, column).type === 'icon_image'"
                :config="field(row, column)"
              />
              <icon-toggle-field
                v-if="field(row, column).type === 'icon_toggle'"
                :item="field(row, column).item"
                :name="field(row, column).key"
                :config="field(row, column)"
                :center="false"
              />
              <date-time
                v-if="field(row, column).type === 'datetime'"
                :item="field(row, column)"
              />
              <div v-else-if="field(row, column).type === 'chip'">
                <span v-if="field(row, column).value.length > 0">
                  <v-chip
                    v-for="item in field(row, column).value"
                    :key="item.id"
                    :color="`${getRandomColor(item, 'id')} lighten-3`"
                    class="mx-1"
                    small
                  >
                    <span>{{ item.name }} </span>
                  </v-chip>
                </span>
                <span v-else>
                  n/a
                </span>
              </div>
              <text-field
                v-else-if="field(row, column).value"
                :item="field(row, column)"
              />
            </td>
            <td
              v-else
              :key="`${row}-${column}-value`"
            />
          </template>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>

import IconField from '@/global/components/IconField'
import IconImageField from '@/global/components/IconImageField'
import IconToggleField from '@/global/components/IconToggleField'
import TextField from '@/global/components/show-details/fields/data-column/fields/TextField'
import DateTime from '@/global/components/show-details/fields/data-column/fields/DateTime'
import randomColorsMixin from '@/global/mixins/randomColorsMixin'

export default {
  name: 'DataColumn',

  mixins: [randomColorsMixin],

  components: {
    IconField,
    IconImageField,
    IconToggleField,
    TextField,
    DateTime
  },

  props: {
    fields: {
      type: Array,
      required: true
    },
    tightColumn: {
      type: Boolean,
      default: false
    },
    splitColumn: {
      type: Boolean,
      default: false
    },
    columnCount: {
      type: Number,
      default: 2
    },
    // if split column is true, we can set max number of fields when splitting should happen
    maxNumOfFieldsBeforeSplitting: {
      type: Number,
      default: 10
    }
  },

  computed: {
    useSplitColumns () {
      return this.splitColumn && !this.$vuetify.breakpoint.xs && this.fields.length >= this.maxNumOfFieldsBeforeSplitting
    },
    columns () {
      return this.useSplitColumns ? this.columnCount : 1
    },
    rows () {
      return Math.ceil(this.fields.length / this.columns)
    }
  },

  methods: {
    field (row, column) {
      return this.fields[(column - 1) * this.rows + (row - 1)]
    }
  }
}
</script>

<style scoped lang="scss">
tbody {
  > tr {
      white-space: nowrap;
      height: 45px;
      &:last-child {
        > th, td {
          border-bottom: thin solid rgba(0, 0, 0, 0.12);
        }
      }
      &:first-child {
        > th, td {
          border-top: thin solid rgba(0, 0, 0, 0.12);
        }
      }
      > th {
        padding-left: 0.2rem !important;
        width: 100px;
        &.custom-width {
          white-space: normal;
          min-width: 1%;
          width: 2%;
        }
      }
      > td {
        padding-left: 0.2rem !important;
        font-size: 0.75rem !important;
        &.custom-width-td {
          padding-right: 5rem !important;
          min-width: 7%;
          width: 10%;
        }
      }
    p {
        line-height: 1.2;
      }
  }
}
</style>
