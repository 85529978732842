<template>
  <v-card-actions
    v-if="viewConfig[formType]"
    class="pa-3"
  >
    <v-spacer />

    <v-btn
      class="mr-4"
      color="primary"
      @click="close"
    >
      {{ viewConfig[formType].button_cancel }}
    </v-btn>

    <v-btn
      class="mx-1"
      type="submit"
      color="primary"
    >
      {{ viewConfig[formType].button_save }}
    </v-btn>
  </v-card-actions>
</template>

<script>
export default {
  name: 'FormActions',

  props: {
    viewConfig: {
      type: Object,
      required: true
    },
    formType: {
      type: String,
      required: true
    }
  },

  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
