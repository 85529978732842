<template>
  <div class="mb-4">
    <div class="d-flex align-center justify-space-between mb-8">
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-btn
            color="primary"
            icon
            v-bind="attrs"
            @click="duplicateValues"
            v-on="on"
          >
            <v-icon>mdi-content-duplicate</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('people/person.working_hours_copy_tooltip') }}</span>
      </v-tooltip>
    </div>
    <v-row
      v-for="(day, num, index) in days"
      :key="num"
      class="align-baseline"
      :class="{'mb-n12': mediumOrHigherScreen }"
      align="center"
    >
      <v-col class="col-12 col-sm-3 col-md">
        {{ day }}:
      </v-col>
      <v-col
        v-for="(field, name) in fields"
        :key="name"
        class="col-12 col-md-2"
        :class="{ 'col-sm-3': field.type === 'label',
                  'col-sm-4': field.type !== 'label',
                  'py-0' : $vuetify.breakpoint.sm || (field.type !== 'label' && $vuetify.breakpoint.xs),
                  'pt-0' : $vuetify.breakpoint.xs,

        }"
      >
        <v-col
          v-if="field.type === 'label'"
          class="pa-0"
          :class="{'text-right': mediumOrHigherScreen}"
        >
          {{ field.label }}:
        </v-col>
        <v-menu
          v-if="field.type === 'timepicker'"
          :ref="`timepicker_${index}_${name}`"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="formData[index][name]"
              :error-messages="validationErrors[`working_hours.${index}.${name}`]"
              readonly
              clearable
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-time-picker
            v-model="formData[index][name]"
            format="24hr"
            full-width
            @input="handleTimepickerInput(index, name)"
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="$refs[`timepicker_${index}_${name}`][0].save(formData[index][name])"
            >
              {{ $t('base.ok') }}
            </v-btn>
          </v-time-picker>
        </v-menu>
      </v-col>
    </v-row>
    <div
      v-if="errorMessages.length"
      class="mt-7 v-messages theme--light error--text"
      role="alert"
    >
      <div class="v-messages__wrapper">
        <div class="v-messages__message">
          {{ errorMessages[0] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WorkingHoursInput',
  props: {
    days: {
      type: Object,
      default: () => {}
    },
    fields: {
      type: Object,
      default: () => {}
    },
    errorMessages: {
      type: Object,
      default: () => {}
    },
    label: {
      type: String,
      default: () => ''
    },
    value: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      formData: [],
      validationErrors: {}
    }
  },

  computed: {
    mediumOrHigherScreen () {
      return this.$vuetify.breakpoint.width > 960
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (newValue) {
        if (newValue && newValue.length > 0) {
          this.formData = newValue
        }
        else {
          this.initFormData()
        }
      }
    },
    errorMessages (value) {
      this.validationErrors = value
    }
  },

  methods: {
    clearValidationError (index, field) {
      this.$delete(this.validationErrors, `working_hours.${index}.${field}`)
    },

    handleTimepickerInput (index, name) {
      this.clearValidationError(index, name)
      this.$emit('input', this.formData)
    },

    initFormData () {
      this.formData = []

      const initialValues = {}
      Object.keys(this.fields).forEach(field => {
        initialValues[field] = ''
      })

      Object.keys(this.days).forEach(day => {
        this.formData.push({ day, ...initialValues })
      })
    },

    duplicateValues () {
      const firstDay = this.formData[0]
      if (!firstDay.from || !firstDay.to) {
        return
      }

      this.formData.forEach(item => {
        item.from = firstDay.from
        item.to = firstDay.to
      })
    }
  }
}
</script>
