<template>
  <div class="NotFound">
    <v-container>
      <v-layout
        row
        wrap
      >
        <v-flex
          xs12
        >
          <v-card
            color="transparent"
            flat
            class="px-6 pt-3 pb-7 grey lighten-4 text-center rounded-lg elevation-10"
          >
            <v-card-title
              v-if="!isFetching"
              class="justify-center"
            >
              <img
                :src="company.logo"
                alt="Logo"
                class="logo"
                style="max-width: 100%; max-height: 100%;"
              >
            </v-card-title>
            <v-skeleton-loader
              v-if="isFetching"
              class="mx-auto"
              type="card, article, article"
            />
            <div
              v-if="!isFetching"
              class="flex justify-space-between"
            >
              <div class="mt-5 display-4 red--text text--darken-2 font-weight-bold">
                404
              </div>
              <div class="mt-5 display-2 red--text font-weight-regular">
                {{ translate('base.page_not_found_title') || '' }}
              </div>
              <div class="mt-10 headline">
                {{ translate('base.page_not_found_message') || '' }}
              </div>
              <div
                class="my-2 title text-decoration-underline blue--text text--darken-3"
                style="cursor: pointer"
                @click="$router.push('/')"
              >
                {{ translate('base.go_back_action') || '' }}
              </div>
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { api } from '@/global/services/api'
import { getEnglishLocaleCodes } from '@/global/services/helpers/dates'

export default {
  name: 'NotFoundView',

  data () {
    return {
      isFetching: false,
      isAuthenticated: false,
      translations: [],
      company: null,
      languageCode: null
    }
  },

  created () {
    this.setConfig()
  },

  methods: {
    async setConfig () {
      this.isFetching = true
      const { data } = await api().base.get('/config')
      const {
        authenticated,
        company,
        not_found_page_translations: translations,
        language
      } = data

      this.isAuthenticated = authenticated
      this.company = company
      this.translations = translations
      if (authenticated) {
        this.languageCode = language
      }
      else {
        const browserLanguageCode = navigator.language.toLowerCase()
        this.languageCode = getEnglishLocaleCodes().includes(browserLanguageCode) ? 'en' : 'hr'
      }
      this.isFetching = false
    },

    translate (key) {
      return this.translations[this.languageCode][key]
    }
  }
}
</script>
