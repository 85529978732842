import liveTracking from '@/modules/satellite-tracking-module/live-tracking/routes/index.child'
import trackingHistory from '@/modules/satellite-tracking-module/tracking-history/routes/index.child'
import alarms from '@/modules/satellite-tracking-module/alarms/routes/index.child'
import alarmEvents from '@/modules/satellite-tracking-module/alarm-events/routes/index.child'
import fuelProbe from '@/modules/satellite-tracking-module/fuel-probe/routes/index.child'
import temperatureSensor from '@/modules/satellite-tracking-module/temperature-sensor/routes/index.child'
import vehicleConfiguration from '@/modules/satellite-tracking-module/trip-configuration/routes/index.child'
import locationHistory from '@/modules/satellite-tracking-module/location-history/routes/index.child'

export default {
  path: '/satellite-tracking',
  redirect: {
    name: 'NotFound',
    params: ['/satellite-tracking']
  },
  component: () => import(/* webpackChunkName: "satellite-tracking" */ '../views/SatelliteTracking.vue'),
  meta: {
    exclude: false,
    requiresAuth: true
  },
  children: [
    liveTracking,
    trackingHistory,
    fuelProbe,
    temperatureSensor,
    alarms,
    alarmEvents,
    vehicleConfiguration,
    locationHistory
  ]
}
