<template>
  <v-list-item
    exact
    :to="{ name: menu.vue_name }"
  >
    <v-list-item-icon class="mr-3">
      <v-icon size="18">
        {{ menu.icon }}
      </v-icon>
    </v-list-item-icon>

    <v-list-item-title class="avatar-menu-text">
      {{ menu.name }}
    </v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  props: {
    menu: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
