import File from '../file'

export default class StoreFile extends File {
  markNamespaced () {
    this.content = { ...this.getContent(), namespaced: true }

    return this
  }

  createNamespace () {
    return this.getModuleName() + '/' + this.getName()
  }
}
