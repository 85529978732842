import { forEach, isEmpty } from 'lodash'

const indexMethods = {
  methods: {
    convertTextValueToDefaultColumns (formData) {
      forEach(this.viewConfig.columns, (column, key) => {
        if (
          column.type === 'autocomplete_combobox' &&
          column.data_type === 'multi_relation' &&
          column.creatable &&
          column.editable &&
          !isEmpty(formData) &&
          !!formData[key]
        ) {
          formData[key] = formData[key]
            .map(item => ({
              [column.columns.id]: item.value,
              [column.columns.text]: item.text
            }))
        }
      })

      return formData
    }
  }
}

export default indexMethods
