<template>
  <div>
    <v-text-field
      v-model="selectedPeopleNames"
      :label="label"
      :prepend-inner-icon="prependIcon"
      :error-messages="errorMessages"
      readonly
      :outlined="outlined"
      :dense="dense"
      @mousedown.stop="dialog = true"
    />
    <v-card v-if="selectedPeople.length && showList">
      <v-treeview
        :items="peopleTreeForDisplayList"
        hoverable
        activatable
        open-on-click
      >
        <template #prepend="{ item }">
          <v-icon v-if="item.children && item.children.length > 0">
            {{ 'mdi-account-multiple' }}
          </v-icon>
          <v-icon v-else>
            {{ 'mdi-account' }}
          </v-icon>
        </template>
        <template
          #label="{ item }"
        >
          <div class="font-weight-bold">
            {{ item.full_name }} {{ item.working_place ? '(' + item.working_place.name + ')' : '' }}
          </div>
        </template>
        <template
          #append="{ item }"
          class="col text-right"
        >
          <v-btn
            icon
            @click="removeItem(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-treeview>
    </v-card>
    <v-dialog
      v-model="dialog"
      max-width="30em"
      scrollable
      @keydown.enter.prevent="closeDialog"
      @keydown.esc.prevent="closeDialog"
    >
      <v-card
        :loading="loading"
      >
        <v-row
          class="flex ma-0"
          :class="[title ? 'justify-space-between' : 'justify-end']"
        >
          <v-card-title v-if="title">
            {{ title }}
          </v-card-title>

          <v-btn
            text
            x-small
            class="mt-3 mr-2 pa-0 no-background-hover"
            elevation="0"
            @click="dialog = false"
          >
            <v-icon
              color="grey darken-1"
              size="1.8rem"
            >
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>

        <v-card-subtitle v-if="subtitle">
          {{ subtitle }}
        </v-card-subtitle>
        <v-card-text>
          <v-row
            v-if="showCompanyScope && !isEmpty(viewConfig.change_company_scope)"
            class="mb-3"
          >
            <v-col>
              <autocomplete
                v-model="companyScopeId"
                company-scope
                :label="viewConfig.change_company_scope.label"
                :hide-details="true"
                :clearable="true"
                :items.sync="descendantCompanies"
                :options="viewConfig.change_company_scope.autocomplete_options"
              />
            </v-col>
          </v-row>
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            clearable
          />
          <v-row
            v-if="!maxSelect"
            class="mb-3"
          >
            <v-col class="py-0">
              <v-checkbox
                v-model="selectAll"
                :label="$t('base.select_all')"
                color="accent"
                hide-details
              />
            </v-col>
          </v-row>
          <v-treeview
            v-model="selectedPeople"
            :items="peopleTree"
            :search="search"
            :filter="filter"
            return-object
            selectable
            open-on-click
            activatable
            :active="active"
            @update:active="selectOnActive"
          >
            <template #label="{ item }">
              <div>
                {{ item[personIdentifier] }} {{ item.working_place ? '(' + item.working_place.name + ')' : '' }}
              </div>
            </template>
          </v-treeview>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="closeDialog">
            {{ confirmSelection }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import { api } from '@/global/services/api'
import store from '@/global/store'
import withoutWatchersMixin from '@/global/mixins/withoutWatchersMixin'
import Autocomplete from '@/modules/base-module/autocomplete/components/Autocomplete'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('base/config')

export default {
  name: 'PersonPicker',
  components: {
    Autocomplete
  },
  mixins: [withoutWatchersMixin],
  props: {
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: function () {
        return this.$t('people.person')
      }
    },
    confirmSelection: {
      type: String,
      default: function () {
        return this.$t('base.ok')
      }
    },
    prependIcon: {
      type: String,
      default: 'mdi-account-multiple'
    },
    startOpen: {
      type: Boolean,
      default: false
    },
    showList: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    formField: {
      type: Boolean,
      default: false
    },
    maxSelect: {
      type: Number,
      default: null
    },
    scopeId: {
      type: Number,
      default: null
    },
    errorMessages: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    },
    viewConfig: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      dialog: false,
      selectAll: false,
      loading: true,
      search: null,
      active: [],
      people: [],
      peopleTree: [],
      selectedPeople: [],
      companyScopeId: null,
      descendantCompanies: [],
      personIdentifier: 'full_name'
    }
  },
  computed: {
    ...mapGetters({
      globalCompanyScope: 'companyScope',
      isCompanyScopeUpdated: 'isCompanyScopeUpdated'
    }),

    peopleTreeForDisplayList () {
      return this.buildPeopleTree(this.selectedPeople)
    },

    // Display human-readable names instead of person IDs
    selectedPeopleNames () {
      return this.selectedPeople
        .map(person => `${person[this.personIdentifier]} ${person.working_place?.name ? '(' + person.working_place.name + ')' : ''}`)
        .join(', ')
    },
    showCompanyScope () {
      if (this.globalCompanyScope) {
        return false
      }
      return true
    }
  },
  watch: {
    dialog (value) {
      // Apply model changes after the dialog is closed
      if (value === false) {
        this.$emit('input', this.selectedPeople)
      }
    },
    value () {
      this.populateSelectedPeople()
    },
    selectAll (value) {
      this.selectedPeople = value ? this.peopleTree : []
    },
    selectedPeople (newValue) {
      if (this.maxSelect && newValue.length > this.maxSelect) {
        this.$withoutWatchers(() => {
          // Remove first array item if max limit is set to 1 and user try to select another item
          if (this.maxSelect === 1) {
            this.selectedPeople.shift()
          }
          else {
            this.selectedPeople = this.selectedPeople.slice(0, this.maxSelect)
            store.dispatch('base/notifications/push', this.$t('people/person.selection_limit') + ' ' + this.maxSelect)
          }
        })
      }
      // Update select all status based on number of selected people, without triggering watcher
      this.$withoutWatchers(() => {
        this.selectAll = this.selectedPeople.length === this.people.length
      })
    },
    companyScopeId: async function () {
      this.$emit('updated-scope', this.companyScopeId)
      await this.resetPeople()
    },
    scopeId: async function () {
      await this.resetPeople()
    },
    isCompanyScopeUpdated: async function () {
      await this.resetPeople()
      this.unsetCompanyScopeUpdated()
    }
  },
  async created () {
    // await new Promise(resolve => setTimeout(resolve, 3_000))
    // TODO: investigate how this will function when a large amount of person is present
    await this.getPeople()
  },
  async mounted () {
    this.dialog = this.startOpen
  },
  methods: {
    ...mapActions(['unsetCompanyScopeUpdated']),
    async resetPeople () {
      this.selectedPeople = []
      await this.getPeople()
    },
    removeItem (item) {
      if (item.children) {
        const peopleToBeRemovedIds = item.children.map(person => person.id)
        this.selectedPeople = this.selectedPeople.filter(person => !peopleToBeRemovedIds.includes(person.id))
        this.$emit('input', this.selectedPeople)
      }
      this.selectedPeople = this.selectedPeople.filter(person => person.id !== item.id)
      this.$emit('input', this.selectedPeople)
    },
    isEmpty,
    async getPeople () {
      const companyScopeid = this.companyScopeId || this.scopeId
      this.loading = true
      const people = await api('people').get(
        '/people/picker-options',
        companyScopeid && { company_scope_id: companyScopeid }
      )
      this.people = people.data
      this.populateSelectedPeople()
      this.peopleTree = this.buildPeopleTree(this.people)
      this.loading = false
    },
    filter (item, queryText) {
      // TODO write more filter criteria
      return item.full_name.toLowerCase().indexOf(queryText.toLowerCase()) > -1
    },
    // Goes through the list of person and builds a group-based tree for the treeview component
    buildPeopleTree (people) {
      const groups = []
      const independent = []
      for (const person of people) {
        if (person.group) {
          let group = groups.find(group => group.id === 'group-' + person.group.id)
          if (!group) {
            group = {
              id: 'group-' + person.group.id,
              [this.personIdentifier]: person.group.name,
              children: []
            }
            groups.push(group)
          }
          group.children.push(person)
        }
        else {
          independent.push(person)
        }
      }
      return [
        ...groups,
        ...independent
      ]
    },
    selectOnActive ([person]) {
      if (person) {
        // Don't actually allow the user to mark person as active
        this.active = []
        // Toggle the selected status of the activated person
        if (this.selectedPeople.includes(person)) {
          this.selectedPeople.splice(this.selectedPeople.indexOf(person), 1)
        }
        else {
          this.selectedPeople.push(person)
        }
      }
    },
    populateSelectedPeople () {
      if (!this.value) {
        this.selectedPeople = []
      }
      else {
        if (this.people) {
          this.selectedPeople = this.people?.filter(person => {
            return this.value.includes(this.formField ? person.id : person)
          })
        }
      }
    },
    closeDialog () {
      this.dialog = false
    }
  }
}
</script>
