<template>
  <div class="d-flex align-center">
    <!-- Label styles as custom switch button -->
    <button
      type="button"
      class="check-trail"
      :class="{ 'disabled': disabled, 'active': value }"
      @click.prevent="toggle"
    >
      <span class="check-handler" />
    </button>
    <!-- Actual label text -->
    <span
      class="ml-2 check-label"
      @click.prevent="toggle"
    >
      {{ label }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'CustomSwitch',

  props: {
    value: {
      type: Boolean,
      default: false
    },

    name: {
      type: String,
      required: true
    },

    label: {
      type: String,
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    toggle () {
      this.$emit('input', !this.value)
    }
  }
}
</script>

<style scoped lang="scss">
.check-trail {
  display: flex;
  align-items: center;
  width: 2.6em;
  height: 1.2em;
  background: $icon-toggle-inactive-bg;
  border-radius: 2.1em;
  transition: $icon-toggle-transition;
  cursor: pointer;

  &.disabled {
    background: $icon-toggle-disabled-bg !important;
    pointer-events: none !important;
    cursor: default !important;
  }

  &.active {
    background: $icon-toggle-active-bg;

    .check-handler {
      margin-left: 1.3rem;
      background: $icon-toggle-handler-bg;
    }
  }

  .check-handler {
    display: flex;
    margin-left: 0.2em;
    justify-content: center;
    align-items: center;
    width: 0.9em;
    height: 0.9em;
    background: $icon-toggle-handler-bg;
    border-radius: 50%;
    transition: $icon-toggle-transition;
    box-shadow: 0 0 8px rgba(0,0,0,0.3);
  }
}

.check-label {
  font-size: 11px;
  font-weight: 500;
  white-space: nowrap;
}
</style>
