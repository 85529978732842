<template>
  <v-chip
    :input-value="activeStatus"
    :disabled="!hasValue"
    :close="hasValue"
    :value="name"
    active-class="primary--text"
    filter
    outlined
    @input="event => setActiveStatus(event)"
    @click:close="removeFilter"
  >
    {{ filterText }}
  </v-chip>
</template>

<script>
import { supportedTypes } from '@/global/components/filters/typesMap'
import filtersMixin from '@/global/components/filters/filtersMixin'
import { createNamespacedHelpers } from 'vuex'

const {
  mapActions: mapActionsDependantAutocompletes,
  mapState: mapStateDependantAutocompletes
} = createNamespacedHelpers('dependant-autocompletes')
const {
  mapActions: mapActionsAlarmEvents
} = createNamespacedHelpers('satellite-tracking/alarm-events')

export default {
  name: 'FilterDisplayItem',

  mixins: [filtersMixin],

  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => supportedTypes.indexOf(value) !== -1
    },

    label: {
      type: String,
      default: ''
    },

    name: {
      type: String,
      required: true
    },

    value: {
      type: [String, Number, Boolean, Array, Object],
      default: null
    },

    activeStatus: {
      type: Boolean,
      required: true
    },

    possibleValues: {
      type: Array,
      default: () => []
    },

    isDefaultFilter: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    displayItemClicked: false
  }),

  computed: {
    ...mapStateDependantAutocompletes({
      dependsOn: state => state.filters.dependsOn,
      dependsOnText: state => state.filters.dependsOnText,
      dependantText: state => state.filters.dependantText,
      dependantLabel: state => state.filters.label
    })
  },

  watch: {
    activeStatus: {
      handler (activeStatus) {
        if (!this.displayItemClicked) {
          const activeStatusValue = this.isDefaultFilter ? true : activeStatus
          this.emitStatusUpdateAndValue(activeStatusValue)
        }
        else {
          this.displayItemClicked = false
        }
      }
    },

    hasValue: {
      immediate: true,
      handler (hasValue) {
        const activeStatusObject = {
          name: this.name,
          activeStatus: hasValue
        }
        this.$emit('set-active-status', activeStatusObject)
      }
    }
  },

  created () {
    if (!this.displayItemClicked) {
      if (this.isDefaultFilter) {
        const activeStatusValue = this.isDefaultFilter ? true : this.activeStatus
        this.emitStatusUpdateAndValue(activeStatusValue)
      }
    }
    else {
      this.displayItemClicked = false
    }
  },

  methods: {
    ...mapActionsDependantAutocompletes({
      resetDataDependantAutocompletes: 'resetData'
    }),

    ...mapActionsAlarmEvents(['setShowFilters']),

    emitStatusUpdateAndValue (activeStatus) {
      const updateStatus = {
        name: this.name,
        isActive: activeStatus
      }

      this.$emit('status-update', updateStatus)

      const updateValue = !activeStatus ? !this.isDefaultFilter : true
      this.$emit('update:value', updateValue)
    },

    setActiveStatus (activeStatus) {
      const activeStatusObject = {
        name: this.name,
        activeStatus
      }
      this.$emit('set-active-status', activeStatusObject)

      this.displayItemClicked = true
      this.emitStatusUpdateAndValue(activeStatus)
    },

    // When user click on close button of a filter this method is called.
    removeFilter () {
      this.$emit('update:value', null)

      if (this.isDefaultFilter) {
        this.$emit('reset-default-filters')
      }

      if (this.type === 'datepicker') {
        this.$emit('reset-dates')
      }
      else if (['date', 'autocomplete_combobox'].includes(this.type)) {
        this.$emit('reset-component-data', this.name)
      }
      else if (this.type === 'dependant_autocompletes') {
        this.resetDataDependantAutocompletes('filters')
      }
    }
  }
}
</script>
