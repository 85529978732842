export default {
  path: '/login',
  name: 'Login',
  component: () => import(/* webpackChunkName: "base/login" */ '../views/LoginView.vue'),
  meta: {
    title: 'base.login',
    exclude: true,
    layout: 'login',
    requiresAuth: false
  }
}
