<template>
  <v-list-group>
    <template #activator>
      <v-list-item-icon class="mr-3">
        <gb-flag
          :code="languageMap[selectedLanguage]"
          size="micro"
        />
      </v-list-item-icon>

      <v-list-item-title class="avatar-menu-text">
        {{ menu.name }}
      </v-list-item-title>
    </template>
    <template #default>
      <v-list-item-group
        v-model="selectedLanguage"
        active-class="primary--text"
        mandatory
      >
        <v-list-item
          v-for="language in menu.children"
          :key="language.code"
          :value="language.code"
        >
          <gb-flag
            :code="languageMap[language.code]"
            size="micro"
          />
          <v-list-item-title class="avatar-menu-text ml-2">
            {{ language.name }}
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </template>
  </v-list-group>
</template>

<script>
import { api } from '@/global/services/api'
import { createNamespacedHelpers } from 'vuex'

const {
  mapGetters,
  mapActions
} = createNamespacedHelpers('base/config')

export default {
  props: {
    menu: {
      type: Object,
      default: () => ({})
    }
  },
  data: function () {
    return {
      selectedLanguage: null,
      languageMap: {
        hr: 'hr',
        en: 'gb'
      }
    }
  },
  computed: {
    ...mapGetters({ language: 'language' })
  },
  watch: {
    selectedLanguage: async function () {
      if (this.language !== this.selectedLanguage) {
        await api().base.patch('/profile/language', { language: this.selectedLanguage })
        // Reload the configuration to enact the language change.
        this.fetchConfig()
      }
    }
  },
  created () {
    this.selectedLanguage = this.language
  },
  methods: {
    ...mapActions({ fetchConfig: 'fetch' })
  }
}
</script>
