import Vue from 'vue'
import each from 'lodash/each'

/**
 * State is "dynamic", each key fetched from server is directly assigned to state
 * @see mutations.SET_TRANSLATIONS
 */
const state = {}

const getters = {
  getByKey: state => key => (state[key] || key)
}

const actions = {}

const mutations = {
  SET_TRANSLATIONS (state, payload) {
    each(payload,
      (value, key) => Vue.set(state, key, value)
    )
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
