// TODO: refactor this to be sent with other config data from the backend
export const typesMap = {
  autocomplete: 'autocomplete',
  checkbox: 'v-checkbox',
  date: 'custom-date-picker',
  datepicker: 'v-date-picker',
  select: 'v-select',
  text: 'v-text-field',
  autocomplete_combobox: 'autocomplete-combobox',
  dependant_autocompletes: 'dependant-autocompletes',
  datepicker_range: 'datepicker-range'
}

export const supportedTypes = Object.keys(typesMap)
