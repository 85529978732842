<template>
  <v-row
    class="mt-4"
    align="center"
  >
    <v-col :cols="2">
      <p class="mb-0 font-weight-bold">
        {{ label }}
      </p>
    </v-col>
    <v-col :cols="$vuetify.breakpoint.xs ? 10 : 4">
      <p
        v-if="isDinOff"
        class="mb-0 font-weight-bold text-center"
        :style="{ lineHeight: 2.5 }"
      >
        Off
      </p>
      <v-autocomplete
        v-else
        v-model="formData.selectedType"
        :items="analogInputTypes"
        label=""
        append-icon="$dropdown"
        :error="`${attributeName}.analog_input_type` in errorMessages"
        :no-filter="true"
        hide-details
        clearable
        outlined
        dense
      />
    </v-col>
    <v-col
      :cols="$vuetify.breakpoint.xs ? 12 : 6"
      class="d-flex align-center"
    >
      <custom-switch
        class="mr-4 mr-sm-6"
        :name="`${attributeName}_din_mode`"
        :disabled="isDinOff || !formData.selectedType"
        :label="options.din_mode_label"
        v-model="formData.dinMode"
      />
      <custom-switch
        class="mr-4 mr-sm-6"
        :name="`${attributeName}_invert`"
        :disabled="isDinOff || !formData.selectedType"
        :label="options.invert_label"
        v-model="formData.invert"
      />
      <v-text-field
        type="number"
        outlined
        dense
        hide-details
        :error="`${attributeName}.threshold_value` in errorMessages"
        :disabled="isDinOff || !formData.selectedType"
        :placeholder="options.threshold_value_label"
        v-model="formData.thresholdValue"
      />
    </v-col>
    <!-- Validation errors -->
    <v-col
      v-if="Object.keys(errorMessages).length > 0"
      cols="12"
      class="mt-n1 mb-2 py-0"
    >
      <p
        v-for="(error, key) in errorMessages"
        :key="key"
        class="error--text mb-0 text-right"
      >
        {{ error[0] }}
      </p>
    </v-col>
  </v-row>
</template>

<script>
import CustomSwitch from '@/modules/base-module/ain-input/CustomSwitch'
import { has } from 'lodash'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters } = createNamespacedHelpers('base/config')

export default {
  name: 'AinInput',

  components: {
    CustomSwitch
  },

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    label: {
      type: String,
      required: true
    },

    attributeName: {
      type: String,
      required: true
    },

    options: {
      type: Object,
      required: true,
      validator: (options) => {
        const properties = [
          'analog_input_types',
          'threshold_value_label',
          'din_mode_label',
          'invert_label'
        ]

        return properties.every(prop => has(options, prop))
      }
    },

    items: {
      type: Array,
      default: () => ([])
    },

    errorMessages: {
      type: Object,
      default: () => ({})
    },

    editItem: {
      type: Object,
      default: () => null
    }
  },

  data () {
    return {
      formData: {
        selectedType: null,
        dinMode: null,
        invert: null,
        thresholdValue: null
      }
    }
  },

  computed: {
    ...mapGetters(['digitalSensorsConfig']),

    isDinOff () {
      return this.digitalSensorsConfig && this.digitalSensorsConfig[this.attributeName] === 'off'
    },

    analogInputTypes () {
      return this.options.analog_input_types
        .map(item => {
          return {
            text: item.name,
            value: item.id
          }
        })
    }
  },

  watch: {
    value: {
      deep: true,
      immediate: true,
      handler: function (newVal, oldVal) {
        if (
          newVal && this.editItem &&
          this.checkForNullValues(this.formData) &&
          !this.checkForNullValues(newVal)
        ) {
          this.formData.selectedType = newVal.analog_input_type
          this.formData.dinMode = !!newVal.din_mode
          this.formData.invert = !!newVal.invert
          this.formData.thresholdValue = newVal.threshold_value
        }

        if (!newVal || this.checkForNullValues(newVal)) {
          this.resetFields()
        }

        // We need to reset fields and emit input event in case when edit form is being reset,
        // that will cause field to be null, and with this we make it into valid object
        if (oldVal && !newVal) {
          this.resetFields()
          this.$emit('input', this.getAinData())
        }
      }
    },

    formData: {
      deep: true,
      handler (value) {
        if (value.selectedType === null) {
          this.formData.dinMode = null
          this.formData.invert = null
          this.formData.thresholdValue = null
        }

        this.$emit('input', this.getAinData())
      }
    },

    isDinOff (value) {
      if (value) {
        this.resetFields()

        // We manually emit input event here because the watcher is never called if din is off
        this.$emit('input', this.getAinData())
      }
    },

    digitalSensorsConfig (value) {
      if (!value && !this.editItem) {
        this.resetFields()
      }
    }
  },

  mounted () {
    this.$emit('input', this.getAinData())
  },

  methods: {
    getAinData () {
      return {
        analog_input_type: this.formData.selectedType,
        din_mode: this.formData.dinMode,
        invert: this.formData.invert,
        threshold_value: this.formData.thresholdValue
      }
    },

    checkForNullValues (object) {
      return Object.values(object).every(item => item === null)
    },

    resetFields () {
      this.formData.selectedType = null
      this.formData.dinMode = null
      this.formData.invert = null
      this.formData.thresholdValue = null
    }
  }
}
</script>
