import people from '@/modules/people-module/people/routes/index.child'
import person from '@/modules/people-module/people/routes/show.child'
import groups from '@/modules/people-module/groups/routes/index.child'
import idCards from '@/modules/people-module/id-cards/routes/index.child'

export default {
  path: '/people',
  redirect: {
    name: 'NotFound',
    params: ['/people']
  },
  component: () => import(/* webpackChunkName: "people" */ '../views/PeopleView.vue'),
  meta: {
    exclude: false,
    requiresAuth: true
  },
  children: [
    people,
    person,
    groups,
    idCards
  ]
}
