<template>
  <v-menu
    v-model="menu"
    class="custom-date-picker-menu"
    :close-on-content-click="false"
    transition="scale-transition"
    @click="menu = true"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="formattedDate"
        append-icon="mdi-calendar-month-outline"
        :label="$attrs.label"
        outlined
        readonly
        hide-details
        class="py-3"
        dense
        clearable
        v-bind="attrs"
        v-on="on"
        @click:clear="date = null"
      />
    </template>
    <v-date-picker
      v-model="date"
      first-day-of-week="1"
      no-title
    >
      <v-spacer />
      <v-btn
        text
        color="primary"
        @click="menu = false"
      >
        {{ $t('base.ok') }}
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { formatSqlDate } from '@/global/services/helpers/dates'

export default {
  props: {
    resetData: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      menu: false,
      date: null
    }
  },

  computed: {
    formattedDate: {
      get () {
        return this.date ? formatSqlDate(this.date) : ''
      }
    }
  },

  watch: {
    date (date) {
      this.$emit('input', date || '')
    },

    resetData (resetData) {
      if (resetData) {
        this.date = null
      }
    }
  }
}
</script>
<style scoped>
.v-menu__content {
  min-width: 0 !important;
}
</style>
