import { api } from '@/global/services/api'

const state = {
  people: [],
  viewConfig: {},
  pagination: {
    count: 0,
    currentPage: 1,
    perPage: 15,
    totalPages: 1,
    total: 0
  },
  sort: {},
  filtersCache: {
    activeFilters: [],
    internalFilters: {}
  }
}

const getters = {
  list: state => state.people,
  pagination: state => state.pagination,
  sort: state => state.sort,
  filtersCache: state => state.filtersCache,
  viewConfig: state => state.viewConfig
}

const actions = {

  async fetch ({ commit, state }, options) {
    try {
      const params = {
        pagination: options.pagination,
        sort: options.sort,
        filters: options.filters,
        company_scope_id: options.company_scope_id
      }

      const {
        config,
        data,
        pagination,
        sort
      } = await api().people.get('people', params)

      commit('SET_PEOPLE', data)
      commit('SET_PAGINATION', pagination)
      commit('SET_SORT', sort)
      commit('SET_VIEW_CONFIG', config)
    }
    catch (e) {
      console.dir(e)
    }
  },

  setFiltersCacheKey ({ commit }, payload) {
    commit('SET_FILTERS_CACHE_KEY', payload)
  },

  resetFiltersCache ({ commit }) {
    commit('RESET_FILTERS_CACHE')
  }
}

const mutations = {

  SET_VIEW_CONFIG: (state, payload) => (state.viewConfig = payload),

  SET_PEOPLE: (state, payload) => (state.people = payload),

  SET_PAGINATION ({ pagination }, payload) {
    const { count, currentPage, perPage, totalPages, total } = payload

    pagination.count = count || 0
    pagination.currentPage = currentPage || 1
    pagination.perPage = perPage || 0
    pagination.totalPages = totalPages || 1
    pagination.total = total || 0
  },

  SET_SORT: (state, payload) => (state.sort = payload),

  SET_FILTERS_CACHE_KEY: (state, { key, value }) => {
    state.filtersCache[key] = value
  },

  RESET_FILTERS_CACHE: (state) => {
    state.filtersCache = {
      activeFilters: [],
      internalFilters: {}
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
