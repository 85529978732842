<template>
  <div class="form-fields">
    <div
      v-for="(attribute, index, key) in formFields"
      :key="'create-attribute-' + index"
    >
      <v-row>
        <v-col
          v-if="shouldShowLabelColumn(attribute, index) && shouldShowWhenCompanyScope(attribute)"
          class="font-weight-bold col-4 col-sm-3"
          align-self="center"
        >
          <!-- we have to use 'mb' class because of input(text-field). Error messages expanding text-field height and align-content-center wont show desired result  -->
          <p class="text-left mb-5">
            {{ getFieldLabel(attribute, index) }}:
          </p>
        </v-col>
        <v-col
          v-if="attribute.type === 'dependant_autocompletes'"
          :cols="$vuetify.breakpoint.xs ? 4: 3"
          align-self="center"
        >
          <div
            v-for="dependant in attribute.dependants"
            :key="dependant.label"
            class="font-weight-bold py-3"
          >
            <p class="text-left">
              {{ dependant.label }}:
            </p>
          </div>
        </v-col>
        <v-col class="pb-0 pt-1">
          <v-row
            v-if="attribute.type === 'image' && !attribute.multiple_files && ['avatar', 'logo', 'image'].includes(index)"
            class="mb-4"
          >
            <v-col cols="4">
              <v-img
                :src="getProfileImage(index)"
                class="avatar-image"
              />
            </v-col>
            <v-col
              align-self="end"
              cols="8"
            >
              <v-row>
                <v-col cols="auto">
                  <div>
                    <label
                      for="avatar_upload"
                      style="cursor: pointer"
                      class="d-flex justify-center align-end"
                    >
                      <img
                        src="/img/icons/tray-arrow-up-lighter.svg"
                        alt="Upload-img"
                        width="30"
                        height="30"
                      >
                    </label>
                    <input
                      id="avatar_upload"
                      type="file"
                      enctype="multipart/form-data"
                      hidden
                      @change="imageSelected($event, index)"
                    >
                  </div>
                </v-col>
                <v-col class="pl-0">
                  <div
                    v-if="validationErrors[index]"
                    class="pb-1 v-messages error--text d-inline"
                  >
                    {{ validationErrors[index][0] }}
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            v-if="attribute.type === 'image' && attribute.multiple_files && ['avatar', 'logo', 'image'].includes(index)"
            class="mb-4"
          >
            <v-col cols="4">
              <div style="position: relative;">
                <v-img
                  :src="getFirstUploadedImage(index)"
                  class="avatar-image"
                />
                <v-tooltip color="black" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="grey darken-1"
                      style="position: absolute; top: 0.2rem; right: 1.2rem;"
                      size="25"
                      class="ml-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="viewUploadedImages(index)"
                    >
                      mdi-folder-multiple-image
                    </v-icon>
                  </template>
                  <div style="max-width: 250px; word-break: break-word;">
                    {{ $t('fleet/vehicle.images_preview_title') }}
                  </div>
                </v-tooltip>
              </div>
            </v-col>
            <v-col
              align-self="end"
              cols="8"
            >
              <v-row>
                <v-col cols="auto">
                  <div>
                    <label
                      for="avatar_upload"
                      style="cursor: pointer"
                      class="d-flex justify-center align-end"
                    >
                      <img
                        src="/img/icons/tray-arrow-up-lighter.svg"
                        alt="Upload-img"
                        width="30"
                        height="30"
                      >
                    </label>
                    <input
                      id="avatar_upload"
                      type="file"
                      enctype="multipart/form-data"
                      multiple
                      :accept="'image/*'"
                      :max="3"
                      hidden
                      @change="imagesSelected($event, index)"
                    >
                  </div>
                </v-col>
                <v-col class="pl-0">
                  <div
                    v-if="validationErrors[index]"
                    class="pb-1 v-messages error--text d-inline"
                  >
                    {{ validationErrors[index][0] }}
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-text-field
            v-if="(attribute.type === 'free' && !checkIfDynamicText(attribute)) || shouldShowDynamicAttribute(attribute, index, 'free')"
            v-model="formData[index]"
            :type="getFieldType(attribute, index)"
            :step="'step' in attribute ? attribute.step : 1"
            :error-messages="validationErrors[index]"
            :placeholder="'placeholder' in attribute ? attribute.placeholder : ''"
            outlined
            dense
            :disabled="checkDisabled(attribute)"
            @input="clearInputValidationErrors(index)"
          />
          <v-text-field
            v-if="attribute.type === 'input_location_picker'"
            :type="getFieldType(attribute, index)"
            append-icon="mdi-map"
            outlined
            dense
            :value="geolocationFormatted"
            :error-messages="validationErrors[index]"
            :disabled="checkDisabled(attribute)"
            @input="value => setInputGeolocation(value, index)"
            @click:append="openGeolocationDialog"
          />
          <v-textarea
            v-if="checkForTextarea(attribute)"
            v-model="formData[index]"
            :rows="attribute.type === 'textarea' ? 5 : 2"
            outlined
            :type="getFieldType(attribute, index)"
            :min="getFieldType(attribute, index) === 'number' ? 0 : ''"
            :error-messages="validationErrors[index]"
            :disabled="checkDisabled(attribute)"
            @input="clearInputValidationErrors(index)"
          />
          <v-checkbox
            v-if="attribute.type === 'checkbox'"
            v-model="formData[index]"
            class="mb-5"
            :error-messages="validationErrors[index]"
            @input="clearInputValidationErrors(index)"
          />
          <v-text-field
            v-if="attribute.type === 'free' && checkIfDynamicText(attribute)"
            :value="getDynamicArrayValue(attribute)"
            :type="getFieldType(attribute, index)"
            :step="'step' in attribute ? attribute.step : 1"
            :error-messages="validationErrors[index]"
            :placeholder="'placeholder' in attribute ? attribute.placeholder : ''"
            outlined
            dense
            :disabled="checkDisabled(attribute)"
            @change="event => setDynamicArray(event, attribute)"
          />
          <v-switch
            v-if="checkIfDynamicSwitch(attribute)"
            :input-value="getDynamicArrayValue(attribute)"
            class="mb-5"
            hide-details
            @change="event => setDynamicArray(event, attribute)"
          />
          <v-switch
            v-if="attribute.type === 'switch' && shouldShowSwitchOrSlider(attribute, index)"
            v-model="formData[index]"
            class="mb-5"
            hide-details
            @input="clearInputValidationErrors(index)"
          />
          <v-range-slider
            v-if="attribute.type === 'range_slider' && shouldShowSwitchOrSlider(attribute, index)"
            v-model="formData[index]"
            :min="attribute.min"
            :max="attribute.max"
            inverse-label
            thumb-label="always"
            thumb-size="25px"
            class="mt-7"
            @input="clearInputValidationErrors(index)"
          />
          <v-slider
            v-if="attribute.type === 'slider' && shouldShowSwitchOrSlider(attribute, index)"
            v-model="formData[index]"
            :min="attribute.min"
            :max="attribute.max"
            hide-details
            inverse-label
            thumb-label="always"
            thumb-size="25px"
            class="my-5"
            @input="clearInputValidationErrors(index)"
          />
          <autocomplete
            v-if="checkForAutocomplete(attribute, index)"
            :key="'autocomplete' + formData[getAutocompleteIndex(index)] + '-' + key"
            v-model="formData[getAutocompleteIndex(index)]"
            label=""
            :readonly="!canEdit(attribute) || checkDisabled(attribute)"
            :error-messages="validationErrors[getAutocompleteIndex(index)]"
            :clearable="attribute.clearable"
            :items.sync="relations[index]"
            :options="getAutocompleteOptions(attribute, index)"
            @input="clearInputValidationErrors(getAutocompleteIndex(index))"
          />
          <DependantAutocompletes
            v-if="attribute.type === 'dependant_autocompletes'"
            :key="'autocomplete-' + formData[getDependantAutocompletesIndex(attribute)] + '-' + key"
            :dep-ids-prop="getDependantIds(attribute)"
            :error-messages="validationErrors[getDependantAutocompletesIndex(attribute)]"
            :items.sync="relations[index]"
            :dependants="attribute.dependants"
            label=""
            store-suffix="form-fields"
            @input="$event => setDependantIdsAndClearInputValidationErrors(getDependantAutocompletesIndex(attribute), $event)"
          />
          <v-text-field
            v-if="attribute.type === 'autocomplete' && previewOnlyField(attribute)"
            label=""
            readonly
            dense
            flat
            solo
            :value="getNestedRelationField(attribute.nested_value, formData)"
          />
          <many2many-autocomplete
            v-if="attribute.type === 'many2many_autocomplete'"
            v-model="formData[index]"
            label=""
            :error-messages="validationErrors[index]"
            :items.sync="relations[index]"
            :options="attribute.autocomplete_options"
            @input="clearInputValidationErrors(index)"
          />
          <many2many-autocomplete-list
            v-if="attribute.type === 'many2many_autocomplete_list'"
            v-model="formData[index]"
            :label="attribute.label"
            :error-messages="validationErrors[index]"
            :items.sync="relations[index]"
            :edit-item="editItem"
            :options="attribute.autocomplete_options"
            @input="clearInputValidationErrors(index)"
          />
          <autocomplete-combobox
            v-if="viewConfig && attribute.type === 'autocomplete_combobox'"
            v-model="formData[index]"
            :error-messages="validationErrors[index]"
            :items.sync="relations[index]"
            :selected="formData[index]"
            :hide-details="false"
            :options="attribute.autocomplete_options"
            :use-random-colors="true"
            :reset-data="containerDialog"
            :include-all="!!editItem"
            @input="clearInputValidationErrors(index)"
          />
          <din-input
            v-if="attribute.type === 'din_input'"
            v-model="formData[index]"
            :label="attribute.label"
            :error-messages="validationErrors[index]"
            :items.sync="relations[index]"
            :attribute-name="index"
            :edit-item="editItem"
            :options="attribute.options"
            @input="clearInputValidationErrors(index)"
          />
          <bt-input
            v-if="attribute.type === 'bt_input'"
            v-model="formData[index]"
            :label="attribute.label"
            :error-messages="validationErrors[index]"
            :items.sync="relations[index]"
            :attribute-name="index"
            :edit-item="editItem"
            :options="attribute.options"
            @input="clearInputValidationErrors(index)"
          />
          <ain-input
            v-if="attribute.type === 'ain_input'"
            v-model="formData[index]"
            :class="{ 'mb-4' : index === 'ain4' }"
            :label="attribute.label"
            :error-messages="getDynamicInputSelectValidationErrors(index)"
            :items.sync="relations[index]"
            :attribute-name="index"
            :edit-item="editItem"
            :options="attribute.options"
            @input="clearInputValidationErrors(index)"
          />

          <v-menu
            v-if="attribute.type === 'datepicker'"
            :ref="`datepicker_${index}`"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-height="290px"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-if="formattedDates[index]"
                v-model="formattedDates[index]"
                :error-messages="validationErrors[index]"
                append-icon="mdi-calendar-month-outline"
                label=""
                outlined
                readonly
                dense
                clearable
                v-bind="attrs"
                v-on="on"
                @click:clear="formData[index] = null"
              />
              <v-text-field
                v-else
                v-model="createFormattedDate"
                :error-messages="validationErrors[index]"
                append-icon="mdi-calendar-month-outline"
                label=""
                outlined
                readonly
                dense
                clearable
                v-bind="attrs"
                v-on="on"
                @click:clear="createDate = null"
              />
            </template>
            <v-date-picker
              v-if="formData[index]"
              v-model="formData[index]"
              first-day-of-week="1"
              full-width
              @input="clearInputValidationErrors(index)"
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="$refs[`datepicker_${index}`][0].save(formData[index])"
              >
                {{ $t('base.ok') }}
              </v-btn>
            </v-date-picker>
            <v-date-picker
              v-else
              v-model="createDate"
              first-day-of-week="1"
              full-width
              @input="clearInputValidationErrors(index)"
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="$refs[`datepicker_${index}`][0].save(createDate)"
              >
                {{ $t('base.ok') }}
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-menu
            v-if="shouldShowTimePicker(attribute, index, 'timepicker')"
            :key="timePickerMenuKey"
            :ref="`timepicker_${index}`"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
            @input="timePickerMenuState"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="formData[index]"
                label=""
                :error-messages="validationErrors[index]"
                append-icon="mdi-clock-outline"
                readonly
                clearable
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-time-picker
              v-model="formData[index]"
              format="24hr"
              full-width
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="$refs[`timepicker_${index}`][0].save(formData[index])"
              >
                {{ $t('base.ok') }}
              </v-btn>
            </v-time-picker>
          </v-menu>
          <v-select
            v-if="attribute.type === 'selection' || shouldShowDynamicAttribute(attribute, index, 'selection')"
            :value="formData[index]"
            label=""
            :multiple="isMultipleSelection(attribute, index)"
            :items="getSelectionItems(attribute, index)"
            :error-messages="getMultipleSelectValidationErrors(index)"
            :clearable="attribute.clearable || false"
            dense
            outlined
            @input="setSelectionValue($event, attribute, index)"
          >
            <template
              v-if="isMultipleSelection(attribute, index)"
              #prepend-item
            >
              <v-list-item
                ripple
                :disabled="shouldToggleSelectAllBeDisabled(attribute, index)"
                @click="toggleSelectAll(attribute, index)"
              >
                <v-list-item-action>
                  <v-icon :color="formData[index] && formData[index].length > 0 ? 'primary' : ''">
                    {{ getSelectAllIcon(attribute, index) }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('base.select_all') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2" />
            </template>
          </v-select>
          <vehicle-picker
            v-if="attribute.type === 'vehicle_picker'"
            :value="formData[index]"
            :chosen-temperature-sensors="getChosenTempSensorsPerVehicleData()"
            :title="attribute.title"
            :label="attribute.label"
            :error-messages="validationErrors[index]"
            :refresh-vehicles="refreshVehicles"
            :active-temperature-sensors-only="showVehiclesWithActiveSensorsOnly()"
            trackers-only
            form-field
            prepend-icon=""
            @input="syncSelectedVehicles($event, index)"
            @reset-refresh-vehicles="refreshVehicles = false"
          />
          <reminder-notification-interval-picker
            v-if="attribute.type === 'reminder_notification_interval_picker'"
            :value="formData[index]"
          />
          <location-picker
            v-if="attribute.type === 'location_picker' || shouldShowDynamicAttribute(attribute, index, 'location_picker')"
            :value="formData[index]"
            :max-select="getFieldMaxSelect(attribute, index)"
            :title="attribute.title"
            :label="attribute.label"
            :error-messages="validationErrors[index]"
            :refresh-locations="refreshLocations"
            form-field
            prepend-icon=""
            @input="syncSelectedVehicles($event, index)"
            @reset-refresh-locations="refreshLocations = false"
          />
        </v-col>
        <v-col
          v-if="!['ain_input', 'din_input', 'bt_input'].includes(attribute.type) && $vuetify.breakpoint.name !== 'xs'"
          :cols="$vuetify.breakpoint.sm ? 3 : 4"
          class="pb-0"
        />
      </v-row>
      <working-hours-input
        v-if="attribute.type === 'working_hours'"
        v-model="formData[index]"
        :days="attribute.days"
        :label="attribute.label"
        :fields="attribute.fields"
        :error-messages="getDynamicInputSelectValidationErrors(index)"
        @input="clearInputValidationErrors(index)"
      />
      <div v-if="attribute.type === 'color'">
        <v-menu offset-y>
          <template #activator="{ on }">
            <v-btn
              :color="formData[index]"
              dark
              v-on="on"
              v-text="attribute.label"
            />
          </template>
          <v-color-picker
            :value="formData[index]"
            mode="hexa"
            hide-inputs
            show-swatches
            @update:color="updateColor($event, index)"
          />
        </v-menu>
        <div
          v-if="validationErrors[index] && validationErrors[index].length"
          class="mt-3 v-messages theme--light error--text"
          role="alert"
        >
          <div class="v-messages__wrapper">
            <div class="v-messages__message">
              {{ validationErrors[index][0] }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="attribute.type === 'multi_autocomplete' && !editingMasterUser">
        <div
          v-for="(relation, ind) in autocompleteOptions"
          :key="'multi-autocomplete-' + relation[`${relation.module}_${index}_id`] + '-' + ind"
        >
          <!-- TODO: This component doesn't have internal functionalities, but in future it will. -->
          <multi-autocomplete
            v-if="canSeePermissions(relation)"
            v-model="formData[`${relation.module}_${index}_id`]"
            :label="relation.label"
            :disabled="authenticatedUserIsEdited"
            :error-messages="validationErrors[`${relation.module}_${index}_id`]"
            :clearable="attribute.clearable"
            :items.sync="relations.multi_autocomplete[`${relation.module}_${index}_id`]"
            @input="clearInputValidationErrors(`${relation.module}_${index}_id`)"
          />
        </div>
      </div>
      <div v-if="attribute.type === 'multi_autocomplete' && editingMasterUser">
        <h3>{{ $t('base/user.master_user_roles') }}</h3>
      </div>
      <vehicle-picker
        v-if="attribute.type === 'vehicle_picker_with_list'"
        :value="formData[index]"
        :title="attribute.title"
        :show-list="true"
        label=""
        :error-messages="validationErrors[index]"
        :refresh-vehicles="refreshVehicles"
        form-field
        :outlined="true"
        :dense="true"
        @input="syncSelectedItemsFromPicker($event, index)"
        @reset-refresh-vehicles="refreshVehicles = false"
      />
      <person-picker
        v-if="attribute.type === 'person_picker_with_list'"
        :value="formData[index]"
        :title="attribute.title"
        :show-list="true"
        label=""
        :outlined="true"
        :dense="true"
        :error-messages="validationErrors[index]"
        form-field
        @input="syncSelectedItemsFromPicker($event, index)"
      />
      <calibration-values-input
        v-if="attribute.type === 'calibration_values_input'"
        v-model="formData[index]"
        :label="attribute.label"
        :error-messages="getDynamicInputSelectValidationErrors(index)"
        :items.sync="relations[index]"
        :edit-item="editItem"
        :attribute-name="index"
        :options="attribute.options"
        :selected-type="formData[attribute.input_type_field]"
        @input="clearInputValidationErrors(index)"
        @reset-error-message="errorKey => clearInputValidationErrors(errorKey)"
      />
      <dynamic-input-select
        v-if="attribute.type === 'dynamic_input_select'"
        :key="'dynamic-input-select-' + formData[index + '_id'] + '-' + key"
        class="mb-4"
        :label="attribute.label"
        :show-label="attribute.show_label"
        :error-messages="getDynamicInputSelectValidationErrors(index)"
        :clearable="attribute.clearable"
        :items.sync="relations[index]"
        :existing-dis-data="dis[index] ? formData[dis[index].key] : []"
        :remove-existing-entries="attribute.remove_created_entries"
        :edit-existing-entries="attribute.edit_created_entries"
        :options="attribute.autocomplete_options"
        :dis="dis[index]"
        :edit-item-extracted-data="getEditItemExtractedData()"
        :active-status-condition-for-add-empty-dis-data-obj="getActiveStatus()"
        :max-din-inputs="getMaxDinInputs(index)"
        :model-id="editItem ? editItem.id : null"
        :hint-message="'hint_message' in attribute ? attribute.hint_message : '' "
        @input="clearInputValidationErrors(index + '_id')"
        @update-dis-data="disData => setDisData(disData)"
        @reset-error-message="errorKey => clearInputValidationErrors(errorKey)"
      />
      <intervals-dynamic-input
        v-if="attribute.type === 'intervals_dynamic_input'"
        :key="'intervals_dynamic_input-' + formData[index + '_id'] + '-' + key"
        class="ml-0 mr-0 mb-4"
        style="max-width: 100%"
        :label="attribute.label"
        :show-label="attribute.show_label"
        :error-messages="getDynamicInputSelectValidationErrors(index)"
        :clearable="attribute.clearable"
        :items.sync="relations[index]"
        :existing-dis-data="dis[index] ? formData[dis[index].key] : []"
        :remove-existing-entries="attribute.remove_created_entries"
        :edit-existing-entries="attribute.edit_created_entries"
        :options="attribute.autocomplete_options"
        :options2="attribute.autocomplete_options2"
        :type-not-selected-error-msg="attribute.type_not_selected_error_msg"
        :dis="dis[index]"
        :reminder-type="formData['reminder_type_id']"
        :edit-item-extracted-data="getEditItemExtractedData()"
        :active-status-condition-for-add-empty-dis-data-obj="getActiveStatus()"
        :max-din-inputs="getMaxDinInputs(index)"
        :model-id="editItem ? editItem.id : null"
        @input="clearInputValidationErrors(index + '_id')"
        @update-dis-data="disData => setDisData(disData)"
        @reset-error-message="errorKey => clearInputValidationErrors(errorKey)"
      />
      <service-intervals-input
        v-if="attribute.type === 'service_intervals_input'"
        :key="'service-intervals-input-' + formData[index + '_id'] + '-' + key"
        class="mb-4"
        :options="attribute.options"
        :error-messages="getDynamicInputSelectValidationErrors(index)"
        :existing-dis-data="dis[index] ? formData[dis[index].key] : []"
        :edit-item="editItem"
        :remove-existing-entries="attribute.remove_created_entries"
        :edit-existing-entries="attribute.edit_created_entries"
        :dis="dis[index]"
        @input="clearInputValidationErrors(index + '_id')"
        @update-dis-data="disData => setDisData(disData)"
        @reset-error-message="errorKey => clearInputValidationErrors(errorKey)"
      />
      <GeolocationModal
        v-if="attribute.type === 'input_location_picker'"
        :geolocation="formData[geolocationFormField] || {}"
        :geolocation-dialog="geolocationDialog"
        :dialog-title="$t('administration.set_gps_position')"
        @set-geolocation="latLng => setGeolocation(latLng, index)"
        @close-dialog="geolocationDialog = false"
      />
    </div>
    <p
      v-if="note"
      class="my-2 text-h5 error--text text-body-2 font-weight-bold"
    >
      {{ note }}
    </p>
  </div>
</template>

<script>
import Autocomplete from '@/modules/base-module/autocomplete/components/Autocomplete'
import DependantAutocompletes from '@/global/components/autocompletes/dependant-autocompletes/DependantAutocompletes'
import MultiAutocomplete from '@/modules/base-module/multi-autocomplete/MultiAutocomplete'
import DynamicInputSelect from '@/modules/fleet-module/dynamic-input-select/DynamicInputSelect'
import IntervalsDynamicInput from '@/modules/fleet-module/intervals-dynamic-input/IntervalsDynamicInput'
import ServiceIntervalsInput from '@/modules/fleet-module/components/ServiceIntervalsInput'
import WorkingHoursInput from '@/modules/people-module/components/WorkingHoursInput'
import LocationPicker from '@/modules/satellite-tracking-module/location-picker/LocationPicker'
import VehiclePicker from '@/modules/satellite-tracking-module/vehicle-picker/VehiclePicker'
import PersonPicker from '@/modules/people-module/components/PersonPicker'
import Many2manyAutocomplete from '@/modules/base-module/many2many_autocomplete/Many2manyAutocomplete'
import Many2manyAutocompleteList from '@/modules/base-module/many2many_autocomplete_list/Many2manyAutocompleteList'
import GeolocationModal from '@/global/components/modals/GeolocationModal'
import AinInput from '@/modules/base-module/ain-input/AinInput'
import DinInput from '@/modules/base-module/din-input/DinInput'
import BtInput from '@/modules/base-module/bt-input/BtInput'
import CalibrationValuesInput from '@/modules/base-module/calibration-values-input/CalibrationValuesInput'
import AutocompleteCombobox from '@/global/components/filters/AutocompleteCombobox'
import ReminderNotificationIntervalPicker from '@/modules/satellite-tracking-module/components/ReminderNotificationIntervalPicker'
import { formatSqlDate, formatIsoDate, formatIsoString } from '../../services/helpers/dates'
import {
  debounce,
  forEach,
  has,
  isArray,
  isEmpty,
  reduce
} from 'lodash'
import dayjs from 'dayjs'
import 'dayjs/locale/hr'
import { api } from '@/global/services/api'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('form-fields')
const {
  mapGetters: mapGettersConfig,
  mapActions: mapActionsConfig
} = createNamespacedHelpers('base/config')

export default {
  name: 'FormFields',

  components: {
    AinInput,
    BtInput,
    Autocomplete,
    AutocompleteCombobox,
    CalibrationValuesInput,
    DependantAutocompletes,
    DinInput,
    DynamicInputSelect,
    IntervalsDynamicInput,
    GeolocationModal,
    LocationPicker,
    Many2manyAutocomplete,
    Many2manyAutocompleteList,
    MultiAutocomplete,
    PersonPicker,
    ServiceIntervalsInput,
    VehiclePicker,
    WorkingHoursInput,
    ReminderNotificationIntervalPicker
  },

  props: {
    module: {
      type: String,
      default: 'base',
      required: true
    },
    viewConfig: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    },
    containerDialog: {
      type: Boolean,
      required: true
    },
    createDateKey: {
      type: String,
      default: ''
    },
    validationErrors: {
      type: Object,
      default: () => {}
    },
    fields: {
      type: Array,
      default: () => []
    },
    formType: {
      type: String,
      required: true
    },
    editItem: {
      type: Object,
      default: () => null
    },
    model: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      dis: {},
      formData: {},
      autocompleteOptions: [],
      dynamicFields: {},
      geolocationDialog: false,
      geolocationFormField: null,
      relations: {
        multi_autocomplete: {},
        tags: []
      },
      formattedDates: {},
      createDate: null,
      createFormattedDate: null,
      image: null,
      imagePreview: null,
      note: null,
      refreshVehicles: false,
      refreshLocations: false,
      timePickerMenuKey: 0,
      uploadedImages: []
    }
  },

  computed: {
    ...mapGetters(['dynamicArrayByKey']),
    ...mapGettersConfig(['user', 'isMaster', 'permissions', 'companyScope', 'language']),

    authenticatedUserIsEdited () {
      return this.model === 'users' && this.editItem && this.user.id === this.editItem.id
    },

    formFields () {
      return this.fields.length
        ? this.getTabFormFields()
        : this.viewConfig[this.formType].form
    },

    geolocationFormatted () {
      let returnString = ''
      const latLng = this.formData[this.geolocationFormField]
      if (this.geolocationFormField in this.formData && !isEmpty(latLng)) {
        const latitude = latLng.lat ? parseFloat(latLng.lat) : ''
        const longitude = latLng.lng ? parseFloat(latLng.lng) : ''
        returnString = `${latitude}, ${longitude}`
      }

      return returnString
    },

    editingMasterUser () {
      return this.isMaster && this.model === 'users' && this.editItem?.id === this.user.id
    },

    alarmNextTriggerFields () {
      if (['PersonAlarms', 'VehicleAlarms'].includes(this.$route.name)) {
        const startDate = this.$refs.datepicker_start_date?.[0]?.$children?.[1]?.$children?.[0].value

        return {
          start_date: startDate,
          interval_type_id: this.formData.interval_type_id,
          interval_value: this.formData.interval_value,
          active_days: this.formData.active_days,
          notify_days_before: this.formData.notify_days_before
        }
      }

      return null
    }
  },

  watch: {
    viewConfig: {
      deep: true,
      immediate: true,
      async handler (viewConfigValue) {
        const dynamicAttributesColumns = []
        for (const [key, {
          data_type: dataType,
          type,
          fields
        }] of Object.entries(viewConfigValue.columns)) {
          const column = viewConfigValue.columns[key]

          if ((type === 'switch' || type === 'free') && 'dynamic_settings' in column) {
            dynamicAttributesColumns.push(column)
            continue
          }

          if (type === 'input_location_picker') {
            this.geolocationFormField = key
          }

          if (key in viewConfigValue.edit_form.form && type === 'datepicker') {
            const field = viewConfigValue.edit_form.form[key].field
            this.formData[`formatted_${field}`] = null
          }

          if (dataType === 'many2many_relation' || dataType === 'one2many_relation') {
            this.$set(this.dis, key, {
              key: key,
              fields: fields,
              formDataFields: this.getFormDataFields(fields)
            })
          }

          if (dataType === 'array') {
            this.relations.multi_autocomplete = this.setMultiAutocompleteDefaultValues(key)
            this.autocompleteOptions = this.viewConfig.columns[key].autocomplete_options

            const routeString = this.autocompleteOptions[0].route || ''
            const { data: multiAutocompleteConfigData } = await api()[this.module].get((routeString))

            this.setMultiAutocompleteResourcesAndFormData(multiAutocompleteConfigData, key)
          }
        }
        this.setDynamicAttributes(dynamicAttributesColumns)
      }
    },
    editItem: {
      immediate: true,
      async handler (editItem) {
        if (editItem && !isEmpty(editItem)) {
          this.formattedDates[this.createDateKey] = formatSqlDate(editItem[this.createDateKey])

          this.formData = reduce(this.formData, (result, value, key) => {
            const empty = this.viewConfig.edit_form.form[key]?.data_type === 'boolean' ? 0 : null
            result[key] = (has(editItem, key) && !!editItem[key] ? editItem[key] : empty)
            return result
          }, {})

          for (const [key, column] of Object.entries(this.viewConfig.columns)) {
            // Check if field is from nested relationship
            if ('field' in column && column.field.includes('.')) {
              if ('dynamic_settings' in column) {
                const value = this.getNestedRelationField(column.field, editItem)
                const payload = {
                  keysArray: column.field.split('.'),
                  value: value
                }

                this.setDynamicArrayValue(payload)
                continue
              }
              else {
                // TODO: This will be solved when all the component data is transitioned to the
                //  Vuex store. Until then, this problem is intentionally not solved by sending
                //  parameters from the backend to avoid adding additional complexity.
                if (key.startsWith('fuel_probe_status_')) {
                  const value = this.getNestedRelationField(column.field, editItem)
                  if (value !== null) this.formData[key] = value
                }
                else {
                  this.formData[key] = this.getNestedRelationField(column.field, editItem)
                }
              }
            }

            // Sometimes there is an object passed as the select items, and since object keys
            // are always strings, we must convert number to a string if appropriate config is set
            if (
              'type' in column && column.type === 'selection' &&
              'string_value' in column && column.string_value
            ) {
              this.formData[key] = this.formData[key]?.toString()
            }

            if ('type' in column && column.type === 'autocomplete') {
              this.formData[key + '_id'] = null
              if (editItem[key]) {
                this.relations[key + '_id'] = [{
                  value: editItem[key].id,
                  text: editItem[key].name
                }]
              }
              if (has(editItem, key) && editItem[key]) {
                this.formData[key + '_id'] = editItem[key].id ?? null
              }

              // Check if field is from nested relationship
              if ('field' in column && column.field.includes('.')) {
                this.formData[key + '_id'] = this.getNestedRelationField(column.field, editItem)
              }
            }
            // If type is `dependant_autocompletes` proceed
            if ('type' in column && column.type === 'dependant_autocompletes') {
              // Extract `dependsOnKey` and `dependantKey` from the appropriate autocomplete_options object.
              const dependsOnKey = column.dependants.depends_on.autocomplete_options.model
              const dependantKey = column.dependants.dependant.autocomplete_options.model

              // Initialize `dependsOnKey` model id in `formData` with null
              this.formData[dependsOnKey + '_id'] = null

              // If edit item has relations loaded for `dependsOn` object
              // set its `id` and `name` values to relations object.
              if (editItem[dependantKey][dependsOnKey]) {
                this.relations[dependsOnKey + '_id'] = [{
                  value: editItem[dependantKey][dependsOnKey].id,
                  text: editItem[dependantKey][dependsOnKey].name
                }]
              }
              // Set `dependsOn` object id to formData as well.
              if (has(editItem, dependantKey) && has(editItem[dependantKey], dependsOnKey) && editItem[dependantKey][dependsOnKey]) {
                this.formData[dependsOnKey + '_id'] = editItem[dependantKey][dependsOnKey].id
              }

              // If edit item has relations loaded for `dependant` object
              // set its `id` and `name` values to relations object.
              if (editItem[dependantKey]) {
                this.relations[dependantKey + '_id'] = [{
                  value: editItem[dependantKey].id,
                  text: editItem[dependantKey].name
                }]
              }
              // Set `dependant` object id to formData as well.
              if (has(editItem, dependantKey) && editItem[dependantKey]) {
                this.formData[dependantKey + '_id'] = editItem[dependantKey].id ?? null
              }
            }

            if ('type' in column && column.type === 'multi_autocomplete') {
              const { data: selectedData } = await api()[this.module].get((`${key}/${editItem.id}` || ''))
              const routeString = this.autocompleteOptions[0].route || ''
              const { data: multiAutocompleteConfigData } = await api()[this.module].get((routeString))

              this.setMultiAutocompleteResourcesAndFormData(multiAutocompleteConfigData, key, selectedData)
            }

            if ('type' in column && column.type === 'autocomplete_combobox') {
              this.formData[key] = editItem[column.relation]
                .map(item => ({
                  text: item.name,
                  value: item.id
                }))
            }

            if ('type' in column && this.checkIfManyToManyType(column.type)) {
              if (editItem && !isEmpty(editItem) && editItem[key]) {
                this.formData[key] = editItem[key].map(item => item.id)
              }
            }

            // Dynamic fields must be checked inside "$nextTick" callback
            this.$nextTick(() => {
              if (
                'type' in column && column.type === 'dynamic' &&
                this.dynamicFields[key]?.type === 'location_picker'
              ) {
                this.formData[key] = editItem[key].map(item => item.id)
              }
            })

            if ('type' in column && column.type === 'dynamic_input_select' && editItem[key]) {
              const isOneToMany = column.data_type === 'one2many_relation'
              this.formData[key] = this.modifyDisData(editItem[key], isOneToMany, key)
            }
          }
          this.$nextTick(() => {
            if (this.dynamicFields.allowed_vehicle_use_from && this.dynamicFields.allowed_vehicle_use_to) {
              this.formData.allowed_vehicle_use_from = editItem.active_from
              this.formData.allowed_vehicle_use_to = editItem.active_to
            }
          })
          this.imagePreview = null
        }
      }
    },
    data: {
      immediate: true,
      deep: true,
      handler (data) {
        this.formData = data
      }
    },
    containerDialog: {
      immediate: true,
      handler (containerDialog) {
        this.createDate = null
        if (containerDialog) this.setSelectionsDefaultValue()
      }
    },
    formData: {
      deep: true,
      handler (formData) {
        for (const key of Object.keys(this.formattedDates)) {
          this.formattedDates[key] = formData[key] ? formatSqlDate(formData[key]) : null
        }
        this.$emit('update-data', formData)
      }
    },
    'formData.model_id': function (newValue, oldValue) {
      // If model_id is changed inside form, fetch digital sensors config for that model
      if (newValue && newValue !== oldValue) {
        this.getDigitalSensorsConfig()
      }
    },
    alarmNextTriggerFields: {
      deep: true,
      handler (value) {
        if (value && Object.values(value).every(item => !!item)) {
          const intervalValue = (+value.interval_type_id === 1 && +value.notify_days_before === +value.interval_value)
            ? +value.interval_value + 1
            : +value.interval_value

          // Keys are ids of the possible interval types
          const unitIntervalMap = {
            1: 'days',
            2: 'months',
            3: 'years'
          }

          // Keys are dayjs value for day in the week,
          // values are used for the active days selection
          const daysMap = {
            1: 1,
            2: 2,
            3: 3,
            4: 4,
            5: 5,
            6: 6,
            0: 7
          }

          // Parse the selected start date from the form
          const startDate = dayjs(value.start_date, 'DD.MM.YYYY', true)

          // Calculate next trigger date based on interval value and notify days before
          let nextDate = startDate
            .add(intervalValue, unitIntervalMap[value.interval_type_id])
            .subtract(+value.notify_days_before, 'days')

          // Save the copy of the next date to be used in the note message
          const initialNextDate = nextDate

          // Day of the week of the next trigger date (0-6)
          const nextDayOfTheWeek = nextDate.day()

          // Make a sorted copy of the active days from the form
          const activeDays = [...value.active_days]
          if (activeDays.length) activeDays.sort()

          // Check if next day of the week is not in selected active days
          if (activeDays.length && !activeDays.includes(daysMap[nextDayOfTheWeek].toString())) {
            const greater = []
            const lower = []

            // Loop over active days and push to appropriate arrays
            activeDays.forEach(day => {
              +day >= nextDayOfTheWeek ? greater.push(day) : lower.push(day)
            })

            // Flip keys and values of the day map object
            const flippedDays = Object.fromEntries(Object.entries(daysMap).map(a => a.reverse()))
            // Grab the first next day (1-7)
            const firstNext = [...greater, ...lower][0]
            // Get the next day number (0-6)
            const nextDay = flippedDays[firstNext]

            // Add one day until we get the date of the next available trigger date
            // Important to reassign "nextDate" because dayjs is immutable
            while (nextDate.day() !== +nextDay) {
              nextDate = nextDate.add(1, 'day')
            }

            // Update next trigger date note
            this.note = this.$t('satellite-tracking/alarm.next_trigger_note')
              .replace(':next_date', nextDate.format('DD.MM.YYYY (dddd)'))
              .replace(':initial_date', initialNextDate.format('DD.MM.YYYY, dddd'))
          }
          else {
            this.note = ''
          }
        }
        else {
          this.note = ''
        }
      }
    },
    createDate (date) {
      this.createFormattedDate = date ? formatSqlDate(date) : null
      this.$emit('update-create-date', date)
    },
    companyScope () {
      this.refreshVehicles = true
    }
  },

  mounted () {
    dayjs.locale(this.language)
  },

  methods: {
    ...mapActions(['setDynamicAttributes', 'setDynamicArrayValue']),
    ...mapActionsConfig(['fetchDigitalSensorsConfig']),

    showVehiclesWithActiveSensorsOnly () {
      return 'temperature_range' in this.formData || 'time_tolerance' in this.formData
    },
    timePickerMenuState (value) {
      if (!value) this.timePickerMenuKey++
    },

    getChosenTempSensorsPerVehicleData () {
      return this.formData.options?.alarm_attached_sensors_vehicles_data || null
    },

    getEditItemExtractedData () {
      // should be used and extended for any other data or value from editItem
      // that is needed for passing to a dis component
      return {
        traccarId: this.editItem?.traccar_id || null,
        modelName: this.editItem?.model?.name || null
      }
    },

    getActiveStatus () {
      if ('temperature_sensors_status' in this.formData) {
        return this.formData.temperature_sensors_status === 1
      }
    },

    getMaxDinInputs (index) {
      try {
        const match = this.viewConfig.tabs_config?.tabs.find(item => item.key === index)
        return match?.max_inputs || null
      }
      catch (e) {
        return null
      }
    },

    checkDisabled (attribute) {
      // first double exclamation marks are shorten ternary, and second doubles are because
      // of data type. Property master_app is returned as 0 or 1 so we translate it to true/false
      return !!(attribute.disable_master_synced && !!this.editItem?.master_app)
    },

    canEdit (model) {
      if (model.autocomplete_options?.model === 'tracker') {
        return 'tracker' in this.permissions &&
          this.permissions[model.autocomplete_options?.model].includes('edit')
      }

      return true
    },

    shouldShowWhenCompanyScope (attribute) {
      // Check `show_for_company_scope` attribute config and global company scope to determine
      // if field should be visible in the form
      if (
        'show_for_company_scope' in attribute &&
        !attribute.show_for_company_scope &&
        this.companyScope?.company
      ) {
        return false
      }

      return true
    },

    /**
     * This function exist only to debounce call to "fetchDigitalSensorsConfig" action,
     * necessary because there are multiple triggers of "formData" watcher
     */
    getDigitalSensorsConfig: debounce(function () {
      this.fetchDigitalSensorsConfig(this.formData?.model_id)
    }, 100),

    shouldShowLabelColumn (attribute, index) {
      if (attribute.type === 'dynamic' || 'depends_on' in attribute) {
        return !!this.dynamicFields[index]?.label
      }

      const types = [
        'image',
        'ain_input',
        'din_input',
        'bt_input',
        'service_intervals_input',
        'calibration_values_input',
        (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) ? '' : 'dynamic_input_select',
        'dependant_autocompletes',
        'many2many_autocomplete_list'
      ]

      if (this.dynamicFields.allowed_vehicle_use_from || this.dynamicFields.allowed_vehicle_use_to) {
        types.push('timepicker')
      }

      return !types.includes(attribute.type)
    },

    checkIfManyToManyType (type) {
      const manyToManyTypes = [
        'vehicle_picker',
        'vehicle_picker_with_list',
        'person_picker_with_list',
        'many2many_autocomplete',
        'many2many_autocomplete_list'
      ]

      return manyToManyTypes.includes(type)
    },

    checkForTextarea (attribute) {
      return this.shouldShowWhenCompanyScope(attribute) &&
        ['icon_tooltip', 'textarea'].includes(attribute.type)
    },

    getProfileImage (index) {
      if (!this.formData[index]) {
        return this.viewConfig.edit_form.form[index].default_form_image
      }
      else if (!(this.formData[index] instanceof File)) {
        return this.formData[index]
      }
      else {
        return this.imagePreview ? this.imagePreview : this.viewConfig.edit_form.form[index].default_form_image
      }
    },

    getFirstUploadedImage (index) {
      if (this.formData[index] && this.formData[index].length && !(this.formData[index][0] instanceof File)) {
        return this.formData[index][0]
      }

      return this.imagePreview || this.viewConfig.edit_form.form[index].default_form_image
    },

    viewUploadedImages (index) {
    },

    imageSelected (event, index) {
      if (event.target.files[0]) {
        this.formData[index] = event.target.files[0]

        const reader = new FileReader()
        reader.readAsDataURL(this.formData[index])
        reader.onload = e => {
          this.imagePreview = e.target.result
        }
      }
    },

    imagesSelected (event, index) {
      const uploadedImages = event.target.files

      if (uploadedImages.length) {
        this.formData[index] = []
      }

      if (event.target.files[0]) {
        const reader = new FileReader()
        reader.readAsDataURL(event.target.files[0])
        reader.onload = e => {
          this.imagePreview = e.target.result
        }
      }

      forEach(uploadedImages, uploadedImage => {
        this.uploadedImages.push(uploadedImage)
      })
      this.$emit('uploaded-images', this.uploadedImages)
    },

    formatItemSelectionList (selectionList, index = null, singleSelectableItem = null, disabledOptions = []) {
      const formattedSelection = []

      forEach(selectionList, (text, value) => {
        let disabled = false
        if (singleSelectableItem && this.formData[index]?.includes(singleSelectableItem)) {
          disabled = value !== singleSelectableItem
        }
        if (disabledOptions.includes(value)) {
          disabled = true
        }

        formattedSelection.push({
          text: text,
          value: value,
          disabled: disabled
        })
      })

      return formattedSelection
    },

    canSeePermissions (relation) {
      return relation.allowed
    },

    clearInputValidationErrors (key) {
      this.$emit('clear-errors', key)
    },

    /**
     * Sets `dependsOn` and `dependant` models IDs in the `formData` object.
     * @param key
     * @param valueObject
     */
    setDependantIdsAndClearInputValidationErrors (key, valueObject) {
      this.clearInputValidationErrors(key)

      for (const [key, value] of Object.entries(valueObject)) {
        this.formData[key] = value
      }
    },

    getSelectAllIcon (attribute, index) {
      const items = this.getSelectionItems(attribute, index)

      if (items.length === this.formData[index]?.length) {
        return 'mdi-close-box'
      }
      if (this.formData[index]?.length > 0 && this.formData[index]?.length < items.length) {
        return 'mdi-minus-box'
      }
      return 'mdi-checkbox-blank-outline'
    },

    toggleSelectAll (attribute, index) {
      let items = this.getSelectionItems(attribute, index)

      if ('single_selectable_item' in attribute) {
        items = items.filter(item => item.value !== attribute.single_selectable_item)
      }
      if ('disabled_options' in attribute && attribute.disabled_options.length) {
        items = items.filter(item => !attribute.disabled_options.includes(item.value))
      }

      if (items.length === this.formData[index]?.length) {
        this.$set(this.formData, index, [])
      }
      else {
        this.$set(this.formData, index, items.map(item => item.value))
      }

      this.$forceUpdate()
    },

    updateColor (event, index) {
      this.formData[index] = event.hex
      this.clearInputValidationErrors(index)
    },

    syncSelectedVehicles (data, index) {
      // this condition means that chosen alarm type is temperature range
      if ('temperature_range' in this.formData || 'time_tolerance' in this.formData) {
        this.formData[index] = data.map(item => item.vehicleId)
        // this sends data when creating and updating alarm
        this.formData.alarm_attached_sensors_vehicles_data = this.mapSensorVehicleData(data)
        // below part is for syncing fields in vehicle picker. When returned from backend
        // attached sensor data are placed in options field what is used to send
        // data to vehicle picker. See getChosenTempSensorsPerVehicleData method below.
        // Also needed because of different structure when creating alarm for temperature range,
        // as it is not only for vehicle but for sensor attached to vehicle. See populateSelectedVehicles()
        // in VehiclePicker component
        if (!this.formData.options) {
          this.$set(this.formData, 'options', {})
          this.$set(this.formData.options, 'alarm_attached_sensors_vehicles_data', this.mapSensorVehicleData(data))
        }
        else this.formData.options.alarm_attached_sensors_vehicles_data = this.mapSensorVehicleData(data)
      }
      else this.formData[index] = data.map(item => item.id)
      this.clearInputValidationErrors(index)
    },

    mapSensorVehicleData (data) {
      return data.map(item => ({
        sensorId: +item.id.replace('sensorId-', ''),
        vehicleId: item.vehicleId
      }))
    },

    syncSelectedLocations (data, index) {
      this.formData[index] = data.map(item => item.id)
      this.clearInputValidationErrors(index)
    },

    syncSelectedItemsFromPicker (data, index) {
      this.formData[index] = data.map(item => item.id)
      this.clearInputValidationErrors(index)
    },

    setDisData (disData) {
      this.$set(this.formData, disData.dis_key, [...disData.data])
    },

    modifyDisData (disData, isOneToMany, key) {
      return isArray(disData) && disData.length ? this.disFieldsFormatted(disData, isOneToMany, key) : []
    },

    disFieldsFormatted (disData, formData = false, key) {
      return disData.map(disDataObject => this.disFieldFormatted(disDataObject, formData, key))
    },

    disFieldFormatted (disDataObject, formData = false, key) {
      const fields = formData ? this.dis[key].formDataFields : this.dis[key].fields
      const obj = {}
      for (const [key, value] of Object.entries(fields)) {
        switch (value.type) {
          case 'primary_key':
            obj[key] = parseInt(disDataObject[key])
            break
          case 'foreign_key':
          case 'foreign_key_autocomplete':
            obj[key] = parseInt(disDataObject[key]) || parseInt(disDataObject.id)
            break
          case 'string':
          case 'checkbox':
            obj[key] = disDataObject[key]
            break
          case 'datepicker':
            obj[key] = formatIsoDate(disDataObject[key])
            break
          case 'datetimepicker':
            obj[key] = formatIsoString(disDataObject[key])
            break
        }
      }
      return obj
    },

    setMultiAutocompleteResourcesAndFormData (multiAutocompleteConfigData, key, selectedData = null) {
      this.autocompleteOptions.forEach(option => {
        const { module: moduleName } = option
        const resources = multiAutocompleteConfigData[moduleName]
        if (resources) {
          const relationKey = `${moduleName}_${key}_id`
          this.setMultiAutocompleteResources(resources, relationKey, key)
          this.setMultiAutocompleteSelectedIdInFormDataIfNotEmpty(selectedData, relationKey, moduleName)
        }
      })
    },

    setMultiAutocompleteResources (resources, relationKey, key) {
      const { display_field: displayField } = this.viewConfig.columns[key].selected

      resources.forEach(resource => {
        const obj = {
          value: resource.id,
          text: resource[displayField]
        }

        this.pushToMultiAutocompleteKeyArrayIfExistsOrCreateIt(relationKey, obj)
      })
    },

    setMultiAutocompleteSelectedIdInFormDataIfNotEmpty (selectedData, relationKey, moduleName) {
      // If its separated module then remove module prefix
      let modifiedModuleName
      const fleetModules = ['administration', 'fleet', 'locations', 'people', 'satellite-tracking']
      if (moduleName.includes('/')) modifiedModuleName = moduleName.split('/')[1]

      let selectedIdsForModule
      if (selectedData) {
        if (modifiedModuleName) {
          selectedIdsForModule = selectedData
            .filter(resource => resource.name.startsWith('base.') && resource.name.endsWith(modifiedModuleName))
            .map(resource => resource.id)
        }
        else if (fleetModules.includes(moduleName)) {
          selectedIdsForModule = selectedData
            .filter(resource => resource.name.includes('administration') ||
              resource.name.includes('fleet') ||
              resource.name.includes('locations') ||
              resource.name.includes('people') ||
              resource.name.includes('satellite-tracking'))
            .map(resource => resource.id)
        }
        else {
          selectedIdsForModule = selectedData
            .filter(resource => resource.name.includes(moduleName) &&
              (
                !resource.name.includes('base.') ||
                (
                  !resource.name.includes('sims_trackers') &&
                  !resource.name.includes('companies') &&
                  !resource.name.includes('reports'))
              )
            )
            .map(resource => resource.id)
        }
      }
      if (selectedIdsForModule) {
        this.formData[relationKey] = selectedIdsForModule
      }
    },

    setMultiAutocompleteDefaultValues (key) {
      const { autocomplete_options: autocompleteOptions } = this.viewConfig.columns[key]
      const defaultValues = {}

      autocompleteOptions.forEach(option => {
        const moduleName = option.module.includes('-') ? option.module.replace('-', '_') : option.module
        defaultValues[`${moduleName}_${key}_id`] = []
      })

      return defaultValues
    },

    checkIfDynamicSwitch (attribute) {
      return attribute.type === 'switch' &&
        'dynamic_settings' in attribute
    },

    checkIfDynamicText (attribute) {
      return attribute.type === 'free' &&
        'dynamic_settings' in attribute
    },

    checkForAutocomplete (attribute, index) {
      return (
        attribute.type === 'autocomplete' ||
        this.shouldShowDynamicAttribute(attribute, index, 'autocomplete')
      ) && !this.previewOnlyField(attribute)
    },

    previewOnlyField (attribute) {
      return 'preview_only' in attribute && attribute.preview_only
    },

    pushToMultiAutocompleteKeyArrayIfExistsOrCreateIt (relationKey, obj) {
      const { multi_autocomplete: multiAutocomplete } = this.relations
      if (has(multiAutocomplete, relationKey)) {
        multiAutocomplete[relationKey].push(obj)
      }
      else {
        multiAutocomplete[relationKey] = [obj]
      }
    },

    getFormDataFields (fields) {
      const obj = {}
      for (const [key, value] of Object.entries(fields)) {
        if (value.form_data) {
          obj[key] = value
        }
      }

      return obj
    },

    getTabFormFields () {
      const form = this.viewConfig[this.formType].form
      const formFields = {}

      this.fields.forEach(field => {
        if (field in form) {
          formFields[field] = form[field]
        }
      })

      return formFields
    },

    shouldShowSwitchOrSlider (attribute, index) {
      if ('depends_on' in attribute) {
        const selectedValue = this.formData[attribute.depends_on + '_id']
        const shouldShow = Array.isArray(attribute.show_when)
          ? attribute.show_when.includes(+selectedValue)
          : +selectedValue === attribute.show_when

        // If field should be shown, set value in the dynamic fields object (to be able to show the label)
        if (shouldShow) {
          this.$set(this.dynamicFields, index, attribute)
        }

        // Otherwise, remove value from the dynamic fields object (to be able to hide the label)
        // Also, delete value from the form data object to prevent potential errors
        else {
          this.$delete(this.dynamicFields, index)
          this.$delete(this.formData, index)
        }

        return shouldShow
      }

      return !('dynamic_settings' in attribute)
    },

    shouldShowDynamicAttribute (attribute, index, type) {
      if (attribute.type === 'dynamic') {
        if (attribute.show_dynamic_field) {
          const selectedValue = this.formData[attribute.depends_on]

          // Convert boolean to number (0 or 1), because we cannot use booleans as object keys
          const typeMapValue = attribute.depends_on_type === 'bool' && typeof selectedValue === 'boolean'
            ? +selectedValue
            : selectedValue

          if (typeMapValue && typeMapValue.id) {
            selectedValue
              ? this.$set(this.dynamicFields, index, attribute.options_map[typeMapValue.id])
              : this.$delete(this.dynamicFields, index)
          }
          else {
            selectedValue
              ? this.$set(this.dynamicFields, index, attribute.options_map[typeMapValue])
              : this.$delete(this.dynamicFields, index)
          }

          return this.dynamicFields[index]?.type === type
        }
        else {
          let selectedValue = this.formData[attribute.depends_on + '_id']
          if (attribute.depends_on_type === 'bool') {
            selectedValue = this.formData[attribute.depends_on]
          }

          // Convert boolean to number (0 or 1), because we cannot use booleans as object keys
          const typeMapValue = attribute.depends_on_type === 'bool' && typeof selectedValue === 'boolean'
            ? +selectedValue
            : selectedValue

          selectedValue
            ? this.$set(this.dynamicFields, index, attribute.type_map[typeMapValue])
            : this.$delete(this.dynamicFields, index)

          return this.dynamicFields[index]?.type === type
        }
      }

      return false
    },

    shouldShowTimePicker (attribute, index, type) {
      if (this.dynamicFields.allowed_vehicle_use_from || this.dynamicFields.allowed_vehicle_use_to) {
        if (attribute.show_dynamic_timepicker) {
          const selectedValue = this.formData[attribute.depends_on + '_id']

          selectedValue ? this.$set(this.dynamicFields, index, attribute.type_map[selectedValue]) : this.$delete(this.dynamicFields, index)

          return this.dynamicFields[index]?.type === type
        }
      }
      else if (attribute.type === type) {
        return true
      }
      return false
    },

    getFieldType (attribute, index) {
      return this.dynamicFields[index] ? this.dynamicFields[index].data_type : attribute.data_type
    },

    getFieldLabel (attribute, index) {
      return this.dynamicFields[index] ? this.dynamicFields[index].label : attribute.label
    },

    getFieldMaxSelect (attribute, index) {
      return this.dynamicFields[index] ? this.dynamicFields[index].selectionLimit : attribute.selectionLimit
    },

    isMultipleSelection (attribute, index) {
      return this.dynamicFields[index] ? !!this.dynamicFields[index].multiple : !!attribute.multiple
    },

    getSelectionItems (attribute, index) {
      return this.dynamicFields[index]
        ? this.formatItemSelectionList(this.dynamicFields[index].selectionList)
        : this.formatItemSelectionList(attribute.selectionList, index, attribute.single_selectable_item, attribute.disabled_options ?? [])
    },

    getAutocompleteOptions (attribute, index) {
      return this.dynamicFields[index]
        ? this.dynamicFields[index].autocomplete_options
        : this.viewConfig.columns[index].autocomplete_options
    },

    getAutocompleteIndex (index) {
      return this.dynamicFields[index] ? index : index + '_id'
    },

    /**
     * Get dependants field.
     * @param attribute
     * @returns string
     */
    getDependantAutocompletesIndex (attribute) {
      return attribute.dependants.dependant.field
    },

    /**
     * Get the IDs for `dependsOn` and `dependant` that were set in the `formData` object
     * when user picked some values in dependant autocompletes.
     * @param attribute
     * @returns {{dependsOnId, dependantId}}
     */
    getDependantIds (attribute) {
      const dependsOnModelId = attribute.dependants.depends_on.field
      const dependantModelId = attribute.dependants.dependant.field

      return {
        dependsOnId: this.formData[dependsOnModelId],
        dependantId: this.formData[dependantModelId]
      }
    },

    getDynamicInputSelectValidationErrors (index) {
      return Object.keys(this.validationErrors)
        .filter(key => key.startsWith(index))
        .reduce((obj, key) => {
          obj[key] = this.validationErrors[key]
          return obj
        }, {})
    },

    getMultipleSelectValidationErrors (index) {
      return Object.keys(this.validationErrors)
        .filter(key => key.startsWith(index))
        .reduce((array, key) => {
          array.push(this.validationErrors[key])
          return array
        }, [])
        .flat(1)
    },

    /**
     * Return nested object value using the `nestedRelationString` parameter path
     * from the `containingObject` object.
     * @param nestedRelationString
     * @param containingObject
     * @returns {*|null}
     */
    getNestedRelationField (nestedRelationString, containingObject) {
      const relationsArray = nestedRelationString.split('.')
      const field = relationsArray.splice(relationsArray.length - 1)

      let result = { ...containingObject }
      for (let i = 0; i < relationsArray.length; i++) {
        result = result[relationsArray[i]]
        if (!result) {
          break
        }
      }

      return result ? result[field] : null
    },

    openGeolocationDialog () {
      this.geolocationDialog = true
    },

    setGeolocation (latLng, key) {
      this.clearInputValidationErrors(key)
      this.$set(this.formData, this.geolocationFormField, latLng)
    },

    getDynamicArrayValue (attr) {
      const array = attr.dynamic_settings.array
      const name = attr.dynamic_settings.name
      const attribute = attr.dynamic_settings.attribute
      const keysObject = { array, name, attribute }
      return this.dynamicArrayByKey(keysObject)
    },

    setDynamicArray (event, attribute) {
      const payload = {
        keysArray: attribute.field.split('.'),
        value: event
      }
      this.setDynamicArrayValue(payload)
    },

    setSelectionsDefaultValue () {
      let thereIsSelectionWithDefaultValue = false
      for (const [key, column] of Object.entries(this.viewConfig.columns)) {
        if ('type' in column && column.type === 'selection' && 'default_value' in column) {
          thereIsSelectionWithDefaultValue = true
          this.formData[key] = column.default_value
        }
      }
      if (thereIsSelectionWithDefaultValue) this.$emit('update-data', this.formData)
    },

    setSelectionValue (value, attribute, index) {
      if (
        'multiple' in attribute && attribute.multiple &&
        'single_selectable_item' in attribute && value.includes(attribute.single_selectable_item)
      ) {
        this.$set(
          this.formData,
          index,
          value.filter(item => item === attribute.single_selectable_item)
        )
      }
      else {
        this.$set(this.formData, index, value)
      }

      this.clearInputValidationErrors(index)
    },

    shouldToggleSelectAllBeDisabled (attribute, index) {
      return Array.isArray(this.formData[index])
        ? this.formData[index].includes(attribute.single_selectable_item)
        : false
    },

    setInputGeolocation (value, field) {
      this.clearInputValidationErrors(field)

      const commaCount = (value.match(/,/g) || []).length
      const valueTrimmed = value.trim()
      if (valueTrimmed === '') {
        this.formData[field] = {
          lat: null,
          lng: null
        }
      }

      if (commaCount === 1) {
        const valueTrimmed = value.trim()
        const numbersCount = (valueTrimmed.match(/[0-9.]+/g) || []).length

        if ([1, 2].includes(numbersCount)) {
          const valueWithoutSpaces = value.replace(/\s+/g, '')
          const valueArray = valueWithoutSpaces.split(',')
          this.formData[field] = {
            lat: !isEmpty(valueArray[0]) ? Number.parseFloat(valueArray[0]).toFixed(9) : null,
            lng: !isEmpty(valueArray[1]) ? Number.parseFloat(valueArray[1]).toFixed(9) : null
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
.form-fields {
  p {
    font-size: 0.8225rem;
    line-height: 1.5;
  }

  .v-input {
    font-size: 0.8rem !important;
  }

  .avatar-image {
    width: 90%;
  }
}

.edit-dialog-card {
  .v-item-group {
    &:first-of-type {
      width: 20% !important;
      .v-slide-group__wrapper {
        margin-top: 0.5rem !important;
      }
    }
    &:nth-of-type(2) {
      width: 80% !important;
    }
  }
}
</style>
