<template>
  <v-container
    fluid
    class="ma-0 show-page"
  >
    <v-card
      :disabled="fetchingData"
      class="px-5 py-3"
    >
      <ShowPageSkeleton v-if="pageIsLoading" />
      <!-- Content -->
      <div
        v-if="!pageIsLoading"
        class="px-5 pt-4"
      >
        <!-- Buttons back and edit -->
        <v-row class="ma-0 flex justify-space-between">
          <v-btn
            color="primary"
            primary
            class="px-7"
            @click="$router.go(-1)"
          >
            {{ $t('base.back') }}
          </v-btn>
          <v-btn
            v-if="canEdit()"
            color="primary"
            primary
            class="px-7"
            @click="openCreateEditDialog()"
          >
            {{ $t('base.edit') }}
          </v-btn>
        </v-row>
        <!-- Title -->
        <v-row class="ma-0 mt-3">
          <p class="ma-0 title">
            {{ viewConfig.title }}
          </p>
        </v-row>
        <!-- Img and basic info -->
        <v-row class="ma-0 mt-2">
          <v-col
            v-if="hasImage(showItem.item)"
            class="col-lg-2 col-auto"
            :class="{'pl-0': !$vuetify.breakpoint.xs}"
          >
            <v-img
              v-if="viewConfig.edit_form"
              :src="getImagePath(showItem.item)"
              :width="$vuetify.breakpoint.xs ? '100%' : '255'"
            />
          </v-col>
          <v-col
            class="col-lg-10 details-wrapper"
            :class="{'px-0': $vuetify.breakpoint.xs}"
          >
            <div class="item-tables-container">
              <template v-if="twoTables && $vuetify.breakpoint.width > 960">
                <DataColumn :fields="firstPartElements" />
                <DataColumn :fields="secondPartElements" />
              </template>
              <DataColumn
                v-else
                :fields="showItem.fields"
              />
            </div>
          </v-col>
        </v-row>
        <!-- Tabbed details for smAndUp screens-->
        <v-row
          v-if="!$vuetify.breakpoint.xs"
          class="mt-10 mb-2 mx-0 resource-tabs"
        >
          <v-card
            fluid
            width="100%"
          >
            <v-tabs v-model="selectedTab">
              <v-tab
                v-for="tab in viewConfig.tabs"
                :key="tab.key"
                class="table-tabs"
              >
                {{ tab.label }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="selectedTab">
              <v-tab-item
                v-for="tab in viewConfig.tabs"
                :key="tab.key"
              >
                <show-details
                  v-if="namespace"
                  :tab="tab"
                  :module="module"
                  :fetching-data="fetchingData"
                  :namespace="namespace"
                  :data-table-data="dataTableData"
                  :show-item="showItem"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-row>
        <!-- Details for mobile view -->
        <v-row v-else>
          <v-col
            v-for="tab in viewConfig.tabs"
            :key="tab.key"
            class="table-tabs mx-0"
            :cols="$vuetify.breakpoint.xs ? 12 : ''"
          >
            <p class="font-weight-bold">
              {{ tab.label }}
            </p>
            <show-details
              v-if="namespace"
              :tab="tab"
              :module="module"
              :fetching-data="fetchingData"
              :namespace="namespace"
              :data-table-data="dataTableData"
              :show-item="showItem"
            />
          </v-col>
        </v-row>
      </div>
    </v-card>

    <create-or-update-component
      v-if="createOrEditDialog"
      :module="module"
      :model="model"
      :create-or-update-dialog="createOrEditDialog"
      :view-config="viewConfig"
      :item-id="showItem.item.id"
      @closeEditOrUpdateDialog="closeEditDialog"
      @itemSaved="closeDialogAndFetchItem"
    />
  </v-container>
</template>

<script>
import DataColumn from '@/global/components/show-details/fields/data-column/DataColumn'
import ShowPageSkeleton from '@/global/components/skeletons/ShowPageSkeleton'
import { has } from 'lodash'
import ShowDetails from '@/global/components/show-details/ShowDetails'
import CreateOrUpdateComponent from '@/global/components/CreateOrUpdateComponent'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('base/config')

export default {
  name: 'Show',

  components: {
    DataColumn,
    ShowDetails,
    ShowPageSkeleton,
    CreateOrUpdateComponent
  },

  props: {
    module: {
      type: String,
      default: 'base',
      required: true
    },
    model: {
      type: String,
      required: true
    },
    showItem: {
      type: Object,
      required: true
    },
    singleColumnTableCondition: {
      type: Number,
      default: 8
    },

    dataTableData: {
      type: Object,
      default: () => ({})
    },
    fetch: {
      type: Function,
      required: true
    },
    namespace: {
      type: String,
      required: true
    },
    autoRefresh: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
      fetchingData: false,
      pageIsLoading: true,
      twoTables: false,
      createOrEditDialog: false,
      selectedTab: null,
      firstPartElements: [],
      secondPartElements: [],
      routeString: null,
      autoRefreshInterval: null
    }
  },

  computed: {
    ...mapGetters(['permissions']),
    viewConfig () {
      return this.$store.getters[this.namespace + '/viewConfig']
    }
  },

  watch: {
    showItem: function (showItem) {
      // By design, if there is more than 8 fields in show details view table, it should be split into two columns.
      // Condition added as prop width default value so it could be changed when creating component
      this.twoTables = showItem.fields.length >= this.singleColumnTableCondition
      if (this.twoTables) {
        this.separateElementsIntoTwoColumns(showItem.fields)
      }
    }
  },

  mounted () {
    setTimeout(() => (this.pageIsLoading = false), 200)
    if (this.autoRefresh) {
      this.autoRefreshInterval = setInterval(() => this.getItem(), this.autoRefresh)
    }
  },

  destroyed () {
    if (this.autoRefreshInterval) {
      clearInterval(this.autoRefreshInterval)
    }
  },

  methods: {
    separateElementsIntoTwoColumns (fields) {
      const fieldsLength = fields.length
      const firstPartElementsLength = Math.ceil(fieldsLength / 2)
      this.firstPartElements = fields.slice(0, firstPartElementsLength)
      const secondPartElementsLength = fieldsLength - firstPartElementsLength
      this.secondPartElements = fields.slice(firstPartElementsLength, firstPartElementsLength + secondPartElementsLength)
    },

    canEdit () {
      return this.permissions[this.module] && this.permissions[this.module].includes('edit')
    },

    async getItem () {
      try {
        await this.fetch(this.showItem.item.id)
      }
      finally {
        this.fetchingData = false
      }
    },

    clearInputValidationErrors (key) {
      if (has(this.validationErrors, key)) {
        this.validationErrors[key] = []
      }
    },

    async openCreateEditDialog () {
      this.createOrEditDialog = true
    },

    closeEditDialog () {
      this.groups = []
      this.createOrEditDialog = false
      this.$emit('close-edit')
    },

    closeDialogAndFetchItem () {
      this.closeEditDialog()
      this.fetchingData = true
      this.getItem()
    },

    getImagePath (item) {
      const formConfig = this.viewConfig.edit_form?.form
      if ('logo' in item) {
        return item.logo ? item.logo : formConfig.logo.default_image
      }
      else if ('image' in item) {
        return item.image ? item.image : formConfig.image.default_image
      }
      else if ('avatar' in item) {
        return item.avatar ? item.avatar : formConfig.avatar.default_image
      }
      else if (this.model === 'vehicle') {
        return '/img/default_images/car.png'
      }
      return ''
    },
    hasImage (item) {
      if ('logo' in item) {
        return true
      }
      else if ('image' in item) {
        return true
      }
      else if ('avatar' in item) {
        return true
      }
      else if (this.model === 'vehicle') {
        return true
      }

      return false
    }
  }
}
</script>

<style lang="scss">
.show-page {
  .v-item-group {
    margin-top: 0.75rem;
  }
  .v-item-group.v-slide-group {
    height: 34px;
  }
  .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
  }
  .v-slide-group__content.v-tabs-bar__content {
    color: rgba(0, 0, 0, 0.87);
    .v-tab {
      text-transform: none !important;
      padding: 0;
      &:before {
        background-color: transparent !important;
      }
    }
    .v-tabs-slider-wrapper {
      color: var(--v-primary-base);
      height: 4px !important;
    }
  }
  .logo-image {
    width: 100%;
  }
  .details-wrapper {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    .item-tables-container {
      flex-grow: 1;
      max-width: 70rem;
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      > div {
        flex-grow: 1;
        padding: 0 1.5rem;
      }
    }
  }
}

// TODO: Refactor below css with edit dialog component
.edit-dialog-card {
  border: 2px solid var(--v-grey-lighten-1-base) !important;
  .v-card__title {
    padding: 10px 8px 8px 16px;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.6);
  }
}

.table-data-preview {
  width: 30px;
  height: 30px;
  border-radius: 4px;
}
::v-deep .theme--light.v-tabs > .v-tabs-bar {
  width: 25%;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.table-tabs {
  margin-right: 5rem;
}
.v-tabs--vertical > .v-tabs-bar {
  .v-tab {
    height: 36px;
    font-size: 0.8125rem;
    font-weight: 500;
    line-height: 1rem;
    letter-spacing: normal;
    text-transform: none;
  }
  .v-tabs-slider {
    width: 6px !important;
    background-color: var(--v-primary-base);
  }
}
.v-tab--active:not(.table-tabs) {
  background: var(--v-light-grey-base);
}
::v-deep .v-tabs-slider-wrapper {
  height: 36px;
  width: 6px !important;
  .v-tabs-slider {
    background-color: var(--v-primary-base);
  }
}

.resource-tabs {
  .v-tab {
    font-size: 0.85rem;
  }
}
.header-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
div.tabs-wrapper {
  border-top: 2px solid var(--v-grey-lighten-1-base);
  .form-tabs {
    .v-window-item {
      min-height: 65vh;
      .form-inputs-card {
        border-radius: 0 !important;
      }
    }
    .v-item-group {
      &:first-of-type {
        border-right: 2px solid var(--v-grey-lighten-1-base) !important;
        .v-slide-group__wrapper {
          margin-top: 0.5rem;
        }
      }
    }
  }
  .v-card__actions {
    background-color: var(--v-light-grey-base);
  }
}

.toolbar-container {
  padding: 0 1rem;
}

p {
  line-height: 1.5;
}

</style>
