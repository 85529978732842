export default {
  path: 'excavation-service/excavation-settings/road_categories',
  name: 'ExcavationSettingsRoadCategories',
  meta: {
    title: 'road-maintenance/dropdown-types.road_category',
    create_title: 'road-maintenance/excavation-dropdown-type.create_road_category',
    edit_title: 'road-maintenance/excavation-dropdown-type.edit_road_category',
    delete_title: 'road-maintenance/excavation-dropdown-type.delete_road_category',
    confirm_delete: 'road-maintenance/excavation-dropdown-type.confirm_delete_road_category',
    import_title: 'road-maintenance/excavation-dropdown-type.import_road_category',
    name_label: 'road-maintenance/dropdown-types.road_category',
    name: 'ROAD_CATEGORY'
  },
  component: () => import(/* webpackChunkName: "road-maintenance" */ '../views/ExcavationSettingsIndex')
}
