export default {
  path: 'excavation-service/excavation-settings/responsible_person_of_zagreb_roads',
  name: 'ExcavationSettingsExcavationSettingsResponsiblePersonOfZagrebRoads',
  meta: {
    title: 'road-maintenance/dropdown-types.zc_responsible_person',
    create_title: 'road-maintenance/excavation-dropdown-type.create_zc_responsible_person',
    edit_title: 'road-maintenance/excavation-dropdown-type.edit_zc_responsible_person',
    delete_title: 'road-maintenance/excavation-dropdown-type.delete_zc_responsible_person',
    confirm_delete: 'road-maintenance/excavation-dropdown-type.confirm_delete_zc_responsible_person',
    import_title: 'road-maintenance/excavation-dropdown-type.import_zc_responsible_person',
    name_label: 'road-maintenance/excavation-dropdown-type.name',
    name: 'ZC_RESPONSIBLE_PERSON'
  },
  component: () => import(/* webpackChunkName: "road-maintenance" */ '../views/ExcavationSettingsIndex')
}
