import { api } from '@/global/services/api'

const state = {
  showItem: {
    item: {},
    fields: []
  },
  viewConfig: {}
}

const getters = {
  showItem: state => state.showItem,
  viewConfig: state => state.viewConfig
}

const actions = {
  async fetch ({ commit, state }, id) {
    try {
      if (!id) {
        id = 0
      }
      const { config, data } = await api('road-maintenance').get(`tasks/${id}/details`)

      if (data.item && data.item.id) {
        commit('SET_SHOW_ITEM', data)
        commit('SET_VIEW_CONFIG', config)
      }
    }
    catch (e) {
      console.dir(e)
    }
  }
}

const mutations = {
  SET_SHOW_ITEM: (state, payload) => (state.showItem = payload),
  SET_VIEW_CONFIG: (state, payload) => (state.viewConfig = payload)
}

export default {
  state,
  getters,
  actions,
  mutations
}
