import { api } from '@/global/services/api'
import { isEmpty, set } from 'lodash'

const state = {
  initialized: false,
  authenticated: false,
  isMaster: false,
  partiallyInitialized: false,
  companyScopeUpdated: false,
  company: {
    logo: '',
    logoSmall: '',
    name: '',
    phoneNumber: ''
  },
  language: null,
  menu: {},
  user: {},
  userConfig: {},
  userMenu: {},
  companyScope: {},
  permissions: {},
  traccarToken: '',
  authUserSessionId: '',
  digitalSensorsConfig: null
}

const getters = {
  isInitialized: state => state.initialized === true,

  isAuthenticated: state => state.authenticated === true,

  isMaster: state => state.isMaster,

  isPartiallyInitialized: state => state.partiallyInitialized === true,

  isCompanyScopeUpdated: state => state.companyScopeUpdated === true,

  logo: state => state.company.logo,

  logoSmall: state => state.company.logoSmall,

  companyName: state => state.company.name,

  companyPhoneNumber: state => state.company.phoneNumber,

  menu: state => state.menu,

  user: state => state.user,

  userConfig: state => state.userConfig,

  userMenu: state => state.userMenu,

  companyScope: state => state.companyScope,

  permissions: state => state.permissions,

  userMenuComponentNames: state => !isEmpty(state.userMenu)
    ? state.userMenu.map(menu => menu.vue_name)
    : [],

  language: state => state.language,

  traccarToken: state => state.traccarToken,

  authUserSessionId: state => state.authUserSessionId,

  digitalSensorsConfig: state => state.digitalSensorsConfig,

  canEditTrackers: function (state) {
    return Array.isArray(state.permissions.tracker) && state.permissions.tracker.includes('edit')
  }
}

const actions = {
  async fetch ({ commit, getters }, payload) {
    const { forceRecreation = false } = payload || {}

    if (!getters.isPartiallyInitialized || forceRecreation === true) {
      try {
        const { data } = await api().base.get('/config')

        const {
          authenticated,
          is_master: isMaster,
          translations,
          menu,
          company,
          language,
          user,
          user_config: userConfig,
          user_menu: userMenu,
          company_scope: companyScope,
          permissions
        } = data

        commit('base/translations/SET_TRANSLATIONS', translations, { root: true })
        commit('SET_MENU', menu)
        commit('SET_COMPANY', company)
        commit('SET_LANGUAGE', language)
        commit('SET_IS_MASTER', isMaster)

        if (authenticated) {
          commit('SET_AUTHENTICATED')
          commit('SET_INITIALIZED')
          commit('UNSET_PARTIALLY_INITIALIZED')
          commit('SET_USER', user)
          commit('SET_USER_CONFIG', userConfig)
          commit('SET_USER_MENU', userMenu)
          commit('SET_COMPANY_SCOPE', companyScope)
          commit('SET_PERMISSIONS', permissions)
        }
        else {
          commit('SET_PARTIALLY_INITIALIZED')
          commit('UNSET_AUTHENTICATED')
          commit('UNSET_INITIALIZED')
        }
      }
      catch (e) {
        console.dir(e) // TODO: error-handling
      }
    }
  },

  async fetchTraccarToken ({ commit }) {
    const data = await api()['satellite-tracking'].get('/traccar-token')
    commit('SET_TRACCAR_TOKEN', data.token ?? '')
  },

  async fetchWebsocketUserToken ({ commit }) {
    const data = await api()['satellite-tracking'].get('/websocket-get-user-token')
    commit('SET_USER_SESSION_ID', data.token ?? '')
  },

  async fetchDigitalSensorsConfig ({ commit }, payload) {
    const { data } = await api('base').get('/digital-sensors-config', { model_id: payload })
    commit('SET_DIGITAL_SENSORS_CONFIG', data ?? null)
  },

  setUser ({ commit }, payload) {
    commit('SET_USER', payload)
  },

  resetUserReadNewsCount ({ commit }) {
    commit('RESET_USER_READ_NEWS_COUNT')
  },

  updateCompanyScope ({ commit, state }, payload) {
    commit('SET_COMPANY_SCOPE_UPDATED')

    commit('SET_COMPANY_SCOPE', {
      ...state.companyScope,
      company: payload.data ? { ...payload.data } : null
    })
  },

  unsetCompanyScopeUpdated ({ commit }) {
    commit('UNSET_COMPANY_SCOPE_UPDATED')
  },

  updateDigitalSensorsConfig ({ commit }, payload) {
    commit('SET_DIGITAL_SENSORS_CONFIG', payload)
  },

  async updateUserConfigKey ({ commit, state }, payload) {
    commit('SET_USER_CONFIG_KEY', payload)
    await api().base.patch(`user/${state.user.id}/config`, { config: state.userConfig })
  }
}

const mutations = {

  SET_INITIALIZED: state => (state.initialized = true),

  UNSET_INITIALIZED: state => (state.initialized = false),

  SET_AUTHENTICATED: state => (state.authenticated = true),

  UNSET_AUTHENTICATED: state => (state.authenticated = false),

  SET_PARTIALLY_INITIALIZED: state => (state.partiallyInitialized = true),

  UNSET_PARTIALLY_INITIALIZED: state => (state.partiallyInitialized = false),

  SET_COMPANY_SCOPE_UPDATED: state => (state.companyScopeUpdated = true),

  UNSET_COMPANY_SCOPE_UPDATED: state => (state.companyScopeUpdated = false),

  SET_IS_MASTER: (state, isMaster) => (state.isMaster = isMaster),

  SET_COMPANY: (state, company) => {
    const {
      logo,
      logo_small: logoSmall,
      name,
      phone_number: phoneNumber
    } = company

    state.company.logo = logo
    state.company.logoSmall = logoSmall
    state.company.name = name
    state.company.phoneNumber = phoneNumber
  },

  SET_MENU: (state, menu) => (state.menu = menu),

  SET_UNREAD_NEWS: (state, unreadNews) => (state.unreadNews = unreadNews),

  UPDATE_UNREAD_NEWS: (state, payload) => {
    state.unreadNews = state.unreadNews.filter((article) => article.id !== payload)
  },

  SET_USER: (state, user) => (state.user = user),

  RESET_USER_READ_NEWS_COUNT: (state) => (state.user.unread_news.unread_news_count = 0),

  SET_USER_CONFIG: (state, userConfig) => (state.userConfig = userConfig),

  SET_USER_CONFIG_KEY: (state, { key, value }) => (set(state.userConfig, key, value)),

  SET_USER_MENU: (state, userMenu) => (state.userMenu = userMenu),

  SET_COMPANY_SCOPE: (state, companyScope) => (state.companyScope = companyScope),

  SET_PERMISSIONS: (state, permissions) => (state.permissions = permissions),

  SET_LANGUAGE: (state, language) => (state.language = language),

  SET_TRACCAR_TOKEN: (state, token) => (state.traccarToken = token),

  SET_USER_SESSION_ID: (state, token) => (state.authUserSessionId = token),

  SET_DIGITAL_SENSORS_CONFIG: (state, config) => (state.digitalSensorsConfig = config)
}

export default {
  state,
  getters,
  actions,
  mutations
}
