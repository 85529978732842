import { api } from '@/global/services/api'

const state = {
  locations: [],
  viewConfig: {},
  pagination: {
    count: 0,
    currentPage: 1,
    perPage: 15,
    totalPages: 1,
    total: 0
  }
}

const getters = {
  list: state => state.locations,

  pagination: state => state.pagination,

  viewConfig: state => state.viewConfig
}

const actions = {

  async fetch ({ commit, state }, options) {
    try {
      const params = {
        pagination: options.pagination,
        sort: options.sort,
        filters: options.filters,
        company_scope_id: options.company_scope_id
      }

      const { config, data, pagination } = await api().locations.get('locations', params)

      commit('SET_LOCATIONS', data)
      commit('SET_PAGINATION', pagination)
      commit('SET_VIEW_CONFIG', config)
    }
    catch (e) {
      console.dir(e)
    }
  }
}

const mutations = {

  SET_VIEW_CONFIG: (state, payload) => (state.viewConfig = payload),

  SET_LOCATIONS: (state, payload) => (state.locations = payload),

  SET_PAGINATION ({ pagination }, payload) {
    const { count, currentPage, perPage, totalPages, total } = payload

    pagination.count = count || 0
    pagination.currentPage = currentPage || 1
    pagination.perPage = perPage || 0
    pagination.totalPages = totalPages || 1
    pagination.total = total || 0
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
